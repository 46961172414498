import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { scroller } from "react-scroll";
import Navbar from "components/Navbar/Navbar.js";
import Footer from "components/Footer.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import NHRCLogo from "assets/images/icon/NHRCLogo.svg";
import banner from "assets/images/banner/network.svg";
import GoogleMapReact from "google-map-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SError from "components/SError";
import { authenticationService } from "services/authentication.service.js";
import utils from "utils/utils";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
const swal = withReactContent(Swal);

export default function ContactUs(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [educationData, SetEducationData] = useState([]);
  const [selectEduData, setSelectEDUData] = useState(0);
  const [mapMode, setMapMode] = useState(false);
  const [complainMode, setComplainMode] = useState(false);
  //const [masterEdu, setMasterEDU] = useState({});
  const [selectEdu, setSelectEDU] = useState(0);
  const [errors, setError] = useState({});
  const [contactType, setContactType] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [userID, setUserID] = useState(null);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubdistrictList] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    utils.AccessInfo("ติดต่อเรา", "/contact/ContactUs", "F", currentUser);
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }

    setUserID(userid);
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getProvince")
      .then((response) => {
        //provinceList = [...response.data];
        setProvinceList(response.data);
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getDistrict")
          .then((response) => {
            setDistrictList(response.data);
            axios
              .get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict")
              .then((response) => {
                setSubdistrictList(response.data);
                axios
                  .get(
                    process.env.REACT_APP_APIURL + "/api-web/searchContactUs",
                    {
                      params: { status: 1 },
                    }
                  )
                  .then((response) => {
                    SetEducationData(response.data);
                    if (typeof response.data[0] !== "undefined") {
                      setSelectEDUData(response.data[0]);
                      //setMasterEDU(response.data[0]);
                      //console.log(response.data.length);
                      for (var i = 0; i < response.data.length; i++) {
                        if (Number(response.data[i].IsMainBranch) === 1) {
                          setSelectEDU(response.data[i].ContactID);
                          setSelectEDUData(response.data[i]);
                        }
                      }
                    }
                    axios
                      .get(
                        process.env.REACT_APP_APIURL +
                          "/api-web/getContactUsType"
                      )
                      .then((response) => {
                        setContactType(response.data);
                        setLoading(false);
                      });
                  });
              });
          });
      });
  }, []); /* <-- add this for run once*/
  const ContactTypeSelect = contactType.map((item) => (
    <option key={item.ContactTypeID} value={item.ContactTypeID}>
      {language === "Thai" ? item.ContactTypeNameTH : item.ContactTypeNameEN}
    </option>
  ));

  const MasterbranchList = educationData
    .filter((el) => {
      return Number(el.IsMainBranch) === 1;
    })
    .map((item, i) => (
      <li
        className={
          selectEdu === item.ContactID
            ? "row mt-2 btn btn-list active"
            : "row mt-2 btn btn-list"
        }
        key={item.ContactID}
        onClick={(e) => {
          setSelectEDU(item.ContactID);
          setSelectEDUData(item);
          setComplainMode(true);
        }}
      >
        <span className="title text-dark-blue">
          <i className="fas fa-map-marker-alt mr-2"></i>
          {language === "Thai"
            ? item.OrgName
            : item.OrgNameEN === null
            ? item.OrgName
            : item.OrgNameEN}{" "}
        </span>
        <span className="detail">
          {" "}
          {language === "Thai"
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN === null
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN + DisplayAddress(item)}{" "}
        </span>
        <span className="detail">
          {" "}
          {language === "Thai"
            ? "เบอร์โทรศัพท์ : " + item.Phone
            : "Phone : " + item.Phone}
        </span>
      </li>
    ));
  const EducationList = educationData
    .filter((el) => {
      return Number(el.IsMainBranch) === 0;
    })
    .map((item, i) => (
      <li
        className={
          selectEdu === item.ContactID
            ? "mt-2 btn btn-list active"
            : "mt-2 btn btn-list"
        }
        key={item.ContactID}
        onClick={(e) => {
          setSelectEDU(item.ContactID);
          setSelectEDUData(item);
          setComplainMode(true);
        }}
      >
        <span className="title text-dark-blue">
          <i className="fas fa-map-marker-alt mr-2"></i>
          {language === "Thai"
            ? item.OrgName
            : item.OrgNameEN === null
            ? item.OrgName
            : item.OrgNameEN}{" "}
        </span>
        <span className="detail">
          {language === "Thai"
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN === null
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN + DisplayAddress(item)}
        </span>
        <span className="detail">
          {" "}
          {language === "Thai"
            ? "เบอร์โทรศัพท์ : " + item.Phone
            : "Phone : " + item.Phone}
        </span>
      </li>
    ));
  const SouthEducationList = educationData
    .filter((el) => {
      return Number(el.IsMainBranch) === 2;
    })
    .map((item, i) => (
      <li
        className={
          selectEdu === item.ContactID
            ? "row mt-2 btn btn-list active"
            : "row mt-2 btn btn-list"
        }
        key={item.ContactID}
        onClick={(e) => {
          setSelectEDU(item.ContactID);
          setSelectEDUData(item);
          setComplainMode(true);
        }}
      >
        <span className="title text-dark-blue">
          <i className="fas fa-map-marker-alt mr-2"></i>
          {language === "Thai"
            ? item.OrgName
            : item.OrgNameEN === null
            ? item.OrgName
            : item.OrgNameEN}{" "}
        </span>
        <span className="detail">
          {language === "Thai"
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN === null
            ? item.DisplayAddress + DisplayAddress(item)
            : item.DisplayAddressEN + DisplayAddress(item)}
        </span>
        <span className="detail">
          {" "}
          {language === "Thai"
            ? "เบอร์โทรศัพท์ : " + item.Phone
            : "Phone : " + item.Phone}
        </span>
      </li>
    ));

  const renderMarkers = (map, maps) => {
    let newmarker = [];
    /*let mastermarker = new maps.Marker({
            position: { lat: 13.881569528975819, lng: 100.56532508472404 },
            map,
            title: 'สำนักงานใหญ่'
        });
        newmarker.push(mastermarker)*/
    educationData.map((item, i) => {
      let marker = new maps.Marker({
        position: { lat: item.Latitude, lng: item.Longitude },
        map,
        title: item.OrgName,
      });
      newmarker.push(marker);
    });
    return newmarker;
  };
  const contactTypeRef = React.createRef();
  const detailRef = React.createRef();
  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    if (Number(contactTypeRef.current.value) === -1) {
      formIsValid = false;
      serrors["contactType"] = "กรุณาเลือกประเภทการติดต่อ";
    }
    if (title.trim().length === 0) {
      formIsValid = false;
      serrors["title"] = "กรุณากรอกข้อมูล";
    }
    if (detailRef.current.value.trim().length === 0) {
      formIsValid = false;
      serrors["detail"] = "กรุณากรอกข้อมูล";
    }
    if (fullName.trim().length === 0) {
      formIsValid = false;
      serrors["fullName"] = "กรุณากรอกข้อมูล";
    }
    if (email.trim().length === 0) {
      formIsValid = false;
      serrors["email"] = "กรุณากรอกข้อมูล";
    }
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false;
      serrors["email"] = "รูปแบบของอีเมลไม่ถูกต้อง";
    }

    setError(serrors);
    //console.log(status)
    return formIsValid;
  };
  const saveData = (open) => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("contactID", selectEduData.ContactID);
      formData.append("contactTypeID", contactTypeRef.current.value);

      const selectType = contactType.find(
        (c) => Number(c.ContactTypeID) === Number(contactTypeRef.current.value)
      );
      if (typeof selectType !== "undefined")
        formData.append("contactType", selectType.ContactTypeNameTH);
      else formData.append("contactType", "");
      formData.append("title", title);
      formData.append("detail", detailRef.current.value);
      formData.append("fullName", fullName);
      formData.append("email", email);
      let userid = 0;
      if (userID !== null) {
        userid = userID;
      }
      formData.append("userID", userid);

      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/saveContactUSMessage",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              if (open) {
                window.location.reload();
              } else {
                props.history.push({ pathname: "/authen/contact/ContactUs" });
              }
            });
        });
    } else {
      scroller.scrollTo("error", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };
  function DisplayAddress(data) {
    const province = provinceList.find((item) => {
      return Number(item.ProvinceID) === Number(data.ProvinceID);
    });
    const district = districtList.find((item) => {
      return Number(item.DistrictID) === Number(data.DistrictID);
    });
    const subDistrict = subDistrictList.find((item) => {
      return Number(item.SubDistrictID) === Number(data.SubDistrictID);
    });
    let tmpaddress = ""; //'เลขที่ ' + data.NetHouseNo;
    if (Number(data.ProvinceID) === 1) {
      //console.log(province)
      if (typeof subDistrict !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " แขวง" + subDistrict.SubDistrictTH
            : subDistrict.SubDistrictEN === null
            ? " " + subDistrict.SubDistrictTH + " Subdistrict"
            : " " + subDistrict.SubDistrictEN + " Subdistrict";
      if (typeof district !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " เขต" + district.DistrictTH
            : district.DistrictEN === null
            ? " " + district.DistrictTH + " District"
            : " " + district.DistrictEN + " District";
      if (typeof province !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " " + province.ProvinceTH
            : " " + province.ProvinceEN === null
            ? " " + province.ProvinceTH
            : " " + province.ProvinceEN;
      //console.log(tmpaddress)
    } else {
      if (typeof subDistrict !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " ตำบล" + subDistrict.SubDistrictTH
            : subDistrict.SubDistrictEN === null
            ? " " + subDistrict.SubDistrictTH + " Subdistrict"
            : " " + subDistrict.SubDistrictEN + " Subdistrict";
      if (typeof district !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " อำเภอ" + district.DistrictTH
            : district.DistrictEN === null
            ? " " + district.DistrictTH + " District"
            : " " + district.DistrictEN + " District";
      if (typeof province !== "undefined")
        tmpaddress +=
          language === "Thai"
            ? " จังหวัด" + province.ProvinceTH
            : province.ProvinceEN === null
            ? " " + province.ProvinceTH + " Province"
            : " " + province.ProvinceEN + " Province";
    }
    tmpaddress += " " + data.PostCode;
    return tmpaddress;
  }
  if (isLoading) {
    return <></>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"ติดต่อเรา"}
          eheader={"Contact us"}
          bannerkey="CONTACT"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/contact/ContactUs",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "ติดต่อเรา" : "Contact Us"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page contactUs">
        <div className="container">
          <section className="edit-profile">
            <div className="content-data">
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  {complainMode === false && (
                    <>
                      <h3>
                        {language === "Thai"
                          ? "สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ"
                          : "National Human Rights Commission of Thailand"}
                      </h3>
                      <ul>{MasterbranchList}</ul>
                      <h3>
                        {language === "Thai"
                          ? "สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                          : "The Center for Human Rights Study and Coordination, Southern Border Provinces"}
                      </h3>
                      <ul>{SouthEducationList}</ul>
                      {/* <h3>
                        {language === "Thai"
                          ? "สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                          : "Center for Human Rights Study and Coordination"}
                      </h3> */}
                      <ul>{EducationList}</ul>
                    </>
                  )}
                  {complainMode === true && (
                    <>
                      <div className="card">
                        <div className="card-body row">
                          <div className="col-12 text-center" style={{}}>
                            <img
                              src={
                                selectEduData.LogoImage == null
                                  ? NHRCLogo
                                  : selectEduData.LogoImage
                              }
                              className="NHRCLogo"
                              alt="Organization Logo"
                              style={{ maxHeight: "77px" }}
                            />
                          </div>
                          <div className="col-12 ">
                            <h3 className="text-primary text-center">
                              <i className="fas fa-map-marker-alt mr-2"></i>
                              {selectEduData.OrgName}
                            </h3>
                          </div>
                          <div className="col-12 ">
                            {selectEduData.DisplayAddress !== "" ? (
                              <p>
                                {selectEduData.DisplayAddress +
                                  DisplayAddress(selectEduData)}
                              </p>
                            ) : null}
                            {selectEduData.Phone !== "" ? (
                              <p>
                                <i className="fas fa-phone mr-2"></i>
                                {language === "Thai"
                                  ? "โทรศัพท์"
                                  : "Phone"} : {selectEduData.Phone}
                              </p>
                            ) : null}
                            {selectEduData.Fax !== "" ? (
                              <p>
                                <i className="fas fa-fax mr-2"></i>
                                {language === "Thai" ? "โทรสาร" : "Fax"} :{" "}
                                {selectEduData.Fax}
                              </p>
                            ) : null}
                            {selectEduData.Email !== "" ? (
                              <p>
                                <i className="far fa-envelope mr-2"></i>
                                {language === "Thai" ? "อีเมล" : "Email"} :{" "}
                                <a href={"mailto:" + selectEduData.Email}>
                                  {selectEduData.Email}
                                </a>
                              </p>
                            ) : null}
                            {selectEduData.Phone !== "" ? (
                              <p>
                                <i className="fas fa-headphones mr-2"></i>
                                {language === "Thai"
                                  ? "สายด่วนร้องเรียน"
                                  : "Hotline"}{" "}
                                {selectEduData.Phone} (ในเวลาราชการ)
                              </p>
                            ) : null}
                            {selectEduData.URL !== "" ? (
                              <p>
                                <i className="fas fa-external-link-alt mr-2"></i>
                                {language === "Thai"
                                  ? "ลิงก์ที่เกี่ยวข้อง"
                                  : "Link"}{" "}
                                :{" "}
                                <a
                                  href={selectEduData.URL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {selectEduData.URL}
                                </a>
                              </p>
                            ) : null}

                            <p style={{ marginLeft: 25 }}>Social Media</p>
                            <p style={{ marginLeft: 25 }}>
                              {selectEduData.Facebook !== "" ? (
                                <a
                                  href={selectEduData.Facebook}
                                  style={{ color: "#135EB7" }}
                                >
                                  <i className="fab fa-facebook-f mr-2"></i>
                                </a>
                              ) : null}
                              {selectEduData.Youtube !== "" ? (
                                <a
                                  href={selectEduData.Youtube}
                                  style={{ color: "#EA4335" }}
                                >
                                  <i className="fab fa-youtube mr-2"></i>
                                </a>
                              ) : null}
                            </p>
                          </div>

                          <div className="col-12 formContent">
                            <div className="sub-title ">
                              <h2 className="thai-header text-left">
                                <span className="orang content-underline">
                                  {language === "Thai"
                                    ? "ติดต่อ/สอบถาม"
                                    : "Contact US"}
                                </span>
                              </h2>
                            </div>
                            <input
                              type="text"
                              id="fullName"
                              name="fullName"
                              style={{ maxWidth: "100%" }}
                              placeholder={
                                language === "Thai"
                                  ? "ชื่อ - นามสกุล"
                                  : "Firstname - Lastname"
                              }
                              autoComplete="off"
                              value={fullName}
                              onChange={(e) => {
                                setFullName(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["fullName"]} />
                            <input
                              type="text"
                              id="email"
                              name="email"
                              style={{ maxWidth: "100%" }}
                              placeholder={
                                language === "Thai" ? "อีเมล" : "Email"
                              }
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["email"]} />
                            <select
                              className="custom-select"
                              ref={contactTypeRef}
                              style={{ minWidth: "350px" }}
                            >
                              <option value="-1">
                                {language === "Thai"
                                  ? "ประเภทการติดต่อ"
                                  : "Contact type"}
                              </option>
                              {ContactTypeSelect}
                            </select>
                            <SError error={errors["contactType"]} />
                            <input
                              type="text"
                              id="title"
                              name="title"
                              style={{ maxWidth: "100%" }}
                              placeholder={
                                language === "Thai" ? "เรื่อง" : "Subject"
                              }
                              autoComplete="off"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["title"]} />
                            <textarea
                              className="form-control"
                              name="detail"
                              id="detail"
                              rows="4"
                              ref={detailRef}
                              style={{ maxWidth: "100%" }}
                              placeholder={
                                language === "Thai" ? "รายละเอียด" : "Detail"
                              }
                            ></textarea>
                            <SError error={errors["detail"]} />
                          </div>
                          <div className="col-12 ">
                            <div className="pl-4 pr-4 text-center margintop40 formContent">
                              <input
                                type="submit"
                                className="fadeIn fourth btnSecondary"
                                style={{
                                  padding: "10px 36px",
                                  maxWidth: 150,
                                  height: 50,
                                }}
                                value={
                                  language === "Thai" ? "ย้อนกลับ" : "Back"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setComplainMode(
                                    false
                                  ); /*window.location.reload();sendTestData(true);*/
                                }}
                              />
                              <input
                                type="button"
                                className="fadeIn fourth btnPimary"
                                style={{
                                  padding: "10px 36px",
                                  maxWidth: 150,
                                  height: 50,
                                }}
                                value={
                                  language === "Thai" ? "ส่งข้อมูล" : "Submit"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  saveData();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="col-sm-6 col-xs-12"
                  style={{ height: "100vh", width: "100%" }}
                >
                  <div className=" clearfix">
                    <span
                      className="float-right ml-2"
                      style={{ lineHeight: 2 + "rem" }}
                    >
                      {" "}
                      Image
                    </span>
                    <label className="switch float-right">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setMapMode(!mapMode);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span
                      className="float-right mr-2"
                      style={{ lineHeight: 2 + "rem" }}
                    >
                      {" "}
                      Map
                    </span>
                  </div>
                  {mapMode === false && (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyBmCzwy3dIFgheFGeSr8jKOVcxF-x2jQ5w",
                      }}
                      center={{
                        lat: selectEduData.Latitude,
                        lng: selectEduData.Longitude,
                      }}
                      defaultZoom={11}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        renderMarkers(map, maps)
                      }
                    ></GoogleMapReact>
                  )}
                  {mapMode === true && (
                    <a>
                      <img
                        onClick={handleClickOpen}
                        src={selectEduData.MapImage}
                        className="contactusMap"
                        alt="สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        // className={classes.root}
      >
        <DialogTitle id="responsive-dialog-title">แผนที่</DialogTitle>
        <DialogContent>
          <DialogContent>
            <img
              src={selectEduData.MapImage}
              className="contactusMap"
              alt="สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}
