import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import utils from "utils/utils";
import wordIcon from "assets/images/icon/word.svg";
import excelIcon from "assets/images/icon/excel.svg";
import pptIcon from "assets/images/icon/ppt.svg";
import pdfIcon from "assets/images/icon/pdf.svg";
import zipIcon from "assets/images/icon/zip.svg";
import unknowIcon from "assets/images/icon/unknowfile.svg";

export default function AttatchmentList(props) {
  //const [isLoading, setLoading] = useState(true);
  //console.log(props);
  /*if(typeof props.fileList !== "undefined")
    setLoading(false)*/
  /*if(props.fileList!=null)
    setLoading(false)*/
  /*const fileListData = useMemo(() => {
        setLoading(false)
        return props.fileList;
    }, []);*/

  /*if (isLoading) {
        return (<></>);
    }*/
    const [attatchCount, setAttachCount] = useState(0);
    useEffect(() => {
      const vdolist = props.fileList.filter((member) => {
        return member.AttachmentType !== "VDO" && member.AttachmentType !== "IMG"
      })
      setAttachCount(vdolist.length);
    }, []);
  const FileList = props.fileList?.map(
    (fileItem) =>
          fileItem.AttachmentType !== "VDO" &&
          fileItem.AttachmentType !== "IMG" && (
            <div style={{height: "6rem"}} className="card-item" key={fileItem.AttachmentID}>
              <div className="row list-download">
                <div className="col-md-8 box-download-left">
                    <img
                      src={
                        fileItem.AttachmentType === "PDF"
                          ? pdfIcon
                          : fileItem.AttachmentType === "ZIP"
                            ? zipIcon
                            : fileItem.AttachmentType === "WORD"
                              ? wordIcon
                              : fileItem.AttachmentType === "PPT"
                                ? pptIcon
                                : fileItem.AttachmentType === "XLS"
                                  ? excelIcon
                                  : unknowIcon
                      }
                      className="icon"
                      alt="file icon"
                    />
                    <span style={{ fontSize: "18px", overflow: "hidden", width: "80%", whiteSpace: "nowrap" }} className="file-name">{`${fileItem.FilenameOld.substring(0, 85)} ${fileItem.FilenameOld.length > 84 ? "..." : ""} `}</span>
                    { fileItem.Remark && 
                      <p style={{ marginTop: "9px", fontSize: "18px" }}> หมายเหตุ: {fileItem.Remark}</p>
                    }
                    
                </div>
                <div className="col-md-4 box-download-right float-right">
                    <span className="time-ago"><i className="far fa-clock" /></span>
                    <span style={{ fontSize: "18px" }}>{`${utils.MariatoThaiDateStringShort(fileItem.CreatedDate)}`}</span>
                    |
                    <a href={fileItem.AttachmentPath} className="view-download text-gray"><i className="fas fa-download" /></a>
                    <span style={{ fontSize: "18px" }}>({utils.Bytes2Size(fileItem.Filesize)})</span>
                </div>
              </div>
            </div>
          )
  );
  return (
    <div className="cardOut">
      <div className="cardIn">{attatchCount==0?'ไม่พบข้อมูล':FileList}</div>
    </div>
  );
}
