import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewMenu(props) {
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});

    const [nameTH, setNameTH] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [menuType, setMenuType] = useState('');
    const [menuSide, setMenuSide] = useState('BE');
    const [parentMenu, setParentMenu] = useState(0);
    const [link, setLink] = useState('');
    const [windowType, setWindowType] = useState('C');
    const [icon, setIcon] = useState('');
    const [status, setStatus] = useState('Y');
    const [menuList, setMenuList] = useState([]);

    function getSubmenu(item, allmenu, level) {
        const childmenu = allmenu.filter(x => x.ParentID === item.MenuID);
        //console.log(childmenu)
        if (childmenu.length > 0) {
            for (let i = 0; i < childmenu.length; i++) {
                childmenu[i].submenu = getSubmenu(childmenu[i], allmenu, level + 1);
            }
            //console.log(childmenu)
            return childmenu;
        }
        else {
            return [];
        }
    }
    useEffect(() => {
        //console.log(props.position.state.id)
        let menu = [];
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
            let data = response.data;
            for (let i = 0; i < data.length; i++) {
                if (Number(data[i].ParentID) === 0) {
                    let mainmenu = data[i];
                    mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                    //console.log(mainmenu)
                    menu.push(mainmenu)
                }
            }
            setMenuList(menu);
        }).then(() => { ChangeMenuType('BE') })

    }, []); /* <-- add this for run once*/
    const ChangeMenuType = (value) => {
        let menu = [];

        if (value === 'FE') {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].Side === 'FE' || data[i].Side === 'ME') {
                        if (Number(data[i].ParentID) === 0) {
                            let mainmenu = data[i];
                            mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                            menu.push(mainmenu)
                        }
                    }
                }
                setMenuList(menu);
            })
        }
        else if (value === 'BE') {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].Side === 'BE') {
                        if (Number(data[i].ParentID) === 0) {
                            let mainmenu = data[i];
                            mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                            menu.push(mainmenu)
                        }
                    }
                }
                setMenuList(menu);
            })
        }
        else {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    if (Number(data[i].ParentID) === 0) {
                        let mainmenu = data[i];
                        mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                        //console.log(mainmenu)
                        menu.push(mainmenu)
                    }
                }
                setMenuList(menu);
            })
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (nameTH.trim().length === 0) {
            formIsValid = false;
            serrors["nameTH"] = "กรุณาระบุชื่อ";
        }
        /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุรายละเอียด";
        }*/
        if (menuType.trim().length === 0) {
            formIsValid = false;
            serrors["menuType"] = "กรุณาเลือกประเภทเมนู";
        }
        if (menuSide.trim().length === 0) {
            formIsValid = false;
            serrors["menuSide"] = "กรุณาระบุรายละเอียด";
        }
        if (windowType.trim().length === 0) {
            formIsValid = false;
            serrors["windowType"] = "กรุณาระบุรายละเอียด";
        }
        setError(serrors);
        //console.log(serrors)
        if (formIsValid === false) {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                offset: -50,
                smooth: "easeInOutQuart",
            });
        }
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("userID", currentUser.UserID);
            formData.append("nameTH", nameTH);
            formData.append("nameEN", nameEN);
            formData.append("menuType", menuType);
            formData.append("parentMenu", parentMenu);
            formData.append("link", link);
            formData.append("windowType", windowType);
            formData.append("menuSide", menuSide);
            formData.append("icon", icon);
            formData.append("status", status);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveMenu', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/menu/MenuManagement" });
                        }
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                offset: -50,
                smooth: "easeInOutQuart",
            });
        }
    };
    const MenuList = () => {
        let option = <></>;
        for (let i = 0; i < menuList.length; i++) {
            let item = menuList[i];
            let subdiv = <option value={item.MenuID} >{item.NameMenuTH}</option>
            for (let j = 0; j < item.submenu.length; j++) {
                let jitem = item.submenu[j];
                let subdiv = <option value={jitem.MenuID} >--{jitem.NameMenuTH}</option>
                for (let k = 0; k < jitem.submenu.length; k++) {
                    let kitem = jitem.submenu[k];
                    let subdiv = <option value={kitem.MenuID} >----{kitem.NameMenuTH}</option>
                    option = (<>{option}{subdiv}</>)
                }
                option = (<>{option}{subdiv}</>)
            }
            option = (<>{option}{subdiv}</>)
        }
        return option;
    }

    const cancel = () => {
        props.history.push({ pathname: "/admin/menu/MenuManagement" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/menu/MenuManagement" }}>การจัดการเมนู</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/menu/NewMenu" }}>{"สร้าง"}</Link></>}

            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="alert alert-dark col-12" role="alert">
                                    ข้อมูลทั่วไป
                                </div>
                                <div className="col-6">
                                    <label htmlFor="nameTH" className="required">ชื่อเมนูภาษาไทย</label>
                                    <input type="text" className="form-control " id="nameTH" value={nameTH} name="nameTH" placeholder="กรุณากรอก" onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                    <SError error={errors["nameTH"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="nameEN">ชื่อเมนูภาษาอังกฤษ</label>
                                    <input type="text" className="form-control " id="nameEN" value={nameEN} name="nameEN" placeholder="กรุณากรอก" onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                    <SError error={errors["nameEN"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="menuType" className="required">เลือกประเภทลิงก์เมนู</label>
                                    <select className="form-control mb-2 mr-2" id="menuType" name="menuType" value={menuType} onChange={(e) => { setMenuType(e.currentTarget.value); }} >
                                        <option value="" >กรุณาเลือก</option>
                                        <option value="A" >ลิ้งค์ไปกลุ่มcategory</option>
                                        <option value="B" >Link เนื้อหา</option>
                                        <option value="C" >Link URL</option>
                                    </select>
                                    <SError error={errors["menuType"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="parentMenu" className="required">เมนูแม่</label>
                                    <select className="form-control mb-2 mr-2" id="parentMenu" name="parentMenu" value={parentMenu} onChange={(e) => { setParentMenu(e.currentTarget.value); }} >
                                        <option value="0" >เป็นเมนูหลัก</option>
                                        <MenuList />
                                    </select>
                                    <SError error={errors["menuType"]} />
                                </div>
                                {(menuType === 'B' || menuType === 'C') && (<div className="col-6">
                                    <label htmlFor="link" className="required">{menuType === 'B' ? 'เมนู link เนื้อหา' : 'URL'}</label>
                                    <input type="text" className="form-control " id="link" value={link} name="link" placeholder="กรุณากรอก" onChange={(e) => { setLink(e.currentTarget.value) }} />
                                    <SError error={errors["link"]} />
                                </div>)
                                }
                                <div className="col-6">
                                    <label htmlFor="icon">Icon</label>
                                    <input type="text" className="form-control " id="icon" value={icon} name="icon" placeholder="กรุณากรอก" onChange={(e) => { setIcon(e.currentTarget.value) }} />
                                    <SError error={errors["icon"]} />
                                </div>
                                <div className="col-6 my-bottom">
                                    <a href="https://fontawesome.com/v5.15/icons?d=gallery&p=2&q=list&m=free" target="_blank">{"ข้อมูลไอคอน"}</a>
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">ประเภทเมนู</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="menuSide" id="menuSide1" checked={menuSide === 'FE'} onChange={(e) => { setMenuSide('FE'); ChangeMenuType('FE'); }} />
                                        <label className="form-check-label" htmlFor="menuSide1"> หน้าบ้าน</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="menuSide" id="menuSide2" checked={menuSide === 'BE'} onChange={(e) => { setMenuSide('BE'); ChangeMenuType('BE') }} />
                                        <label className="form-check-label" htmlFor="menuSide2"> หลังบ้าน</label>
                                    </div>
                                    <SError error={errors["menuSide"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">การแสดงผล</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="windowType" id="windowType1" checked={windowType === 'N'} onChange={(e) => { setWindowType('N') }} />
                                        <label className="form-check-label" htmlFor="windowType1"> หน้าต่างใหม่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="windowType" id="windowType2" checked={windowType === 'C'} onChange={(e) => { setWindowType('C') }} />
                                        <label className="form-check-label" htmlFor="windowType2"> หน้าต่างปัจจุบัน</label>
                                    </div>
                                    <SError error={errors["windowType"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status1" checked={status === 'Y'} onChange={(e) => { setStatus('Y') }} />
                                        <label className="form-check-label" htmlFor="status1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status2" checked={status !== 'Y'} onChange={(e) => { setStatus('N') }} />
                                        <label className="form-check-label" htmlFor="status2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
