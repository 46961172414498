import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';

const swal = withReactContent(Swal);
const Comment = (props) => {

    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [sourceId, setSourceId] = useState(null);
    const [type, setType] = useState(null);
    const [name, setName] = useState(null);
    const [module, setModule] = useState(null); 

    useEffect(() => {
        const id = props.location.state.sourceId;
        const type = props.location.state.type;
        const name = props.location.state.name;
        setSourceId(id)
        setType(type)
        setName(name)
       
        if(rowData.length === 0 && id && type){
            loadData(id, type);
            if(type === 'ACTIVITY'){
                setModule({
                    name: 'จัดการกิจกรรม',
                    link: '/admin/activity/Activity'
                })
            }else if( type === 'BOOK'){
                setModule({
                    name: 'จัดการคลังความรู้ด้านสิทธิมนุษยชน',
                    link: '/admin/elibrary/ElibraryList'
                })
            }else if( type === 'NEWS'){
                setModule({
                    name: 'การจัดการการประชาสัมพันธ์',
                    link: '/admin/news/News'
                })
            }
        }
        
    }, [])
    
    const handleDelete = (params) => {
        swal.fire({
            title: "ยืนยันการลบข้อมูล",
            showDenyButton: true,
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSingleRow(params);
            }
        })
    };

    const handleUpdate = (params) => {
        swal.fire({
            title: "ยืนยันการระงับความคิดเห็นนี้",
            showDenyButton: true,
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                updateRow(params);
            }
        })
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const getRowHeight = (params) => {
        return 80;
    };

    const createYearCellRenderer = params => {
        if (params.value == null)
            return '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p></span>';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };

    const updateYearCellRenderer = (params) => {
        if (params.value == null) return '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' + utils.MariatoThaiDateString(params.data.UpdatedDate) + '</p></span>';    
        return (
          '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
          utils.MariatoThaiDateString(params.data.UpdatedDate) +
          '</p><p style="line-height:20px;margin-bottom: -4px;">' +
          params.value +
          "<p></span>"
        );
      };

    const RenderDescription = (params) => {
        const desc = params.data.Description;
        const parentId = params.data.ParentCommentID;
        let parent = [];
        if(parentId) {
            return (<><p>{desc} { parentId > 0 && parent ? <span style={{color: 'red'}}>( เป็นรายการย่อย )</span> : ''}</p>  </>)
        }else{
            return (<><p>{desc}</p> </>)
        }
       
    }
    
    const RowTool = params => {
        const isDeleted = params.data.IsDeleted;
        let tool = null;
        tool = (
            <span className="row-tool">
                <button type="button" className={`btn btn-${isDeleted === true ? 'secondary' : 'primary'} btn-sm wd-40`} onClick={() => { handleUpdate(params); }} >{ isDeleted === true ? 'ถูกระงับ' : 'กำลังแสดง'}</button>
                <button type="button" className="btn btn-danger btn-sm wd-40" onClick={() => { handleDelete(params); }} >ลบ</button>
            </span>
        );
        return tool;
    };

    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };

    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };

    const loadData = async (id, type) => {
        setTimeout(() => {
            axios.get(process.env.REACT_APP_APIURL +`/api-web/commentListByType?sourceID=${id}&type=${type}`)
                    .then((response) => {
                        setRowData(response.data);
                    })
        }, 100)
        
    }

    const updateRow = async (params) => {
        const isDeleted = params.data.IsDeleted;
        const url = isDeleted === true ? '/api-web/restoreComment' : '/api-web/deleteComment';
        const sourceId = props.location.state.sourceId;
        const type = props.location.state.type;
        try{
            await axios.post(process.env.REACT_APP_APIURL + url, { id: params.data.CommentID, userID: params.data.UserID})
            await loadData(sourceId, type);
        }catch(err){
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: err
            })
        }
    };

    const deleteSingleRow = async params => {
        const sourceId = props.location.state.sourceId;
        const type = props.location.state.type;
        try{
            await axios.post(process.env.REACT_APP_APIURL + '/api-web/delete/comment/permanently', { id: params.data.CommentID})
            let timerInterval;
            swal.fire({
                title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                timer: 1000, timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                        const content = Swal.getHtmlContainer()
                        if (content) {
                            const b = content.querySelector('b')
                            if (b) {
                                b.textContent = Swal.getTimerLeft()
                            }
                        }
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            })
            await loadData(sourceId, type)
        }catch(err){
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: err
            })
        }
    };

    const voidClick = (e) => {
        e.preventDefault()
    }

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={""} path={
                <><span><i className="fas fa-angle-right"></i></span>
                    <Link className="active" to={{ pathname: module?.link }}>{module?.name}</Link>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a style={{textOverflow: 'unset', overflow: 'unset'}} href="/" onClick={voidClick}>{`การจัดการ Comment ${module?.name} ของหัวข้อ "${name}"`}</a>
                </>}
            />
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'CommentID',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}
                                modules={AllCommunityModules}
                                frameworkComponents={{
                                    rowTool: RowTool,
                                    renderDescription: RenderDescription,
                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                pagination={true}
                                paginationPageSize={10}
                            >
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={40} maxWidth={100} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="CommentID" headerName="รหัสอ้างอิง" minWidth={40} maxWidth={100} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="ParentCommentID" headerName="รหัสอ้างอิงความสัมพันธ์" minWidth={40} maxWidth={180} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="Description" headerName="ความคิดเห็น" cellRenderer="renderDescription" minWidth={420} />
                                <AgGridColumn
                                    field="CreateFullname"
                                    headerName="วันที่สร้าง"
                                    cellRenderer={createYearCellRenderer}
                                    minWidth={150}
                                    wrapText={true}
                                    cellClass="cell-border cell-vertical-align-text-left"
                                />
                                <AgGridColumn
                                    field="UpdateFullname"
                                    headerName="วันที่แก้ไข"
                                    cellRenderer={updateYearCellRenderer}
                                    minWidth={150}
                                    wrapText={true}
                                    cellClass="cell-border cell-vertical-align-text-left"
                                />
                                <AgGridColumn field="CommentID" headerName="จัดการ" cellRenderer="rowTool" minWidth={130} sortable={false} filter={false} resizable={false} />
                            </AgGridReact>
                            <div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={10} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="100">25</option>
                                    <option value="500">50</option>
                                    <option value="1000">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Comment