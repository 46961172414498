import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import utils from "utils/utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
/*import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';*/
import AdminBanner from "components/Banner/AdminBanner";
//const swal = withReactContent(Swal)

export default function ContactUsReorder(props) {
  //const currentUser = authenticationService.currentUserValue;
  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  //const [newsCat, setNewsCat] = useState([]);
  const [catOne, setCatOne] = useState(null);
  const [orderCatOne, setOrderCatOne] = useState(null);
  const [catTwo, setCatTwo] = useState(null);
  const [orderCatTwo, setOrderCatTwo] = useState(null);
  const [catThree, setCatThree] = useState(null);
  const [orderCatThree, setOrderCatThree] = useState(null);
  const [category, setCategory] = useState("-1");
  useEffect(() => {
    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/newsCat')
            .then((response) => {
                setNewsCat(response.data);
            });*/
  }, []); /* <-- add this for run once*/

  const onGridReady = (params) => {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    const updateData = (data) => {
      setRowData(data);
    };
    

    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getContactUs")
      .then((response) => {
        // updateData(response.data);
        let one = [];
        let orderOne = [];
        let two = [];
        let orderTwo = [];
        let three = [];
        let orderThree = [];
        if (response.data.length > 0) {
          response.data.map((item) => {
            if (item.IsMainBranch === 0) {
              one.push(item);
              orderOne.push(item.ordering);
            } else if (item.IsMainBranch === 1) {
              two.push(item);
              orderTwo.push(item.ordering);
            } else {
              three.push(item);
              orderThree.push(item.ordering);
            }
          });
        }
        setCatOne(one);
        setOrderCatOne(orderOne);
        setCatTwo(two);
        setOrderCatTwo(orderTwo);
        setCatThree(three);
        setOrderCatThree(orderThree);
      });
  };

  const fetchData = () => {
    const updateData = (data) => {
      setRowData(data);
    };
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getContactUs")
      .then((response) => {
        updateData(response.data);
        let one = [];
        let orderOne = [];
        let two = [];
        let orderTwo = [];
        let three = [];
        let orderThree = [];
        if (response.data.length > 0) {
          response.data.map((item) => {
            if (item.IsMainBranch === 0) {
              one.push(item);
              orderOne.push(item.ordering);
            } else if (item.IsMainBranch === 1) {
              two.push(item);
              orderTwo.push(item.ordering);
            } else {
              three.push(item);
              orderThree.push(item.ordering);
            }
          });
        }
        setCatOne(one);
        setOrderCatOne(orderOne);
        setCatTwo(two);
        setOrderCatTwo(orderTwo);
        setCatThree(three);
        setOrderCatThree(orderThree);
      });
  };

  const getRowHeight = (params) => {
    //console.log(params.data.rowHeight);
    //return params.data.rowHeight;
    return 80;
  };
  const statusRenderer = (params) => {
    if (params.data.Status === 1) return "เผยแพร่";
    return "ไม่เผยแพร่";
  };
 
  const createYearCellRenderer = (params) => {
    if (params.value == null)
      return (
        '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' +
        utils.MariatoThaiDateString(params.data.CreatedDate) +
        "</p></span>"
      );
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.CreatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const updateYearCellRenderer = (params) => {
    if (params.value == null) return "";
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.UpdatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };

  var hashValueGetter = function (params) {
    return params.node.rowIndex + 1;
  };

  const onRowDragEnd = (event) => {
    
    let orderItem = [];
    gridApi.forEachNode((rowNode, index) => {
      // console.log("rowNode", rowNode);
      orderItem.push(rowNode.data.ContactID);
    });
    if (category === "-1") {
      // axios
      //   .put(
      //     process.env.REACT_APP_APIURL + "/api-web/updateContactUsOrdering",
      //     { ordering: orderItem },
      //     {}
      //   )
      //   .then((response) => {
      //     fetchData();
      //   });
      setRowData(null);
    } else if (category === "0") {
      let order = orderItem.concat(orderCatTwo).concat(orderCatThree);
      axios
        .put(
          process.env.REACT_APP_APIURL + "/api-web/updateContactUsOrdering",
          { ordering: order },
          {}
        )
        .then((response) => {
          fetchData();
        });
    } else if (category === "1") {
      let order = orderItem.concat(orderCatOne).concat(orderCatThree);
      axios
        .put(
          process.env.REACT_APP_APIURL + "/api-web/updateContactUsOrdering",
          { ordering: order },
          {}
        )
        .then((response) => {
          fetchData();
        });
    } else {
      let order = orderItem.concat(orderCatOne).concat(orderCatTwo);
      axios
        .put(
          process.env.REACT_APP_APIURL + "/api-web/updateContactUsOrdering",
          { ordering: order },
          {}
        )
        .then((response) => {
          fetchData();
        });
    }
  };

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"จัดการการติดต่อ"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              จัดการการติดต่อ
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{ pathname: "/admin/contact/ContactUs" }}
            >
              {"จัดการการติดต่อ"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/contact/ContactUsReorder" }}
            >
              {"จัดลำดับ"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="form-inline form-border">
          <select
            className="form-control mb-2 col-4  col-lg-3 col-md-3"
            // ref={statusRef}
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option value="-1">หมวดหมู่</option>
            <option value="1">สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</option>
            <option value="2">
              สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติพื้นที่ภาคใต้
            </option>
            <option value="0">
              สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค
            </option>
          </select>
        </form>
      </div>
      <div className="col-12">
        <div className="ag-theme-alpine" style={{ height: 100 + "vh" }}>
          <AgGridReact
            autoGroupColumnDef={{
              headerName: "Group",
              minWidth: 30,
              field: "CategoriesID",
              valueGetter: function (params) {
                if (params.node.group) {
                  return params.node.key;
                } else {
                  return params.data[params.colDef.field];
                }
              },
              headerCheckboxSelection: true,
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: { checkbox: true },
            }}
            modules={AllCommunityModules}
            frameworkComponents={{}}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              //cellClass: "cell-border cell-vertical-align-text-left",
            }}
            getRowHeight={getRowHeight}
            onGridReady={onGridReady}
            rowData={
              category === "-1"
                ? rowData
                : category === "0"
                ? catOne
                : category === "1"
                ? catTwo
                : catThree
            }
            rowSelection={"multiple"}
            rowDragManaged={true}
            pagination={false}
            //paginationPageSize= {10}
            onRowDragEnd={onRowDragEnd}
          >
            <AgGridColumn
              field="Ordering"
              headerName="เรียงลำดับ"
              minWidth={10}
              rowDrag={true}
            />
            <AgGridColumn
              field=""
              valueGetter={hashValueGetter}
              headerName="ลำดับ"
              minWidth={70}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn
              field="OrgName"
              headerName="ชื่อรายการ"
              minWidth={180}
            />


            <AgGridColumn
              field="Published"
              headerName="สถานะ"
              cellRenderer={statusRenderer}
              sortable={true}
              minWidth={100}
            />
            <AgGridColumn
              field="CreateFullname"
              headerName="ผู้สร้าง"
              cellRenderer={createYearCellRenderer}
              minWidth={150}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn
              field="UpdateFullname"
              headerName="ผู้แก้ไข"
              cellRenderer={updateYearCellRenderer}
              minWidth={150}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
          </AgGridReact>
        </div>
      </div>
    </section>
  );
}
