import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import { authenticationService } from 'services/authentication.service.js';
import SError from 'components/SError';
//import { ChangeHistoryOutlined } from "@material-ui/icons";
const swal = withReactContent(Swal)
export default function Permission(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [errors, setError] = useState({});
    const errors = {};
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [oldData, setOldData] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [roleID, setRoleID] = useState(0);
    useEffect(() => {
        setRoleID(props.location.state.id);
        //console.log(props.location.state.id)
        /*axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElibraryCat', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
            });     */
        axios.get(process.env.REACT_APP_APIURL + '/api-web/memberGroup').then((response) => {

            //console.log(response.data);
            setRoleList(response.data);

        })

    }, []); /* <-- add this for run once*/


    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/backendsMenus', { params: { id: props.location.state.id } }).then((response) => {
            //console.log(response.data)
            updateData(response.data);
            let tmp = [];
            response.data.forEach((item) => {
                if (item.Permission != null) {
                    //if(Number(item.GroupID)===Number(roleID))
                    tmp.push(item);
                }
            });
            setOldData(tmp);
        })

    };
    /*var checkboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };
    var headerCheckboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };*/
    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 80;
    };

    /*const createYearCellRenderer = params => {
        if (params.value == null)
            return '<span style="line-height:0.75rem;"><p>' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p></span>';
        return '<span style="line-height:0.75rem;"><p>' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p><p>' + params.value + '<p></span>';
    };*/
    const updateYearCellRenderer = params => {
        if (params.value == null)
            return '';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.UpdatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };

    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };
    /*function handleChange(e,param){
        alert(e.target.checked);
    }*/
    const checkboxRenderer = (params) => {
        //return "<input type='checkbox' ${params.Permission ==='1111'? 'checked' : ''} ${onChange={(e)=>{handleChange(e,params);}}} />";
        //const data = params;
        //let resultdiv= (<><input type='checkbox' onChange={(e)=>{handleChange(e,data);}} /></>);
        //return resultdiv;
        var input = document.createElement("input");
        input.type = "checkbox";
        input.checked = params.value;

        input.addEventListener("click", function (event) {
            //console.log(event.target.checked);
            params.value = !params.value;
            if (event.target.checked === true)
                params.node.data.Permission = '1111';
            else
                params.node.data.Permission = null;
            /*console.log(
              that.state.rowData.reduce(
                (acc, obj) => (acc.selected || acc) + ", " + obj.selected
              )
            );*/
        });
        return input;
    };

    const saveData = (open) => {
        let insertitem = [];
        let delitem = [];
        gridApi.forEachNode((rowNode, index) => {
            if (rowNode.data.Permission !== null) {
                insertitem.push(rowNode.data)
            }
            //console.log(rowNode.data)
            //console.log('node ' + rowNode.data.GroupID + ' is in the grid'+rowNode.data.Permission);
        });
        let test = [...insertitem]
        //console.log(test)
        //console.log(oldData)
        oldData.forEach((item) => {
            const del = test.find(el => Number(el.MenuID) === Number(item.MenuID));
            //สิทธิ์ของกลุ่มตอนโหลดไม่มีในตอนที่จะส่งให้เก็บเข้าลิสต์ที่จะลบข้อมูล
            //console.log(del)
            if (typeof del === 'undefined') {
                delitem.push(item);

            }
            else {
                const index = insertitem.indexOf(item);
                //มีมาตั้งแต่แรกให้ลบออกจากส่วนที่จะเพิ่มในเบส
                if (index > -1) {
                    insertitem.splice(index, 1);
                }
            }

        });
        //console.log(delitem)
        //console.log(insertitem)
        const formData = new FormData();
        formData.append("roleID", roleID);
        formData.append("userID", currentUser.UserID);

        delitem.forEach((item) => {
            formData.append('delitem[]', JSON.stringify(item));
        });
        //console.log(delitem)
        insertitem.forEach((item) => {
            formData.append('insertitem[]', JSON.stringify(item));
        });
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateGroupPermission', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                let timerInterval;
                swal.fire({
                    title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval);

                    }
                }).then((result) => {
                    if (open) {
                        window.location.reload();
                    }
                    else {
                        props.history.push({ pathname: "/admin/members/MemberGroup" });
                    }
                })
            });
    };
    //const roleRef = React.createRef();
    const RoleSelect = roleList.map((item) => (
        <option
            key={item.GroupID}
            value={item.GroupID}
        >
            {item.GroupNameTH}
        </option>
    ));
    const Changerole = (id) => {
        setRoleID(Number(id));
        axios.get(process.env.REACT_APP_APIURL + '/api-web/backendsMenus', { params: { id: id } }).then((response) => {
            //console.log(response.data)
            //console.log(id)
            setRowData(response.data);
            let tmp = [];
            response.data.forEach((item) => {
                if (item.Permission != null) {
                    //if (item.Permission === '1111')
                    tmp.push(item);
                }
            });
            setOldData(tmp);
        })
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/members/MemberGroup" });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกลุ่มผู้ใช้งาน"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/members/MemberGroup" }}>{"การจัดการกลุ่มผู้ใช้งาน"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/members/Permission" }}>{"กำหนดสิทธิ์เมนู"}</Link></>}
            />
            <div className="col-12">
                <form className="form-inline form-border">
                    <div className="form-group mx-sm mb-2 col-12">
                        <select className="form-control mb-2 mr-2" id="roleID" name="roleID" value={roleID} onChange={(e) => { Changerole(e.currentTarget.value); }}>
                            {RoleSelect}
                        </select>
                        <SError error={errors["roleID"]} />
                    </div>
                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="form-inline button-group">
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2 wd-150" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark mr-2" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                /*autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'CategoriesID',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}*/

                                modules={AllCommunityModules}

                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={true}
                                pagination={true}
                                paginationPageSize={10}

                            >

                                <AgGridColumn field="MenuID" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={70} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="NameMenuTH" headerName="เมนูการจัดการ" minWidth={780} />
                                <AgGridColumn field="Permission" headerName="มองเห็น" cellRenderer={checkboxRenderer} sortable={true} minWidth={100} />
                                <AgGridColumn field="UpdateFullname" headerName="ผู้แก้ไขล่าสุด" cellRenderer={updateYearCellRenderer} minWidth={150} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                            </AgGridReact>
                            <div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={10} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="100">25</option>
                                    <option value="500">50</option>
                                    <option value="1000">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
