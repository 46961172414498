import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import searchicon from "assets/images/icon/search.svg";
import { authenticationService } from "services/authentication.service.js";

export default function SearchMOU(props) {
  const currentUser = authenticationService.currentUserValue;
  const PageSize = 10;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [mouList, setMOUList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    utils.AccessInfo(
      "หน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)",
      "/authen/network/SearchMOU",
      "F",
      currentUser
    );
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchNetworkMOU", {
        params: { status: 1 },
      })
      .then((response) => {
        // console.log(response.data);
        setMOUList(response.data);
        setCurrentPage(1);
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return mouList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const NewsDIV = currentTableData.map((item, i) => (
    <div className="listProUi2fellows" key={i}>
      <img title="icon-mou" alt="icon-logo-mou" className="icon-logo-mou" src={item.LogoImgPath} />
      <Link
        to={{
          pathname: "/authen/network/ViewMOU",
          state: { mouID: item.CorporateMOUID },
        }}
      >
        
        {language === "Thai"
          ? item.Name
          : item.NameEN.trim().length === 0
            ? item.Name
            : item.NameEN}
      </Link>
      <div className="listDatePro">
        <span className="time-ago">
          <p>{language === "Thai" ? "วันที่เผยแพร่" : "Published Date"}{" "}</p>
          <i className="far fa-clock" />
          <p>{utils.MariatoThaiDateStringShort(item.CreatedDate)}</p>
        </span>
      </div>
    </div>
  ));
  const searchRef = React.createRef();
  const searchData = () => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchNetworkMOU", {
        params: { search: searchRef.current.value, status: 1 },
      })
      .then((response) => {
       
        setMOUList(response.data);
        //console.log(newsList);
        setCurrentPage(0);
        setCurrentPage(1);
      });
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/SearchMOU",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " หน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)"
                  : "Agencies under MOU"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data SearchMOU">
              <div className="sub-title">
                <h2 className="thai-header text-center">
                  <span className="orang content-underline">
                    {language === "Thai"
                      ? "ค้นหารายชื่อหน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)"
                      : "Search Agencies under MOU"}
                  </span>
                </h2>
              </div>
              <div className="row">
                <div className="col-12">
                  <form className="formContent row">
                    <div className="col-12">
                      <input
                        className="form-control mr-sm-2 search"
                        ref={searchRef}
                        type="text"
                        placeholder={
                          language === "Thai" ? "ค้นหาที่นี่" : "Search"
                        }
                        aria-label="Search"
                      />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          searchData();
                        }}
                      >
                        <img
                          src={searchicon}
                          className="searchicon"
                          alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                        />
                      </a>
                    </div>
                  </form>
                </div>
                {/* <div className="col-12">
                  <span className="big-text badge badge-info">
                    {mouList.length}
                  </span>
                  <span className="big-text text-gray">
                    {" "}
                    {language === "Thai" ? "ผลลัพธ์" : "Result"}
                  </span>
                </div> */}
              </div>
              <div className="controlflex">
                <div className="textInfo">{mouList.length}</div>
                <div className="textSum">{language === "Thai" ? "ผลลัพธ์" : "Result"}</div>
              </div>
              <div className="CardProUi2fellows">{NewsDIV}</div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={mouList.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
