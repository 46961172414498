import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import utils from "utils/utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AdminBanner from "components/Banner/AdminBanner";
import MomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import "moment/locale/th";
import moment from "moment";
const swal = withReactContent(Swal);
export default function ComplainReport(props) {
  const language = localStorage.getItem("language");
  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [publishstartDate, SetPublishStartDate] = useState(null);
  const [publishendDate, SetPublishEndDate] = useState(null);
  useEffect(() => {
    resetsearch();
    //console.log(props.location.state.id)
    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElibraryCat', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
            });     */
  }, []); /* <-- add this for run once*/

  const onGridReady = (params) => {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    const updateData = (data) => {
      setRowData(data);
    };
    let nowDate = new Date();
    let startDate = nowDate.toISOString().split("T")[0];
    let endDtate = nowDate.toISOString().split("T")[0];
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/complainReport", {
        params: {
          startDate: startDate,
          endDate: endDtate,
        },
      })
      .then((response) => {
        setRowData([response.data]);
        console.log(response);
        //console.log(response.data);
      });
  };
  const getRowHeight = (params) => {
    //console.log(params.data.rowHeight);
    //return params.data.rowHeight;
    return 80;
  };
  const createYearCellRenderer = (params) => {
    if (params.value == null)
      return (
        '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' +
        utils.MariatoThaiDateString(params.data.CreatedDate) +
        "</p></span>"
      );
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.CreatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const updateYearCellRenderer = (params) => {
    if (params.value == null) return "";
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.UpdatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const RowTool = (params) => {
    let tool = null;
    tool = (
      <span className="row-tool">
        <button
          type="button"
          className="btn btn-primary btn-sm wd-40"
          onClick={() => {
            handleView(params);
          }}
        >
          ดู
        </button>
      </span>
    );
    return tool;
  };
  var hashValueGetter = function (params) {
    return params.node.rowIndex + 1;
  };
  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
  };
  const StateRenderer = (params) => {
    //console.log(params.value)
    if (params.value === "WAIT") return "ส่งเรื่องร้องทุกข์";
    else if (params.value === "ACCEPT") return "รับเป็นคำร้อง";
    else if (params.value === "DECLINE") return "ไม่รับเป็นคำร้อง";
    else if (params.value === "CANCEL") return "ยกเลิก";
    else if (params.value === "FORWARD") return "ส่งต่อหน่วยงานภายนอก";
    else if (params.value === "TERMINATED") return "ยุติ";
    return "กำลังดำเนินการ/อยู่ระหว่างดำเนินการ";
  };

  const searchData = () => {
    let startDate = publishstartDate.toISOString().split("T")[0];
    let endDtate = publishendDate.toISOString().split("T")[0];
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/complainReport", {
        params: {
          startDate: startDate,
          endDate: endDtate,
        },
      })
      .then((response) => {
        setRowData([response.data]);
        console.log(response);
        //console.log(response.data);
      });
  };
  const resetsearch = () => {
    let nowDate = new Date();
    let startDate = nowDate.toISOString().split("T")[0];
    let endDtate = nowDate.toISOString().split("T")[0];
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/complainReport", {
        params: {
          startDate: startDate,
          endDate: endDtate,
        },
      })
      .then((response) => {
        setRowData([response.data]);
        console.log(response);
        //console.log(response.data);
      });
  };

  const handleView = (params) => {
    props.history.push({
      pathname: "/admin/complain/ComplainInfo",
      state: { id: params.data.ComplainID },
    });
    //window.location.reload();
  };

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"}
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{ pathname: "/admin/complain/ComplainReport" }}
            >
              {"ติดตามเรื่องร้องเรียน"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="form-inline form-border justify-content-between">
          <div className="col-2">
            <label className="required">วันที่เริ่มต้น</label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
              <DatePicker
                value={publishstartDate}
                format="D MMMM YYYY"
                pickerHeaderFormat="ddd D MMM"
                yearOffset={543}
                /*maxDate={publishendDate}*/
                onChange={SetPublishStartDate}
                name="startpublishday"
                id="startpublishday"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-2">
            <label className="required">วันที่สิ้นสุด</label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
              <DatePicker
                value={publishendDate}
                format="D MMMM YYYY"
                pickerHeaderFormat="ddd D MMM"
                yearOffset={543}
                /*minDate={publishstartDate}*/
                onChange={SetPublishEndDate}
                name="endpublishday"
                id="endpublishday"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="button-group col-2">
            <button
              type="button"
              className="btn btn-info mr-2"
              onClick={(e) => {
                e.preventDefault();
                searchData();
              }}
            >
              ค้นหา
            </button>
            <button
              type="submit"
              className="btn btn-light"
              onClick={(e) => {
                e.preventDefault();
                resetsearch();
              }}
            >
              ล้างคำค้นหา
            </button>
          </div>
        </form>
      </div>
      <div className="col-12">
        <div className="form-border">
          <div className="col-12">
            <div className="ag-theme-alpine" style={{ height: 900 }}>
              <AgGridReact
                autoGroupColumnDef={{
                  headerName: "Group",
                  minWidth: 30,
                  field: "CategoriesID",
                  valueGetter: function (params) {
                    if (params.node.group) {
                      return params.node.key;
                    } else {
                      return params.data[params.colDef.field];
                    }
                  },
                  headerCheckboxSelection: false,
                  cellRenderer: "agGroupCellRenderer",
                  cellRendererParams: { checkbox: false },
                }}
                modules={AllCommunityModules}
                frameworkComponents={{
                  rowTool: RowTool,
                }}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  //cellClass: "cell-border cell-vertical-align-text-left",
                }}
                getRowHeight={getRowHeight}
                onGridReady={onGridReady}
                rowData={rowData}
                rowSelection={"multiple"}
                rowDragManaged={true}
                pagination={true}
                paginationPageSize={10}
              >
                <AgGridColumn
                  field=""
                  valueGetter={hashValueGetter}
                  headerName="ลำดับ"
                  width={70}
                  cellClass="cell-border cell-vertical-align-text-left"
                />
                {/*<AgGridColumn field="OrganizationName" headerName="องค์กร" minWidth={165} />*/}
                <AgGridColumn
                  field="Complain"
                  headerName="จำนวนการร้องเรียน"
                  sortable={true}
                  minWidth={100}
                />
                <AgGridColumn
                  field="ComplainFollow"
                  headerName="จำนวนการติดตาม"
                  sortable={true}
                  minWidth={100}
                />
              </AgGridReact>
              {/* <div className="page-size formContent">
                <p>Page size:</p>
                <select
                  className="custom-select"
                  defaultValue={10}
                  onChange={() => onPageSizeChanged()}
                  id="page-size"
                >
                  <option value="10">10</option>
                  <option value="100">25</option>
                  <option value="500">50</option>
                  <option value="1000">100</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
