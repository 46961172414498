import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import SingleFileTable from "components/SingleFileTable";
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function RenewNetwork(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [errors, setError] = useState({});
    const [networkID, SetNetworkID] = useState('');

    const [netType, SetNetType] = useState("");
    const [organization, SetOrganization] = useState("");
    const [netHouseNo, SetNetHouseNo] = useState("");
    const [provinceCode, SetProvinceCode] = useState(0);
    const [districtCode, SetDistrictCode] = useState(0);
    const [subDistricCode, SetSubDistricCode] = useState(0);
    const [postCode, SetPostCode] = useState("");
    const [phone, SetPhone] = useState("");
    const [fax, SetFax] = useState("");
    const [coTitleID, SetCoTitleID] = useState(0);
    const [coFirstName, SetCoFirstName] = useState("");
    const [coLastName, SetCoLastName] = useState("");
    const [coPosition, SetCoPosition] = useState("");
    const [coPhone, SetCoPhone] = useState("");
    const [coFax, SetCoFax] = useState("");
    const [coEmail, SetCoEmail] = useState("");

    const [coProperty1, SetCoProperty1] = useState(false);
    const [coProperty2, SetCoProperty2] = useState(false);
    const [coProperty3, SetCoProperty3] = useState(false);
    const [coProperty4, SetCoProperty4] = useState(false);

    const [coProperty1Extra, SetCoProperty1Extra] = useState("");
    const [titleList, SetTitleList] = useState([]);
    const [coOtherTitleTH, SetCoOtherTitleTH] = useState("");
    const [coOtherTitleEN, SetCoOtherTitleEN] = useState("");
    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [topicList, setTopicList] = useState([]);

    useEffect(() => {
        let networkid = '';
        if (typeof props.location.state !== 'undefined') {
            SetNetworkID(props.location.state.networkID);
            networkid = props.location.state.networkID;
        }
        else {
            SetNetworkID(localStorage.getItem("networkID"));
            networkid = localStorage.getItem("networkID");
        }
        //console.log(networkid)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/corporateNetworkbyID', { params: { id: networkid } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            SetOrganization(data.NetOrganization);
            SetNetType(data.NetType);

            axios
                .get(process.env.REACT_APP_APIURL + "/api-web/memberbyID", {
                    params: { id: currentUser.UserID },
                })
                .then((response) => {
                    //console.log(response.data)
                    SetCoTitleID(response.data[0].TitleID);
                    SetCoFirstName(response.data[0].FirstnameTH);
                    SetCoLastName(response.data[0].LastnameTH);
                    SetCoPhone(response.data[0].Phone);
                    SetCoEmail(response.data[0].Email);
                    //setMember(response.data);
                    axios.get(
                            process.env.REACT_APP_APIURL + "/api-web/searchMemberInterestTopic",
                            { params: { status: 1 } }
                        ).then((response) => {
                            setTopicList(response.data);
                        });
                    axios.get(process.env.REACT_APP_APIURL + "/api-web/getTitle").then((response) => {
                            SetTitleList(response.data);
                        });
                    axios.get(process.env.REACT_APP_APIURL + "/api-web/getProvince").then((response) => {
                            SetProvinceList(response.data);
                        });
                    axios.get(process.env.REACT_APP_APIURL + "/api-web/getDistrict").then((response) => {
                            SetDistrictList(response.data);
                            SetAllDistrictList(response.data);
                    });
                    axios.get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict").then((response) => {
                        //console.log(response.data)
                        SetSubDistrictList(response.data);
                        SetAllSubDistrictList(response.data);
                    });
                    SetNetHouseNo(data.NetHouseNo);
                    SetProvinceCode(data.NetProvinceID);
                    SetDistrictCode(data.NetDistrictID);
                    SetSubDistricCode(data.NetSubDistrictID);
                    SetPostCode(data.NetPostCode);
                    SetPhone(data.NetPhone);
                    SetFax(data.NetFax);
                    SetCoTitleID(data.CoTitleID);
                    SetCoPosition(data.CoPosition);
                    SetCoProperty1(data.CoProperty1);
                    SetCoProperty2(data.CoProperty2);
                    SetCoProperty3(data.CoProperty3);
                    SetCoProperty4(data.CoProperty4);
                    SetCoProperty1Extra(data.CoProperty1Extra);
                    setLoading(false);
                });
        });

    }, []); /* <-- add this for run once*/

    const handleChangeProvince = (ProvinceID) => {
        SetDistrictList(
            allDistrictList.filter(
                (item) => item["ProvinceID"] === Number(ProvinceID)
            )
        );
        SetSubDistrictList(
            allSubDistrictList.filter(
                (item) => item["ProvinceID"] === Number(ProvinceID)
            )
        );
        SetDistrictCode(0);
        SetSubDistricCode(0);
    };
    const handleChangeDistrict = (DistrictID) => {
        SetSubDistrictList(
            allSubDistrictList.filter(
                (item) => item["DistrictID"] === Number(DistrictID)
            )
        );
        SetSubDistricCode(0);
    };
    const handleChangeSubDistrict = (SubDistrictID) => {
        const subdistrictitem = allSubDistrictList.filter(
            (item) => item["SubDistrictID"] === Number(SubDistrictID)
        );
        if (subdistrictitem[0] != null) {
            SetPostCode(subdistrictitem[0].PostCode);
        } else {
            SetPostCode("");
        }
    };
    const rx_int = /^\d+$/;

    const handlepostCodeChange = (e) => {
        if (rx_int.test(e.target.value)) {
            SetPostCode(e.target.value);
        }
    };
    const rx_phone = /(\d{1})+(\-)?$/;
    const handlePhoneChange = (e) => {
        if (e.target.value === '') {
            SetPhone('');
        }
        else if (rx_phone.test(e.target.value)) {
            SetPhone(e.target.value);
        }
    };
    const handleFaxChange = (e) => {
        if (e.target.value === '') {
            SetFax('');
        }
        else if (rx_phone.test(e.target.value)) {
            SetFax(e.target.value);
        }
    };
    const handleCoPhoneChange = (e) => {
        if (e.target.value === '') {
            SetCoPhone('');
        }
        else if (rx_phone.test(e.target.value)) {
            SetCoPhone(e.target.value);
        }
    };
    const handleCoFaxChange = (e) => {
        if (e.target.value === '') {
            SetCoFax('');
        }
        else if (rx_phone.test(e.target.value)) {
            SetCoFax(e.target.value);
        }
    };

    const TitleSelect = titleList.map((title) => (
        <option key={title.TitleID} value={title.TitleID}>
            {title.TitleNameTH}
        </option>
    ));
    const ProvinceSelect = provinceList.map((province) => (
        <option key={province.ProvinceID} value={province.ProvinceID}>
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district) => (
        <option key={district.DistrictID} value={district.DistrictID}>
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict) => (
        <option key={subdistrict.SubDistrictID} value={subdistrict.SubDistrictID}>
            {subdistrict.SubDistrictTH}
        </option>
    ));

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (organization.trim().length === 0) {
            formIsValid = false;
            serrors["organization"] = "กรุณาระบุชื่อ";
        }
        if (netHouseNo.trim().length === 0) {
            formIsValid = false;
            serrors["netHouseNo"] = "กรุณาระบุรายละเอียด";
        }
        if (Number(provinceCode) === 0) {
            formIsValid = false;
            serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
        }
        if (Number(districtCode) === 0) {
            formIsValid = false;
            serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
        }
        if (Number(subDistricCode) === 0) {
            formIsValid = false;
            serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
        }
        if (postCode.trim().length === 0) {
            formIsValid = false;
            serrors["postCode"] = "กรุณากรอกรหัสไปรษณีย์";
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณาระบุรายละเอียด";
        }
        if (coFirstName.trim().length === 0) {
            formIsValid = false;
            serrors["coFirstName"] = "กรุณาระบุรายละเอียด";
        }
        if (coLastName.trim().length === 0) {
            formIsValid = false;
            serrors["coLastName"] = "กรุณาระบุรายละเอียด";
        }
        if (coPosition.trim().length === 0) {
            formIsValid = false;
            serrors["coPosition"] = "กรุณาระบุรายละเอียด";
        }
        if (coPhone.trim().length === 0) {
            formIsValid = false;
            serrors["coPhone"] = "กรุณาระบุรายละเอียด";
        }
        //coFax
        if (coEmail.trim().length === 0) {
            formIsValid = false;
            serrors["coEmail"] = "กรุณาระบุรายละเอียด";
        } else {
            let lastAtPos = coEmail.lastIndexOf("@");
            let lastDotPos = coEmail.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    coEmail.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    coEmail.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                serrors["coEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
            }
        }
        if (netType === "ORG") {
            if (
                coProperty1 === false &&
                coProperty2 === false &&
                coProperty3 === false &&
                coProperty4 === false
            ) {
                formIsValid = false;
                serrors["coProperty"] =
                    "กรุณารับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
            }
        } else {
            if (
                coProperty1 === false &&
                coProperty2 === false &&
                coProperty3 === false
            ) {
                formIsValid = false;
                serrors["coProperty"] =
                    "กรุณารับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
            }
            if (
                coProperty1 === false &&
                coProperty2 === false &&
                coProperty3 === false &&
                coProperty4 === false
            ) {
                formIsValid = false;
                serrors["accept"] =
                    "กรุณายอมรับการรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
            }
        }
        if (coProperty1 === true && netType === "ORG") {
            if (coProperty1Extra.trim().length === 0) {
                serrors["coProperty1Extra"] = "โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง";
            }
        }
        if (topicList.length === 0) {
            formIsValid = false;
            //console.log(seltopiclist1)
            serrors["seltopiclist1"] =
                "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
        }

        setError(serrors);
        //console.log(serrors)
        return formIsValid;
    };
    const saveData = () => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("networkID", networkID);
            formData.append("netType", netType);
            formData.append("userID", currentUser.UserID);
            formData.append("organization", organization);
            formData.append("netHouseNo", netHouseNo);
            formData.append("provinceCode", provinceCode);
            formData.append("districtCode", districtCode);
            formData.append("subDistricCode", subDistricCode);
            formData.append("postCode", postCode);
            formData.append("phone", phone);
            formData.append("fax", fax);
            formData.append("coTitleID", coTitleID);
            formData.append("coOtherTitleTH", coOtherTitleTH);
            formData.append("coOtherTitleEN", coOtherTitleEN);
            formData.append("coFirstName", coFirstName);
            formData.append("coLastName", coLastName);
            formData.append("coPosition", coPosition);
            formData.append("coPhone", coPhone);
            formData.append("coFax", coFax);
            formData.append("coEmail", coEmail);
            formData.append("coProperty1", coProperty1);
            formData.append("coProperty2", coProperty2);
            formData.append("coProperty3", coProperty3);
            formData.append("coProperty4", coProperty4);
            formData.append("coProperty1Extra", coProperty1Extra);

            axios
                .post(
                    process.env.REACT_APP_APIURL + "/api-web/RenewCorporateNetwork",
                    formData,
                    {
                        headers: {
                            "Content-Type": `multipart/form-data`,
                        },
                    }
                )
                .then((response) => {
                    let timerInterval;
                    swal
                        .fire({
                            title: "บันทึกข้อมูลเรียบร้อย",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        })
                        .then((result) => {
                            //props.history.push({ pathname: "/authen/network/NetworkList" });
                            window.close();
                        });
                });
        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/authen/Profile" });
    };

    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner
                    language={language}
                    theader={"เครือข่ายด้านสิทธิมนุษยชน"}
                    eheader={"Network"}
                    bannerkey="NETWORK"
                    banner={banner}
                    path={
                        <>
                            <span>/</span>
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {language === "Thai" ? "เครือข่าย" : "Network"}
                            </a>
                            <span>/</span>
                            <Link
                                to={{
                                    pathname: "/authen/network/NetworkList",
                                    state: { language: language },
                                }}
                            >
                                {language === "Thai"
                                    ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ"
                                    : "Cooperationnetwork and Profesional Council"}
                            </Link>
                            <span>/</span>
                            <Link
                                className="active"
                                to={{
                                    pathname: "/authen/network/CoorperationRegister",
                                    state: { language: language },
                                }}
                            >
                                {language === "Thai"
                                    ? "ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน"
                                    : "Cooperationnetwork Register"}
                            </Link>
                        </>
                    }
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section>
                        <div className="content-data CoorperationRegister">
                            <form method="post" encType="multipart/form-data" className="row">
                                <div className="col-12">
                                    <div className="col-12">
                                        <div className="sub-title">
                                            <h2 className="thai-header text-center">
                                                <span className="orang content-underline">
                                                    {netType === "ORG"
                                                        ? "ยื่นเรื่องขอรับการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน"
                                                        : "ยื่นเรื่องขอรับการจดแจ้งสภาวิชาชีพ"}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {netType === "ORG" && (
                                            <>
                                                <h4 className="text-center text-gray">
                                                    คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน
                                                    ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                                                    พ.ศ. 2560
                                                </h4>
                                            </>
                                        )}
                                        {netType === "PROCOUN" && (
                                            <>
                                                <h4 className="text-center text-gray">
                                                    คำขอรับการจดแจ้งเป็นสภาวิชาชีพ
                                                    ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                                                    พ.ศ. 2560
                                                </h4>
                                            </>
                                        )}
                                    </div>
                                    <div className="subheader col-12" role="alert">
                                        ข้อมูลทั่วไป
                                    </div>
                                    <div className="formContent">
                                        <div className="formMaxwidth">
                                            <div className="row">
                                                <div className="col-12 title text-blue">
                                                    <h2>
                                                        <span>
                                                            {netType === "ORG"
                                                                ? "ด้วยองค์กร"
                                                                : "ด้วยสภาวิชาชีพ"}
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control1 "
                                                        id="organization"
                                                        defaultValue={organization}
                                                        name="organization"
                                                        placeholder="ชื่อองค์กร"
                                                        autoComplete="off"
                                                        onBlur={(e) => {
                                                            SetOrganization(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["organization"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control1 "
                                                        id="netHouseNo"
                                                        defaultValue={netHouseNo}
                                                        name="netHouseNo"
                                                        placeholder="ที่ตั้งเลขที่"
                                                        autoComplete="off"
                                                        onBlur={(e) => {
                                                            SetNetHouseNo(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["netHouseNo"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <select
                                                        className="custom-select "
                                                        id="provinceCode"
                                                        name="provinceCode"
                                                        value={provinceCode}
                                                        onChange={(e) => {
                                                            SetProvinceCode(e.currentTarget.value);
                                                            handleChangeProvince(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            จังหวัด
                                                        </option>
                                                        {ProvinceSelect}
                                                    </select>
                                                    <SError error={errors["provinceCode"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <select
                                                        className="custom-select "
                                                        id="districtCode"
                                                        name="districtCode"
                                                        value={districtCode}
                                                        onChange={(e) => {
                                                            SetDistrictCode(e.currentTarget.value);
                                                            handleChangeDistrict(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            เขต/อำเภอ
                                                        </option>
                                                        {DistrictSelect}
                                                    </select>
                                                    <SError error={errors["districtCode"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <select
                                                        className="custom-select "
                                                        id="subDistricCode"
                                                        name="subDistricCode"
                                                        value={subDistricCode}
                                                        onChange={(e) => {
                                                            SetSubDistricCode(e.currentTarget.value);
                                                            handleChangeSubDistrict(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            แขวง/ตำบล
                                                        </option>
                                                        {SubDistrictSelect}
                                                    </select>
                                                    <SError error={errors["subDistricCode"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="postCode"
                                                        value={postCode}
                                                        name="postCode"
                                                        placeholder="รหัสไปรษณีย์"
                                                        autoComplete="off"
                                                        maxLength={5}
                                                        onChange={(e) => {
                                                            handlepostCodeChange(e);
                                                        }}
                                                    />
                                                    <SError error={errors["postCode"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="phone"
                                                        value={phone}
                                                        name="phone"
                                                        placeholder="หมายเลขโทรศัพท์"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            handlePhoneChange(e);
                                                        }}
                                                    />
                                                    <SError error={errors["phone"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="fax"
                                                        value={fax}
                                                        name="fax"
                                                        placeholder="โทรสาร"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            handleFaxChange(e);
                                                        }}
                                                    />
                                                    <SError error={errors["fax"]} />
                                                </div>

                                                <div className="col-12">
                                                    <label className="mb-4">
                                                        มีความประสงค์ให้สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                                                        รับจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน
                                                        ตามพระราชบัญญัติประกอบรัฐธรรมนูญ
                                                        ว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560
                                                        และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์
                                                        วิธีการ และเงื่อนไข การจดแจ้ง การรับจดแจ้ง
                                                        และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชน
                                                        และสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม
                                                        (ฉบับที่ 2) พ.ศ. 2563
                                                    </label>
                                                </div>
                                                <div className="col-12 title text-blue mb-4">
                                                    <h2>
                                                        <span>
                                                            {netType === "ORG"
                                                                ? "โดยมีผู้ประสานงาน คือ"
                                                                : "โดยมีผู้ประสานงาน คือ"}
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <select
                                                        className="custom-select "
                                                        id="coTitleID"
                                                        name="coTitleID"
                                                        value={coTitleID}
                                                        onChange={(e) => {
                                                            SetCoTitleID(e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            คำนำหน้า
                                                        </option>
                                                        {TitleSelect}
                                                        <option key="-1" value="-1">
                                                            อื่น ๆ
                                                        </option>
                                                    </select>
                                                    <SError error={errors["coTitleID"]} />
                                                </div>
                                                {Number(coTitleID) === -1 && (
                                                    <>
                                                        <div className="col-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                id="coOtherTitleTH"
                                                                value={coOtherTitleTH}
                                                                name="coOtherTitleTH"
                                                                placeholder="คำนำหน้าอื่น ๆ ภาษาไทย"
                                                                onChange={(e) => {
                                                                    SetCoOtherTitleTH(e.currentTarget.value);
                                                                }}
                                                            />
                                                            <SError error={errors["coOtherTitleTH"]} />
                                                        </div>
                                                        <div className="col-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                id="coOtherTitleEN"
                                                                value={coOtherTitleEN}
                                                                name="coOtherTitleEN"
                                                                placeholder="คำนำหน้าอื่น ๆ ภาษาอังกฤษ"
                                                                onChange={(e) => {
                                                                    SetCoOtherTitleEN(e.currentTarget.value);
                                                                }}
                                                            />
                                                            <SError error={errors["coOtherTitleEN"]} />
                                                        </div>
                                                    </>
                                                )}
                                                {Number(coTitleID) !== -1 && (
                                                    <>
                                                        <div className="col-6"></div>
                                                    </>
                                                )}
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coFirstName"
                                                        defaultValue={coFirstName}
                                                        autoComplete="off"
                                                        name="coFirstName"
                                                        placeholder="ชื่อ"
                                                        readOnly={true}
                                                        onBlur={(e) => {
                                                            SetCoFirstName(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["coFirstName"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coLastName"
                                                        defaultValue={coLastName}
                                                        autoComplete="off"
                                                        name="coLastName"
                                                        placeholder="นามสกุล"
                                                        readOnly={true}
                                                        onBlur={(e) => {
                                                            SetCoLastName(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["coLastName"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coPosition"
                                                        defaultValue={coPosition}
                                                        autoComplete="off"
                                                        name="coPosition"
                                                        placeholder="ตำแหน่ง"
                                                        onBlur={(e) => {
                                                            SetCoPosition(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["coPosition"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coPhone"
                                                        value={coPhone}
                                                        name="coPhone"
                                                        autoComplete="off"
                                                        placeholder="โทรศัพท์"
                                                        onChange={(e) => {
                                                            handleCoPhoneChange(e);
                                                        }}
                                                    />
                                                    <SError error={errors["coPhone"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coFax"
                                                        value={coFax}
                                                        name="coFax"
                                                        autoComplete="off"
                                                        placeholder="โทรสาร"
                                                        onChange={(e) => {
                                                            handleCoFaxChange(e);
                                                        }}
                                                    />
                                                    <SError error={errors["coFax"]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="coEmail"
                                                        defaultValue={coEmail}
                                                        autoComplete="off"
                                                        name="coEmail"
                                                        placeholder="อีเมล"
                                                        onBlur={(e) => {
                                                            SetCoEmail(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["coEmail"]} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="subheader col-12" role="alert">
                                            การรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร
                                        </div>
                                        <div className="formMaxwidth">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="mb-4">
                                                        ( โปรดใส่เครื่องหมาย √ ในช่อง{" "}
                                                        <i className="far fa-square"></i>{" "}
                                                        ตามความเป็นจริงเกี่ยวกับองค์กร )
                                                    </label>
                                                </div>
                                                <div className="col-12 title text-blue mb-3">
                                                    <h2>
                                                        <span>
                                                            {netType === "ORG"
                                                                ? "ขอรับรองว่า"
                                                                : "ขอรับรองว่าสภาวิชาชีพ"}
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="input-addon">
                                                        <span className="require">*</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="organization"
                                                        defaultValue={organization}
                                                        name="organization"
                                                        placeholder={
                                                            netType === "ORG"
                                                                ? "ชื่อองค์กร"
                                                                : "ชื่อสภาวิชาชีพ"
                                                        }
                                                        autoComplete="off"
                                                        onBlur={(e) => {
                                                            SetOrganization(e.currentTarget.value);
                                                        }}
                                                    />
                                                    <SError error={errors["organization"]} />
                                                </div>
                                                <div className="col-12 my-bottom">
                                                    <label>
                                                        {netType === "ORG"
                                                            ? "มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 7 แห่งระเบียบนี้"
                                                            : "มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 8 แห่งระเบียบนี้"}
                                                    </label>
                                                </div>
                                                {netType === "ORG" && (
                                                    <>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty1"
                                                                    name="coProperty1"
                                                                    checked={coProperty1 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty1(!coProperty1);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty1"
                                                                >
                                                                    เป็นองค์กรที่จัดตั้งขึ้นตามกฎหมายไทย
                                                                    โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                id="coProperty1Extra"
                                                                defaultValue={coProperty1Extra}
                                                                name="coProperty1Extra"
                                                                placeholder="กฎหมายและมาตราที่เกี่ยวข้อง"
                                                                onBlur={(e) => {
                                                                    SetCoProperty1Extra(e.currentTarget.value);
                                                                }}
                                                            />
                                                            <SError error={errors["coProperty1Extra"]} />
                                                        </div>
                                                        <div className="col-6"></div>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty2"
                                                                    name="coProperty2"
                                                                    checked={coProperty2 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty2(!coProperty2);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty2"
                                                                >
                                                                    มีวัตถุประสงค์
                                                                    และการดําเนินการเกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty3"
                                                                    name="coProperty3"
                                                                    checked={coProperty3 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty3(!coProperty3);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty3"
                                                                >
                                                                    มีการดำเนินการอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า
                                                                    2 ปี นับแต่วันที่จัดตั้ง
                                                                    และมีผลการดำเนินงานเป็นที่ประจักษ์
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty4"
                                                                    name="coProperty4"
                                                                    checked={coProperty4 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty4(!coProperty4);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty4"
                                                                >
                                                                    ไม่มีวัตถุประสงค์ในทางการเมือง
                                                                    หรือแสวงหากําไรจากการดําเนินการ
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <SError error={errors["coProperty"]} />
                                                        </div>

                                                    </>
                                                )}
                                                {netType === "PROCOUN" && (
                                                    <>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty1"
                                                                    name="coProperty1"
                                                                    checked={coProperty1 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty1(!coProperty1);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty1"
                                                                >
                                                                    เป็นสภาวิชาชีพตามกฎหมายไทย
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty2"
                                                                    name="coProperty2"
                                                                    checked={coProperty2 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty2(!coProperty2);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty2"
                                                                >
                                                                    มีหลักฐานการรับรองหรือมีคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty3"
                                                                    name="coProperty3"
                                                                    checked={coProperty3 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty3(!coProperty3);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty3"
                                                                >
                                                                    สภาวิชาชีพนี้มีการให้ความเห็นชอบในการยื่นคำขอจดแจ้งสภาวิชาชีพ
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <SError error={errors["coProperty"]} />
                                                        </div>
                                                        <div className="col-12">
                                                            <hr />
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="coProperty4"
                                                                    name="coProperty4"
                                                                    checked={coProperty4 === true}
                                                                    onChange={(e) => {
                                                                        SetCoProperty4(!coProperty4);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="coProperty4"
                                                                >
                                                                    ข้าพเจ้าขอยอมรับว่าข้อมูลดังกล่าวเป็นความจริงทุกประการ
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <SError error={errors["accept"]} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="controlBtn">
                                        {netType === "ORG" && (
                                            <input
                                                type="button"
                                                className="fadeIn fourth btnSecondary"
                                                value="ตกลง"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    //nextPageFunction(true);
                                                }}
                                            />
                                        )}
                                        {netType === "PROCOUN" && (
                                            <input
                                                type="submit"
                                                className="fadeIn fourth btnSecondary"
                                                value="ตกลง"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                }}
                                            />
                                        )}
                                        <input
                                            type="button"
                                            className="fadeIn fourth btnPimary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                cancel();
                                            }}
                                            value="ยกเลิก"
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
