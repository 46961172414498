import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function ChapterQuestion(props) {
    const currentUser = authenticationService.currentUserValue;
    const chapterID = props.location.state.chapterID;
    const questionType = props.location.state.questionType;
    const [errors, setError] = useState({});
    //const [eLearnTitle, setELearnTitle] = useState('');
    const [parentChapter, setParentChapter] = useState([]);
    const [chapterTitle, setChapterTitle] = useState('');
    const [description, setDescription] = useState('');
    const [testStartDate, SetLearnStartDate] = useState(new Date());
    const [testEndDate, SetLearnEndDate] = useState(new Date());
    const [duration, setDuration] = useState(0);
    const [durationType, setDurationType] = useState(0);
    const [useDuration, setUseDuration] = useState(false);
    const [criteriaPercent, setCriteriaPercent] = useState(50);
    const [customcriteriaPercent, setCustomCriteriaPercent] = useState(50);
    const [displayTestResult, setDisplayTestResult] = useState(false);
    const [questionPerPage, setQuestionPerPage] = useState(0);
    const [allowCrossPage, setAllowCrossPage] = useState(false);
    const [allowRetry, setAllowRetry] = useState(false);
    const [allowEditAnswer, setAllowEditAnswer] = useState(false);
    const [allowRetryCount, setAllowRetryCount] = useState(0);


    function reLoadData() {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/ESubjectChapter', { params: { id: chapterID } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            setParentChapter(data)
            //setELearnTitle(data.ELearnTitle);
        })
    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/
    const saveData = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (chapterTitle.trim().length === 0) {
            formIsValid = false;
            serrors["chapterTitle"] = "กรุณากรอกข้อมูล";
        }
        if (description.trim().length === 0) {
            formIsValid = false;
            serrors["description"] = "กรุณากรอกข้อมูล";
        }
        if (useDuration === true) {
            if (Number(duration) <= 0) {
                formIsValid = false;
                serrors["duration"] = "กรุณากรอกข้อมูลเวลาที่ถูกต้อง";
            }
            if (Number(durationType) == 0) {
                formIsValid = false;
                serrors["durationType"] = "กรุณาเลือกหน่วยในการนับเวลา";
            }
        }
        if (Number(criteriaPercent) === 0) {
            formIsValid = false;
            serrors["criteriaPercent"] = "กรุณากรอกข้อมูล";
        }
        if (Number(criteriaPercent) === 1) {
            if (customcriteriaPercent.trim().length === 0) {
                formIsValid = false;
                serrors["customcriteriaPercent"] = "กรุณากรอกข้อมูล";
            }
        }
        if (Number(questionPerPage) === 0) {
            formIsValid = false;
            serrors["questionPerPage"] = "กรุณากรอกข้อมูลจำนวนคำถามต่อหนึ่งหน้าที่เหมาะสม";
        }
        if (allowRetry === true) {
            if (Number(allowRetryCount) === 0) {
                formIsValid = false;
                serrors["allowRetryCount"] = "กรุณากรอกข้อมูลจำนวนครั้งในการทำข้อสอบที่เหมาะสม";
            }
        }
        setError(serrors);
        if (formIsValid) {
            const formData = new FormData();
            formData.append("eLearnID", parentChapter.ELearnID);
            formData.append("chapterTitle", chapterTitle);
            formData.append("chapterContentType", questionType);
            formData.append("description", description);
            formData.append("parentChapterID", chapterID);

            formData.append("allowEditAnswer", allowEditAnswer);
            formData.append("testStartDate", testStartDate);
            formData.append("testEndDate", testEndDate);
            formData.append("duration", duration);
            formData.append("durationType", durationType);
            formData.append("useDuration", useDuration);
            if (Number(criteriaPercent) === 1) {
                formData.append("scoreCriteria", customcriteriaPercent);
            }
            else {
                formData.append("scoreCriteria", criteriaPercent);
            }
            formData.append("displayTestResult", displayTestResult);
            formData.append("allowRetry", allowRetry);
            formData.append("allowRetryCount", allowRetryCount);
            formData.append("allowCrossPage", allowCrossPage);
            formData.append("questionPerPage", questionPerPage);

            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveChapterQuestionair', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        //reLoadData();    
                        props.history.push({ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } });
                    })
                });
        }
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } });
    };
    const rx_int = /^\d+$/;
    const handleMaxDuration = (e) => {
        if (rx_int.test(e.target.value)) {
            setDuration(parseInt(e.target.value));
        }
    }
    const handleCustomCriteriaPercent = (e) => {
        if (rx_int.test(e.target.value)) {
            setCustomCriteriaPercent(parseInt(e.target.value));
        }
    }
    const handleQuestionStep = (e) => {
        if (rx_int.test(e.target.value)) {
            if (Number(e.target.value) <= 50)
                setQuestionPerPage(parseInt(e.target.value));
        }
    }
    const handleAllowRetryCount = (e) => {
        if (rx_int.test(e.target.value)) {
            setAllowRetryCount(parseInt(e.target.value));
        }
    }
    /*for(let i=2;i<=50;i++){
              setQuestionPerPage                                          
    }*/
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } }}>วิชา {parentChapter.ELearnTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elearning/ChapterQuestion", state: { id: chapterID } }}>แบบทดสอบ</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>{parentChapter.ELearnTitle} (<span style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: 1.5 + 'rem' }}>{parentChapter.ChapterTitle}</span>)</h1>
                                    <div className="alert alert-dark col-12" role="alert">ทั่วไป</div>
                                    <div className="col-12">
                                        <label htmlFor="chapterTitle" className="required">ชื่อ</label>
                                        <input type="text" className="form-control " id="chapterTitle" value={chapterTitle} name="chapterTitle" placeholder="กรุณากรอก" onChange={(e) => { setChapterTitle(e.currentTarget.value) }} />
                                        <SError error={errors["chapterTitle"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="description" className="required">คำอธิบาย</label>
                                        <div>
                                            <textarea className="form-control" name="completeDocRemark" id="description" rows="3" value={description} onChange={e => { setDescription(e.target.value) }}></textarea>
                                            <SError error={errors["description"]} />
                                        </div>
                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">เวลา</div>
                                    <div className="row m-1">
                                        <div className="col-5">
                                            <label className="required">วันที่เริ่มทำแบบทดสอบ</label>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                                <DatePicker value={testStartDate} format="D MMMM YYYY"
                                                    pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                    {SetLearnStartDate} name="testStartDate" id="testStartDate" />
                                            </MuiPickersUtilsProvider>

                                        </div>
                                        <div className="col-5">
                                            <label className="required">วันที่สิ้นสุดทำแบบทดสอบ</label>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                                <DatePicker value={testEndDate} format="D MMMM YYYY"
                                                    pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                    minDate={testStartDate}
                                                    onChange={SetLearnEndDate} name="testEndDate" id="testEndDate" />
                                            </MuiPickersUtilsProvider>
                                            <SError error={errors["testEndDate"]} />
                                        </div>
                                        {useDuration === true && (<>
                                            <div className="col-3">
                                                <label htmlFor="duration" className="required">เวลาทำแบบทดสอบ</label>
                                                <input type="text" className="form-control" id="duration" name="duration" placeholder="กรุณากรอก" value={duration} onChange={(e) => { handleMaxDuration(e); }} />
                                                <SError error={errors["duration"]} />
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="durationType" className="required">หน่วย</label>
                                                <select className="form-control mb-2 mr-2" id="durationType" name="durationType" value={durationType} onChange={(e) => { setDurationType(e.currentTarget.value); }}>
                                                    <option value="0" >กรุณาเลือก</option>
                                                    <option value="1" >สัปดาห์</option>
                                                    <option value="2" >วัน</option>
                                                    <option value="3" >ชั่วโมง</option>
                                                    <option value="4" >นาที</option>
                                                    <option value="5" >วินาที</option>
                                                </select>
                                                <SError error={errors["durationType"]} />
                                            </div>
                                        </>)}
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox" style={{ marginTop: 30 }}>
                                                <input type="checkbox" className="custom-control-input" id="useDuration" name="useDuration" checked={useDuration === true} onChange={(e) => { setUseDuration(!useDuration) }} />
                                                <label className="custom-control-label" htmlFor="useDuration">เปิดการใช้งานการนับเวลา</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">การทดสอบ</div>
                                    <div className="row m-1">
                                        <div className="col-3">
                                            <label htmlFor="criteriaPercent" className="required">เปอร์เซ็นต์การผ่านการทดสอบ</label>
                                            <select className="form-control mb-2 mr-2" id="criteriaPercent" name="criteriaPercent" value={criteriaPercent} onChange={(e) => {
                                                setCriteriaPercent(e.currentTarget.value);
                                                if (Number(e.currentTarget.value) > 1) { setCustomCriteriaPercent(e.currentTarget.value) } else { setCustomCriteriaPercent('') }
                                            }}>
                                                <option value="0" >กรุณาเลือก</option>
                                                <option value="100" >100%</option>
                                                <option value="90" >90%</option>
                                                <option value="80" >80%</option>
                                                <option value="70" >70%</option>
                                                <option value="60" >60%</option>
                                                <option value="50" >50%</option>
                                                <option value="1" >กำหนดเอง</option>
                                            </select>
                                            <SError error={errors["criteriaPercent"]} />
                                        </div>
                                        <div className="col-3">
                                            <label htmlFor="customcriteriaPercent" className="required">กำหนดเอง</label>
                                            <input type="text" className="form-control" id="customcriteriaPercent" name="customcriteriaPercent" placeholder="กรุณากรอก" value={customcriteriaPercent} onChange={(e) => { handleCustomCriteriaPercent(e); }} />
                                            <SError error={errors["customcriteriaPercent"]} />
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox" style={{ marginTop: 30 }}>
                                                <input type="checkbox" className="custom-control-input" id="displayTestResult" name="displayTestResult" checked={displayTestResult === true} onChange={(e) => { setDisplayTestResult(!displayTestResult) }} />
                                                <label className="custom-control-label" htmlFor="displayTestResult">แสดงผลการสอบ</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">การทดสอบ</div>
                                    <div className="row m-1">
                                        <div className="col-6">
                                            <label htmlFor="questionPerPage" className="required">จำนวนคำถามต่อหนึ่งหน้า (ไม่เกิน 50 คำถาม)</label>
                                            <input type="text" className="form-control" id="questionPerPage" name="questionPerPage" placeholder="กรุณากรอก" value={questionPerPage} onChange={(e) => { handleQuestionStep(e); }} />
                                            <SError error={errors["questionPerPage"]} />
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox" style={{ marginTop: 30 }}>
                                                <input type="checkbox" className="custom-control-input" id="allowCrossPage" name="allowCrossPage" checked={allowCrossPage === true} onChange={(e) => { setAllowCrossPage(!allowCrossPage) }} />
                                                <label className="custom-control-label" htmlFor="allowCrossPage">ทำข้อสอบข้ามหน้าได้</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">การกระทำ</div>
                                    <div className="row m-1">
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="allowRetry" name="allowRetry" checked={allowRetry === true} onChange={(e) => { setAllowRetry(!allowRetry) }} />
                                                <label className="custom-control-label" htmlFor="allowRetry">อนุญาตให้ทำต่อจากครั้งที่แล้ว </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="allowEditAnswer" name="allowEditAnswer" checked={allowEditAnswer === true} onChange={(e) => { setAllowEditAnswer(!allowEditAnswer) }} />
                                                <label className="custom-control-label" htmlFor="allowEditAnswer">อนุญาตให้แก้ไขคำตอบ </label>
                                            </div>
                                        </div>
                                        {allowRetry === true && (<div className="col-6">
                                            <label htmlFor="allowRetryCount" className="required">จำนวนครั้งสูงสุดในการเข้าสอบ</label>
                                            <input type="text" className="form-control" id="allowRetryCount" name="allowRetryCount" placeholder="กรุณากรอก" value={allowRetryCount} onChange={(e) => { handleAllowRetryCount(e); }} />
                                            <SError error={errors["allowRetryCount"]} />
                                        </div>)}

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
