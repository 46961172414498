import { React, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { authenticationService } from 'services/authentication.service.js';
/*import {
  useQuery,
  gql,
} from "@apollo/client";*/
//import * as menuData from '../../api/db/menu.json';
/*const NAVMENU = gql`
    query GetMenu{
  menus(where:{
    Status:{equals:"Y"}    
  },orderBy:{Order:asc},){
    MenuID
    NameMenuTH
    NameMenuEN
    ParentID
    Type
    Side
    WindowType
    Order
    Link
    Status
  }
}
  `;*/

const currentUser = authenticationService.currentUserValue;
function NavMenuItem({ item, menulist, level }) {
  let childrenlist = null;
  let newlevel = level + 1;
  let newli = null;
  let language = localStorage.getItem("language");
  //console.log(currentUser);
  //console.log(menulist);
  const childmenu = menulist.filter(x => x.ParentID === item.MenuID && (x.Side === 'FE' || x.Side === 'ME'));
  if (childmenu) {
    if ((item.Side === 'ME' && (currentUser !== null)) || item.Side === 'FE') {
      childrenlist = (
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          {childmenu.map(i => <NavMenuItem item={i} menulist={menulist} level={newlevel} key={i.MenuID} />)}
        </ul>
      );
      if (childmenu.length === 0) {
        newli = (
          <li>
            <Link className="nav-link" to={{ pathname: item.Link, state: { language: language } }} onClick={(e) => {
                  e.preventDefault();
                  window.location = item.Link;
                }}>
              {language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}
              <span className="sr-only">(current)</span>
            </Link>
          </li>
        );
      }
      else {
        if (level === 1) {
          newli = (
            <li className="dropdown-submenu">
              <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">{language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}</a>
              {/*<Link className="nav-link dropdown-toggle" to={{ pathname: item.Link, state: { language: language } }} data-toggle="dropdown" aria-expanded="false">
                {language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}
              </Link>*/}
              {childrenlist}
            </li>
          );
        }
        else {
          newli = (
            <li className="nav-item custom dropdown">
              <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">{language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}</a>
              {/*<Link className="nav-link dropdown-toggle" to={{ pathname: item.Link, state: { language: language } }} data-toggle="dropdown" aria-expanded="false">
                {language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}
              </Link>*/}
              {childrenlist}
            </li>
          );
        }
      }
    }
  }
  else {
    if ((item.Side === 'ME' && (currentUser !== null)) || item.Side === 'FE') {
      newli = (
        <li>
          <Link className="dropdown-item" to={{ pathname: item.Link, state: { language: language } }} data-toggle="dropdown" aria-expanded="false">
            {language === "Thai" ? item.NameMenuTH : item.NameMenuEN.trim().length === 0 ? item.NameMenuTH : item.NameMenuEN}
          </Link>
        </li>
      );
    }
  }
  return (
    newli
  );
}

const NavbarDropdown = () => {

  const [menus, setMenu] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getAllMenus();
  }, []);

  const getAllMenus = () => {
    if (currentUser !== null) {
      axios.get(process.env.REACT_APP_APIURL + '/api-web/menus', { params: { userid: currentUser.UserID } }).then((response) => {
        setMenu(response.data);
        setLoading(false);
      })
    }
    else {
      axios.get(process.env.REACT_APP_APIURL + '/api-web/menus').then((response) => {
        setMenu(response.data);
        setLoading(false);
      })
    }
    /*axios.get("http://localhost:5001/").then((response) => {
      setNodes(response.data);
      setLoading(false);
    });*/

  };


  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <ul className="navbar-nav custom">
      {
        menus.filter(x => x.ParentID === 0).map(i => (
          <NavMenuItem item={i} menulist={menus} level={0} key={i.MenuID} />
        ))
      }
    </ul>

  );
};

export default NavbarDropdown;