import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from 'jquery';
import { scroller } from "react-scroll";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//import idcardico from 'assets/images/icon/idcard.svg';
//import passportico from 'assets/images/icon/passport.svg';
const swal = withReactContent(Swal)
export default function ChangePassword(props) {
  let language = localStorage.getItem("language");
  const user = JSON.parse(localStorage.getItem('user'));
  const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errors, setError] = useState({});
  useEffect(() => {
    $(function () {
      $("#show_hide_password a").on('click', function (event) {
        //alert('click');
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") === "text") {
          $('#show_hide_password input').attr('type', 'password');
          $('#show_hide_password i').addClass("fa-eye-slash");
          $('#show_hide_password i').removeClass("fa-eye");
        }
        else if ($('#show_hide_password input').attr("type") === "password") {
          $('#show_hide_password input').attr('type', 'text');
          $('#show_hide_password i').removeClass("fa-eye-slash");
          $('#show_hide_password i').addClass("fa-eye");
        }
      });
    });
    return () => {
      //cleanup
    }
  }, []);
  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    if (newPassword.trim().length === 0) {
      formIsValid = false;
      serrors["newPassword"] = "กรุณากรอกรหัสผ่านใหม่";
    }
    if (confirmNewPassword.trim().length === 0) {
      formIsValid = false;
      serrors["confirmNewPassword"] = "กรุณากรอกข้อมูลยืนยันรหัสผ่านใหม่";
    }
    if (oldPassword.trim().length === 0) {
      formIsValid = false;
      serrors["oldPassword"] = "กรุณากรอกข้อมูลรหัสผ่านเก่า";
    }
    if (newPassword.trim() !== confirmNewPassword.trim()) {
      formIsValid = false;
      serrors["confirmNewPassword"] = "รหัสผ่านใหม่และการยืนยันรหัสผ่านใหม่ไม่ตรงกัน";
    }
    setError(serrors);
    return formIsValid;
  };
  const changePassword = (open) => {
    if (handleValidation()) {
      const formData = new FormData();
        
      formData.append("oldPassword", oldPassword);
      formData.append("newPassword", newPassword);      

      formData.append("userID", user.UserID);

      axios.post(process.env.REACT_APP_APIURL + '/api-web/changePassword', formData, {
          headers: {
              'Content-Type': `multipart/form-data`
          }
      })
        .then((response) => {
          //console.log(response.data)
          if (response.data.success === false) {
            swal.fire({
              icon: 'error',
              title: 'Failed',
              text: response.data.message,
              //footer: '<a href="">Why do I have this issue?</a>'
            })
          }
          else{
              let timerInterval;
              swal.fire({
                  title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                  timer: 1000, timerProgressBar: true,
                  didOpen: () => {
                      Swal.showLoading()
                      timerInterval = setInterval(() => {
                          const content = Swal.getHtmlContainer()
                          if (content) {
                              const b = content.querySelector('b')
                              if (b) {
                                  b.textContent = Swal.getTimerLeft()
                              }
                          }
                      }, 100)
                  },
                  willClose: () => {
                      clearInterval(timerInterval);

                  }
              }).then((result) => {
                  window.location.reload();
              })
            }
          });
    }
    else {
        setTimeout(() => {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                offset: -50,
                smooth: "easeInOutQuart",
            });
        }, 100);
    }
};
  return (
    <>
        <div className="container-fluid sec-event">
          <div className="container">
          <div className="row">
                      <div className="col-12">
              <div className="formContent">
                <h1 className="title-header"><span className="orang underline">เปลี่ยนรหัสผ่าน</span></h1>
                <form>
                  <div id="show_hide_password">
                    <input className="hasicon login-password" id="oldPassword" name="oldPassword" type="password" placeholder={language === "Thai" ? "รหัสผ่านเก่า" : "Old password"} autoComplete="off" value={oldPassword} onChange={(e)=>{setOldPassword(e.currentTarget.value)}}/>
                    <div className="input-addon">
                      <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i>&nbsp;</a>
                    </div>
                    <span className="error d-block">{errors["oldPassword"]}</span>
                  </div>
                  <div id="show_hide_password">
                    <input className="hasicon login-password" id="newPassword" name="newPassword" type="password" placeholder={language === "Thai" ? "รหัสผ่านใหม่" : "New password"} autoComplete="off" value={newPassword} onChange={(e)=>{setNewPassword(e.currentTarget.value)}} />
                    <div className="input-addon">
                      <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i>&nbsp;</a>
                    </div>
                    <span className="error d-block">{errors["newPassword"]}</span>
                  </div>
                  <div id="show_hide_password">
                    <input className="hasicon login-password" id="confirmNewPassword" name="confirmNewPassword" type="password" placeholder={language === "Thai" ? "ยืนยันรหัสผ่านใหม่" : "Confirm new password"} autoComplete="off" value={confirmNewPassword}  onChange={(e)=>{setConfirmNewPassword(e.currentTarget.value)}} />
                    <div className="input-addon">
                      <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i>&nbsp;</a>
                    </div>
                    <span className="error d-block">{errors["confirmNewPassword"]}</span>
                  </div>

                  <input type="submit" className="fadeIn fourth" value="ตกลง" onClick={(e) => { e.preventDefault(); changePassword(); }}/>
                </form>
              </div>
            </div>
          </div>
          {/*<div className="row">
              
              <div className="col">
                <ul id="icon-tab" className="nav nav-pills mb-3 mx-auto justify-content-center" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                      aria-controls="pills-home" aria-selected="true">
                      <div className="bg-orang icon-circle"><img src={idcardico} alt="ID Card" /></div>
                      <span className="button-tab">{language === "Thai" ? "บัตรประชาชน" : "ID Card"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                      aria-controls="pills-profile" aria-selected="true">
                      <div className="bg-orang icon-circle"><img src={passportico} alt="ID Card" /></div>
                      <span className="button-tab">{language === "Thai" ? "หนังสือเดินทาง" : "Passport"}</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="mhk-tabContent">

                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-12">
                        <div className="formContent">
                          <h1 className="title-header"><span className="orang underline">เปลี่ยนรหัสผ่าน</span></h1>
                          <form onSubmit={e => {
                            e.preventDefault();
                          }}>

                            <input type="text" id="IDCard" className="hasicon login-idcard" name="IDCard" placeholder={language === "Thai" ? "เลขที่บัตรประชาชน" : "ID Card"} autoComplete="off" />
                            <input type="text" id="Email" className="hasicon login-email" name="Email" placeholder={language === "Thai" ? "อีเมล" : "Email"} autoComplete="off" />
                            <input type="text" id="IDQuestion" className="hasicon login-question" name="IDQuestion" placeholder={language === "Thai" ? "คำถามยืนยันตัวตน" : "Identity verification question"} autoComplete="off" />
                            <input type="text" id="IDAnswer" className="hasicon login-answer" name="IDAnswer" placeholder={language === "Thai" ? "คำตอบยืนยันตัวตน" : "Identity verification answer"} autoComplete="off" />
                            <div id="show_hide_password">
                              <input className="hasicon login-password" id="IDCardPassword" name="IDCardPassowrd" type="password" placeholder={language === "Thai" ? "รหัสผ่านใหม่" : "New password"} autoComplete="off" />
                              <div className="input-addon">
                                <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i>&nbsp;</a>
                              </div>
                            </div>

                            <input type="submit" className="fadeIn fourth" value="ตกลง" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row">
                      <div className="col-12">
                        <div className="formContent">
                          <h1 className="title-header"><span className="orang underline">เปลี่ยนรหัสผ่าน</span></h1>
                          <form>
                            <input type="text" id="PassportID" className="hasicon login-idcard" name="PassportID" placeholder="เลขที่หนังสือเดินทาง" autoComplete="off" />
                            <input type="text" id="Email" className="hasicon login-email" name="Email" placeholder={language === "Thai" ? "อีเมล" : "Email"} autoComplete="off" value={email} readOnly={true} />
                            <input type="text" id="IDQuestion" className="hasicon login-question" name="IDQuestion" placeholder={language === "Thai" ? "คำถามยืนยันตัวตน" : "Identity verification question"} autoComplete="off" />
                            <input type="text" id="IDAnswer" className="hasicon login-answer" name="IDAnswer" placeholder={language === "Thai" ? "คำตอบยืนยันตัวตน" : "Identity verification answer"} autoComplete="off" />
                            <div id="show_hide_password">
                              <input className="hasicon login-password" id="IDCardPassword" name="IDCardPassowrd" type="password" placeholder={language === "Thai" ? "รหัสผ่านใหม่" : "New password"} autoComplete="off" />
                              <div className="input-addon">
                                <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i>&nbsp;</a>
                              </div>
                            </div>

                            <input type="submit" className="fadeIn fourth" value="ตกลง" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                        </div>
            </div>*/}
          </div>
        </div>
    </>
  );
}