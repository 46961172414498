import {useLocalStorage} from 'hooks/useLocalStorage';
import { useReadLocalStorage } from 'hooks/useReadLocalStorage';
import React from 'react';
import { Link } from "react-router-dom";
import clsx from 'classnames'
import styles from './cookie.module.css'



export default function Cookies() {

    // const [consent, setConsent] = useState(false);
    // const [hasConsentKey, setHasConsentKey] = useState(false);
    
    const keyText = "agreePolicyNhrc"
    const ttl = 604800;
    const readPolicyLocal =  useReadLocalStorage(keyText)
    const [readPolicy, setReadPolicy] = useLocalStorage(keyText, readPolicyLocal)
    
    
    
    // useEffect( () => {
    //     const hasConsent = getWithExpiry(keyText);
    //     console.log(hasConsent);
        
    //     if(hasConsent) {
    //         setHasConsentKey(true);
    //     }
    // }, [])

    // useEffect(() => {
    //     if(consent === true && hasConsentKey === false) {
    //         setWithExpiry(keyText, Date.now(), ttl)
    //     }
    //   }, [consent, hasConsentKey]);

    // const handleClick = event => {
    //     setConsent(true);
    // };

    // const getWithExpiry = key => {
    //     const itemStr = localStorage.getItem(key)
    //     console.log("itemStr", itemStr);
        
    //     if (!itemStr) {
    //         return null
    //     }
    //     const item = JSON.parse(itemStr)
    //     const now = new Date()
    //     if (now.getTime() > item.expiry) {
    //         localStorage.removeItem(key)
    //         return null
    //     }
    //     return item.value
    // }

    // const setWithExpiry = (key, value, ttl) => {
    //     const now = new Date()
    //     const item = {
    //         value: value,
    //         expiry: now.getTime() + ttl,
    //     }
    //     localStorage.setItem(key, JSON.stringify(item))
    // }

    // const cookiesBody = () => {
    //     return (  
    //         <>
    //             <div
    //                 className="cookies-bg">
    //                 <div className="container">
    //                     <div className="box-cookies">
    //                         <div className="row">
    //                             <div className='col-md-11 col-sm-8 col-xs-12'>
    //                                 <p>เว็บไซต์ของสำนักงานคณะกรรมการสิทธิมนุษยชนเเห่งชาติมีการใช้งานคุกกี้ที่จำเป็นอย่างยิ่ง (Strictly Necessary Cookies) เพื่อประสิทธิภาพการใช้งานเว็บไซต์ 
    //                                 ทั้งนี้ คุณสามารถศึกษารายละเอียดเกี่ยวกับการใช้คุกกี้ของสำนักงานคณะกรรมการสิทธิมนุษยชนเเห่งชาติได้ที่ <Link to={{ pathname: "/PolicyWeb" }}>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</Link></p>
    //                             </div>
    //                             <div className='col-md-1 col-sm-4 col-xs-12'>
    //                                 <button onClick={handleClick} type="button" className="btn btn-secondary">ยอมรับทั้งหมด</button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //        </>
    //     )
    // }

    if(typeof readPolicy?.expiry === 'number' ? new Date().getTime() < readPolicy?.expiry : false) return null
    
    // return (
    //     <>
    //         {
    //             consent === false && hasConsentKey === false ? cookiesBody() : <></>
    //         }
    //     </>
    // );

    return <div className={clsx('d-flex position-fixed', styles.paper)}>
        <div className='d-flex flex-column flex-md-row align-items-center p-2 mx-auto' style={{maxWidth: 1160}}>
            <div className='flex-grow-1 mx-2'>
            <p style={{color: '#FFF', fontSize: 25 }}>เว็บไซต์ของสำนักงานคณะกรรมการสิทธิมนุษยชนเเห่งชาติมีการใช้งานคุกกี้ที่จำเป็นอย่างยิ่ง (Strictly Necessary Cookies) เพื่อประสิทธิภาพการใช้งานเว็บไซต์ 
                ทั้งนี้ คุณสามารถศึกษารายละเอียดเกี่ยวกับการใช้คุกกี้ของสำนักงานคณะกรรมการสิทธิมนุษยชนเเห่งชาติได้ที่ <Link to={{ pathname: "/PolicyWeb" }}>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</Link></p>
            </div>
            <button onClick={() => setReadPolicy({value: new Date(), expiry: new Date().getTime() + ttl })} type="button" className="btn btn-secondary cursor-pointer" style={{backgroundColor: '#2095d3', borderColor: '#2095d3', width: 200}}>ยอมรับทั้งหมด</button>
        </div>
    </div>
}