import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
import noimage from 'assets/images/no_image.svg'
import moment from "moment";
import SingleFileTable from 'components/SingleFileTable';
import FileReorder from './../../../components/FileReorder';
const swal = withReactContent(Swal)

export default function EditActivity(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});
    const [filespreview, setFilesPreview] = useState([]);
    const [myFiles, setMyFiles] = useState([]);

    const [imgWebCover, setIMGWebCover] = useState([]);
    const [imgMobileCover, setIMGMobileCover] = useState([]);
    const [selectActivityCat, SetSelectActivityCat] = useState([]);

    const [thname, setNameTH] = useState('');
    const [enname, setNameEN] = useState('');

    const [thcontent, setTHContent] = useState("");
    const [encontent, setENContent] = useState("");
    const [activitycat, setActivityCat] = useState(0);

    const [regstartDate, SetRegStartDate] = useState(null);
    const [regendDate, SetRegEndDate] = useState(null);
    const [activitystartDate, SetActivityStartDate] = useState(new Date());
    const [activityendDate, SetActivityEndDate] = useState(new Date());
    const [publishstartDate, SetPublishStartDate] = useState(null);
    const [publishendDate, SetPublishEndDate] = useState(null);
    const [organizer, setOrganizer] = useState('');
    const [lecturer, setLecturer] = useState('');
    const [location, setLocation] = useState('');
    const [suitable, setSuitable] = useState('');
    const [questionairelink, setQuestionairelink] = useState('');
    const [broadcastlink, setBroadcastlink] = useState('');
    const [published, setPublished] = useState(false);
    const [pin, setPin] = useState(false);
    const [invite, setInvite] = useState(false);
    const [comment, setComment] = useState(1);
    //const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    const rx_int = /^\d+$/;
    const rx_latln = /^-?[0-9.,]+$/;
    const [maxregister, setMaxRegister] = useState(null);
    const [reserveregister, setReserveRegister] = useState(null);
    const handlelocationChange = (e) => {
        if (e.target.value === ''||rx_latln.test(e.target.value)) {
            setLocation(e.target.value);
        }
    }
    const handleMaxRegisterChange = (e) => {
        if (e.target.value === '') {
            setMaxRegister(null);
            //setReserveRegister(null);
        }
        else if (rx_int.test(e.target.value)) {
            setMaxRegister(parseInt(e.target.value));
            //setReserveRegister(parseInt(e.target.value));
        }
    }
    useEffect(() => {
        //console.log(props.location.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/activitybyID', { params: { id: props.location.state.id, userID: currentUser.UserID } }).then((response) => {
            //console.log(response.data[0])
            let data = response.data[0];
            setNameTH(data.TitleTH);
            setNameEN(data.TitleEN);
            setTHContent(data.DescriptionTH);
            setENContent(data.DescriptionEN);
            setPublished(data.Published);
            setActivityCat(data.ActivityCatID);
            if (data.MaxRegis !== null)
                setMaxRegister(data.MaxRegis)
            if (data.ReserveRegis !== null)
                setReserveRegister(data.ReserveRegis)
            if (data.RegisStartDate !== null)
                SetRegStartDate(new Date(data.RegisStartDate))
            if (data.RegisEndDate !== null)
                SetRegEndDate(new Date(data.RegisEndDate))
            SetActivityStartDate(new Date(data.ActivityStartDate))
            SetActivityEndDate(new Date(data.ActivityEndDate))
            SetPublishStartDate(new Date(data.PublishedStartDate))
            SetPublishEndDate(new Date(data.PublishedEndDate))
            if (data.Organizer)
                setOrganizer(data.Organizer)
            setLecturer(data.Lecturer)
            if (data.Location!==null)
                setLocation(data.Location)
            if (data.Suitable!==null)
                setSuitable(data.Suitable)
            if (data.QuestionaireLink)
                setQuestionairelink(data.QuestionaireLink)
            if (data.BroadCastLink)
                setBroadcastlink(data.BroadCastLink)
            if (data.Pin)
                setPin(data.Pin)
            if (data.Invite)
                setInvite(data.Invite)
            setComment(data.Comment)
            if (data.ThumbnailWeb !== null) {
                const webimageFile = {
                    file: data.ThumbnailWeb,
                    name: data.ThumbnailWeb.substring(data.ThumbnailWeb.lastIndexOf('/') + 1),
                    preview: data.ThumbnailWeb,
                    size: utils.getFileSize(data.ThumbnailWeb)
                };
                setIMGWebCover(webimageFile);
            }
            if (data.ThumbnailMobile !== null) {
                const mobileimageFile = {
                    file: data.ThumbnailMobile,
                    name: data.ThumbnailMobile.substring(data.ThumbnailMobile.lastIndexOf('/') + 1),
                    preview: data.ThumbnailMobile,
                    size: utils.getFileSize(data.ThumbnailMobile)
                };
                setIMGMobileCover(mobileimageFile);
            }
            //setMyFiles(data.filelist);
            const newFiles = [...myFiles];
            for (let i = 0; i < data.filelist.length; i += 1) {
                //let filedata ={path:data.filelist[i].FilenameNew, size:data.filelist[i].Filesize}
                const filedata = {
                    //file: newImageFile[0],
                    id: data.filelist[i].AttachmentID,
                    path: data.filelist[i].FilenameNew,
                    name: data.filelist[i].FilenameOld,
                    preview: data.filelist[i].AttachmentPath,
                    size: data.filelist[i].Filesize
                };
                newFiles[i] = filedata;

            }
            setMyFiles(newFiles);
        })
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchActivityCat', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
            });
    }, []); /* <-- add this for run once*/
    useEffect(() => {
        if (moment(regstartDate).format('YYYY-MM-DD') > moment(regendDate).format('YYYY-MM-DD')) {
            SetRegEndDate(regstartDate);
        }
    }, [regstartDate]);
    useEffect(() => {
        if (moment(regendDate).format('YYYY-MM-DD') < moment(regstartDate).format('YYYY-MM-DD')) {
            SetRegStartDate(regendDate);
        }
    }, [regendDate]);
    useEffect(() => {
        if (moment(activitystartDate).format('YYYY-MM-DD HH:mm:ss') > moment(activityendDate).format('YYYY-MM-DD HH:mm:ss')) {
            SetActivityEndDate(activitystartDate);
        }
    }, [activitystartDate]);
    useEffect(() => {
        if (moment(activityendDate).format('YYYY-MM-DD HH:mm:ss') < moment(activitystartDate).format('YYYY-MM-DD HH:mm:ss')) {
            SetActivityStartDate(activityendDate);
        }
    }, [activityendDate]);
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);
    const ActivityCatSelect = selectActivityCat.map((activitycat) => (
        <option
            key={activitycat.ActivityCatID}
            value={activitycat.ActivityCatID}
        >
            {activitycat.TitleTH}
        </option>
    ));
    const files = myFiles.map((file, i) => (

        <tr key={file.path}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const HandlePin = (pinStatus) => {
        if (pinStatus === true) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getActivityPinCount')
                .then((response) => {
                    //console.log(response.data[0].PinCount)
                    if (Number(response.data[0].PinCount) >= 3) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: 'มีข้อมูลในการปักหมุดครบจำนวนสามชุดแล้ว',
                        })
                    }
                    else {
                        setPin(pinStatus)
                    }
                });
        }
        else {
            setPin(pinStatus)
        }
    }
    const handleRemoveFile = filename => {
        for (let file of myFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setMyFiles(myFiles.filter(item => item['name'] !== filename));
    };

    const WebCoverPreview = (
        <img className="preview-upload" src={typeof imgWebCover.preview === 'undefined' ? noimage : imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt="Web Cover Preview" />
    );

    const MobileCoverPreview = (
        <img className="preview-upload" src={typeof imgMobileCover.preview === 'undefined' ? noimage : imgMobileCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgMobileCover); }} alt="Mobile Cover Preview" />
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGWebCover(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGMobileCover(imageFile);
    };

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (thname.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (thcontent.trim().length === 0) {
            formIsValid = false;
            serrors["thcontent"] = "กรุณาระบุรายละเอียดภาษาไทย";
        }
        /*if (enname.trim().length===0) {
            formIsValid = false;
            serrors["enname"] = "กรุณาระบุชื่อภาษาอังกฤษ";
        }
        if(encontent.trim().length===0){
            formIsValid = false;
            serrors["encontent"] = "กรุณาระบุรายละเอียดภาษาอังกฤษ";
        }*/
        if (activitycat === 0) {
            formIsValid = false;
            serrors["activitycat"] = "กรุณาเลือกหมวดหมู่กิจกรรม";
        }
        /*if (maxregister === 0) {
            formIsValid = false;
            serrors["maxregister"] = "กรุณากำหนดจำนวนผู้เข้าร่วมกิจกรรม";
        }*/
        if (organizer.trim().length === 0) {
            formIsValid = false;
            serrors["organizer"] = "กรุณากรอกข้อมูล";
        }
        /*if (lecturer.trim().length === 0) {
            formIsValid = false;
            serrors["lecturer"] = "กรุณากรอกข้อมูล";
        }
        if (suitable.trim().length === 0) {
            formIsValid = false;
            serrors["suitable"] = "กรุณากรอกข้อมูล";
        }*/
        if (imgWebCover.length === 0) {
            formIsValid = false;
            serrors["webcover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับเว็บ";
        }
        if (imgMobileCover.length === 0) {
            formIsValid = false;
            serrors["mobilecover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับมือถือ";
        }
        if (new Date(activitystartDate) > new Date(activityendDate)) {
            formIsValid = false;
            serrors["activityday"] = "ช่วงวันเวลาไม่ถูกต้อง";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", props.location.state.id);
            formData.append("thname", thname);
            formData.append("thcontent", thcontent);
            formData.append("enname", enname);
            formData.append("encontent", encontent);
            formData.append("catid", activitycat);
            formData.append("maxregister", maxregister);
            formData.append("reserveregister", reserveregister);
            formData.append("regstartDate", regstartDate);
            formData.append("regendDate", regendDate);
            formData.append("activitystartDate", activitystartDate);
            formData.append("activityendDate", activityendDate);
            formData.append("organizer", organizer);
            formData.append("lecturer", lecturer);
            formData.append("location", location);
            formData.append("suitable", suitable);
            formData.append("questionairelink", questionairelink);
            formData.append("broadcastlink", broadcastlink);
            formData.append("pin", pin);
            formData.append("invite", invite);
            formData.append("published", published);
            formData.append("publishstartDate", publishstartDate);
            formData.append("publishendDate", publishendDate);
            formData.append("comment", comment);
            formData.append("webcoverfile", imgWebCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("updateby", currentUser.UserID);
            for (let i = 0; i < myFiles.length; i += 1) {
                //console.log(myFiles[i])
                formData.append("files[]", myFiles[i]);
            }

            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateActivity', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/activity/Activity" });
                        }
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);

        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/activity/Activity" });
    };
    const handleRemoveWebCover = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", imgWebCover.file);
        formData.append("fieldName", 'ThumbnailWeb');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteActivityCover', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setIMGWebCover('undefined');
    };
    const handleRemoveMobileCover = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", imgMobileCover.file);
        formData.append("fieldName", 'ThumbnailMobile');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteActivityCover', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setIMGMobileCover('undefined');
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการกิจกรรม</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/activity/Activity" }}>{"จัดการกิจกรรม"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/activity/NewActivityCategory" }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="thname" className="required">ชื่อเรื่อง (ภาษาไทย)</label>
                                            <input type="text" className="form-control" id="thname" name="thname" placeholder="กรุณากรอก" value={thname} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                            <SError error={errors["thname"]} />
                                        </div>
                                        <div className="form-group">
                                            <label className="required">รายละเอียด (ภาษาไทย)</label>
                                            <ReactSummernote
                                                value={thcontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setTHContent}
                                            />
                                            <SError error={errors["thcontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="enname">ชื่อเรื่อง (ภาษาอังกฤษ)</label>
                                            <input type="text" className="form-control" id="enname" name="enname" placeholder="กรุณากรอก" value={enname} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["enname"]} />
                                        </div>
                                        <div className="form-group">
                                            <label>รายละเอียด (ภาษาอังกฤษ)</label>
                                            <ReactSummernote
                                                value={encontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setENContent}
                                            />
                                            <SError error={errors["encontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-5">
                                    <label htmlFor="activitycat" className="required">หมวดหมู่</label>
                                    <select className="form-control mb-2 mr-2" id="activitycat" name="activitycat" value={activitycat} onChange={(e) => { setActivityCat(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ActivityCatSelect}
                                    </select>
                                    <SError error={errors["activitycat"]} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="maxregister" >จำนวนที่นั่ง</label>
                                    <input type="text" className="form-control " id="maxregister" value={maxregister===null?'':maxregister} name="maxregister" placeholder="กรุณากรอก" onChange={(e) => { handleMaxRegisterChange(e) }} />
                                    <SError error={errors["maxregister"]} />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="reserveregister" >จำนวนที่นั่งเหลือ</label>
                                    <input type="text" className="form-control " id="reserveregister" value={reserveregister===null?maxregister===null?'':maxregister:maxregister-reserveregister} name="reserveregister" placeholder="กรุณากรอก" readOnly />
                                </div>
                                <div className="col-5">
                                    <label htmlFor="registerstart" >วันที่เปิดลงทะเบียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={regstartDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            /*maxDate={regendDate}*/
                                            onChange={SetRegStartDate} name="registerstart" id="registerstart" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-5">
                                    <label htmlFor="registerend" >วันที่ปิดลงทะเบียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={regendDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            /*minDate={regstartDate}*/
                                            onChange={SetRegEndDate} name="registerend" id="registerend" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-5">
                                    <label htmlFor="startactivityday" className="required">วันที่เริ่มจัดกิจกรรม</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DateTimePicker value={activitystartDate} format="D MMMM YYYY HH:mm"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            /*maxDate={activityendDate}*/
                                            onChange={SetActivityStartDate} name="startactivityday" id="startactivityday" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-5">
                                    <label htmlFor="endactivityday" className="required">วันที่สิ้นสุดกิจกรรม</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DateTimePicker value={activityendDate} format="D MMMM YYYY HH:mm"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            /*minDate={activitystartDate}*/
                                            onChange={SetActivityEndDate} name="endactivityday" id="endactivityday" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-12"><SError error={errors["activityday"]} /></div>
                                <div className="col-5">
                                    <label htmlFor="organizer" className="required">จัดกิจกรรมโดย</label>
                                    <input type="text" className="form-control " id="organizer" name="organizer" placeholder="กรุณากรอก" value={organizer} onChange={(e) => { setOrganizer(e.currentTarget.value); }} />
                                    <span className="error">{errors["organizer"]}</span>
                                </div>
                                <div className="col-5">
                                    <label htmlFor="lecturer" >วิทยากร</label>
                                    <input type="text" className="form-control " id="lecturer" name="lecturer" placeholder="กรุณากรอก" value={lecturer} onChange={(e) => { setLecturer(e.currentTarget.value); }} />
                                    <span className="error">{errors["lecturer"]}</span>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-12">
                                    <label htmlFor="location">พิกัดจัดงาน (ละติจูด,ลองจิจูด)</label>
                                    <input type="text" className="form-control " id="location" name="location" placeholder="กรุณากรอก ละติจูด,ลองจิจูด" value={location} onChange={(e) => { handlelocationChange(e) }} />
                                    <span className="error">{errors["location"]}</span>
                                </div>
                                <div className="col-5">
                                    <label htmlFor="suitable" >เหมาะสำหรับ</label>
                                    <input type="text" className="form-control " id="suitable" name="suitable" placeholder="กรุณากรอก" value={suitable} onChange={(e) => { setSuitable(e.currentTarget.value); }} />
                                    <span className="error">{errors["suitable"]}</span>
                                </div>
                                <div className="col-5">
                                    <label htmlFor="questionairelink">Link แบบสอบถาม</label>
                                    <input type="text" className="form-control " id="questionairelink" name="questionairelink" placeholder="กรุณากรอก" value={questionairelink} onChange={(e) => { setQuestionairelink(e.currentTarget.value); }} />
                                    <span className="error">{errors["questionairelink"]}</span>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-5">
                                    <label htmlFor="broadcastlink">Link การถ่ายทอดสด</label>
                                    <input type="text" className="form-control " id="broadcastlink" name="broadcastlink" placeholder="Link จาก Facebook หรือ Youtube" value={broadcastlink} onChange={(e) => { setBroadcastlink(e.currentTarget.value); }} />
                                    <span className="error">{errors["broadcastlink"]}</span>
                                </div>
                                <div className="col-7"></div>
                                <div className="col-12">
                                    <label className="required mr-2">ปักหมุด</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin1" value="1" checked={pin === true} onChange={(e) => { HandlePin(true); }} />
                                        <label className="form-check-label" htmlFor="pin1"> ปักหมุด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin2" value="0" checked={pin === false} onChange={(e) => { HandlePin(false); }} />
                                        <label className="form-check-label" htmlFor="pin2"> ไม่ปักหมุด</label>
                                    </div>
                                    <span className="error">{errors["pin"]}</span>

                                    <label className="required mr-2">การ Invite</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="invite" id="invite1" value="1" checked={invite === true} onChange={(e) => { setInvite(true); }} />
                                        <label className="form-check-label" htmlFor="invite1"> เปิด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="invite" id="invite2" value="0" checked={invite === false} onChange={(e) => { setInvite(false); }} />
                                        <label className="form-check-label" htmlFor="invite2"> ปิด</label>
                                    </div>
                                    <span className="error">{errors["invite"]}</span>
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published1" value="1" checked={published === true} onChange={(e) => { setPublished(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published2" value="0" checked={published === false} onChange={(e) => { setPublished(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <span className="error">{errors["published"]}</span>
                                </div>
                                {published === true && <>
                                    <div className="col-5">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                {SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-5">
                                        <label className="required">วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                /*minDate={publishstartDate}*/
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-2"></div>
                                </>}
                                <div className="col-12">
                                    <label className="required mr-2">แสดงความคิดเห็น</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment1" value="1" checked={comment === 1} onChange={(e) => { setComment(1); }} />
                                        <label className="form-check-label" htmlFor="comment1"> เปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment2" value="2" checked={comment === 2} onChange={(e) => { setComment(2); }} />
                                        <label className="form-check-label" htmlFor="comment2"> ปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment3" value="3" checked={comment === 3} onChange={(e) => { setComment(3); }} />
                                        <label className="form-check-label" htmlFor="comment3"> เปิดแสดงความคิดเห็นอย่างเดียว</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment4" value="4" checked={comment === 4} onChange={(e) => { setComment(4); }} />
                                        <label className="form-check-label" htmlFor="comment4"> ปิดกล่องแสดงความคิดเห็น</label>
                                    </div>
                                    <span className="error">{errors["comment"]}</span>
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับเว็บไซต์</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgWebCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebCoverPreview}
                                                <SingleFileTable filedata={imgWebCover} removefn={handleRemoveWebCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["webcover"]}</span>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" className="required">รูปภาพหน้าปก สำหรับมือถือ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                                <SingleFileTable filedata={imgMobileCover} removefn={handleRemoveMobileCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["mobilecover"]}</span>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" >ไฟล์แนบ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMyFiles([...myFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    {/* <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {files}
                                                            </tbody>
                                                        </table>
                                                    </div> */}
                                                    <FileReorder files={myFiles}/>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40 margintop40" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
