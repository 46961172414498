import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import utils from "utils/utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
/*import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';*/
import AdminBanner from "components/Banner/AdminBanner";
//const swal = withReactContent(Swal)

export default function RelationReorder(props) {
  //const currentUser = authenticationService.currentUserValue;
  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  //const [newsCat, setNewsCat] = useState([]);

  useEffect(() => {
    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/newsCat')
            .then((response) => {
                setNewsCat(response.data);
            });*/
  }, []); /* <-- add this for run once*/
  const StatusRenderer = (params) => {
    if (params.data.Status === true) return "เผยแพร่";
    else if (params.data.Status === false) return "ไม่เผยแพร่";
    return "-";
  };
  const TypeRenderer = (params) => {
    if (Number(params.data.IsPersonType) === 1) return "บุคคล";
    else if (Number(params.data.IsPersonType) === 2) return "องค์กร";
    return "-";
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    const updateData = (data) => {
      setRowData(data);
    };

    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/masterRelationship")
      .then((response) => {
        updateData(response.data.results);
      });
  };

  const fetchData = () => {
    const updateData = (data) => {
      setRowData(data);
    };
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/masterRelationship")
      .then((response) => {
        updateData(response.data.results);
      });
  };

  const getRowHeight = (params) => {
    //console.log(params.data.rowHeight);
    //return params.data.rowHeight;
    return 80;
  };
  const statusRenderer = (params) => {
    if (params.value === true) return "เผยแพร่";
    return "ไม่เผยแพร่";
  };
  const pinRenderer = (params) => {
    if (params.value === true) return "ปักหมุด";
    return "ไม่ปักหมุด";
  };
  const createYearCellRenderer = (params) => {
    if (params.value == null)
      return (
        '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' +
        utils.MariatoThaiDateString(params.data.CreatedDate) +
        "</p></span>"
      );
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.CreatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const updateYearCellRenderer = (params) => {
    if (params.value == null) return "";
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.UpdatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };

  var hashValueGetter = function (params) {
    return params.node.rowIndex + 1;
  };

  const onRowDragEnd = (event) => {
    let orderItem = [];
    gridApi.forEachNode((rowNode, index) => {
      orderItem.push(rowNode.data.id);
    });

    let order = orderItem;
   
    axios
      .put(
        process.env.REACT_APP_APIURL + "/api-web/reorder/network/relation",
        { ordering: order },
        {}
      )
      .then((response) => {
        fetchData();
      });
  };

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{ pathname: "/admin/network/RelationList" }}
            >
              {"การจัดการความสัมพันธ์"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/network/RelationReorder" }}
            >
              {"จัดลำดับ"}
            </Link>
          </>
        }
      />

      <div className="col-12">
        <div className="ag-theme-alpine" style={{ height: 100 + "vh" }}>
          <AgGridReact
            autoGroupColumnDef={{
              headerName: "Group",
              minWidth: 30,
              field: "CategoriesID",
              valueGetter: function (params) {
                if (params.node.group) {
                  return params.node.key;
                } else {
                  return params.data[params.colDef.field];
                }
              },
              headerCheckboxSelection: true,
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: { checkbox: true },
            }}
            modules={AllCommunityModules}
            frameworkComponents={{}}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              //cellClass: "cell-border cell-vertical-align-text-left",
            }}
            getRowHeight={getRowHeight}
            onGridReady={onGridReady}
            rowData={rowData}
            rowSelection={"multiple"}
            rowDragManaged={true}
            pagination={false}
            //paginationPageSize= {10}
            onRowDragEnd={onRowDragEnd}
          >
            <AgGridColumn
              field="Ordering"
              headerName="เรียงลำดับ"
              minWidth={50}
              rowDrag={true}
            />
            <AgGridColumn
              field=""
              valueGetter={hashValueGetter}
              headerName="ลำดับ"
              minWidth={70}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn
              field="title_TH"
              headerName="ชื่อรายการ"
              minWidth={200}
            />

            <AgGridColumn
              field="is_active"
              headerName="สถานะ"
              cellRenderer={StatusRenderer}
              sortable={true}
              minWidth={130}
            />
            <AgGridColumn
              field="CreatedDate"
              headerName="ผู้สร้าง"
              cellRenderer={createYearCellRenderer}
              minWidth={175}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn
              field="UpdatedDate"
              headerName="ผู้แก้ไข"
              cellRenderer={updateYearCellRenderer}
              minWidth={175}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
          </AgGridReact>
        </div>
      </div>
    </section>
  );
}
