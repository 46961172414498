import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import SError from "components/SError";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import Footer from "components/Footer.js";
import { authenticationService } from "services/authentication.service.js";
import { scroller } from "react-scroll";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import "assets/styles/custompage.css";
const swal = withReactContent(Swal);
export default function ViewSurvey(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [nameTH, setNameTH] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [detailTH, setDetailTH] = useState("");
  const [detailEN, setDetailEN] = useState("");
  const [published, setPublished] = useState(false);
  const [ispublished, setIsPublished] = useState(true);
  const [questionSet, setQuestionSet] = useState([]);
  const [answerSet, setAnswerSet] = useState([]);
  const [userID, setUserID] = useState(null);
  const [errors, setError] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const surveyID = props.match.params.id;

  useEffect(() => {
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    setUserID(userid);
  }, [currentUser]); /* <-- add this for run once*/

  useEffect(() => {
    console.log(surveyID);
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getSurveybyID", {
        params: { id: surveyID },
      })
      .then((response) => {
        let questionlist = [];
        let answerlist = [];
        setNameTH(response.data[0].SurveyNameTH);
        setNameEN(response.data[0].SurveyNameEN);
        setDetailTH(response.data[0].SurveyDetailTH);
        setDetailEN(response.data[0].SurveyDetailEN);
        setPublished(response.data[0].Published);
        let now = new Date(Date.now());
        if (
          moment(response.data[0].PublishedEndDate).format("YYYY-MM-DD") <
          moment(now).format("YYYY-MM-DD")
        ) {
          setIsPublished(false);
          setWarningMessage("สิ้นสุดระยะเวลาในการทำแบบสอบถามนี้แล้ว");
        }
        if (
          moment(response.data[0].PublishedStartDate).format("YYYY-MM-DD") >
          moment(now).format("YYYY-MM-DD")
        ) {
          setIsPublished(false);
          setWarningMessage("ยังไม่ถึงช่วงเวลาในการทำแบบสอบถาม");
        }
        if (response.data[0].Published === false) {
          setIsPublished(false);
          setWarningMessage(
            "แบบสอบถามนี้ยังไม่ได้ทำการเผยแพร่หรือได้ยุติการเผยแพร่ไปแล้ว"
          );
        }
        axios
          .get(
            process.env.REACT_APP_APIURL + "/api-web/getSurveyQuestionbyID",
            { params: { id: surveyID } }
          )
          .then((response) => {
            questionlist = response.data;
            axios
              .get(
                process.env.REACT_APP_APIURL + "/api-web/getSurveyAnswerbyID",
                { params: { id: surveyID } }
              )
              .then((aresponse) => {
                answerlist = aresponse.data;
                //console.log(questionlist)
                for (let i = 0; i < questionlist.length; i++) {
                  let question = questionlist[i];
                  //console.log(question)
                  setQuestionSet((prevQuestions) => [
                    ...prevQuestions,
                    {
                      SurveyQuestionID: question.SurveyQuestionID,
                      SurveyID: question.SurveyID,
                      QuestionType: question.QuestionType,
                      QuestionTitle: question.QuestionTitle,
                      AnswerNum: question.AnswerNum,
                      AnswerList: answerlist.filter(
                        (item) =>
                          Number(item.SurveyQuestionID) ===
                          Number(question.SurveyQuestionID)
                      ),
                    },
                  ]);
                  setAnswerSet((prevAnswers) => [
                    ...prevAnswers,
                    {
                      SurveyQuestionID: question.SurveyQuestionID,
                      QuestionType: question.QuestionType,
                      SurveyID: question.SurveyID,
                      AnswerID: 0,
                      AnswerIDList: [],
                      AnswerText: "",
                    },
                  ]);
                }
                setLoading(false);
              });
          });
      });
  }, []); /* <-- add this for run once*/

  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    let check = answerSet.filter(
      (item) =>
        item.AnswerText === "" &&
        item.AnswerID === 0 &&
        item.AnswerIDList.length === 0
    );
    if (check.length > 0) {
      formIsValid = false;
    }
    if (formIsValid === false) {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    }
    //Name
    /*
        let questionerror="";
        let questionvertify = true;
        for(let i=0; i<questionSet.length; i++){
            let item = questionSet[i];
            let tmperror="";
            
            if (Number(item.QuestionType) === 0 ) {
                tmperror += '\r\nกรุณาเลือกประเภทตัวเลือกของคำถาม';               
            }
            if (item.QuestionTitle.trim().length === 0) {
                tmperror += '\r\nกรุณากรอกคำถาม';
            }
            if ((Number(item.QuestionType) > 0 && Number(item.QuestionType) < 4) && item.AnswerList.length === 0) {
                tmperror += '\r\nกรุณาระบุจำนวนคำตอบ';
            }
            if ((Number(item.QuestionType) > 0 && Number(item.QuestionType) < 4) && item.AnswerList.length > 0) {
                for (let i = 0; i < item.AnswerList.length; i++) {
                    let ans = item.AnswerList[i];
                    if(ans.AnswerTitle.trim().length===0){
                        tmperror += '\r\nกรุณากรอกข้อมูลคำตอบ ลำดับที่ '+(i+1).toString();                        
                    }
                }
                
            }  
            if(tmperror.trim().length > 0){
                questionvertify = false;
                questionerror += '\r\nผบข้อผิดพลาดในคำถามลำดับที่ '+(i+1).toString()+tmperror;
            }
        }
        if (questionvertify===false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: questionerror,
            })
            return;
        }
        if(formIsValid===false){
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }*/
    setError(serrors);
    return formIsValid;
  };
  const saveData = (open) => {
    if (handleValidation()) {
      console.log("answerSet", answerSet);
      const formData = new FormData();
      formData.append("surveyID", surveyID);
      if (userID === null) setUserID(0);
      formData.append("userID", userID);
      for (var i = 0; i < answerSet.length; i++) {
        formData.append("answerSet[]", JSON.stringify(answerSet[i]));
      }
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/userSendSurvey",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              if (open) {
                window.location.reload();
              } else {
                props.history.push({ pathname: "/" });
              }
            });
        });
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };
  const cancel = () => {
    props.history.push({ pathname: "/" });
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"แบบสอบถาม"}
          eheader={"Survey"}
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link to={{ pathname: "/survey/SurveyList" }}>
                {language === "Thai" ? " แบบสอบถาม " : " Survey "}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/survey/ViewSurvey/" + surveyID,
                  state: { id: surveyID },
                }}
              >
                {language === "Thai"
                  ? nameTH
                  : nameEN.trim().length === 0
                  ? nameTH
                  : nameEN}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section
            className="edit-profile row custom-survey"
            style={{ marginTop: 0 }}
          >
            <div className="content-data">
              <div className="news-activity-data">
                <div className="row">
                  <div className="col-12">
                    <div className="sub-title">
                      <h2 className="text-center underline-header">
                        {" "}
                        {language === "Thai"
                          ? nameTH
                          : nameEN.trim().length === 0
                          ? nameTH
                          : nameEN}{" "}
                      </h2>
                      <div className="underline-title"></div>
                    </div>
                    <div
                      className="sub-title-detail"
                      dangerouslySetInnerHTML={{
                        __html:
                          language === "Thai"
                            ? detailTH
                            : detailEN.trim().length === 0
                            ? detailTH
                            : detailEN,
                      }}
                    />
                  </div>
                </div>
              </div>
              {(ispublished === false || published === false) && (
                <div className="row custom-survey">
                  <div className="col-12">
                    <h3 className="text-danger">{warningMessage}</h3>
                  </div>
                </div>
              )}
              {ispublished === true && published === true && (
                <>
                  <div className="row custom-survey">
                    <div className="col-12">
                      {questionSet.map((item, i) => (
                        <div
                          key={"question-" + item.SurveyQuestionID}
                          className="row mt-2 question-item"
                        >
                          <div className="card-list detail">
                            <div className="detail-content">
                              <div className="col-12 detail-question">
                                <h2>
                                  {i + 1}. {item.QuestionTitle}
                                </h2>
                              </div>

                              {Number(item.QuestionType) === 1 && (
                                <div className="detail-answer">
                                  {item.AnswerList.map((ansitem, j) => (
                                    <div
                                      className="col-12 ans"
                                      key={"ans-" + ansitem.SurveyAnswerID + j}
                                    >
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input "
                                          type="radio"
                                          name={"ans-" + item.SurveyQuestionID}
                                          id={
                                            "ans-" + ansitem.SurveyAnswerID + j
                                          }
                                          checked={
                                            Number(answerSet[i].AnswerID) ===
                                            Number(ansitem.SurveyAnswerID)
                                              ? true
                                              : false
                                          }
                                          value={ansitem.SurveyAnswerID}
                                          onChange={(e) => {
                                            answerSet[i].AnswerID =
                                              ansitem.SurveyAnswerID;
                                            setAnswerSet([...answerSet]);
                                            console.log(answerSet);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label answer-input-radio"
                                          htmlFor={
                                            "ans-" + ansitem.SurveyAnswerID + j
                                          }
                                        >
                                          {" "}
                                          {ansitem.AnswerTitle}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                  <SError
                                    error={
                                      errors[
                                        "question-" + item.SurveyQuestionID
                                      ]
                                    }
                                  />
                                </div>
                              )}
                              {Number(item.QuestionType) === 2 && (
                                <div className="detail-answer">
                                  {item.AnswerList.map((ansitem, j) => (
                                    <div
                                      className="col-12 ans"
                                      key={"ans-" + ansitem.SurveyAnswerID + j}
                                    >
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input "
                                          name={"ans-" + item.SurveyQuestionID}
                                          id={
                                            "ans-" + ansitem.SurveyAnswerID + j
                                          }
                                          checked={
                                            typeof answerSet[
                                              i
                                            ].AnswerIDList.find((asc) => {
                                              return (
                                                Number(asc) ===
                                                ansitem.SurveyAnswerID
                                              );
                                            }) !== "undefined"
                                              ? true
                                              : false
                                          }
                                          value={ansitem.SurveyAnswerID}
                                          onChange={(e) => {
                                            setAnswerSet(
                                              answerSet.map((value, k) => {
                                                if (k === i) {
                                                  if (e.target.checked) {
                                                    value.AnswerIDList.push(
                                                      ansitem.SurveyAnswerID
                                                    );
                                                  } else {
                                                    const rmorder =
                                                      value.AnswerIDList.indexOf(
                                                        ansitem.SurveyAnswerID
                                                      );
                                                    //console.log(rmorder)
                                                    if (rmorder >= 0) {
                                                      value.AnswerIDList.splice(
                                                        rmorder,
                                                        1
                                                      );
                                                    }
                                                  }
                                                }
                                                return value;
                                              })
                                            );
                                            //console.log(answerSet)
                                          }}
                                        />
                                        <label
                                          className="custom-control-label answer-input-radio"
                                          htmlFor={
                                            "ans-" + ansitem.SurveyAnswerID + j
                                          }
                                        >
                                          {ansitem.AnswerTitle}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                  <SError
                                    error={
                                      errors[
                                        "question-" + item.SurveyQuestionID
                                      ]
                                    }
                                  />
                                </div>
                              )}
                              {Number(item.QuestionType) === 3 && (
                                <div style={{ position: "relative" }}>
                                  <select
                                    property="mr-4"
                                    className="form-control mb-2 mr-2 answer-input-select custom-select"
                                    id="QuestionType"
                                    name="QuestionType"
                                    value={answerSet[i].AnswerID}
                                    onChange={(e) => {
                                      answerSet[i].AnswerID = Number(
                                        e.currentTarget.value
                                      );
                                      setAnswerSet([...answerSet]);
                                      console.log(answerSet);
                                    }}
                                  >
                                    <option key={"ans-"} value="0">
                                      กรุณาเลือกคำตอบ
                                    </option>
                                    {item.AnswerList.map((ansitem, k) => (
                                      <option
                                        key={
                                          "ans-" + ansitem.SurveyAnswerID + k
                                        }
                                        value={ansitem.SurveyAnswerID}
                                      >
                                        {ansitem.AnswerTitle}
                                      </option>
                                    ))}
                                  </select>
                                  {/* <span className="select-arrow"><i class="fas fa-caret-down"></i></span> */}
                                  <SError
                                    error={
                                      errors[
                                        "question-" + item.SurveyQuestionID
                                      ]
                                    }
                                  />
                                </div>
                              )}
                              {(Number(item.QuestionType) === 4 ||
                                Number(item.QuestionType) === 5) && (
                                <div className="row custom-survey">
                                  <textarea
                                    type="text"
                                    cols="40"
                                    rows="5"
                                    className="form-control answer-input"
                                    id={"ans-" + item.SurveyQuestionID}
                                    name={"ans-" + item.SurveyQuestionID}
                                    value={answerSet[i].AnswerText}
                                    //ref={refSet[props.order].ref}
                                    placeholder="ตอบ"
                                    onChange={(e) => {
                                      setAnswerSet(
                                        answerSet.map((value, j) => {
                                          if (j === i) {
                                            value.AnswerText = e.target.value;
                                          }
                                          return value;
                                        })
                                      );

                                      //e.preventDefault();
                                      //answerSet[props.order].AnswerText = refSet[props.order].ref.current.value;
                                      //setAnswerSet([...answerSet]);
                                      //console.log(answerSet)
                                      //setRefSet([...refSet]);
                                      //console.log(answerSet)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row custom-survey">
                    <div className="col-12">
                      <div className="col-12 formContent">
                        <input
                          type="submit"
                          className="fadeIn fourth"
                          value="ยกเลิก"
                          onClick={(e) => {
                            e.preventDefault();
                            cancel();
                          }}
                        />
                        <input
                          type="submit"
                          className="fadeIn fourth"
                          value="ส่งข้อมูล"
                          onClick={(e) => {
                            e.preventDefault();
                            saveData(false);
                          }}
                        />
                      </div>

                      {/* <div className="pl-4 pr-4 text-center margintop40" >
                                                <button type="button" className="btn btn-dark cancel mr-2" onClick={e => { e.preventDefault(); cancel(); }}>
                                                    <i className="far fa-times-circle"></i>
                                                    ยกเลิก
                                                </button>
                                                <button type="button" className="btn btn-success save" onClick={e => { e.preventDefault(); saveData(false); }}>
                                                    <i className="fas fa-save"></i>
                                                    ส่งข้อมูล
                                                </button>
                                            </div> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
