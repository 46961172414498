import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar.js";
import HomeBanner from "../components/Banner/HomeBanner.js";
import Footer from "../components/Footer.js";
import HomeContent from "../views/HomeContent.js";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
export default function Index() {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  /*let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = React.useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "Thai"
  );
  console.log(menuData.menus);
  console.log(menuData.menus.filter(x=>x.ParentID==="1"))*/
  useEffect(() => {
    utils.AccessInfo('หน้าหลัก', '/', 'F', currentUser);
    setLoading(false);
  }, []); /* <-- add this for run once*/
  if (isLoading) {
    return <></>;
  }
  return (
    <div className="fecontent">
      <Navbar />
      <HomeBanner />
      <HomeContent />
      <Footer />
    </div>
  );
}