import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import '../../assets/styles/banner.css';
import notifyicon from '../../assets/images/icon/notifyico.png';
import alerticon from '../../assets/images/icon/alertico.png';
import docicon from '../../assets/images/icon/docico.png';
import telicon from '../../assets/images/icon/telico.png';
export default function HomeBanner(props) {
  const history = useHistory();
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_APIURL + '/api-web/getActiveBanner')
      .then((response) => {
        setBanner(response.data);
        //console.log(response.data);
      });

  }, []); /* <-- add this for run once*/

  return (

    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators carousel-indicators-numbers">
        {banner.map((item, index) => (
          <li key={"banner-" + item.BannerID} data-target="#carouselExampleIndicators" data-slide-to={index} className={Number(index) === 0 ? "active" : ""} onClick={(e) => { e.preventDefault(); utils.AccessInfo('ป้ายโฆษณา', '/', 'F', currentUser); }}>{index + 1}</li>
        ))}
      </ol>
      <div className="carousel-inner">
        {banner.map((item, index) => (
          <div key={"img-" + index} className={Number(index) === 0 ? "carousel-item active" : "carousel-item"}>
            <a href={item.BannerLink} target="_blank" rel="noopener noreferrer" >
              <img src={language === "Thai" ? item.BannerImageTH : item.BannerImageEN} className="d-block w-100" alt={"banner" + index + 1} />
            </a>
          </div>
        ))}
      </div>

      <div className="blueline"></div>
      <ul id="iconset">
        <li className="iconrow">
          <a className="btnicon" href="/" onClick={(e) => { e.preventDefault(); history.push({ pathname: "/news/NewsList" }); }}><img className="img " src={notifyicon} alt="Notify" /></a>
        </li>
        <li className="iconrow">
          <a className="btnicon" href="/" onClick={(e) => { e.preventDefault(); history.push({ pathname: "/activity/ActivityList" }); }}><img className="img " src={alerticon} alt="Alert" /></a>
        </li>
        <li className="iconrow">
          <a className="btnicon" href="/" onClick={(e) => { e.preventDefault(); history.push({ pathname: "/authen/elibrary/ELibrary" }); }}><img className="img " src={docicon} alt="Document" /></a>
        </li>
        <li className="iconrow">
          <a className="btnicon" href="/" onClick={(e) => { e.preventDefault(); history.push({ pathname: "/contact/ContactUs" }); }}><img className="img " src={telicon} alt="Telephone" /></a>
        </li>
      </ul>
    </div>
  );

}