import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactPlayer from "react-player";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/elearning.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import searchicon from "assets/images/icon/search.svg";
import { authenticationService } from "services/authentication.service.js";
const swal = withReactContent(Swal);
export default function ELearning(props) {
  const currentUser = authenticationService.currentUserValue;
  const PageSize = 9;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [selectCourseType, setSelectCourseType] = useState([]);
  const [selectCourseCategory, setSelectCourseCategory] = useState([]);
  const [activeElerningList, setActiveElerningList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterMode, setFilterMode] = useState(0);
  const [pinElibList, setPinELibList] = useState([]);
  const [newElibList, setNewELibList] = useState([]);

  useEffect(() => {
    //console.log(props.location.state.id)
    utils.AccessInfo(
      "อีเลิร์นนิง",
      "/authen/elearning/ELearning",
      "F",
      currentUser
    );
    axios
      .get(
        process.env.REACT_APP_APIURL + "/api-web/searchELearningCourseType",
        { params: { status: 1 } }
      )
      .then((response) => {
        setSelectCourseType(response.data);
      });
    axios
      .get(
        process.env.REACT_APP_APIURL + "/api-web/searchElearningCategories",
        { params: { status: 1 } }
      )
      .then((response) => {
        setSelectCourseCategory(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchESubject", {
        params: { status: 1, ispublish: true },
      })
      .then((response) => {
        //console.log(response.data)
        setActiveElerningList(response.data);
        //setELearningList(response.data)

        const arr = response.data;
        const sortedDateElib = [...arr].sort(
          (a, b) =>
            new Date(b.PublishedStartDate) - new Date(a.PublishedStartDate)
        );
        //setNewELibList(sortedDateElib.splice(0, 3))
        setNewELibList(sortedDateElib);

        const pin = [...arr].filter((item) => {
          return item.Pin === true;
        });
        const lastpin = pin.sort((a, b) => b.Ordering - a.Ordering);
        setPinELibList(lastpin);
        setCurrentPage(1);
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return activeElerningList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const SearchResultDIV = currentTableData.map((item, i) => (
    <div className="col-md-4 mt-3 mb-3" key={item.ELearnID}>
      <div className="card subject">
        {Number(item.CoverType) === 1 && (
          <div className="subject-cover">
            <img src={item.ThumnailWeb} className="cover" alt="Subject cover" />
          </div>
        )}
        {Number(item.CoverType) === 2 && (
          <div className="subject-cover">
            <div className="player-wrapper">
              <ReactPlayer
                url={item.ThumnailWeb}
                width="100%"
                height="260px"
                controls={true}
              />
            </div>
          </div>
        )}
        <div className="subject-data">
          <div
            className="card-body card-eleaning"
            onClick={(e) => {
              e.preventDefault();
              readData(item.ELearnID);
            }}
          >
            <h4 className="subject-title">
              {language === "Thai" ? item.ELearnTitle : item.ELearnTitle}
            </h4>
            <div className="subject-detail text-gray">
              <div
                dangerouslySetInnerHTML={{ __html: item.CourseDetail }}
              ></div>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-warning"
                style={{
                  width:
                    ((item.MaxEnroll - item.CurrentEnroll) * 100) /
                    item.MaxEnroll +
                    "%",
                }}
              ></div>
            </div>
            <div className="subject-enroll text-center">
              <label>
                จำนวนที่ว่าง{" "}
                <span
                  style={{
                    fontSize: 3.5 + "rem",
                    color: "#FAAB3E",
                    fontWeight: "bold",
                  }}
                >
                  {item.MaxEnroll - item.CurrentEnroll}
                </span>{" "}
                / {item.MaxEnroll}
              </label>
            </div>
            <div className="card-footer bg-transparent">
              <div className="subjectbutton"> </div>
              <span
                style={{
                  display: "inline-flex",
                  lineHeight: 1 + "rem",
                  verticalAlign: "bottom",
                }}
              >
                <p> {item.CourseTypeNameTH}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  const subjectNameRef = React.createRef();
  const courseTypeRef = React.createRef();
  const categoryRef = React.createRef();
  const searchData = () => {
    /*const formData = new FormData();
        formData.append("search", subjectNameRef.current.value);
        formData.append("courseType", courseTypeRef.current.value);
        formData.append("category", categoryRef.current.value);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/searchESubject', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {

                setActiveElerningList(response.data);
                setCurrentPage(0);
                setCurrentPage(1);
                setFilterMode(1);
            });*/
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchESubject", {
        params: {
          search: subjectNameRef.current.value,
          courseType: courseTypeRef.current.value,
          category: categoryRef.current.value,
          status: 1,
          ispublish: true,
        },
      })
      .then((response) => {
        setActiveElerningList(response.data);
        //console.log(newsList);
        setCurrentPage(0);
        setCurrentPage(1);
        setFilterMode(1);
      });
  };

  const CourseTypeSelect = selectCourseType.map((item) => (
    <option key={item.ELearnCourseTypeID} value={item.ELearnCourseTypeID}>
      {item.CourseTypeNameTH}
    </option>
  ));
  const CourseCategorySelect = selectCourseCategory.map((item) => (
    <option key={item.ELearnCatID} value={item.ELearnCatID}>
      {item.CategoriesNameTH}
    </option>
  ));
  const readData = (id) => {
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }

    let params = {
      ELearnID: id,
      UserID: userid
    }

    axios.get(`${process.env.REACT_APP_APIURL}/api-web/CheckElearningPass`, { params }).then(res => {
      if (res.data.result) {
        props.history.push({
          pathname: "/authen/elearning/ViewElearning/" + id,
          state: { id: id, userID: userid },
        });
      } else {
        alert("กรุณาเรียนวิชาที่ต้องผ่านก่อนวิชานี้")
      }
    })

  };

  const ELibsMode0 = () => {
    let data = <></>;
    let subdata1 = <></>;
    let subdata2 = <></>;
    subdata1 = pinElibList.map((item) => (
      <div className="col-md-4 mt-3 mb-3" key={item.ELearnID}>
        <div className="card subject">
          {Number(item.CoverType) === 1 && (
            <div className="subject-cover">
              <img
                src={item.ThumnailWeb}
                className="cover"
                alt="Subject cover"
              />
            </div>
          )}
          {Number(item.CoverType) === 2 && (
            <div className="subject-cover">
              <div className="player-wrapper">
                <ReactPlayer
                  url={item.ThumnailWeb}
                  width="100%"
                  height="260px"
                  controls={true}
                />
              </div>
            </div>
          )}
          <div className="subject-data">
            <div
              className="card-body card-eleaning"
              onClick={(e) => {
                e.preventDefault();
                readData(item.ELearnID);
              }}
            >
              <h4 className="subject-title">
                {language === "Thai" ? item.ELearnTitle : item.ELearnTitle}
              </h4>
              <div className="subject-detail text-gray">
                <div
                  dangerouslySetInnerHTML={{ __html: item.CourseDetail }}
                ></div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-warning"
                  style={{
                    width:
                      ((item.MaxEnroll - item.CurrentEnroll) * 100) /
                      item.MaxEnroll +
                      "%",
                  }}
                ></div>
              </div>
              <div className="subject-enroll text-center">
                <label>
                  จำนวนที่ว่าง{" "}
                  <span
                    style={{
                      fontSize: 3.5 + "rem",
                      color: "#FAAB3E",
                      fontWeight: "bold",
                    }}
                  >
                    {item.MaxEnroll - item.CurrentEnroll}
                  </span>{" "}
                  / {item.MaxEnroll}
                </label>
              </div>
              <div className="card-footer bg-transparent">
                <div className="subjectbutton"> </div>
                <span
                  style={{
                    display: "inline-flex",
                    lineHeight: 1 + "rem",
                    verticalAlign: "bottom",
                  }}
                >
                  <p> {item.CourseTypeNameTH}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    subdata2 = newElibList.map((item) => (
      <div className="col-md-4 mt-3 mb-3" key={item.ELearnID}>
        <div className="card subject">
          {Number(item.CoverType) === 1 && (
            <div className="subject-cover">
              <img
                src={item.ThumnailWeb}
                className="cover"
                alt="Subject cover"
              />
            </div>
          )}
          {Number(item.CoverType) === 2 && (
            <div className="subject-cover">
              <div className="player-wrapper">
                <ReactPlayer
                  url={item.ThumnailWeb}
                  width="100%"
                  height="260px"
                  controls={true}
                />
              </div>
            </div>
          )}
          <div className="subject-data">
            <div
              className="card-body card-eleaning"
              onClick={(e) => {
                e.preventDefault();
                readData(item.ELearnID);
              }}
            >
              <h4 className="subject-title">
                {language === "Thai" ? item.ELearnTitle : item.ELearnTitle}
              </h4>
              <div className="subject-detail text-gray">
                <div
                  dangerouslySetInnerHTML={{ __html: item.CourseDetail }}
                ></div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-warning"
                  style={{
                    width:
                      ((item.MaxEnroll - item.CurrentEnroll) * 100) /
                      item.MaxEnroll +
                      "%",
                  }}
                ></div>
              </div>
              <div className="subject-enroll text-center">
                <label>
                  จำนวนที่ว่าง{" "}
                  <span
                    style={{
                      fontSize: 3.5 + "rem",
                      color: "#FAAB3E",
                      fontWeight: "bold",
                    }}
                  >
                    {item.MaxEnroll - item.CurrentEnroll}
                  </span>{" "}
                  / {item.MaxEnroll}
                </label>
              </div>
              <div className="card-footer bg-transparent">
                <div className="subjectbutton"> </div>
                <span
                  style={{
                    display: "inline-flex",
                    lineHeight: 1 + "rem",
                    verticalAlign: "bottom",
                  }}
                >
                  <p> {item.CourseTypeNameTH}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    data = (
      <>
        <div className="col-12 mt-4">
          <div className="sub-title">
            <h2 className="thai-header text-left">
              <span className="orang content-underline">หลักสูตรแนะนำ</span>
            </h2>
          </div>
        </div>
        <div className="overflow-chapter">{subdata1}</div>
        <div className="col-12 px-0">
          <form className="formContent row">
            <div className="col-12">
              <input
                className="form-control mr-sm-2 search"
                ref={subjectNameRef}
                type="text"
                placeholder={
                  language === "Thai" ? "ค้นหาที่นี่" : "Search here"
                }
                aria-label="Search"
              />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  searchData();
                }}
              >
                <img
                  src={searchicon}
                  className="searchicon"
                  alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                />
              </a>
            </div>
            <div className="col-6">
              <select
                name="courseTypeRef"
                id="courseTypeRef"
                className="custom-select"
                defaultValue="0"
                ref={courseTypeRef}
                onChange={(e) => {
                  e.preventDefault();
                  searchData();
                }}
              >
                <option key="0" value="0">
                  {language === "Thai"
                    ? "เลือกหลักสูตร"
                    : "Course type"}
                </option>
                {CourseTypeSelect}
              </select>
            </div>
            <div className="col-6">
              <select
                name="categoryRef"
                id="categoryRef"
                className="custom-select"
                defaultValue="0"
                ref={categoryRef}
                onChange={(e) => {
                  e.preventDefault();
                  searchData();
                }}
              >
                <option key="0" value="0">
                  {language === "Thai" ? "เลือกหมวดหมู่" : "Category"}
                </option>
                {CourseCategorySelect}
              </select>
            </div>
          </form>
        </div>
        <div className="col-12 mt-4">
          <div className="sub-title">
            <h2 className="thai-header text-left">
              <span className="orang content-underline">หลักสูตร</span>
            </h2>
          </div>
        </div>
        {subdata2}
      </>
    );
    return data;
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"อีเลิร์นนิง"}
          eheader={"E-learning"}
          bannerkey="ELEARN"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/elearning/ELearning",
                  state: { language: language },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setFilterMode(0);
                }}
              >
                {language === "Thai" ? "อีเลิร์นนิง" : "E-learning"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              <div className="row">

                {filterMode === 0 && (
                  <>
                    <ELibsMode0 />
                  </>
                )}
                {filterMode === 1 && (
                  <>
                    <div className="col-12">
                      <span className="big-text badge badge-info">
                        {activeElerningList.length}
                      </span>
                      <span className="big-text text-gray"> ผลลัพธ์</span>
                    </div>
                    <div className="col-12">
                      <div className="row">{SearchResultDIV}</div>
                    </div>
                    <div className="col-12">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={activeElerningList.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
