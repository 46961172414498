import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
//import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function SubChapterList(props) {
    const currentUser = authenticationService.currentUserValue;
    const chapterID = props.location.state.chapterID;
    const [isLoading, setLoading] = useState(true);
    const [subchapter, setSubchapter] = useState([]);
    const [eLearnID, setELearnID] = useState('');
    const [eLearnTitle, setELearnTitle] = useState('');
    const [chapterTitle, setChapterTitle] = useState('');

    useEffect(() => {
        //console.log(chapterID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getChapterbyID', { params: { chapterID: chapterID } })
            .then((main) => {
                let data = main.data[0];
                setELearnID(data.ELearnID);
                setELearnTitle(data.ELearnTitle);
                setChapterTitle(data.ChapterTitle);
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubChapterbyParentID', { params: { chapterID: chapterID } })
                    .then((sub) => {
                        let subch = [];
                        for (let j = 0; j < sub.data.length; j++) {
                            subch.push(sub.data[j]);
                        }
                        const chapterPrepair = [...subch];
                        //console.log(subch)
                        setSubchapter(chapterPrepair);
                        setLoading(false);
                    })
            })
    }, []); /* <-- add this for run once*/
    const editSubChapter = (subchapterID) => {

        //console.log(activeChapter);
        const result = subchapter.find((member) => {
            return member.ChapterID === subchapterID
        })
        //console.log(result.subChapter[0])
        if (result != null) {
            const data = result;
            //console.log(data)
            switch (data.ChapterContentType) {
                case 'PRE_TEST': props.history.push({ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: eLearnID, parentChapterID: chapterID, chapterID: data.ChapterID, QuestionnaireID: data.QuestionnaireID, questionType: 'PRE_TEST' } }); break;
                case 'TEST': props.history.push({ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: eLearnID, parentChapterID: chapterID, chapterID: data.ChapterID, QuestionnaireID: data.QuestionnaireID, questionType: 'TEST' } }); break;
                case 'POST_TEST': props.history.push({ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: eLearnID, parentChapterID: chapterID, chapterID: data.ChapterID, QuestionnaireID: data.QuestionnaireID, questionType: 'POST_TEST' } }); break;
                case 'CONTENT': props.history.push({ pathname: "/admin/elearning/EditChapterContent", state: { parentChapterID: chapterID, chapterID: data.ChapterID } }); break;
                case 'VDO': props.history.push({ pathname: "/admin/elearning/EditChapterVDO", state: { parentChapterID: chapterID, chapterID: data.ChapterID } }); break;
                case 'LINK': props.history.push({ pathname: "/admin/elearning/EditChapterLINK", state: { parentChapterID: chapterID, chapterID: data.ChapterID } }); break;
            }
        }
    };
    const deleteChapter = (item) => {        
        Swal.fire({
            title: '<strong><u>ยืนยันการลบข้อมูล</u></strong>',
            icon: '',
            html:
                '<hr/><div class="col-12 text-left">' +
                '<div class="form-check">' +
                '<label >' +
                'เมื่อกดปุ่มยืนยันจะไม่สามารถกู้คืนได้อีก' +
                '</label>' +
                '</div>' +
                '</div>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                'ยืนยัน',
            cancelButtonText:
                'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed === true) {
                //console.log(item);
                const formData = new FormData();
                formData.append("chapterID", item.ChapterID);
                formData.append("eLearnID", item.ELearnID);
                formData.append("userID", currentUser.UserID);
                axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteChapter', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`
                    }
                })
                    .then((response) => {
                        console.log(response.data.success)
                        let timerInterval;
                        if (response.data.success === false) {
                            swal.fire({
                                title: response.data.text, showConfirmButton: false,
                                timer: 500, timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getHtmlContainer()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);

                                }
                            }).then((result) => {

                            })
                        }
                        else {
                            swal.fire({
                                title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                                timer: 1000, timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getHtmlContainer()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);

                                }
                            }).then((result) => {
                                window.location.reload();
                            })
                        }
                    });
            }
        })
    }
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: eLearnID } }}>วิชา {eLearnTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/elearning/SubChapterList", state: { chapterID: chapterID } }}>{chapterTitle}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12 pr-5">
                                    <h1>{chapterTitle}</h1 >
                                    {(typeof subchapter !== 'undefined') && (subchapter.map((item, index) => (
                                        <div className="col-12 list-item" key={item.ChapterID} >
                                            <a href="/" onClick={(e) => { e.preventDefault(); editSubChapter(item.ChapterID); }} className="text-dark mr-2" title="คลิกเพื่อแก้ไขกิจกรรม">{item.ChapterTitle}</a>
                                            <div className="float-right">                                                
                                                <a href="/" onClick={(e) => { e.preventDefault(); editSubChapter(item.ChapterID); }} className="text-dark mr-2"><i className="fas fa-edit"></i> แก้ไข</a>
                                                <a href="/" onClick={(e) => { e.preventDefault(); deleteChapter(item); }} className="text-dark mr-2"><i className="fas fa-trash-alt text-danger"></i> ลบ</a>
                                            </div>
                                        </div>
                                    )))}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
