import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import utils from "utils/utils";
import { authenticationService } from "services/authentication.service.js";
import AdminBanner from "components/Banner/AdminBanner";
import SError from "components/SError";
const swal = withReactContent(Swal);

export default function EditChapterVDO(props) {
  const currentUser = authenticationService.currentUserValue;
  const chapterID = props.location.state.chapterID;
  const topParentChapterID = props.location.state.parentChapterID;
  const [topParentTitle, setTopParentTitle] = useState("");
  const [errors, setError] = useState({});
  //const [eLearnTitle, setELearnTitle] = useState('');
  const [chapterData, setChapterData] = useState([]);
  const [chapterTitle, setChapterTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showDetail, setShowDetail] = useState(false);

  const [vdoType, setVDOType] = useState(-1);
  const [outsiteURL, setOutsiteURL] = useState("");
  const [period, setPeriod] = useState(0);
  const [vdoFile, setVDOFile] = useState(null);

  function reLoadData() {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getChapterbyID", {
        params: { chapterID: topParentChapterID },
      })
      .then((response) => {
        let data = response.data[0];
        //console.log(data)
        setTopParentTitle(data.ChapterTitle);
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/ESubjectChapter", {
            params: { id: chapterID },
          })
          .then((response) => {
            let data = response.data[0];
            //console.log(data)
            setChapterData(data);
            setChapterTitle(data.ChapterTitle);
            if (data.Description != null) setDescription(data.Description);
            setShowDetail(data.DisplayDetail);

            setVDOType(String(data.VDOType));
            setPeriod(data.period ? data.period : 0);
            if (data.VDOType === true) {
              const vdoFile = {
                file: data.ContentPath,
                name: data.ContentPath.substring(
                  data.ContentPath.lastIndexOf("/") + 1
                ),
                preview: data.ContentPath,
                size: utils.getFileSize(data.ContentPath),
              };
              setVDOFile(vdoFile);
            } else {
              setOutsiteURL(data.ContentPath);
            }
          });
      });
  }
  useEffect(() => {
    reLoadData();
  }, []); /* <-- add this for run once*/
  const saveData = () => {
    let serrors = {};
    let formIsValid = true;
    //Name
    if (chapterTitle.trim().length === 0) {
      formIsValid = false;
      serrors["chapterTitle"] = "กรุณากรอกข้อมูล";
    }
    if (showDetail === true) {
      if (description.trim().length === 0) {
        formIsValid = false;
        serrors["description"] = "กรุณากรอกข้อมูล";
      }
    }
    if (Number(vdoType) == -1) {
      formIsValid = false;
      serrors["vdoType"] = "กรุณาเลือกรูปแบบการใช้งานวีดีโอ";
    }
    if (vdoType === "false") {
      if (outsiteURL.trim().length === 0) {
        formIsValid = false;
        serrors["outsiteURL"] = "กรุณากรอกข้อมูล";
      }
    } else if (vdoType === "true") {
      if (vdoFile === null) {
        formIsValid = false;
        serrors["vdoFile"] = "กรุณาเลือกไฟล์เพื่ออัปโหลด";
      }
    }
    setError(serrors);
    if (formIsValid) {
      const formData = new FormData();
      formData.append("eLearnID", chapterData.ELearnID);
      formData.append("chapterID", chapterID);
      formData.append("chapterTitle", chapterTitle);
      formData.append("chapterContentType", "VDO");
      formData.append("VDOType", vdoType);
      if (vdoType === "false") formData.append("contentPath", outsiteURL);
      else {
        formData.append("vdoFile", vdoFile.file);
      }
      formData.append("period", period);
      formData.append("description", description);
      formData.append("displayDetail", showDetail);
      formData.append("parentChapterID", chapterData.ParentChapterID);
      formData.append("userID", currentUser.UserID);
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/updateChapter",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              reLoadData();
              props.history.push({
                pathname: "/admin/elearning/ESubjectChapter",
                state: { id: chapterData.ELearnID },
              });
            });
        });
    }
  };
  const cancel = () => {
    props.history.push({
      pathname: "/admin/elearning/ESubjectChapter",
      state: { id: chapterData.ELearnID },
    });
  };
  const handleWebCoverOnDrop = (newImageFile, onChange) => {
    const imageFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size,
    };
    setVDOFile(imageFile);
  };
  const handleRemoveWebCover = () => {
    /*const formData = new FormData();
        if(Number(coverType)===1)
            formData.append("folder", 'images');
        if(Number(coverType)===2)
            formData.append("folder", 'vdo');
        formData.append("url", vdoFile.file);
        formData.append("fieldName", 'FilePath');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteELearningFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });*/
    setVDOFile(null);
  };
  const VDOPreview = vdoFile !== null && (
    <tr key={vdoFile.name}>
      <td>{1}</td>
      <td>{vdoFile.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(vdoFile.size)}</td>
      <td>{vdoFile.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={vdoFile.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveWebCover();
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  );
  console.log("vdoType", vdoType);
  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการกิจกรรม"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              การจัดการอีเลิร์นนิง (E-learning)
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link to={{ pathname: "/admin/elearning/ELearningList" }}>
              {"จัดการวิชา"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              to={{
                pathname: "/admin/elearning/ESubjectChapter",
                state: { id: chapterData.ELearnID },
              }}
            >
              วิชา {chapterData.ELearnTitle}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              to={{
                pathname: "/admin/elearning/SubChapterList",
                state: { chapterID: topParentChapterID },
              }}
            >
              {topParentTitle}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{
                pathname: "/admin/elearning/EditChapterVDO",
                state: {
                  parentChapterID: topParentChapterID,
                  chapterID: chapterID,
                },
              }}
            >
              {chapterData.ChapterTitle}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form method="post" encType="multipart/form-data" className="row">
          <div className="card-content pl-4 pr-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-12">
                  <h1>
                    {chapterData.ELearnTitle} (
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontSize: 1.5 + "rem",
                      }}
                    >
                      {chapterData.ChapterTitle}
                    </span>
                    )
                  </h1>
                  <div className="alert alert-dark col-12" role="alert">
                    ทั่วไป
                  </div>
                  <div className="col-12">
                    <label htmlFor="chapterTitle" className="required">
                      ชื่อ
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      id="chapterTitle"
                      value={chapterTitle}
                      name="chapterTitle"
                      placeholder="กรุณากรอก"
                      onChange={(e) => {
                        setChapterTitle(e.currentTarget.value);
                      }}
                    />
                    <SError error={errors["chapterTitle"]} />
                  </div>
                  <div className="col-12">
                    <label htmlFor="description">คำอธิบาย</label>
                    <div>
                      <textarea
                        className="form-control"
                        name="completeDocRemark"
                        id="description"
                        rows="3"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      <SError error={errors["description"]} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="showDetail"
                        name="showDetail"
                        checked={showDetail === true}
                        onChange={(e) => {
                          setShowDetail(!showDetail);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showDetail"
                      >
                        แสดงคำอธิบายในหน้ารายวิชา
                      </label>
                    </div>
                  </div>
                  <div className="alert alert-dark col-12" role="alert">
                    อัพโหลดไฟล์ วิดีโอ
                  </div>
                  <div className="col-6">
                    <label htmlFor="vdoType" className="required">
                      ประเภทการแสดงผล
                    </label>
                    <select
                      className="form-control mb-2 mr-2"
                      id="vdoType"
                      name="vdoType"
                      value={vdoType}
                      onChange={(e) => {
                        setVDOType(e.currentTarget.value);
                      }}
                    >
                      <option value="-1">กรุณาเลือก</option>
                      <option value="false">ลิงก์วีดีโอจากภายนอก</option>
                      <option value="true">ไฟล์ VDO</option>
                    </select>
                    <SError error={errors["vdoType"]} />
                  </div>

                  {vdoType === "false" && (
                    <>
                      <div className="col-12">
                        <label className="required">ลิงก์วีดีโอจากภายนอก</label>
                        <input
                          type="text"
                          className="form-control "
                          id="chapterTitle"
                          value={outsiteURL}
                          name="chapterTitle"
                          placeholder="กรุณากรอก"
                          onChange={(e) => {
                            setOutsiteURL(e.currentTarget.value);
                          }}
                        />
                        <SError error={errors["outsiteURL"]} />
                      </div>
                      <div className="col-6 mb-2">
                        <label htmlFor="chapterTitle" /*className="required"*/>
                          ระยะเวลาที่ต้องดู VDO (วินาที)
                        </label>
                        <input
                          type="number"
                          min="0"
                          step="1"
                          className="form-control "
                          id="chapterTitle"
                          value={period}
                          name="time"
                          placeholder="กรุณากรอก"
                          onChange={(e) => {
                            setPeriod(e.currentTarget.value);
                          }}
                        />
                        <SError error={errors["chapterTitle"]} />
                      </div>
                    </>
                  )}
                  {vdoType === "true" && (
                    <>
                      <div className="col-6 mb-2">
                        <label htmlFor="chapterTitle" /*className="required"*/>
                          ระยะเวลาที่ต้องดู VDO (วินาที)
                        </label>
                        <input
                          type="number"
                          min="0"
                          step="1"
                          className="form-control "
                          id="chapterTitle"
                          value={period}
                          name="time"
                          placeholder="กรุณากรอก"
                          onChange={(e) => {
                            setPeriod(e.currentTarget.value);
                          }}
                        />
                        <SError error={errors["chapterTitle"]} />
                      </div>
                      <div className="col-12">
                        <label className="required">ไฟล์วีดีโอ</label>
                        <Dropzone
                          className="dropzone"
                          activeClassName="active-dropzone"
                          multiple={false}
                          onDrop={handleWebCoverOnDrop}
                          accept=".mp3,.mp4,.avi,.fla,.wma,.wmv,.mpeg,.mpeg4"
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="container">
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <div>
                                  ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                </div>
                              </div>
                              <aside>
                                <SError error={errors["vdoFile"]} />
                                <h4>Files</h4>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>ลำดับ</th>
                                        <th>ชื่อ</th>
                                        <th>ขนาดไฟล์</th>
                                        <th>ประเภทไฟล์</th>
                                        <th>จัดการ</th>
                                      </tr>
                                    </thead>
                                    <tbody>{VDOPreview}</tbody>
                                  </table>
                                </div>
                              </aside>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12">
                  <div className="pl-4 pr-4 text-center margintop40">
                    <button
                      type="button"
                      className="btn btn-success mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        saveData(false);
                      }}
                    >
                      <i className="fas fa-save"></i> บันทึก
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        saveData(true);
                      }}
                    >
                      <i className="fas fa-save"></i> บันทึกและเปิด
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        cancel();
                      }}
                    >
                      <i className="far fa-times-circle"></i> ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
