import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
    FacebookShareButton,
    EmailShareButton,
    LineShareButton,
     FacebookIcon,
    EmailIcon,
    LineIcon,
} from "react-share";
//import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import searchicon from 'assets/images/icon/search.svg';
import avatar from 'assets/images/icon/user.svg';

const swal = withReactContent(Swal)

export default function ActivityInviteFriend({activitydata}) {
    //console.log(activitydata);
    const shareUrl = /*process.env.REACT_APP_SITEURL*/'https://merights.nhrc.or.th/activity/ReadActivity/' + activitydata.ActivitiesID;
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [friendlist, setFriendList] = useState([]);
    const searchRef = React.createRef();
    const searchFriend = () => {
        //let serrors = {};
        Swal.resetValidationMessage()
        let formIsValid = true;
        if (searchRef.current.value.trim().length === 0) {
            formIsValid = false;
            //serrors["searchfriend"] = "กรุณากรอกข้อมูลในการค้นหา";
            //setError(serrors);
        }
        if (formIsValid) {
            //console.log(currentUser.UserID);
            
            const formData = new FormData();
            formData.append("search", searchRef.current.value);
            formData.append("userID", currentUser.UserID);
            formData.append("activityID", activitydata.ActivitiesID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/activityInviteSearch', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    setFriendList(response.data)
                    //console.log(response.data)
                });
        }
        else{
            Swal.showValidationMessage(
                `กรุณากรอกข้อมูลในการค้นหา`
              )
        }
    };
    const saveData = (friend) => {
        const formData = new FormData();
        formData.append("activitiesID", activitydata.ActivitiesID);        
        formData.append("userID", friend.UserID);
        formData.append("UserInviteID", currentUser.UserID);
        formData.append("notititle", activitydata.TitleTH);
console.log(friend)
        axios.post(process.env.REACT_APP_APIURL + '/api-web/activityInvite', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                let timerInterval;
                swal.fire({
                    title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                        window.location.reload();
                    }
                }).then((result) => {
                    swal.close();
                })
            });

    };
    const FriendList = friendlist.map((friend) => (

        <div className="col-12 table-cell" key={friend.UserID}>
            <div className="avatar-circle">
                <img src={friend.Avatar===null?avatar:friend.Avatar.trim().length==0?avatar:friend.Avatar} className="icon" alt="file icon" style={{width:'64px',height:'64px'}}/>
            </div>
            <span className="file-name">{friend.FirstnameTH + " " + friend.LastnameTH}</span>
            <div className="mt-2 mb-2 float-right">
                <input type="button" className="fadeIn fourth btn-small" value={friend.acceptStatus!=null?"เชิญแล้ว":"เชิญ"} disabled ={friend.acceptStatus!=null} onClick={(e) => { e.preventDefault(); saveData(friend);}} />
            </div>
        </div>

    ));
    useEffect(() => {
        

    }, []); /* <-- add this for run once*/
    return (
        <>
            <div className="share-tool ">
                <FacebookShareButton
                    url={shareUrl}
                    quote={language === "Thai" ? activitydata.TitleTH : activitydata.TitleEN}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={50} round />
                </FacebookShareButton>
                <LineShareButton
                    url={shareUrl}
                    quote={language === "Thai" ? activitydata.TitleTH : activitydata.TitleEN}
                    className="Demo__some-network__share-button"
                >
                    <LineIcon size={50} round />
                </LineShareButton>
                <EmailShareButton
                    url={shareUrl}
                    quote={language === "Thai" ? activitydata.TitleTH : activitydata.TitleEN}
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={50} round />
                </EmailShareButton>
            </div>
            <div className="formContent">
                <div className="col-12">
                    <input className="form-control mr-sm-2 search" ref={searchRef} type="text" placeholder={language === "Thai" ? "ค้นหาที่นี่" : "Search"} aria-label="Search" />
                    <a href="/" onClick={e => { e.preventDefault(); searchFriend(); }}>
                        <img src={searchicon} className="searchicon" alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"} />
                    </a>
                </div>
                {FriendList}
            </div>
        </>
    );
}