import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/banner.css";
/*import notifyicon from '../../assets/images/icon/notifyico.png';
import alerticon from '../../assets/images/icon/alertico.png';
import docicon from '../../assets/images/icon/docico.png';*/
//import banner from '../../assets/images/banner/advertise.svg';
export default function AdminBanner(props) {
  //console.log(props);
  return (
    <div id="admin-content-title">
      <div id="banner-title">
        <h1 className="title-header">{props.title}</h1>
      </div>
      <div id="sitemap">
        <Link id="profile-btn" className="Home-sitemap1" to={{ pathname: "/" }}>
          {"กสม. "}
        </Link>
        {props.path}
      </div>
    </div>
  );
}
