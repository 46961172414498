export const FILE_ATTACH = 'หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png, .zip มีขนาดไฟล์ไม่เกิน 10 MB';
export const NETWORK_IMG_REMARK = 'หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, .pdf, .jpg, .pptx, .png, .zip มีขนาดไฟล์ไม่เกิน 10MB';


export const CONTACT_LOGO_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 46x77 px";
export const CONTACT_MAP_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 500x287 px";

export const NEWS_WEB_COVER_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 355x260 px";
export const NEWS_MOBILE_COVER_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 355x260 px";
export const NEWS_ATTACH_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png, .zip มีขนาดไฟล์ไม่เกิน 10 MB";
export const NEWS_GALLERY_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png, .zip มีขนาดไฟล์ไม่เกิน 10 MB";
export const NEWS_MEDIA_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .mp4 มีขนาดไฟล์ไม่เกิน 10 MB";


export const REWARD_PERSON_AVATAR_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 242x242 px";
export const REWARD_PERSON_ATTACH_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png, .zip มีขนาดไฟล์ไม่เกิน 10 MB";
export const REWARD_PERSON_MEDIA_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .mp4 มีขนาดไฟล์ไม่เกิน 10 MB";


export const ELIBRARY_WEB_COVER_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 355x260 px";
export const ELIBRARY_MOBILE_COVER_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .jpg, .png ขนาดที่เหมาะสมคือ 355x260 px";
export const ELIBRARY_ATTACH_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png, .zip มีขนาดไฟล์ไม่เกิน 10 MB";
export const ELIBRARY_MEDIA_REMARK = "หมายเหตุ รองรับสกุลไฟล์ .mp4 มีขนาดไฟล์ไม่เกิน 10 MB";