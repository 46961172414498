import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AdminBanner from 'components/Banner/AdminBanner';
const swal = withReactContent(Swal)

export default function MenuManagement(props) {
    const [oldMenuList, setOldMenuList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const history = useHistory();
    function getSubmenu(item, allmenu, level) {
        const childmenu = allmenu.filter(x => x.ParentID === item.MenuID);
        //console.log(childmenu)
        if (childmenu.length > 0) {
            for (let i = 0; i < childmenu.length; i++) {
                childmenu[i].submenu = getSubmenu(childmenu[i], allmenu, level + 1);
            }
            //console.log(childmenu)
            return childmenu;
        }
        else {
            return [];
        }
    }
    function reLoadData() {
        let menu = [];
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus', { params: { status: 'all' } }).then((response) => {
            let data = response.data;
            setOldMenuList(data);
            for (let i = 0; i < data.length; i++) {
                if (Number(data[i].ParentID) === 0) {
                    let mainmenu = data[i];
                    mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                    //console.log(mainmenu)
                    menu.push(mainmenu)
                }
            }
            setMenuList(menu);
        })

    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/

    const SubMenu = (props) => {
        let div = <></>;
        let menu = props.menu;
        if (typeof menu === 'undefined')
            return div;
        let maindiv = (<></>);

        for (let i = 0; i < props.menu.submenu.length; i++) {
            let item = props.menu.submenu[i];
            //console.log(item)
            let subdiv = (<div key={item.MenuID}>
                <Draggable
                    key={item.MenuID}
                    draggableId={'item-' + item.MenuID}
                    index={i}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="menu-sub-item col-12"
                        >
                            <div
                                className="col-12 menu-subset-item" style={{ paddingLeft: 45 * props.level }}
                            >
                                <i className="fas fa-th-list mr-2"></i>
                                {item.NameMenuTH}
                                <span className="pl-4 pr-4 text-right float-right" >
                                    <button type="button" className="btn btn-primary mr-2" onClick={e => { e.preventDefault(); history.push({ pathname: "/admin/menu/EditMenu", state: { id: item.MenuID } }); }}>แก้ไข</button>
                                    <button type="button" className="btn btn-danger mr-2" onClick={e => { e.preventDefault(); deleteMenu(item.MenuID); }}> ลบ</button>
                                </span>
                            </div>
                        </div>
                    )}
                </Draggable>
                {(typeof item.submenu !== 'undefined') && (<SubMenu menuNum={i} menu={item} level={props.level + 1} />)}
            </div>
            );
            div = (<>{div} {subdiv}</>)
        }
        if (props.menu.submenu.length > 0) {
            maindiv = (
                <Droppable droppableId={`droppable${props.menu.MenuID}`} type={`${props.menuNum}`}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={{ padding: 12 }}
                        >
                            {div}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable >)
            return maindiv;
        }
        return div;
    }
    const catRef = React.createRef();
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            //console.log("no-change");
            return;
        }

        if (result.type === "MainMenu") {
            const items = reorder(
                menuList,
                result.source.index,
                result.destination.index
            );
            setMenuList(items);

        } else {
            let id = result.draggableId.substring(result.draggableId.indexOf("-") + 1);
            const newordermenu = Array.from(menuList);
            //console.log(id);
            const dropItem = oldMenuList.find(el => Number(el.MenuID) === Number(id));
            for (let i = 0; i < newordermenu.length; i++) {
                let item = newordermenu[i];
                if (Number(item.MenuID) === Number(dropItem.ParentID)) {
                    const orderitems = reorder(item.submenu, result.source.index,
                        result.destination.index);
                    //console.log(orderitems)
                    newordermenu[i].submenu = orderitems;
                    setMenuList(newordermenu);
                    return;
                }
                if (item.submenu.length > 0) {
                    for (let j = 0; j < item.submenu.length; j++) {
                        let childitem = item.submenu[j];
                        if (Number(childitem.MenuID) === Number(dropItem.ParentID)) {
                            //console.log(childitem)
                            const orderitems = reorder(newordermenu[i].submenu[j].submenu, result.source.index,
                                result.destination.index);
                            newordermenu[i].submenu[j].submenu = orderitems;
                            setMenuList(newordermenu);
                            return;
                        }
                    }
                }
            }
        }
    }

    const ChangeMenuType = () => {
        let menu = [];

        if (catRef.current.value === 'FE') {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                setOldMenuList(data);
                for (let i = 0; i < data.length; i++) {
                    if (data[i].Side === 'FE' || data[i].Side === 'ME') {
                        if (Number(data[i].ParentID) === 0) {
                            let mainmenu = data[i];
                            mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                            menu.push(mainmenu)
                        }
                    }
                }
                setMenuList(menu);
            })
        }
        else if (catRef.current.value === 'BE') {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                setOldMenuList(data);
                for (let i = 0; i < data.length; i++) {
                    if (data[i].Side === 'BE') {
                        if (Number(data[i].ParentID) === 0) {
                            let mainmenu = data[i];
                            mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                            menu.push(mainmenu)
                        }
                    }
                }
                setMenuList(menu);
            })
        }
        else {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getAllMenus').then((response) => {
                let data = response.data;
                setOldMenuList(data);
                for (let i = 0; i < data.length; i++) {
                    if (Number(data[i].ParentID) === 0) {
                        let mainmenu = data[i];
                        mainmenu.submenu = getSubmenu(mainmenu, data, 0);
                        //console.log(mainmenu)
                        menu.push(mainmenu)
                    }
                }
                setMenuList(menu);
            })
        }
    }
    const SaveOrder = () => {
        const formData = new FormData();
        for (let i = 0; i < menuList.length; i += 1) {
            let item = menuList[i];
            formData.append("menu[]", item.MenuID);
            formData.append("menuOrder[]", (i + 1));
            for (let j = 0; j < item.submenu.length; j += 1) {
                let jitem = item.submenu[j]
                formData.append("menu[]", jitem.MenuID);
                formData.append("menuOrder[]", (j + 1));
                for (let k = 0; k < jitem.submenu.length; k += 1) {
                    let kitem = jitem.submenu[k]
                    formData.append("menu[]", kitem.MenuID);
                    formData.append("menuOrder[]", (k + 1));
                }
            }
        }
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMenuOrder', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                let timerInterval;
                swal.fire({
                    title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval);

                    }
                }).then((result) => {

                })
            });
    }
    const deleteMenu = (id) => {
        let timerInterval;
        swal.fire({
            title: "ยืนยันการลบข้อมูล",
            showDenyButton: true,
            /*showCancelButton: true,*/
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteSingleRow(id);
            } else if (result.isDenied) {
                //swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    const deleteSingleRow = id => {
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteMenu', null, { params: { id: id } })
            .then((response) => {
                if (response.data.success === false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'ผิดพลาด',
                        text: response.data.text,
                    })
                }
                else {
                    let timerInterval;
                    swal.fire({
                        title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        window.location.reload();
                    })
                }
            })
    };
    return (
        <section className="row custom-admin-input MenuManagement" >
            <AdminBanner title={"การจัดการเมนู"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/menu/MenuManagement" }}>การจัดการเมนู</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <select className="form-control mb-2 mr-2" ref={catRef} onChange={(e) => { ChangeMenuType(); }}>
                                        <option value="">เลือกประเภทเมนู</option>
                                        <option value="FE">หน้าบ้าน</option>
                                        <option value="BE">หลังบ้าน</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <div className="pl-4 pr-4 text-right createMenu" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); SaveOrder(); }}><i className="fas fa-save"></i> บันทึกเรียงลำดับเมนู</button>
                                        <button type="button" className="btn btn-primary mr-2" onClick={e => { e.preventDefault(); history.push({ pathname: "/admin/menu/NewMenu" }); }}> สร้างเมนู</button>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable-1" type="MainMenu">
                                            {provided => (
                                                <div
                                                    className="list-content"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {(
                                                        <div >
                                                            {menuList.map((mainmenu, index) => (

                                                                <Draggable
                                                                    key={mainmenu.MenuID}
                                                                    draggableId={'item-' + mainmenu.MenuID}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="menu-main-item col-12"
                                                                        >
                                                                            <div className="col-12" >
                                                                                <i className="fas fa-th-list mr-2"></i>
                                                                                {mainmenu.NameMenuTH}
                                                                                <span className="pl-4 pr-4 text-right float-right" >
                                                                                    <button type="button" className="btn btn-primary mr-2" onClick={e => { e.preventDefault(); history.push({ pathname: "/admin/menu/EditMenu", state: { id: mainmenu.MenuID } }); }}>แก้ไข</button>
                                                                                    <button type="button" className="btn btn-danger mr-2" onClick={e => { e.preventDefault(); deleteMenu(mainmenu.MenuID); }}> ลบ</button>
                                                                                </span>
                                                                            </div>
                                                                            <SubMenu menuNum={index} menu={mainmenu} level={0} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
