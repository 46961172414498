import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { scroller } from "react-scroll";
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import utils from 'utils/utils';
//const swal = withReactContent(Swal)

export default function ComplainInfo(props) {
    //const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [NHRCComplaintJobID, setNHRCComplaintJobID] = useState('');
    const [complainTitle, setComplainTitle] = useState('');
    const [comFirstName, setComFirstName] = useState('');
    const [comLastName, setComLastName] = useState('');
    const [comIdCard, setComIdCard] = useState('');
    const [comPassportNo, setComPassportNo] = useState('');
    const [comAdrNo, setComAdrNo] = useState('');
    const [comAdrMoo, setComAdrMoo] = useState('');
    const [comAdrSoi, setComAdrSoi] = useState('');
    const [comAdrRoad, setComAdrRoad] = useState('');
    //const [comGeoID, setComGeoID] = useState('');
    const [comGeoName, setComGeoName] = useState('');
    //const [comAdrProvinceID, setComAdrProvinceID] = useState('');
    const [comAdrProvinceName, setComAdrProvinceName] = useState('');
    // const [comAdrDistrictID, setComAdrDistrictID] = useState('');
    const [comAdrDistrictName, setComAdrDistrictName] = useState('');
    //const [comAdrSubDistrictID, setComAdrSubDistrictID] = useState('');
    const [comAdrSubDistrictName, setComAdrSubDistrictName] = useState('');
    const [comAdrPostCode, setComAdrPostCode] = useState('');
    const [comEmail, setComEmail] = useState('');
    const [comTel1, setComTel1] = useState('');
    const [comTel2, setComTel2] = useState('');
    const [detail, setDetail] = useState('');
    // const [complainTypeID,setComplainTypeID] = useState('');
    const [comTypeTextTH , setComplainTypeTextTH] = useState('');

    const [organizationName, setOrganizationName] = useState('');
    const [orgFirstName, setOrgFirstName] = useState('');
    const [orgLastName, setOrgLastName] = useState('');
    const [orgAdrNo, setOrgAdrNo] = useState('');
    const [orgAdrMoo, setOrgAdrMoo] = useState('');
    const [orgAdrSoi, setOrgAdrSoi] = useState('');
    const [orgAdrRoad, setOrgAdrRoad] = useState('');
    //const [orgGeoID, setOrgGeoID] = useState('');
    const [orgGeoName, setOrgGeoName] = useState('');
    //const [orgAdrProvinceID, setOrgAdrProvinceID] = useState('');
    const [orgAdrProvinceName, setOrgAdrProvinceName] = useState('');
    // const [orgAdrDistrictID, setOrgAdrDistrictID] = useState('');
    const [orgAdrDistrictName, setOrgAdrDistrictName] = useState('');
    //const [orgAdrSubDistrictID, setOrgAdrSubDistrictID] = useState('');
    const [orgAdrSubDistrictName, setOrgAdrSubDistrictName] = useState('');
    const [orgAdrPostCode, setOrgAdrPostCode] = useState('');

    //const [orgCountryID, setOrgCountryID] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [orgPhone, setOrgPhone] = useState('');

    const [genNumberOfficerName, setGenNumberOfficerName] = useState('');
    const [acceptOfficerName, setAcceptOfficerName] = useState('');
    const [acceptOfficerTel, setAcceptOfficerTel] = useState('');
    const [examinerReportNo, setExaminerReportNo] = useState('');
    const [wsComplianNo, setWSComplianNo] = useState('');
    const [state, setState] = useState('');
    const [responsibleGroupName, setResponsibleGroupName] = useState('');
    const [responsibleName, setResponsibleName] = useState('');
    const [currentProgress, setCurrentProgress] = useState('');

    const [myFiles, setMyFiles] = useState([]);
    const complainID = props.location.state.id;
    useEffect(() => {
        //console.log(complainID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/complainbyID', { params: { id: complainID } })
            .then((response) => {
                let data = response.data[0];
                //console.log(data)
                setResponsibleGroupName('');
                setResponsibleName('');
                setNHRCComplaintJobID(data.NHRCComplaintJobID);
                setComplainTitle(data.ComplainTitle);
                setComFirstName(data.ComFirstName);
                setComLastName(data.ComLastName);
                setComIdCard(data.ComIdCard);
                setComPassportNo(data.ComPassportNo);
                setComAdrNo(data.ComAdrNo);
                setComAdrMoo(data.ComAdrMoo);
                setComAdrSoi(data.ComAdrSoi);
                setComAdrRoad(data.ComAdrRoad);
                setComGeoName(data.ComGeoName);
                setComAdrProvinceName(data.ComAdrProvinceName);
                setComAdrDistrictName(data.ComAdrDistrictName);
                setComAdrSubDistrictName(data.ComAdrSubDistrictName);
                setComAdrPostCode(data.ComAdrPostCode);
                setComEmail(data.ComEmail);
                setComTel1(data.ComTel1);
                setComTel2(data.ComTel2);
                setDetail(data.Detail);
                setComplainTypeTextTH(data.TextTH)
                // setComplainTypeID(data.complainTypeID)

                setOrganizationName(data.OrganizationName);
                setOrgFirstName(data.OrgFirstName);
                setOrgLastName(data.OrgLastName);
                setOrgAdrNo(data.OrgAdrNo);
                setOrgAdrMoo(data.OrgAdrMoo);
                setOrgAdrSoi(data.OrgAdrSoi);
                setOrgAdrRoad(data.OrgAdrRoad);
                setOrgGeoName(data.OrgGeoName);
                setOrgAdrProvinceName(data.OrgAdrProvinceName);
                setOrgAdrDistrictName(data.OrgAdrDistrictName);
                setOrgAdrSubDistrictName(data.OrgAdrSubDistrictName);
                setOrgAdrPostCode(data.OrgAdrPostCode);
                setOrgEmail(data.OrgEmail);
                setOrgPhone(data.OrgPhone);

                setGenNumberOfficerName(data.GenNumberOfficerName);
                setAcceptOfficerName(data.AcceptOfficerName);
                setAcceptOfficerTel(data.AcceptOfficerTel);
                setExaminerReportNo(data.ExaminerReportNo);
                setWSComplianNo(data.WSComplianNo);
                setState(data.State);
                setCurrentProgress(data.CurrentProgress);

                const newFiles = [...myFiles];
                let loop = 0;
                //console.log(data.filelist)
                for (let i = 0; i < data.filelist.length; i += 1) {
                    let item = data.filelist[i];
                    const filedata = {
                        //file: newImageFile[0],
                        id: item.AttachmentID,
                        path: item.FilenameNew,
                        name: item.FilenameOld,
                        preview: item.AttachmentPath,
                        size: item.Filesize
                    };
                    newFiles[loop] = filedata;
                    loop++;
                }
                setMyFiles(newFiles);
                setLoading(false);
            });
    }, []); /* <-- add this for run once*/

    const cancel = () => {
        props.history.push({ pathname: "/admin/complain/Complain" });
    };
    const files = myFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
            </td>
        </tr>
    ));
    function StateRenderer(state) {
        //console.log(params.value)
        if (state === "WAIT")
            return 'รับเรื่องร้องเรียน';
        else if (state === "ACCEPT")
            return 'รับเป็นคำร้อง';
        else if (state === "DECLINE")
            return 'ไม่รับเป็นคำร้อง';
        else if (state === "CANCEL")
            return 'ยกเลิก';
        else if (state === "FORWARD")
            return 'ส่งต่อหน่วยงานภายนอก';
        else if (state === "TERMINATED")
            return 'ยุติ';
        return 'กำลังดำเนินการ/อยู่ระหว่างดำเนินการ';
    };
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={(e) => { e.preventDefault(); }}>{"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"}</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/complain/Complain" }}>{"จัดการการร้องเรียน"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/complain/ComplainInfo", state: { id: complainID } }}>{complainTitle}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body row">
                            <div className="alert alert-dark col-12" role="alert">เรื่องร้องเรียน</div>
                                <div className="form-group col-12">
                                    <label htmlFor="comFirstName">ชื่อเรื่องร้องเรียน</label>
                                    <input type="text" className="form-control" id="complainTitle" name="complainTitle" placeholder="กรุณากรอก" value={complainTitle} readOnly={true} />
                                </div>
                                <div className="alert alert-dark col-12" role="alert">ข้อมูลผู้ร้องเรียน</div>
                                <div className="form-group col-6">
                                    <label htmlFor="comFirstName">ชื่อผู้ร้อง</label>
                                    <input type="text" className="form-control" id="comFirstName" name="comFirstName" placeholder="กรุณากรอก" value={comFirstName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comLastName">นามสกุล</label>
                                    <input type="text" className="form-control" id="comLastName" name="comLastName" placeholder="กรุณากรอก" value={comLastName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comIdCard">เลขบัตรประจำตัวประชาชน</label>
                                    <input type="text" className="form-control" id="comIdCard" name="comIdCard" placeholder="กรุณากรอก" value={comIdCard} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comPassportNo">เลขหนังสือเดินทาง</label>
                                    <input type="text" className="form-control" id="comPassportNo" name="comPassportNo" placeholder="กรุณากรอก" value={comPassportNo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrNo">บ้านเลขที่</label>
                                    <input type="text" className="form-control" id="comAdrNo" name="comAdrNo" placeholder="กรุณากรอก" value={comAdrNo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrMoo">หมู่ที่</label>
                                    <input type="text" className="form-control" id="comAdrMoo" name="comAdrMoo" placeholder="กรุณากรอก" value={comAdrMoo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrSoi">ซอย</label>
                                    <input type="text" className="form-control" id="comAdrSoi" name="comAdrSoi" placeholder="กรุณากรอก" value={comAdrSoi} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrRoad">ถนน</label>
                                    <input type="text" className="form-control" id="comAdrRoad" name="comAdrRoad" placeholder="กรุณากรอก" value={comAdrRoad} readOnly={true} />
                                </div>

                                <div className="form-group col-6">
                                    <label htmlFor="comGeoName">ภาค</label>
                                    <input type="text" className="form-control" id="comGeoName" name="comGeoName" placeholder="กรุณากรอก" value={comGeoName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrProvinceName">จังหวัด</label>
                                    <input type="text" className="form-control" id="comAdrProvinceName" name="comAdrProvinceName" placeholder="กรุณากรอก" value={comAdrProvinceName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrDistrictName">เขต/อำเภอ</label>
                                    <input type="text" className="form-control" id="comAdrDistrictName" name="comAdrDistrictName" placeholder="กรุณากรอก" value={comAdrDistrictName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrSubDistrictName">แขวง/ตำบล</label>
                                    <input type="text" className="form-control" id="comAdrSubDistrictName" name="comAdrSubDistrictName" placeholder="กรุณากรอก" value={comAdrSubDistrictName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comAdrPostCode">รหัสไปรษณีย์</label>
                                    <input type="text" className="form-control" id="comAdrPostCode" name="comAdrPostCode" placeholder="กรุณากรอก" value={comAdrPostCode} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comEmail">อีเมล</label>
                                    <input type="text" className="form-control" id="comEmail" name="comEmail" placeholder="กรุณากรอก" value={comEmail} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comTel1">โทรศัพท์ 1</label>
                                    <input type="text" className="form-control" id="comTel1" name="comTel1" placeholder="กรุณากรอก" value={comTel1} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="comTel2">โทรศัพท์ 2</label>
                                    <input type="text" className="form-control" id="comTel2" name="comTel2" placeholder="กรุณากรอก" value={comTel2} readOnly={true} />
                                </div>

                                <div className="alert alert-dark col-12" role="alert">ข้อมูลถูกผู้ร้อง</div>
                                <div className="form-group col-6">
                                    <label htmlFor="organizationName">ชื่อสถาบันและองค์กร</label>
                                    <input type="text" className="form-control" id="organizationName" name="organizationName" placeholder="กรุณากรอก" value={organizationName} readOnly={true} />
                                </div>
                                <div className="form-group col-6"></div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgFirstName">ชื่อ</label>
                                    <input type="text" className="form-control" id="orgFirstName" name="orgFirstName" placeholder="กรุณากรอก" value={orgFirstName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgLastName">นามสกุล</label>
                                    <input type="text" className="form-control" id="orgLastName" name="orgLastName" placeholder="กรุณากรอก" value={orgLastName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrNo">บ้านเลขที่</label>
                                    <input type="text" className="form-control" id="orgAdrNo" name="orgAdrNo" placeholder="กรุณากรอก" value={orgAdrNo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrMoo">หมู่ที่</label>
                                    <input type="text" className="form-control" id="orgAdrMoo" name="orgAdrMoo" placeholder="กรุณากรอก" value={orgAdrMoo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrSoi">ซอย</label>
                                    <input type="text" className="form-control" id="orgAdrSoi" name="orgAdrSoi" placeholder="กรุณากรอก" value={orgAdrSoi} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrRoad">ถนน</label>
                                    <input type="text" className="form-control" id="orgAdrRoad" name="orgAdrRoad" placeholder="กรุณากรอก" value={orgAdrRoad} readOnly={true} />
                                </div>

                                <div className="form-group col-6">
                                    <label htmlFor="orgGeoName">ภาค</label>
                                    <input type="text" className="form-control" id="orgGeoName" name="orgGeoName" placeholder="กรุณากรอก" value={orgGeoName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrProvinceName">จังหวัด</label>
                                    <input type="text" className="form-control" id="orgAdrProvinceName" name="orgAdrProvinceName" placeholder="กรุณากรอก" value={orgAdrProvinceName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrDistrictName">เขต/อำเภอ</label>
                                    <input type="text" className="form-control" id="orgAdrDistrictName" name="orgAdrDistrictName" placeholder="กรุณากรอก" value={orgAdrDistrictName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrSubDistrictName">แขวง/ตำบล</label>
                                    <input type="text" className="form-control" id="orgAdrSubDistrictName" name="orgAdrSubDistrictName" placeholder="กรุณากรอก" value={orgAdrSubDistrictName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgAdrPostCode">รหัสไปรษณีย์</label>
                                    <input type="text" className="form-control" id="orgAdrPostCode" name="orgAdrPostCode" placeholder="กรุณากรอก" value={orgAdrPostCode} readOnly={true} />
                                </div>
                                {/*<div className="form-group col-6">
                                    <label htmlFor="orgCountryID">ประเทศ</label>
                                    <input type="text" className="form-control" id="orgCountryID" name="orgCountryID" placeholder="กรุณากรอก" value={orgCountryID} readOnly={true} />
                                </div>*/}
                                <div className="form-group col-6">
                                    <label htmlFor="orgEmail">อีเมล</label>
                                    <input type="text" className="form-control" id="orgEmail" name="orgEmail" placeholder="กรุณากรอก" value={orgEmail} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgPhone">โทรศัพท์</label>
                                    <input type="text" className="form-control" id="orgPhone" name="orgPhone" placeholder="กรุณากรอก" value={orgPhone} readOnly={true} />
                                </div>

                                <div className="alert alert-dark col-12" role="alert">รายละเอียด / พฤติการณ์ที่ต้องการร้องเรียน</div>
                                <div className="form-group col-6">
                                    <label htmlFor="detail">ความประสงค์ของผู้ร้อง</label>
                                    <input className="form-control" id="comTypeTextTH" name="comTypeTextTH" rows="4" cols="50" value={comTypeTextTH} readOnly={true}/>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="detail">รายละเอียด</label>
                                    <textarea className="form-control" id="detail" name="detail" rows="4" cols="50" value={detail} readOnly={true}>

                                    </textarea>
                                </div>
        

                                <div className="alert alert-dark col-12" role="alert">เอกสารแนบ</div>
                                <div className="form-group col-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ลำดับ</th>
                                                    <th>ชื่อ</th>
                                                    <th>ขนาดไฟล์</th>
                                                    <th>ประเภทไฟล์</th>
                                                    <th>จัดการ</th>
                                                </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                                {files}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">สถานะการดำเนินการ</div>
                                <div className="form-group col-6">
                                    <label htmlFor="wsComplianNo">หมายเลขรับเรื่อง</label>
                                    <input type="text" className="form-control" id="wsComplianNo" name="wsComplianNo" placeholder="กรุณากรอก" value={wsComplianNo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="orgLastName">หมายเลขคำร้อง</label>
                                    <input type="text" className="form-control" id="NHRCComplaintJobID" name="NHRCComplaintJobID" placeholder="กรุณากรอก" value={NHRCComplaintJobID} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="genNumberOfficerName">เจ้าหน้าที่ออกเลขคำร้อง</label>
                                    <input type="text" className="form-control" id="genNumberOfficerName" name="genNumberOfficerName" placeholder="กรุณากรอก" value={genNumberOfficerName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="acceptOfficerName">เจ้าหน้าที่ผู้รับคำร้อง</label>
                                    <input type="text" className="form-control" id="acceptOfficerName" name="acceptOfficerName" placeholder="กรุณากรอก" value={acceptOfficerName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="acceptOfficerTel">เบอร์โทรติดต่อเจ้าหน้าที่ผู้รับผิดชอบคำร้อง</label>
                                    <input type="text" className="form-control" id="acceptOfficerTel" name="acceptOfficerTel" placeholder="กรุณากรอก" value={acceptOfficerTel} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="responsibleGroupName">กลุ่มงานตรวจสอบการละเมิดสิทธิมนุษยชน 1</label>
                                    <input type="text" className="form-control" id="responsibleGroupName" name="responsibleGroupName" placeholder="กรุณากรอก" value={responsibleGroupName} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="responsibleName">เจ้าหน้าที่ผู้รับผิดชอบสำนวน</label>
                                    <input type="text" className="form-control" id="responsibleName" name="responsibleName" placeholder="กรุณากรอก" value={responsibleName} readOnly={true} />
                                </div>
                                {/*<div className="form-group col-6">
                                    <label htmlFor="orgAdrProvinceName">เบอร์โทรติดต่อเจ้าหน้าที่ผู้รับผิดชอบคำร้อง</label>
                                    <input type="text" className="form-control" id="orgAdrProvinceName" name="orgAdrProvinceName" placeholder="กรุณากรอก" value={orgAdrProvinceName} readOnly={true} />
                            </div>*/}
                                <div className="form-group col-6">
                                    <label htmlFor="examinerReportNo">เลขที่รายงาน</label>
                                    <input type="text" className="form-control" id="examinerReportNo" name="examinerReportNo" placeholder="กรุณากรอก" value={examinerReportNo} readOnly={true} />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="state">สถานะ</label>
                                    <input type="text" className="form-control" id="state" name="state" placeholder="กรุณากรอก" value={StateRenderer(state)} readOnly={true} />
                                </div>
                                {/* <div className="form-group col-12">
                                    <label htmlFor="detail">รายละเอียด</label>
                                    <textarea className="form-control" id="detail" name="detail" rows="4" cols="50" value={detail} readOnly={true}>

                                    </textarea>
                                </div> */}

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40" >
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ย้อนกลับ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
