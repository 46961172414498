import React, { useState, useMemo } from "react";
//import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import avatar from '../assets/images/icon/user.svg';
const swal = withReactContent(Swal)

export default function CommentList(props) {
    
    const sourceid = props.sourceid;
    const sourcetype = props.sourcetype;
    const language = localStorage.getItem("language");
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [commentList, SetCommentList] = useState([]);
    const [reply, SetReply] = useState('');
    const [editcomment, SetEditComment] = useState('');
    const [postcomment, SetPostComment] = useState('');
    //const [editcommenttext, SetEditCommentText] = useState('');
    const [errors, setError] = useState({});
    const commentEditRef = React.createRef();
    const commentReplyRef = React.createRef();
    useMemo(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/commentlist', { params: { sourceid: sourceid } }).then((response) => {
            SetCommentList(response.data)
            //console.log(response)           
            setLoading(false);
        })
    }, []);
    
    if (isLoading) {
        return (<></>);
    }
    const ReplyBox = (props) => {
        if (props.level === 0) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="comment-box">
                        <textarea className="form-control" name="replycomment" id="replycomment" rows="8" ref={commentReplyRef} ></textarea>
                        <span className="error">{errors["replycomment"]}</span>
                        <div className="post-tools"> 
                            <a href="/" className="btn btn-primary" onClick={e => { e.preventDefault(); replyComment(props.CommentID, sourceid, sourcetype)}}>{language === "Thai" ? "ตอบกลับ" : "Reply"}</a>
                        </div>
                    </div> 
                </div>
            </div>
        );
        }
        else{
            return (<></>);
        }
    }
    const EditCommentBox = (props) => {
        //console.log(props)
        return (
            <div className="row">
                <div className="col-12">
                    <div className="comment-box">
                        <textarea className="form-control" name="editcommenttext" id="editcommenttext" rows="8" ref={commentEditRef} defaultValue={props.comment.Description}></textarea>
                        <span className="error">{errors["editcommenttext"]}</span>
                        <div className="post-tools">
                            <a href="/" className="btn btn-primary" onClick={e => { e.preventDefault(); updateComment(props.comment.CommentID)}}>{language === "Thai" ? "ปรับปรุงข้อมูล" : "Update"}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const EditButton = (props) => {
        if (currentUser != null) {
            if (currentUser.UserID === props.comment.UserID) {
                return (
                    <span><a href="/" onClick={(e) => { e.preventDefault(); SetReply(0); editcomment !== props.comment.CommentID ? SetEditComment(props.comment.CommentID) : SetEditComment(0); }}><i className="far fa-edit"></i></a></span>
                );
            }
        }
        return (<></>);

    }
    const DeleteButton = (props) => {
        if (currentUser != null) {
            if (currentUser.UserID === props.comment.UserID) {
                return (
                    <span><a href="/" onClick={(e) => { e.preventDefault(); deleteComment(props.comment.CommentID); }}><i className="far fa-trash-alt"></i></a></span>
                );
            }
        }
        return (<></>);
    }
    const ReplyButton = (props) => {
        if (props.level === 0 && currentUser!==null) {
            return (
                <span><a href="/" onClick={(e) => { e.preventDefault();SetEditComment(0); reply!==props.comment.CommentID?SetReply(props.comment.CommentID):SetReply(0);}}><i className="fas fa-reply"></i></a></span>
            );
        }
        else {
            return (<></>);
        }
    }
    function CommentItem({ item, commentlist, mode, level }) {
        let newitem = null;

        let childmenu = commentlist.filter(x => x.ParentCommentID === item.CommentID);
        //console.log(childmenu)
        if(mode===3 && currentUser !== null){
            childmenu = commentlist.filter(x => x.ParentCommentID === item.CommentID && Number(x.UserID)===Number(currentUser.UserID));
            //console.log(childmenu)
        }
      
        //console.log("level : " + level)
        //console.log(childmenu.length)
        let classname = "media";
        if (level > 0) {
            classname = "reply media";
        }
        
        if (childmenu) {
            if (childmenu.length > 0) {
                newitem = (
                    <>
                        <div className={classname} key={'comment-'+item.CommentID}>
                            <div className="comment-avatar-circle">
                                <img src={item.UserAvatarCache==='null'?avatar:item.UserAvatarCache} className="avatar-icon" alt="avatar icon" />
                            </div>
                            <div className="comment-item">
                                <div className="comment-tool">

                                    <div className="comment-name mt-2 mb-2">
                                        <span className="user-name">{item.UserNameCache}</span>
                                        <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateString(item.CommentDate)}</p></span>
                                    </div>
                                    <div className="comment-button mt-2 mb-2 float-right">
                                        <EditButton comment={item} />
                                        <DeleteButton comment={item} />
                                        <ReplyButton comment={item} level={level} />
                                    </div>
                                </div>
                                <div className="comment-detail">
                                    {editcomment!==item.CommentID &&(item.Description)}
                                    {editcomment===item.CommentID &&(<EditCommentBox comment={item}/>)}
                                </div>
                                
                                
                            </div>
                        </div>
                        {reply===item.CommentID &&(<ReplyBox level={level} CommentID={item.CommentID} />)}
                        {childmenu.map(i => <CommentItem item={i} commentlist={commentList} mode={mode} level={1} key={i.CommentID} />)}
                    </>
                );
            }
            else {
                if (mode === 3 && currentUser !== null) {
                    if(Number(item.UserID)===Number(currentUser.UserID))
                    {
                        newitem = (
                            <>
                                <div className={classname} key={'comment-'+item.CommentID}>
                                    <div className="comment-avatar-circle">
                                        <img src={item.UserAvatarCache==='null'?avatar:item.UserAvatarCache} className="avatar-icon" alt="avatar icon" />
                                    </div>
                                    <div className="comment-item">
                                        <div className="comment-tool">
    
                                            <div className="comment-name mt-2 mb-2">
                                                <span className="user-name">{item.UserNameCache}</span>
                                                <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateString(item.CommentDate)}</p></span>
                                            </div>
                                            <div className="comment-button mt-2 mb-2 float-right">
                                                <EditButton comment={item} />
                                                <DeleteButton comment={item} />
                                                <ReplyButton comment={item} level={level} />
                                            </div>
                                        </div>
                                        {editcomment !== item.CommentID && (item.Description)}
                                        {editcomment === item.CommentID && (<EditCommentBox comment={item} />)}
                                    </div>
                                </div>
                                {reply === item.CommentID && (<ReplyBox level={level} CommentID={item.CommentID} />)}
                            </>
                        );
                    }
                    else{
                        newitem=(<></>);
                    }
                }
                else {
                    newitem = (
                        <>
                            <div className={classname} key={'comment-'+item.CommentID}>
                                <div className="comment-avatar-circle">
                                    <img src={item.UserAvatarCache==='null'?avatar:item.UserAvatarCache} className="avatar-icon" alt="avatar icon" />
                                </div>
                                <div className="comment-item">
                                    <div className="comment-tool">

                                        <div className="comment-name mt-2 mb-2">
                                            <span className="user-name">{item.UserNameCache}</span>
                                            <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateString(item.CommentDate)}</p></span>
                                        </div>
                                        <div className="comment-button mt-2 mb-2 float-right">
                                            <EditButton comment={item} />
                                            <DeleteButton comment={item} />
                                            <ReplyButton comment={item} level={level} />
                                        </div>
                                    </div>
                                    {editcomment !== item.CommentID && (item.Description)}
                                    {editcomment === item.CommentID && (<EditCommentBox comment={item} />)}
                                </div>
                            </div>
                            {reply === item.CommentID && (<ReplyBox level={level} CommentID={item.CommentID} />)}
                        </>
                    );
                }
            }
        }
        return newitem;
    }
    const postComment = (sourceid, sourcetype) => {
        let serrors = {};
        let formIsValid = true;
        if(postcomment.trim().length===0){
            formIsValid = false;
            serrors["postcomment"] = "กรุณาใส่ข้อมูลก่อนแสดงความคิดเห็น";
            setError(serrors);
        }
        if(formIsValid===true){
            const formData = new FormData();
            //formData.append("id", props.location.state.id);
            formData.append("sourceID", sourceid);
            formData.append("userID", currentUser.UserID);
            formData.append("postcomment", postcomment);
            formData.append("avatar", currentUser.Avatar);
            formData.append("userName", currentUser.FirstnameTH+" "+currentUser.LastnameTH);
            formData.append("sourcetype", sourcetype);
            axios.post(process.env.REACT_APP_APIURL+'/api-web/postComment', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        /*if (open) {
                            props.history.push({ pathname: "/admin/news/News" });
                        }
                        else{
                            
                        }*/
                        window.location.reload();
                    })
                });
        }
    };
    const updateComment = (id) => {
        //console.log(commentEditRef.current.value)
        let serrors = {};
        let formIsValid = true;
        if (commentEditRef.current.value.trim().length === 0) {
            formIsValid = false;
            serrors["editcommenttext"] = "กรุณาใส่ข้อมูลก่อนแก้ไขความคิดเห็น";
            setError(serrors);
        }
        if (formIsValid === true) {
            const formData = new FormData();
            //formData.append("id", props.location.state.id);
            formData.append("id", id);
            formData.append("userID", currentUser.UserID);
            formData.append("postcomment", commentEditRef.current.value);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateComment', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        window.location.reload();
                    })
                });
        }
    };
    const replyComment = (parentID, sourceid, sourcetype) => {
        let serrors = {};
        let formIsValid = true;
        if(commentReplyRef.current.value.trim().length===0){
            formIsValid = false;
            serrors["replycomment"] = "กรุณาใส่ข้อมูลก่อนตอบกลับความคิดเห็น";
            setError(serrors);
        }
        if(formIsValid===true){
            const formData = new FormData();
            //formData.append("id", props.location.state.id);
            formData.append("parentID", parentID);
            formData.append("sourceID", sourceid);
            formData.append("userID", currentUser.UserID);
            formData.append("postcomment", commentReplyRef.current.value);
            formData.append("avatar", currentUser.Avatar);
            formData.append("userName", currentUser.FirstnameTH+" "+currentUser.LastnameTH);
            formData.append("sourcetype", sourcetype);
            axios.post(process.env.REACT_APP_APIURL+'/api-web/replyComment', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        /*if (open) {
                            props.history.push({ pathname: "/admin/news/News" });
                        }
                        else{
                            
                        }*/
                        window.location.reload();
                    })
                });
        }
    };
    
    const deleteComment = (id) => {
        let timerInterval;
        swal.fire({
            title: "ยืนยันการลบข้อมูล",
            showDenyButton: true,
            /*showCancelButton: true,*/
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                swal.fire({
                    title: "กำลังดำเนินการ", showConfirmButton: false,
                    timer: 1000,timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                          const content = Swal.getHtmlContainer()
                          if (content) {
                            const b = content.querySelector('b')
                            if (b) {
                              b.textContent = Swal.getTimerLeft()
                            }
                          }
                        }, 100)
                      },
                      willClose: () => {
                        clearInterval(timerInterval)
                      }
                }).then((result) => {
                    const formData = new FormData();
                    //formData.append("id", props.location.state.id);
                    formData.append("id", id);
                    formData.append("userID", currentUser.UserID);
                    //formData.append("postcomment", commentEditRef.current.value);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteComment', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {
                            let timerInterval;
                            swal.fire({
                                title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                                timer: 1000, timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getHtmlContainer()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);

                                }
                            }).then((result) => {
                                window.location.reload();
                            })
                        });
                })

            } else if (result.isDenied) {
                //swal.fire('Changes are not saved', '', 'info')
            }
        })
    };
    return (
        <>
            {(currentUser !== null && (props.mode === 1 || props.mode === 3)) && (<div className="row">
                <div className="col-12">
                    <div className="comment-box">
                        <textarea className="form-control" name="postcomment" id="postcomment" rows="8" onChange={e => { SetPostComment(e.target.value) }}></textarea>
                        <span className="error">{errors["postcomment"]}</span>
                        <div className="post-tools">
                            <a href="/" className="btn btn-primary" onClick={e => { e.preventDefault(); postComment(sourceid, sourcetype) }}>{language === "Thai" ? "แสดงความคิดเห็น" : "Post Your Comment"}</a>
                        </div>
                    </div>
                </div>
            </div>)
            }
            {(props.mode === 1 || props.mode === 3 ||props.mode === 4) && (<div className="comments-list">
                {
                    commentList.filter(x => x.ParentCommentID === 0).map(i => (
                        <CommentItem item={i} commentlist={commentList} mode={props.mode} level={0} key={i.CommentID} />
                    ))
                }
            </div>)
            }
        </>
    );

}