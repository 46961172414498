import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
const swal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 520,
        height: 530,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function ManageMemberGroup(props) {
    const currentUser = authenticationService.currentUserValue;
    const [selectMemberGroup, setSelectMemberGroup] = useState([]);
    const [selectGroupID, setSelectGroupID] = useState(0);
    const [nameth, setNameTH] = useState("");
    const [errors, setError] = useState({});
    const activeGroupID = props.location.state.id;
    const [activeGroup, setActiveGroup] = useState({});
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [allleft, setAllLeft] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    useEffect(() => {
        //console.log(props.location.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/memberGroup')
            .then((response) => {
                setSelectMemberGroup(response.data);
                const active = response.data.find(e => Number(e.GroupID) === Number(activeGroupID));
                setActiveGroup(active);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: activeGroupID, inverse: false } }).then((response) => {
            setRight(response.data);
            //console.log(response.data)
        })
        axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: activeGroupID, inverse: true } }).then((response) => {
            setLeft(response.data);
            setAllLeft(response.data);
            //console.log(response.data)
        })
    }, []); /* <-- add this for run once*/
    /*const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (nameth.trim().length === 0) {
            formIsValid = false;
            serrors["nameth"] = "กรุณากรอกข้อมูล";
        }
        if (Number(status) === -1) {
            formIsValid = false;
            serrors["status"] = "กรุณากำหนดสถานะการเผยแพร่ข้อมูล";
        }
        setError(serrors);
        //console.log(serrors)
        return formIsValid;
    };*/
    const saveData = (open) => {
        /*if (handleValidation()) {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMemberInGroup', null, { params: { titleth: nameth, titleen: nameen, status: status, userID: currentUser.UserID } })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/members/MemberGroup" });
                        }
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }*/
        const formData = new FormData();
        formData.append("activeGroupID", activeGroupID);
        formData.append("userID", currentUser.UserID);
        for (var i = 0; i < left.length; i++) {
            //console.log(listofContent[i])
            formData.append('left[]', JSON.stringify(left[i]));

        }
        for (var i = 0; i < right.length; i++) {
            //console.log(listofContent[i])
            formData.append('right[]', JSON.stringify(right[i]));

        }
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMemberInGroup', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        }).then((response) => {
            let timerInterval;
            swal.fire({
                title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                timer: 1000, timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                        const content = Swal.getHtmlContainer()
                        if (content) {
                            const b = content.querySelector('b')
                            if (b) {
                                b.textContent = Swal.getTimerLeft()
                            }
                        }
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval);

                }
            }).then((result) => {
                if (open) {
                    //window.location.reload();
                }
                else {
                    //props.history.push({ pathname: "/admin/members/MemberGroup" });
                }
            })
        });
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/members/MemberGroup" });
    };
    const MemberGroupSelect = selectMemberGroup.map((item, index) => (
        (Number(activeGroupID) !== Number(item.GroupID)) && (<option
            key={index}
            value={item.GroupID}
        >
            {item.GroupNameTH}
        </option>)
    ));
    const searchRef = React.createRef();
    const handleGroupChange = (id) => {
        //console.log(id)
        if (Number(id) !== 0) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: id, inverse: false } }).then((response) => {
                setRight(response.data);
                //setAllRight(response.data);
                //console.log(response.data)
            })
        }
        else {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: activeGroupID, inverse: true } }).then((response) => {
                setLeft(response.data);
                setAllLeft(response.data);
                //console.log(response.data)
            })
        }
    }
    const handleLeftChange = (name) => {
        /*if (Number(id) !== 0) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: id, inverse: false } }).then((response) => {
                setLeft(response.data);

                //console.log(response.data)
            })
        }
        else {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/MemberInGroup', { params: { id: activeGroupID, inverse: true } }).then((response) => {
                setLeft(response.data);
            })
        }*/
        //console.log(allleft.filter(item => item['FirstnameTH'].indexOf(name) > -1 || item['LastnameTH'].indexOf(name) > -1));
        setLeft(allleft.filter(item => item['FirstnameTH'].toLowerCase().indexOf(name.toLowerCase()) > -1 || item['LastnameTH'].toLowerCase().indexOf(name.toLowerCase()) > -1));
        //allleft.filter()
    }
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        setAllLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setAllLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setAllLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setAllLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value, index) => {
                    const labelId = `transfer-list-item-${value.UserID}-label`;

                    return (
                        <ListItem key={"user_" + value.UserID + "-" + index} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.FirstnameTH + " " + value.LastnameTH} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกลุ่มผู้ใช้งาน"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/members/MemberGroup" }}>{"การจัดการกลุ่มผู้ใช้งาน"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/members/ManageMemberGroup", state: { id: activeGroupID } }}>จัดการกลุ่มผู้ใช้งาน {activeGroup.GroupNameTH}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-5">
                        <select className="form-control mb-2 mr-2" id="selectGroupID" name="selectGroupID" value={selectGroupID} onChange={(e) => { setSelectGroupID(e.currentTarget.value); handleGroupChange(e.currentTarget.value); }}>
                            <option key="0" value="0" >เลือกหน่วยงาน</option>
                            {MemberGroupSelect}
                        </select>
                        <span className="error">{errors["selectGroupID"]}</span>
                    </div>
                    <div className="col-7"></div>
                    <div className="col-7">
                        <div className="form-group">
                            <input type="text" ref={searchRef} className="form-control" id="nameth" name="nameth" placeholder="ชื่อ" value={nameth} onChange={(e) => { e.preventDefault(); setNameTH(e.currentTarget.value); handleLeftChange(e.currentTarget.value); return false; }} />
                            <SError error={errors["nameth"]} />
                        </div>
                    </div>
                    <div className="col-5"></div>
                    <div className="col-12">
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.root}
                        >
                            <Grid item>{customList(left)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllRight}
                                        disabled={left.length === 0}
                                        aria-label="move all right"
                                    >
                                        ≫
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllLeft}
                                        disabled={right.length === 0}
                                        aria-label="move all left"
                                    >
                                        ≪
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList(right)}</Grid>
                        </Grid>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
