import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../assets/styles/banner.css";
/*import notifyicon from '../../assets/images/icon/notifyico.png';
import alerticon from '../../assets/images/icon/alertico.png';
import docicon from '../../assets/images/icon/docico.png';*/
//import banner from '../../assets/images/banner/advertise.svg';
export default function ContentBanner(props) {
  //console.log(props);
  const [bannerImage, setBannerImage] = useState(props.banner);
  const language = localStorage.getItem("language");
  useEffect(() => {
    // axios
    //   .get(process.env.REACT_APP_APIURL + "/api-web/getMenuBannerByKey", {
    //     params: { key: props.bannerkey },
    //   })
    //   .then((response) => {
    //     if (typeof response.data[0] !== "undefined") {
    //       if (response.data[0].BannerImage.trim().length > 0) {
    //         console.log("getMenuBannerByKey :",response.data[0].BannerImage)
    //         setBannerImage(response.data[0].BannerImage);
    //       }
    //     }
    //     //console.log(response.data)
    //   });
    return () => {
      //cleanup
    };
  }, []);
  return (
    <div id="authenheader" className="carousel slide contentBanner" data-ride="carousel">
      <div className="carousel-inner">
        <div className="content-banner">
          <div className="banner-frame">
            <img src={bannerImage} className="d-block w-100" alt="banner1" />
          </div>
        </div>
      </div>
      <div className="blueline"></div>
      <div id="sitemap">
        <div className="container">
          <div className="controlSiteMap">
            <Link
              id="profile-btn"
              className="Home-sitemap"
              to={{ pathname: "/" }}
            >
              {language === "Thai" ? "หน้าแรก" : "Home"}
            </Link>
            {props.path}
          </div>
        </div>
      </div>
      <div id="banner-title">
        <h1 className="eng-header">{props.eheader}</h1>
        <h1 className="thai-header">
          <span className="orang underline">{props.theader}</span>
        </h1>
      </div>
    </div>
  );
}
