import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function EditActivityEnroll(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});
    const enID = props.location.state.id;

    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [position, setPosition] = useState('');
    const [department, setDepartment] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [otherInfo, setOtherInfo] = useState("");
    const [approve, setApprove] = useState(2);
    const [notApproveRemark, setNotApproveRemark] = useState('');
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        //console.log(props.telephone.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getActivityEnrollbyID', { params: { enID: enID } }).then((response) => {

            setFullName(response.data[0].FirstName + ' ' + response.data[0].LastName);
            setGender(response.data[0].Gender);
            setBirthDate(new Date(response.data[0].BirthDate))
            if (response.data[0].Position)
                setPosition(response.data[0].Position)
            if (response.data[0].Department)
                setDepartment(response.data[0].Department)
            if (response.data[0].Telephone)
                setTelephone(response.data[0].Telephone)
            if (response.data[0].Email)
                setEmail(response.data[0].Email)
            if (response.data[0].OtherInfo)
                setOtherInfo(response.data[0].OtherInfo);
            if (response.data[0].Approve !== null) {
                if(Number(response.data[0].Approve)===2){
                    setApprove(1);
                }
                else{
                setApprove(Number(response.data[0].Approve))
                }
            }
            if (response.data[0].NotApproveRemark)
                setNotApproveRemark(response.data[0].NotApproveRemark);
            setLoading(false);

        })
    }, []); /* <-- add this for run once*/

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //console.log(approve);
        //Name
        if (fullName.trim().length === 0) {
            formIsValid = false;
            serrors["fullName"] = "กรุณาระบุชื่อ นามสกุล";
        }
        else {
            let name = fullName.split(" ");
            //console.log(name.length)
            if (name.length < 2) {
                serrors["fullName"] = "ชื่อและนามสกุลต้องเว้นวรรค";
            }
        }

        if (gender.trim().length === 0) {
            formIsValid = false;
            serrors["gender"] = "กรุณาเลือกเพศ";
        }
        if (position.trim().length === 0) {
            formIsValid = false;
            serrors["position"] = "กรุณากรอกข้อมูล";
        }
        if (department.trim().length === 0) {
            formIsValid = false;
            serrors["department"] = "กรุณากรอกข้อมูล";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณากรอกข้อมูล";
        }
        if (otherInfo.trim().length === 0) {
            formIsValid = false;
            serrors["otherInfo"] = "กรุณาระบุรายละเอียด";
        }
        if (approve === 0) {
            if (notApproveRemark.trim().length === 0) {
                formIsValid = false;
                serrors["notApproveRemark"] = "กรุณาระบุรายละเอียด";
            }
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", enID);
            let name = fullName.split(" ");
            formData.append("firstName", name[0]);
            formData.append("lastName", name[1]);
            formData.append("gender", gender);
            formData.append("birthDate", birthDate);
            formData.append("department", department);
            formData.append("position", position);
            formData.append("phone", telephone);
            formData.append("email", email);
            formData.append("otherInfo", otherInfo);
            formData.append("approve", approve);
            formData.append("notApproveRemark", notApproveRemark);
            formData.append("userID", currentUser.UserID);
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateActivityEnroll', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/activity/ActivityMember" });
                        }
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);

        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/activity/ActivityMember" });
    };
    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการกิจกรรม</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/activity/ActivityMember" }}>{"จัดการรายชื่อผู้เข้าร่วมกิจกรรม"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/activity/EditActivityEnroll", state: { id: enID } }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label htmlFor="fullName" className="required">ชื่อ-นามสกุล</label>
                                    <input type="text" className="form-control" id="fullName" name="fullName" placeholder="กรุณากรอก" value={fullName} onChange={(e) => { setFullName(e.currentTarget.value) }} />
                                    <SError error={errors["fullName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="gender" className="required">เพศ</label>
                                    <select className="form-control mb-2 mr-2" id="gender" name="gender" value={gender} onChange={(e) => { setGender(e.currentTarget.value); }} >
                                        <option value="" >กรุณาเลือก</option>
                                        <option value="M" >ชาย</option>
                                        <option value="F" >หญิง</option>
                                    </select>
                                    <SError error={errors["gender"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="registerstart" className="required">วันเกิด</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={birthDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            onChange={setBirthDate} name="registerstart" id="registerstart" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="position" className="required">ตำแหน่ง</label>
                                    <input type="text" className="form-control" id="position" name="position" placeholder="กรุณากรอก" value={position} onChange={(e) => { setPosition(e.currentTarget.value) }} />
                                    <SError error={errors["position"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="department" className="required">หน่วยงาน</label>
                                    <input type="text" className="form-control" id="department" name="department" placeholder="กรุณากรอก" value={department} onChange={(e) => { setDepartment(e.currentTarget.value) }} />
                                    <SError error={errors["department"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="telephone" className="required">หมายเลขโทรศัพท์</label>
                                    <input type="text" className="form-control" id="phone" name="telephone" placeholder="กรุณากรอก" value={telephone} onChange={(e) => { setTelephone(e.currentTarget.value) }} />
                                    <SError error={errors["telephone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="กรุณากรอก" value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="otherInfo" className="required">รายละเอียด</label>
                                    <div>
                                        <textarea className="form-control" name="displayAddress" id="displayAddress" rows="3" value={otherInfo} onChange={e => { setOtherInfo(e.target.value) }}></textarea>
                                        <SError error={errors["otherInfo"]} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <h4 className="col-12">สำหรับเจ้าหน้าที่</h4>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label className="required mr-2">การพิจารณาคุณสมบัติ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve2" value="2" checked={approve === 2} onChange={(e) => { setApprove(2); }} />
                                        <label className="form-check-label" htmlFor="approve2"> คอยการพิจารณา</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve1" value="1" checked={approve === 1} onChange={(e) => { setApprove(1); }} />
                                        <label className="form-check-label" htmlFor="approve1"> คุณสมบัติผ่าน</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve0" value="0" checked={approve === 0} onChange={(e) => { setApprove(0); }} />
                                        <label className="form-check-label" htmlFor="approve0"> คุณสมบัติไม่ผ่าน</label>
                                    </div>
                                    <span className="error">{errors["pin"]}</span>  
                                    <SError error={errors["approve"]} />
                                </div>
                                {(approve === 0) && (<div className="col-12">
                                    <label htmlFor="notApproveRemark" className="required">หมายเหตุ</label>
                                    <div>
                                        <textarea className="form-control" name="notApproveRemark" id="notApproveRemark" rows="3" value={notApproveRemark} onChange={e => { setNotApproveRemark(e.target.value) }}></textarea>
                                        <SError error={errors["notApproveRemark"]} />
                                    </div>
                                </div>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40 mt-2 margintop40" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
