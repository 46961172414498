import { React, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import idcardico from "assets/images/icon/idcard.svg";
import passportico from "assets/images/icon/passport.svg";
import banner from "assets/images/banner/advertise.svg";

const swal = withReactContent(Swal);

export default function ForgotPassword() {
  const history = useHistory();
  let language = localStorage.getItem("language");
  const [fields, setFields] = useState({});
  const [vertifyQuestion, setVertifyQuestion] = useState([]);
  const [errors, setError] = useState({});
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getMemberVertifyQuestion")
      .then((response) => {
        setVertifyQuestion(response.data);
      });
  }, []);
  const handleChange = (e) => {
    let sfields = fields;
    sfields[e.target.name] = e.target.value;
    setFields(sfields);
  };
  const handleValidation = (loginType) => {
    let serrors = {};
    let formIsValid = true;
    if (loginType === "THAIID") {
      if (!fields["IDCard"]) {
        formIsValid = false;
        serrors["IDCard"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCard"] !== "undefined") {
        if (!isFinite(String(fields["IDCard"])))
          serrors["IDCard"] =
            "หมายเลขบัตรประชาชนต้องประกอบไปด้วยตัวเลขเท่านั้น";
        if (fields["IDCard"].length !== 13)
          serrors["IDCard"] = "กรุณากรอกหมายเลข 13 หลัก";
        /*if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["IDCard"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["IDCard"].length - lastDotPos) > 2)) {
                  formIsValid = false;
                  serrors["IDCard"] = "รูปแบบของหมายเลขบัตรประชาชนไม่ถูกต้อง";
                }*/
      }
      if (!fields["IDCardEmail"]) {
        formIsValid = false;
        serrors["IDCardEmail"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["IDCardEmail"] !== "undefined") {
        let lastAtPos = fields["IDCardEmail"].lastIndexOf("@");
        let lastDotPos = fields["IDCardEmail"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["IDCardEmail"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["IDCardEmail"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          serrors["IDCardEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
        }
      }

      if (!fields["IDCardQuestion"]) {
        formIsValid = false;
        serrors["IDCardQuestion"] = "กรุณากรอกข้อมูล";
      }

      if (!fields["IDCardAnswer"]) {
        formIsValid = false;
        serrors["IDCardAnswer"] = "กรุณากรอกข้อมูล";
      }
    } else if (loginType === "PASSPORT") {
      if (!fields["PassportID"]) {
        formIsValid = false;
        serrors["PassportID"] = "กรุณากรอกข้อมูล";
      }

      if (!fields["PassportEmail"]) {
        formIsValid = false;
        serrors["PassportEmail"] = "กรุณากรอกข้อมูล";
      }

      if (typeof fields["PassportEmail"] !== "undefined") {
        let lastAtPos = fields["PassportEmail"].lastIndexOf("@");
        let lastDotPos = fields["PassportEmail"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["PassportEmail"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["PassportEmail"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          serrors["PassportEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
        }
      }

      if (!fields["PassportQuestion"]) {
        formIsValid = false;
        serrors["PassportQuestion"] = "กรุณากรอกข้อมูล";
      }

      if (!fields["PassportAnswer"]) {
        formIsValid = false;
        serrors["PassportAnswer"] = "กรุณากรอกข้อมูล";
      }
    }
    setError(serrors);
    return formIsValid;
  };
  const QuestionSelect = vertifyQuestion.map((item) => (
    <option key={item.VerifyQuestionID} value={item.VerifyQuestionID}>
      {language === "Thai"
        ? item.TextTH
        : item.TextEN.trim().length === 0
          ? item.TextTH
          : item.TextEN}
    </option>
  ));
  const SendMailPassword = (
    datatype,
    dataid,
    useremail,
    questionid,
    answer
  ) => {
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/forgetpassword", null, {
        params: {
          datatype: datatype,
          id: dataid,
          email: useremail,
          questionid: questionid,
          answer: answer,
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          Swal.fire({
            icon: "error",
            title: "ผิดพลาด",
            text: response.data.text,
          }).then((result) => { });
        } else {
          let timerInterval;
          swal
            .fire({
              title: "ส่งอีเมลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              history.push({ pathname: "/" });
            });
        }
      })
      .catch((error) => {
        console.log(error);
        swal
          .fire({
            icon: "error",
            title: "ไม่สามารถส่งอีเมลได้",
            text: error.message,
          })
          .then((result) => {
            history.push({ pathname: "/" });
          });
      });
    history.push({ pathname: "/" });
  };
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"ลืมรหัสผ่าน"}
          eheader={"Forgot Password"}
          bannerkey="FORGOTPASS"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{
                  pathname: "/authen/ForgotPassword",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "ลืมรหัสผ่าน" : "Forgot Password"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page login-bg">
        <div className="container">
          <ul
            id="icon-tab"
            className="nav nav-pills mb-3 mx-auto justify-content-center list_respon"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="bg-orang icon-circle">
                    <img src={idcardico} alt="ID Card" />
                  </div>
                  <span className="button-tab">
                    {language === "Thai" ? "บัตรประชาชน" : "ID Card"}
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="true"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="bg-orang icon-circle">
                    <img src={passportico} alt="ID Card" />
                  </div>
                  <span className="button-tab">
                    {language === "Thai" ? "หนังสือเดินทาง" : "Passport"}
                  </span>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="mhk-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="row">
                <div className="col-12">
                  <div className="formContent box_forgotpassword">
                    <h1 className="title-header">
                      <span className="orang underline">
                        {language === "Thai"
                          ? "ลืมรหัสผ่าน"
                          : "Forgot password"}
                      </span>
                    </h1>
                    <form
                      className="row form-center"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (handleValidation("THAIID")) {
                          SendMailPassword(
                            "THAIID",
                            fields["IDCard"],
                            fields["IDCardEmail"],
                            fields["IDCardQuestion"],
                            fields["IDCardAnswer"]
                          );
                        }
                      }}
                    >
                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="IDCard"
                          name="IDCard"
                          placeholder={
                            language === "Thai"
                              ? "เลขที่บัตรประชาชน"
                              : "ID Card"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["IDCard"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="IDCardEmail"
                          name="IDCardEmail"
                          placeholder={language === "Thai" ? "อีเมล" : "Email"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["IDCardEmail"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <select
                          id="IDCardQuestion"
                          name="IDCardQuestion"
                          className="custom-select"
                          defaultValue="0"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="0">
                            {language === "Thai"
                              ? "คำถามยืนยันตัวตน"
                              : "Vertify Question"}
                          </option>
                          {QuestionSelect}
                        </select>
                        <span className="error d-block">
                          {errors["IDCardQuestion"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="IDCardAnswer"
                          name="IDCardAnswer"
                          placeholder={language === "Thai" ? "คำตอบ" : "Answer"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["IDCardAnswer"]}
                        </span>
                      </div>
                      <div className="controlBtn mt-5 w-100">
                        <input
                          type="submit"
                          className="fadeIn fourth btnPimary"
                          value={language === "Thai" ? "ตกลง" : "Submit"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row">
                <div className="col-12">
                  <div className="formContent box_forgotpassword">
                    <h1 className="title-header">
                      <span className="orang underline">
                        {language === "Thai"
                          ? "ลืมรหัสผ่าน"
                          : "Forgot password"}
                      </span>
                    </h1>
                    <form
                      className="row form-center"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (handleValidation("PASSPORT")) {
                          SendMailPassword(
                            "PASSPORT",
                            fields["PassportID"],
                            fields["PassportEmail"],
                            fields["PassportQuestion"],
                            fields["PassportAnswer"]
                          );
                        }
                      }}
                    >
                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="PassportID"
                          name="PassportID"
                          placeholder={
                            language === "Thai"
                              ? "เลขที่หนังสือเดินทาง"
                              : "Passport number"
                          }
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportID"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="PassportEmail"
                          name="PassportEmail"
                          placeholder={language === "Thai" ? "อีเมล" : "Email"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportEmail"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <select
                          id="PassportQuestion"
                          name="PassportQuestion"
                          className="custom-select"
                          defaultValue="0"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="0">
                            {language === "Thai"
                              ? "คำถามยืนยันตัวตน"
                              : "Vertify Question"}
                          </option>
                          {QuestionSelect}
                        </select>
                        <span className="error d-block">
                          {errors["PassportQuestion"]}
                        </span>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <input
                          type="text"
                          id="PassportAnswer"
                          name="PassportAnswer"
                          placeholder={language === "Thai" ? "คำตอบ" : "Answer"}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span className="error d-block">
                          {errors["PassportAnswer"]}
                        </span>
                      </div>
                      <div className="controlBtn mt-5 w-100">
                        <input
                          type="submit"
                          className="fadeIn fourth btnPimary"
                          value={language === "Thai" ? "ตกลง" : "Submit"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
