import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
const swal = withReactContent(Swal)
export default function Complain(props) {
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    useEffect(() => {
        //console.log(props.location.state.id)
        /*axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElibraryCat', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
            });     */
    }, []); /* <-- add this for run once*/

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getComplain').then((response) => {
            updateData(response.data);
        })

    };
    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 80;
    };
    const createYearCellRenderer = params => {
        if (params.value == null)
            return '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p></span>';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };
    const updateYearCellRenderer = params => {
        if (params.value == null)
            return '';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.UpdatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };
    const RowTool = params => {
        let tool = null;
        tool = (<span className="row-tool"><button type="button" className="btn btn-primary btn-sm wd-40" onClick={() => { handleView(params); }}>ดู</button></span>);
        return tool;
    };
    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };
    const StateRenderer = params => {
        //console.log(params.value)
        if (params.value === "WAIT")
            return 'ส่งเรื่องร้องทุกข์';
        else if (params.value === "ACCEPT")
            return 'รับเป็นคำร้อง';
        else if (params.value === "DECLINE")
            return 'ไม่รับเป็นคำร้อง';
        else if (params.value === "CANCEL")
            return 'ยกเลิก';
        else if (params.value === "FORWARD")
            return 'ส่งต่อหน่วยงานภายนอก';
        else if (params.value === "TERMINATED")
            return 'ยุติ';
        return 'กำลังดำเนินการ/อยู่ระหว่างดำเนินการ';
    };
    const searchRef = React.createRef();
    const bookNoRef = React.createRef();
    const reqNoRef = React.createRef();
    const statusRef = React.createRef();

    const searchData = () => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchcomplain', { params: { search: searchRef.current.value, bookNo: bookNoRef.current.value, reqNo: reqNoRef.current.value, state: statusRef.current.value } })
            .then((response) => {
                setRowData(response.data);
                //console.log(response.data);
            })
    };
    const resetsearch = () => {
        searchRef.current.value = "";
        bookNoRef.current.value = "";
        reqNoRef.current.value = "";
        statusRef.current.value = "ALL";
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getComplain')
            .then((response) => {
                setRowData(response.data);
            })
    };

    const handleView = params => {
        props.history.push({ pathname: "/admin/complain/ComplainInfo", state: { id: params.data.ComplainID } });
        //window.location.reload();
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={(e) => { e.preventDefault(); }}>{"การร้องเรียนและการติดตามเรื่องร้องเรียน (E-complaint)"}</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/complain/Complain" }}>{"จัดการการร้องเรียน"}</Link></>}
            />
            <div className="col-12">
                <form className="form-inline form-border justify-content-between">
                    <div className="form-group mx-sm mb-2 col-4 mt-2 pr-0 col-lg-2 col-xl-3">
                        <input type="text" ref={searchRef} className="form-control " id="searchfield" name="searchfield" placeholder=" ค้นหาที่นี่" />
                    </div>
                    <div className="form-group mx-sm mb-2 col-2 mt-2 pr-0 col-md-3 col-lg-2">
                        <input type="text" ref={bookNoRef} className="form-control mb-0" id="bookNoRef" name="bookNoRef" placeholder="หมายเลขรับเรื่อง" />
                    </div>
                    <div className="form-group mx-sm mb-2 col-2 mt-2 pr-0">
                        <input type="text" ref={reqNoRef} className="form-control mb-0" id="reqNoRef" name="reqNoRef" placeholder="หมายเลขคำร้อง" />
                    </div>
                    <select className="form-control mb-2 col-2 mt-2 mr-3 ml-3" ref={statusRef}>
                        <option value="ALL">สถานะทั้งหมด</option>
                        <option value="WAIT">ส่งเรื่องร้องทุกข์</option>
                        <option value="ACCEPT">รับเป็นคำร้อง</option>
                        <option value="DECLINE">ไม่รับเป็นคำร้อง</option>
                        <option value="CANCEL">ยกเลิก</option>
                        <option value="FORWARD">ส่งต่อหน่วยงานภายนอก</option>
                        <option value="TERMINATED">ยุติ</option>
                    </select>
                    <div className="button-group col-2">
                        <button type="button" className="btn btn-info mr-2" onClick={e => { e.preventDefault(); searchData(); }}>ค้นหา</button>
                        <button type="submit" className="btn btn-light" onClick={e => { e.preventDefault(); resetsearch(); }}>ล้างคำค้นหา</button>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'CategoriesID',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: false,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: false },
                                }}

                                modules={AllCommunityModules}
                                frameworkComponents={{
                                    rowTool: RowTool,
                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={true}
                                pagination={true}
                                paginationPageSize={10}

                            >
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" width={70} cellClass="cell-border cell-vertical-align-text-left" />
                                {/*<AgGridColumn field="OrganizationName" headerName="องค์กร" minWidth={165} />*/}
                                <AgGridColumn field="ComplainTitle" headerName="ชื่อรายการ" sortable={true} minWidth={100} />
                                <AgGridColumn field="WSComplianNo" headerName="หมายเลขรับเรื่อง" sortable={true} minWidth={100} />
                                <AgGridColumn field="NHRCComplaintJobID" headerName="หมายเลขคำร้อง" sortable={true} minWidth={100} />
                                {/*<AgGridColumn field="Detail" headerName="รายละเอียด" minWidth={100} sortable={false} filter={false} resizable={false} />*/}
                                <AgGridColumn field="State" headerName="สถานะ" cellRenderer={StateRenderer} minWidth={130} sortable={false} filter={false} resizable={false} />
                                <AgGridColumn field="CreateFullname" headerName="วันที่สร้าง" cellRenderer={createYearCellRenderer} minWidth={150} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="UpdateFullname" headerName="วันที่แก้ไข" cellRenderer={updateYearCellRenderer} minWidth={150} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />

                                <AgGridColumn field="GroupID" headerName="จัดการ" cellRenderer="rowTool" minWidth={300} sortable={false} filter={false} resizable={false} />
                            </AgGridReact>
                            <div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={10} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="100">25</option>
                                    <option value="500">50</option>
                                    <option value="1000">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}
