import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const swal = withReactContent(Swal)
export default function Complaint(props) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [complainList, setComplainList] = useState([]);
    const [mode, setMode] = useState(0);
    const [activeComplain, setActiveComplain] = useState(null);
    //const [userID, setUserID] = useState(null);
    const history = useHistory();
    useEffect(() => {
        let userid = 0;
        userid = currentUser.UserID;
        utils.AccessInfo('ติดตามเรื่องร้องเรียน', '/authen/complain/Complain', 'F', currentUser);
        axios.get(process.env.REACT_APP_APIURL + '/api-web/complainbyUserID', { params: { userid: userid } })
            .then((response) => {
                setComplainList(response.data);
                if (response.data.length === 0) {                    
                    Swal.fire({
                        icon: 'info',
                        title: language==='Thai'?'แจ้งให้ทราบ':'Information',
                        text: language === "Thai" ? " ไม่พบข้อมูล " : " No information found ",
                    })
                }
            })
    }, []); /* <-- add this for run once*/
    const complain = complainList.map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.NHRCComplaintJobID} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));

    return (
        <div id="complain-list" className="row same-height">
            {(mode === 0) && (<>
                <div className="subheader col-12">เรื่องร้องเรียน</div>
                <div className="content-data">
                    <div className="row formContent">
                        {complain}
                    </div>
                </div>
            </>)
            }
            {(mode === 1) && (<>
                <div className="subheader col-12">เลขคำร้อง : {activeComplain.NHRCComplaintJobID} เรื่อง : {activeComplain.ComplainTitle}</div>
                <div className="content-data">
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เจ้าหน้าที่ออกเลขคำร้อง </span> <span className="col-md-7"> {activeComplain.UpdatedBy === null ? "-" : activeComplain.UpdatedBy} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เจ้าหน้าที่ผู้รับคำร้อง </span> <span className="col-md-7"> {activeComplain.CreatedBy === null ? "-" : activeComplain.CreatedBy} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เบอร์โทรติดต่อเจ้าหน้าที่ผู้รับผิดชอบคำร้อง </span> <span className="col-md-7"> 1377 </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>กลุ่มงานตรวจสอบการละเมิดสิทธิมนุษยชน 1 </span> <span className="col-md-7"> {activeComplain.ExaminerOrganization === null ? "-" : activeComplain.ExaminerOrganization} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เจ้าหน้าที่ผู้รับผิดชอบสำนวน </span> <span className="col-md-7"> {activeComplain.ExaminerName === null ? "-" : activeComplain.ExaminerName} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เลขที่รายงาน </span> <span className="col-md-7"> {activeComplain.ExaminerReportNo === null ? "-" : activeComplain.ExaminerReportNo} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>การปฏิบัติงาน </span> <span className="col-md-7"> {activeComplain.ExaminerAction === null ? "-" : activeComplain.ExaminerAction} </span>
                        </div>
                    </li>
                    <li className="list-item" >
                        <div className="row">
                            <span className="col-md-5" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>ความคืบหน้าล่าสุด </span> <span className="col-md-7"> {activeComplain.CurrentProgress === null ? "-" : activeComplain.CurrentProgress} </span>
                        </div>
                    </li>
                </div>
                <div className="row formContent" style={{ width: 100 + '%' }}>
                    <div className="col-12">
                        <input type="submit" className="fadeIn fourth" value="ย้อนกลับ" onClick={(e) => { e.preventDefault(); setMode(0); }} />
                    </div>
                </div>
            </>)
            }
        </div>
    );
}
