import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import { authenticationService } from "services/authentication.service.js";
import GoogleMapReact from "google-map-react";
import utils from "utils/utils";

export default function EducationCoordinate(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [educationData, SetEducationData] = useState([]);
  const [selectEduData, setSelectEDUData] = useState(0);
  const [selectEdu, setSelectEDU] = useState(0);

  useEffect(() => {
    //console.log(props.location.state.networkID)
    utils.AccessInfo(
      "สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค",
      "/authen/network/EducationCoordinate",
      "F",
      currentUser
    );
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/educationCoordinate")
      .then((response) => {
        SetEducationData(response.data);
        if (response.data.length > 0) {
          setSelectEDUData(response.data[0]);
        }
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/
  function getENName(item) {
    if (item.NameEN === null) {
      return item.Name;
    } else {
      if (item.NameEN.trim().length === 0) return item.Name;
      else return item.NameEN;
    }
  }
  const EducationList = educationData.map((item, i) => (
    <li
      className={selectEdu === i ? "btn btn-list active" : "btn btn-list"}
      key={i}
      onClick={(e) => {
        setSelectEDU(i);
        setSelectEDUData(item);
      }}
    >
      <span className="title text-dark-blue">
        <i className="fas fa-map-marker-alt mr-2"></i>
        {language === "Thai" ? item.Name : getENName(item)}{" "}
      </span>
      <span className="detail">
        {" "}
        {language === "Thai" ? item.Location : item.LocationEN}{" "}
      </span>
      <span className="detail">
        {" "}
        {language === "Thai" ? " เบอร์โทรศัพท์" : "Phone Number"} : {item.Tel}
      </span>
      <Link
        to={{
          pathname: "/authen/network/ViewEducationCord",
          state: { educationID: item.EducationCoordinationID },
        }}
        className="rightbtn btn btn-info"
      >
        {language === "Thai" ? "ข้อมูลเพิ่มเติม" : "More Detail"}
      </Link>
    </li>
  ));
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  const renderMarkers = (map, maps) => {
    let newmarker = [];
    educationData.map((item, i) => {
      let marker = new maps.Marker({
        position: { lat: item.Lat, lng: item.Lng },
        map,
        title: item.Name,
      });
      newmarker.push(marker);
    });
    return newmarker;
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/EducationCoordinate",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                  : "Regional Office of the National Human Rights Commission"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile">
            <div className="news-activity-data">
              <div className="sub-title">
                <h2 className="thai-header text-center">
                  <span className="orang content-underline">
                    {language === "Thai"
                      ? " สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                      : "Regional Office of the National Human Rights Commission"}
                  </span>
                </h2>
              </div>
            </div>
            <div className="content-data">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul className="listEducation">{EducationList}</ul>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ height: "100vh", width: "100%" }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyBmCzwy3dIFgheFGeSr8jKOVcxF-x2jQ5w",
                    }}
                    center={{
                      lat: selectEduData.Lat,
                      lng: selectEduData.Lng,
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      renderMarkers(map, maps)
                    }
                  >
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
