import React, { useState, useEffect } from "react";
import { DropzoneDialog } from 'material-ui-dropzone';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { authenticationService } from 'services/authentication.service.js';
import axios from 'axios';
import { scroller } from "react-scroll";
import 'assets/styles/profile.css';
import cameraicon from 'assets/images/icon/camera.svg';
import SError from 'components/SError';
import avatar from '../../assets/images/icon/user.svg';
const swal = withReactContent(Swal)
export default function Profile(props) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [errors, setError] = useState({});
    const [editprofile, seteditprofile] = useState(false);

    const [openavatar, setOpenavatar] = useState(false);
    const [avatarfiles, setAvatarfiles] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [passportNo, setPassportNo] = useState('');
    const [idCardNo, setidCardNo] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/memberbyID', { params: { id: currentUser.UserID } })
            .then((response) => {
                let data = response.data[0];
                //console.log(data);
                /*if (language === 'Thai')
                {
                    setFirstName(data.FirstnameTH);
                    setLastName(data.LastnameTH);
                }
                else
                {
                    setFirstName(data.FirstnameEN);
                    setLastName(data.LastnameEN);
                }*/
                setFirstName(data.FirstnameTH);
                setLastName(data.LastnameTH);
                setPassportNo(data.PassportNo);
                setidCardNo(data.CitizenID);
                setEmail(data.Email);
                setPhone(data.Phone);
            });

    }, []);
    const rx_int = /^\d+$/;
    const handlePhoneChange = (e) => {
        if (rx_int.test(e.target.value)) {
            setPhone(e.target.value);
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;

        if (firstName.trim().length === 0) {
            formIsValid = false;
            serrors["firstName"] = language === "Thai" ? " กรุณากรอกชื่อ " : " Please enter Firstname ";
        }
        if (lastName.trim().length === 0) {
            formIsValid = false;
            serrors["lastName"] = language === "Thai" ? " กรุณากรอกนามสกุล " : " Please enter Lastname ";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = language === "Thai" ? " กรุณากรอกข้อมูลอีเมล " : " Please enter email ";
        }
        else {
            let lastAtPos = email.trim().lastIndexOf('@');
            let lastDotPos = email.trim().lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.trim().indexOf('@@') === -1 && lastDotPos > 2 && (email.trim().length - lastDotPos) > 2)) {
                formIsValid = false;
                serrors["email"] = language === "Thai" ? "รูปแบบของอีเมลไม่ถูกต้อง" : "Invalid Email format";
            }
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = language === "Thai" ? " กรุณากรอกข้อมูลเบอร์โทรศัพท์ " : " Please enter phone number ";
        }
        else if (phone.trim().length < 10) {
            formIsValid = false;
            serrors["phone"] = language === "Thai" ? " กรุณากรอกข้อมูลเบอร์โทรศัพท์ 10 หลัก" : " Phone number should be 10 digit number";
        }

        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: language === "Thai" ? " กรุณากรอกข้อมูลให้ครบถ้วน " : " Please enter data ",
            })
        }
        setError(serrors);
        return formIsValid;
    };
    const updateProfile = () => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("userID", currentUser.UserID);
            formData.append("language", language);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("passportNo", passportNo);

            formData.append("idCardNo", idCardNo);
            formData.append("email", email);

            formData.append("phone", phone);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMemberInfo', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then((result) => {
                        window.location.reload();
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    }
    const UpdateAvatar = (file) => {
        const formData = new FormData();
        formData.append("userID", currentUser.UserID);
        formData.append("avartarFile", file);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateAvatar', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                currentUser.Avatar = response.data.avatar;
                localStorage.setItem('user', JSON.stringify(currentUser));
                authenticationService.setuserprofile(currentUser);
                window.location.reload();
            });
    }
    return (
        <>

            <section className="edit-profile row" >
                <div className="col-12 col-lg-3">
                    <div className="green-sidebar">
                        <div className="avatar-circle">
                            <img src={currentUser.Avatar===null?avatar:currentUser.Avatar.trim().length==0?avatar:currentUser.Avatar} alt="Avatar" />
                        </div>
                        <a id="edit-avatar-btn" href="/" onClick={(e) => { setOpenavatar(true); e.preventDefault() }}><img className="img " src={cameraicon} alt="Edit Avatar" /></a>
                        <DropzoneDialog
                            open={openavatar}
                            onSave={(fileslist) => { setAvatarfiles(...avatarfiles, fileslist[0]); setOpenavatar(false); UpdateAvatar(fileslist[0]); }}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            filesLimit={1}
                            showPreviews
                            onClose={() => { setOpenavatar(false); }}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-9" >
                    <div className="card" >
                        <div className="card-body formContent">
                            <form onSubmit={e => {
                                e.preventDefault();
                            }}>
                                <div className="row">
                                    <div className="col-12 my-0">
                                        <i className="fa fa-edit editicon" onClick={() => { seteditprofile(!editprofile) }}></i>
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="firstName" className="profile-admin">ชื่อ</label>
                                        <input type="text" id="firstName" name="firstName" placeholder="ชื่อ" autoComplete="off" readOnly={!editprofile} value={firstName} onChange={(e) => { setFirstName(e.currentTarget.value) }} />
                                        <SError error={errors["firstName"]} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="lastName" className="profile-admin">นามสกุล</label>
                                        <input type="text" id="lastName" name="lastName" placeholder="นามสกุล" autoComplete="off" readOnly={!editprofile} value={lastName} onChange={(e) => { setLastName(e.currentTarget.value) }} />
                                        <SError error={errors["lastName"]} />
                                    </div>

                                    <div className="form-group col-6">
                                        <label htmlFor="passportNo" className="profile-admin">เลขหนังสือเดินทาง</label>
                                        <input type="text" id="passportNo" name="passportNo" placeholder="A123456" autoComplete="off" readOnly={true} value={passportNo} onChange={(e) => { setPassportNo(e.currentTarget.value) }} />
                                        <SError error={errors["passportNo"]} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="idCardNo" className="profile-admin">เลขที่บัตรประชาชน</label>
                                        <input type="text" id="idCardNo" name="idCardNo" placeholder="1234567890123" autoComplete="off" readOnly={true} value={idCardNo} onChange={(e) => { setidCardNo(e.currentTarget.value) }} />
                                        <SError error={errors["idCardNo"]} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="email" className="profile-admin">อีเมล</label>
                                        <input type="text" id="email" name="email" placeholder="user@email.com" autoComplete="off" readOnly={!editprofile} value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                        <SError error={errors["email"]} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="phone" className="profile-admin">หมายเลขโทรศัพท์มือถือ</label>
                                        <input type="text" id="phone" name="phone" placeholder="0987654321" autoComplete="off" readOnly={!editprofile} value={phone} maxLength={10} onChange={(e) => { handlePhoneChange(e) }} />
                                        <SError error={errors["phone"]} />
                                    </div>

                                    <div className="form-group col-12">
                                        {editprofile === true ? <input type="submit" className="fadeIn fourth" value="ปรับปรุงข้อมูล" onClick={(e) => { e.preventDefault(); updateProfile(); }} /> : <></>}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
