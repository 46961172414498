import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import 'assets/styles/tailwind.css';
import "assets/styles/merights.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// layouts
//import {grantAdmin, grantMember} from 'services/RoleBasedRouting';
//import {authenticationService} from 'services/authentication.service';
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
//import Login from 'views/authen/Login';
//import Profile from "views/authen/Profile.js";
// views without layouts

import NewsList from "views/news/NewsList.js";
import ReadNews from "views/news/ReadNews.js";
import Previews from "views/news/Previews.js";
import ActivityList from "views/activity/ActivityList.js";
import ReadActivity from "views/activity/ReadActivity.js";
import ContactUs from "views/contact/ContactUs.js";
import SurveyList from "views/survey/SurveyList.js";
import ViewSurvey from "views/survey/ViewSurvey.js";
import QrCode from "views/QrCode.js";
import PolicyWeb from "views/PolicyWeb.js";
import Index from "views/Index.js";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  //useQuery,
  //useMutation,
  //gql,
  //ApolloLink,
  HttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { authenticationService } from "services/authentication.service.js";
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      /*console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )*/
    });
  if (networkError) {
    /*console.log(`[Network error]: ${networkError}`);*/
  }
});

/*const httpLink = new HttpLink({
  uri: "https://merights-api.2smooth.io/graphql"
});*/
const httpLink = new HttpLink({
  //uri: "http://118.174.14.124/api/graphql"
  //uri: "https://nhrc.2fellows.com/api/graphql"
   uri: `${process.env.REACT_APP_APIURL}/api/graphql`,
});
const currentUser = authenticationService.currentUserValue;
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = currentUser.LastToken;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link:
    currentUser === null
      ? from([errorLink, httpLink])
      : from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

//const store = configureStore();
//const { store, persistor } = configureStore()
//
const TITLE = "มีสิทธิ์";
ReactDOM.render(
  <div id="page-container">
    <div id="container-wrap">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            {/* add routes with layouts */}

            {/*
              grantAdmin(currentUser) && (
                <Route path="/admin" component={Admin} />
              )

            }
            {
              grantMember(currentUser) && (
                <Route path="/authen" component={Auth} />
              )*/}

            <Route path="/admin" component={Admin} />
            <Route path="/authen" component={Auth} />
            <Route path="/QRCode" component={QrCode} />
            <Route path="/PolicyWeb" component={PolicyWeb} />
            {/* add routes without layouts */}
            {/*<Route path="/landing" exact component={Landing} />*/}
            {/*<Route path="/profile/Profile" exact component={Profile} />   */}
            <Route path="/news/NewsList" exact component={NewsList} />
            <Route path="/news/ReadNews/:id" exact component={ReadNews} />                        
            <Route path="/news/ReadNews" exact component={ReadNews} />
            <Route path="/news/Previews/:id" exact component={Previews} />
            <Route path="/news/Previews/" exact component={Previews} />
            <Route
              path="/activity/ActivityList"
              exact
              component={ActivityList}
            />
            <Route
              path="/activity/ReadActivity/:id"
              exact
              component={ReadActivity}
            />
            <Route
              path="/activity/ReadActivity"
              exact
              component={ReadActivity}
            />
            <Route path="/contact/ContactUs" exact component={ContactUs} />     
            <Route path="/survey/SurveyList" exact component={SurveyList} />       
            <Route path="/survey/ViewSurvey/:id" exact component={ViewSurvey} />
            <Route path="/survey/ViewSurvey" exact component={ViewSurvey} />
            {/*<Route path="/authen/Login" exact component={Login} />*/}
            {/*<Route path="/authen/Profile" exact component={Profile} />*/}
            <Route path="/" exact component={Index} />
            {/* add redirect for first page */}
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  </div >,
  document.getElementById("root")
);