import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import utils from "utils/utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
/*import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';*/
import AdminBanner from "components/Banner/AdminBanner";
//const swal = withReactContent(Swal)

export default function BestPersonRewardReorder(props) {
  //const currentUser = authenticationService.currentUserValue;
  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  // const [bestPersonList, SetBestPersonList] = useState([]);
  // const [activebestPersonList, SetActiveBestPersonList] = useState([]);
  
  const [yearList, SetYearList] = useState([]);
  const [selectType, setSelectType] = useState(0);
  const [selectTopic, setSelectTopic] = useState(0);
  const [yearSelect, setYearSelect] = useState(0);
  const [topicList, setTopicList] = useState([]);
  //const [newsCat, setNewsCat] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
    axios.get(process.env.REACT_APP_APIURL + '/api-web/searchBestPersonReward', { params: { status: 1 } }).then((response) => {
        response.data.sort(function (vote1, vote2) {
            if (vote1.ToppicTypeID < vote2.ToppicTypeID) return -1;
            if (vote1.ToppicTypeID > vote2.ToppicTypeID) return 1;
            return 0;
        });
        const yearlist = response.data.reduce((prev, cur) => prev.includes(cur.RewardYear)
            ? prev : prev.concat(cur.RewardYear), []).sort().reverse();
        SetYearList(yearlist);
 
        if (yearlist.length > 0) {
            
            const active = response.data?.filter((item) => {
                return Number(item.RewardYear) === Number(yearlist[0])
            });
            active.sort(function (a, b) {
                if (a.ToppicTypeID < b.ToppicTypeID) { return -1; }
                if (a.ToppicTypeID > b.ToppicTypeID) { return 1; }
                return 0;
            })

        }
    })
  }, []); /* <-- add this for run once*/
  const StatusRenderer = (params) => {
    if (params.data.Status === true) return "เผยแพร่";
    else if (params.data.Status === false) return "ไม่เผยแพร่";
    return "-";
  };
  const TypeRenderer = (params) => {
    if (Number(params.data.IsPersonType) === 1) return "บุคคล";
    else if (Number(params.data.IsPersonType) === 2) return "องค์กร";
    return "-";
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    const updateData = (data) => {
      setRowData(data);
    };

    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/bestPersonReward")
      .then((response) => {
        // updateData(response.data);
      });
  };

  const fetchData = () => {
    const updateData = (data) => {
      setRowData(data);
    };
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/bestPersonReward")
      .then((response) => {
        updateData(response.data);
      });
  };

  const getRowHeight = (params) => {
    //console.log(params.data.rowHeight);
    //return params.data.rowHeight;
    return 80;
  };

  const createYearCellRenderer = (params) => {
    if (params.value == null)
      return (
        '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' +
        utils.MariatoThaiDateString(params.data.CreatedDate) +
        "</p></span>"
      );
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.CreatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const updateYearCellRenderer = (params) => {
    if (params.value == null) return "";
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.UpdatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };

  var hashValueGetter = function (params) {
    return params.node.rowIndex + 1;
  };

  const onRowDragEnd = (event) => {
    let orderItem = [];
    gridApi.forEachNode((rowNode, index) => {
      orderItem.push(rowNode.data.BestPersonRewardID);
    });

    let order = orderItem;
    axios
      .put(
        process.env.REACT_APP_APIURL + "/api-web/reorder/network/best-person-reward",
        { ordering: order },
        {}
      )
      .then((response) => {
        SearchFilter();
      });
  };

  const typeRef = React.createRef();
  const topicRef = React.createRef();
  const yearRef = React.createRef();
  
  const SearchFilter = () => { 
    const obj = {
      year: yearSelect,
      type: selectType,
      topic: selectTopic,
    };
    let queryParams = null;
    queryParams =
      '?' +
      Object.keys(obj)
        .map(key => {
          return `${key}=${encodeURIComponent(obj[key])}`;
        })
        .join('&');

    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/bestPersonReward"+queryParams)
      .then((response) => {
        setRowData(response.data);
      });
  }

  const TopicSelect = topicList.map((item) => (
      <option
          key={item.InterestTopicID}
          value={item.InterestTopicID}
      >
          {item.TextTH}
      </option>
  ));

  const YearSelect = yearList?.map((year) => (
    <option
        key={year}
        value={year}
    >
        {(Number(year) + 543)}
    </option>
  ));

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{ pathname: "/admin/network/BestPersonRewardList" }}
            >
              {"การจัดการรางวัลนักสิทธิมนุษยชนดีเด่น"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/network/ContactUsReorder" }}
            >
              {"จัดลำดับ"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="form-inline form-border">
            <div className="mb-2 col-4  col-lg-3 col-md-3" style={{ paddingRight: "10px"}}>
              <select ref={yearRef} name="yearSelect" id="yearSelect" className="form-control" value={yearSelect} onChange={(e) => { setYearSelect(e.currentTarget.value); }}>
                  <option value="0">เลือกปี</option>
                  {YearSelect}
              </select>
            </div>
            <div className="mb-2 col-4  col-lg-3 col-md-3" style={{ paddingRight: "10px"}}>
              <select ref={typeRef} name="typeRef" id="typeRef" className="form-control" value={selectType} onChange={(e) => { setSelectType(e.currentTarget.value); }}>
                  <option value="0">ประเภท</option>
                  <option value="1">บุคคล</option>
                  <option value="2">องค์กร</option>
              </select>
            </div>
            <div className="mb-2 col-4  col-lg-3 col-md-3" style={{ paddingRight: "10px"}}>
              <select ref={topicRef} name="topicRef" id="topicRef" className="form-control" value={selectTopic} onChange={(e) => { setSelectTopic(e.currentTarget.value); }}>
                  <option value="0">ประเภทรางวัล</option>
                  {TopicSelect}
              </select>
            </div>
            <div className="mb-2 col-4  col-lg-3 col-md-3">
              <button onClick={(e) => {
                e.preventDefault(); 
                SearchFilter() 
               }} className="btn btn-primary">Search</button>
            </div>
        </form>
      </div>
      <div className="col-12">
        <div className="ag-theme-alpine" style={{ height: 100 + "vh" }}>
          <AgGridReact
            autoGroupColumnDef={{
              headerName: "Group",
              minWidth: 30,
              field: "CategoriesID",
              valueGetter: function (params) {
                if (params.node.group) {
                  return params.node.key;
                } else {
                  return params.data[params.colDef.field];
                }
              },
              headerCheckboxSelection: true,
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: { checkbox: true },
            }}
            modules={AllCommunityModules}
            frameworkComponents={{}}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              //cellClass: "cell-border cell-vertical-align-text-left",
            }}
            getRowHeight={getRowHeight}
            onGridReady={onGridReady}
            rowData={rowData}
            rowSelection={"multiple"}
            rowDragManaged={true}
            pagination={false}
            //paginationPageSize= {10}
            onRowDragEnd={onRowDragEnd}
          >
            <AgGridColumn
              field="Ordering"
              headerName="เรียงลำดับ"
              minWidth={10}
              rowDrag={true}
            />
            <AgGridColumn
              field=""
              valueGetter={hashValueGetter}
              headerName="ลำดับ"
              minWidth={70}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn field="Name" headerName="ชื่อรายการ" minWidth={180} />

            <AgGridColumn
              field="IsPersonType"
              headerName="กลุ่ม"
              cellRenderer={TypeRenderer}
              sortable={true}
              minWidth={130}
            />
            <AgGridColumn
              field="Status"
              headerName="สถานะ"
              cellRenderer={StatusRenderer}
              sortable={true}
              minWidth={130}
            />
            <AgGridColumn
              field="CreatedDate"
              headerName="ผู้สร้าง"
              cellRenderer={createYearCellRenderer}
              minWidth={175}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
            <AgGridColumn
              field="UpdatedDate"
              headerName="ผู้แก้ไข"
              cellRenderer={updateYearCellRenderer}
              minWidth={175}
              wrapText={true}
              cellClass="cell-border cell-vertical-align-text-left"
            />
          </AgGridReact>
        </div>
      </div>
    </section>
  );
}
