import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authenticationService } from "services/authentication.service.js";
import imgListFirst from "assets/images/icon/corpnetwork.svg";
import imgListSecondary from "assets/images/icon/councilnetwork.svg";
import imgListThird from "assets/images/icon/searchcorp.svg";

const swal = withReactContent(Swal);

export default function NetworkList(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [networkList, setNetworkList] = useState([]);
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  /*const [newsdata, SetNewsData] = useState({});
    const [canRating, setCanRating] = useState(true);
    const [gallery, setGallery] = useState([]);
    
    const newsid= props.match.params.id;
*/
  useEffect(() => {
    //console.log(props.location.state.id)
    utils.AccessInfo(
      "องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ",
      "/authen/network/NetworkList",
      "F",
      currentUser
    );
    axios
      .get(
        process.env.REACT_APP_APIURL + "/api-web/corporateNetworkApproveList"
      )
      .then((response) => {
        //console.log(response.data)
        setNetworkList(response.data);
        setCurrentPage(1);
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return networkList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  const NetworkApproveList = currentTableData.map((item, i) => (
    <div className="controlListNetwork" key={i}>
      <div className="row" >
        <div className="col-lg-4 col-sm-5">
          <div className="network-logo-img text-center">
            {item.LogoImagePath !== null && (
              <img src={item.LogoImagePath} alt="group" width="340px" />
            )}
          </div>
        </div>
        <div className="col-lg-8 col-sm-7">
          <div className="mt-2 mb-2">
            <span className="time-ago">
              <i className="far fa-clock" />
              <p> {utils.MariatoThaiDateStringShort(item.CreatedDate)}</p>
            </span>
            <span className="time-ago viewContent">
              <i className="far fa-eye" />
              <p>490</p>
            </span>
          </div>
          <h2 className="text-dark">
            <Link
              to={{
                pathname: "/authen/network/ViewNetwork",
                state: { networkID: item.NetworkID },
              }}
            >
              {item.NetOrganization}
            </Link>
          </h2>
          <div className="mt-2 mb-2">
            <span className="map-pin">
              <i className="fas fa-map-marker-alt" />
              <p> {item.NetHouseNo}</p>
            </span>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  ));

  function LoginAlert() {
    Swal.fire({
      icon: "error",
      title: "ผิดพลาด",
      text: "กรุณาเข้าสู่ระบบ",
    }).then((result) => { });
  }
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/NetworkList",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ"
                  : "Cooperationnetwork and Profesional Council"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              <div className="sub-title mb-5">
                <h2 className="thai-header text-center">
                  <span className="orang content-underline">
                    องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ
                  </span>
                </h2>
              </div>
              <div className="mb-60">
                <div className="row">
                  {currentUser != null && (
                    <>
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <Link
                          to={{
                            pathname: "/authen/network/CoorperationRegister",
                            state: { language: language, netType: "ORG" },
                          }}
                          className="btn btn-sq-lg"
                        >
                          <img src={imgListFirst} alt="" />
                          ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ
                        </Link>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <Link
                          to={{
                            pathname: "/authen/network/CoorperationRegister",
                            state: { language: language, netType: "PROCOUN" },
                          }}
                          className="btn btn-sq-lg"
                        >
                          <img src={imgListSecondary} alt="" />
                          ยื่นเรื่องขอรับการจดแจ้งสภาวิชาชีพ
                        </Link>
                      </div>
                    </>
                  )}
                  {currentUser === null && (
                    // <>
                    //   <div className="col-md-4 col-sm-6 col-xs-12">
                    //     <a
                    //       className="btn btn-sq-lg corpnetworkbg"
                    //       onClick={(e) => {
                    //         e.preventDefault();
                    //         LoginAlert();
                    //       }}
                    //     >
                    //       ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ
                    //     </a>
                    //   </div>
                    //   <div className="col-md-4 col-sm-6 col-xs-12">
                    //     <a
                    //       className="btn btn-sq-lg councilnetworkbg"
                    //       onClick={(e) => {
                    //         e.preventDefault();
                    //         LoginAlert();
                    //       }}
                    //     >
                    //       ยื่นเรื่องขอรับการจดแจ้งสภาวิชาชีพ
                    //     </a>
                    //   </div>
                    // </>
                    <>
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <Link
                          // to={{
                          //   pathname: "/authen/network/CoorperationRegister",
                          //   state: { language: language, netType: "ORG" },
                          // }}
                          to={{
                            pathname: "/authen/network/NetworkList"
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            LoginAlert();
                          }}
                          className="btn btn-sq-lg"
                        >
                          <img src={imgListFirst} alt="" />
                          ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน
                        </Link>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <Link
                          // to={{
                          //   pathname: "/authen/network/CoorperationRegister",
                          //   state: { language: language, netType: "PROCOUN" },
                          // }}
                          to={{
                            pathname: "/authen/network/NetworkList"
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            LoginAlert();
                          }}
                          className="btn btn-sq-lg"
                        >
                          <img src={imgListSecondary} alt="" />
                          ยื่นเรื่องขอรับการจดแจ้งสภาวิชาชีพ
                        </Link>
                      </div>
                    </>

                  )}
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <Link
                      to={{
                        pathname: "/authen/network/SearchNetwork",
                        state: { language: language },
                      }}
                      className="btn btn-sq-lg"
                    >
                      <img src={imgListThird} alt="" />
                      ค้นหารายชื่อองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="sub-title title">
                    <h2 className="thai-header text-green text-center">
                      <span>
                        รายชิื่อเอกชนเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="col-12">{NetworkApproveList}</div>
                <div className="col-12">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={networkList.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
