import React, { useState /*, useEffect*/, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import {
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  FacebookIcon,
  EmailIcon,
  LineIcon,
} from "react-share";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/activity.svg";
import Footer from "components/Footer.js";
import searchicon from "assets/images/icon/search.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
export default function ActivityList(props) {
  const language = localStorage.getItem("language");
  const PageSize = 12;
  //let language = props.location.state.language;
  const [selectActivityCat, SetSelectActivityCat] = useState([]);
  const [activityList, SetActivityList] = useState([]);
  //const [itemList, SetItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [popupItem, setPopupItem] = useState([]);

  useMemo(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchActivityCat", {
        params: { status: 1 },
      })
      .then((response) => {
        SetSelectActivityCat(response.data);
      });
    const formData = new FormData();
    formData.append("search", "");
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/activityList", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        //console.log(response.data)
        SetActivityList(response.data);
        setCurrentPage(1);
      });
  }, []); /* <-- add this for run once*/
  const searchRef = React.createRef();
  const statusRef = React.createRef();
  const orderRef = React.createRef();
  const searchData = () => {
    const formData = new FormData();
    formData.append("search", searchRef.current.value);
    formData.append("catid", statusRef.current.value);
    formData.append("order", orderRef.current.value);
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/activityList", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        SetActivityList(response.data);
        //console.log(activityList);
        setCurrentPage(0);
        setCurrentPage(1);
      });
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return activityList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const ActivityCatSelect = selectActivityCat.map((activityCat) => (
    <option key={activityCat.ActivityCatID} value={activityCat.ActivityCatID}>
      {language === "Thai"
        ? activityCat.TitleTH
        : activityCat.TitleEN.trim().length === 0
        ? activityCat.TitleTH
        : activityCat.TitleEN}
    </option>
  ));
  const readData = (id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateActivityViews",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        props.history.push({
          pathname: "/activity/ReadActivity/" + id,
          state: { id: id },
        });
      });
  };
  function UpdateShare(id, type) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateActivityShare",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {});
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const Popup = (props) => {
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>
            x
          </span>
          <div className="share-tool text-center">
            <h4>แชร์ให้เพื่อน</h4>
            <FacebookShareButton
              url={
                "https://merights.nhrc.or.th/activity/ReadActivity/" +
                popupItem.ActivitiesID
              }
              quote={
                language === "Thai"
                  ? popupItem.TitleTH
                  : popupItem.TitleEN.trim().length === 0
                  ? popupItem.TitleTH
                  : popupItem.TitleEN
              }
              className="shareEmail"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.NewsID, "Facebook");
              }}
            >
              <FacebookIcon size={50} round />
            </FacebookShareButton>
            <LineShareButton
              url={
                "https://merights.nhrc.or.th/activity/ReadActivity/" +
                popupItem.ActivitiesID
              }
              quote={
                language === "Thai"
                  ? popupItem.TitleTH
                  : popupItem.TitleEN.trim().length === 0
                  ? popupItem.TitleTH
                  : popupItem.TitleEN
              }
              className="Demo__some-network__share-button"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.NewsID, "Line");
              }}
            >
              <LineIcon size={50} round />
            </LineShareButton>
            <EmailShareButton
              url={
                "https://merights.nhrc.or.th/activity/ReadActivity/" +
                popupItem.ActivitiesID
              }
              quote={
                language === "Thai"
                  ? popupItem.TitleTH
                  : popupItem.TitleEN.trim().length === 0
                  ? popupItem.TitleTH
                  : popupItem.TitleEN
              }
              className="Demo__some-network__share-button"
              /*onClick={(e) => { e.preventDefault(); UpdateShare(popupItem.NewsID, 'Email') }}*/
            >
              <EmailIcon size={50} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    );
  };
  const shareData = (item) => {
    setPopupItem(item);
    togglePopup();
  };
  const ActivityDIV = currentTableData.map((activityItem) => (
    <div
      className="col-md-4 col-sm-6 col-xs-12 mt-3 mb-3"
      key={activityItem.ActivitiesID}
    >
      <div className="card cover">
        <img
          src={activityItem.ThumbnailWeb}
          className="cover"
          alt="Activity cover"
        />
        <div
          className="share-button"
          onClick={(e) => {
            e.preventDefault();
            shareData(activityItem);
          }}
        >
          <i className="fas fa-share-alt"></i>
        </div>
        <div className="card-body">
          <h4 className="content-title">
            {language === "Thai"
              ? activityItem.TitleTH
              : activityItem.TitleEN.trim().length === 0
              ? activityItem.TitleTH
              : activityItem.TitleEN}
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html:
                language === "Thai"
                  ? activityItem.DescriptionTH
                  : activityItem.DescriptionEN.trim().length === 0
                  ? activityItem.DescriptionTH
                  : activityItem.DescriptionEN,
            }}
            className="content-info"
          />
          <div className="mt-2 mb-2">
            <span className="time-ago">
              <i className="far fa-clock" />
              <p>
                {" "}
                {utils.MariatoThaiDateTimeStringShort(
                  activityItem.ActivityStartDate
                )}
              </p>
            </span>
            <span className="view-total float-right">
              <i className="fas fa-eye" />
              <p> {activityItem.Views}</p>
            </span>
          </div>
          <a
            href="/"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              readData(activityItem.ActivitiesID);
            }}
          >
            {language === "Thai" ? "อ่านต่อ" : "Read more"}
          </a>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}

        <ContentBanner
          language={language}
          theader={"กิจกรรม"}
          eheader={"Recent events"}
          bannerkey="ACTIVITY"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {language === "Thai"
                  ? " ประชาสัมพันธ์และกิจกรรม "
                  : " Information news and Activities "}
              </a>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{
                  pathname: "/activity/ActivityList",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "กิจกรรม" : "Activities"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile">
            <form className="formContent">
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <input
                    className="form-control mr-sm-2 search"
                    ref={searchRef}
                    type="text"
                    placeholder={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                    aria-label="Search"
                  />
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      searchData();
                    }}
                  >
                    <img
                      src={searchicon}
                      className="searchicon"
                      alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                    />
                  </a>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <select
                    name="cars"
                    className="custom-select"
                    defaultValue="default"
                    ref={statusRef}
                    onChange={(e) => {
                      searchData();
                    }}
                  >
                    <option value="-1">
                      {language === "Thai"
                        ? "เลือกหมวดหมู่"
                        : "Select Category"}
                    </option>
                    {ActivityCatSelect}
                  </select>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <select
                    name="cars"
                    className="custom-select"
                    ref={orderRef}
                    onChange={(e) => {
                      searchData();
                    }}
                  >
                    <option value="0">
                      {language === "Thai" ? "กรองข้อมูล" : "Filter"}
                    </option>
                    <option value="1">
                      {language === "Thai" ? "ล่าสุด/แนะนำ" : "new/Recomment"}
                    </option>
                    <option value="2">
                      {language === "Thai" ? "ยอดนิยม" : "Popular"}
                    </option>
                  </select>
                </div>
              </div>
            </form>
            <div className="controlflex">
              <div className="textInfo">{activityList.length}</div>
              <div className="textSum"> ผลลัพธ์</div>
            </div>
            <div className="row">
              {ActivityDIV}
              <div className="col-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={activityList.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      {isOpen && <Popup handleClose={togglePopup} />}
      <Footer />
    </>
  );
}
