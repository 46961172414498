import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import searchicon from "assets/images/icon/search.svg";
import { authenticationService } from "services/authentication.service.js";

export default function SearchNetwork(props) {
  const currentUser = authenticationService.currentUserValue;
  const PageSize = 10;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [networkList, setNetworkList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  /*const [newsdata, SetNewsData] = useState({});
    const [canRating, setCanRating] = useState(true);
    const [gallery, setGallery] = useState([]);
    
    const newsid= props.match.params.id;
*/
  const [topicList, setTopicList] = useState([]);
  useEffect(() => {
    //console.log(props.location.state.id)
    utils.AccessInfo(
      "องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ",
      "/authen/network/NetworkList",
      "F",
      currentUser
    );
    axios
      .get(
        process.env.REACT_APP_APIURL + "/api-web/searchMemberInterestTopic",
        { params: { status: 1 } }
      )
      .then((response) => {
        setTopicList(response.data);
        axios
          .get(
            process.env.REACT_APP_APIURL +
            "/api-web/corporateNetworkApproveList"
          )
          .then((response) => {
            //console.log(response.data)
            setNetworkList(response.data);
            setCurrentPage(1);
            setLoading(false);
          });
      });
  }, []); /* <-- add this for run once*/
  const TopicList = topicList.map((item) => (
    <option key={item.InterestTopicID} value={item.InterestTopicID}>
      {language === "Thai" ? item.TextTH : item.TextEN}
    </option>
  ));
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return networkList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const NewsDIV = currentTableData.map((item, i) => (
    <div className="listProUi2fellows" key={i}>
      <Link
        to={{
          pathname: "/authen/network/ViewNetwork",
          state: { networkID: item.NetworkID },
        }}
      >
        {item.NetOrganization}
      </Link>
      <div className="listDatePro">
        <span className="time-ago">
          วันที่เผยแพร่ <i className="far fa-clock" />
          <p>{utils.MariatoThaiDateStringShort(item.CreatedDate)}</p>
        </span>
        {/*<span className="view-total float-right"><i className="fas fa-eye" /><p> {item.Views}</p></span>*/}
      </div>
    </div>
  ));
  const searchRef = React.createRef();
  const hrIssueRef = React.createRef();
  const networkType = React.createRef();
  const searchData = () => {
    const formData = new FormData();
    formData.append("search", searchRef.current.value);
    formData.append("hrIssueRef", hrIssueRef.current.value);
    formData.append("networkType", networkType.current.value);
    axios
      .post(
        process.env.REACT_APP_APIURL +
        "/api-web/searchCorporateNetworkApproveList",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setNetworkList(response.data);
        //console.log(newsList);
        setCurrentPage(0);
        setCurrentPage(1);
      });
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/network/NetworkList",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ"
                  : "Cooperationnetwork and Profesional Council"}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/SearchNetwork",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " ค้นหารายชื่อองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ"
                  : "Search Cooperationnetwork and Profesional Council"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data SearchNetwork">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        ค้นหารายชื่อองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form className="formContent row">
                    <div className="col-12">
                      <input
                        className="form-control mr-sm-2 search"
                        ref={searchRef}
                        type="text"
                        placeholder={
                          language === "Thai" ? "ค้นหาที่นี่" : "Search"
                        }
                        aria-label="Search"
                      />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          searchData();
                        }}
                      >
                        <img
                          src={searchicon}
                          className="searchicon"
                          alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                        />
                      </a>
                    </div>
                    <div className="col-md-6">
                      <select
                        name="hrIssueRef"
                        id="hrIssueRef"
                        className="custom-select"
                        defaultValue="default"
                        ref={hrIssueRef}
                      >
                        <option value="0">
                          {language === "Thai"
                            ? " เลือกประเด็นสิทธิมนุษยชนสำหรับกลุ่มองค์กร"
                            : "Select human rights issues for the organization network"}
                        </option>
                        {/*<option value="1">{language === "Thai" ? " สิทธิพลเมืองและสิทธิทางการเมือง" : "POLITICAL"}</option>
                                            <option value="2">{language === "Thai" ? " สิทธิทางเศรษฐกิจ สังคม และ วัฒณธรรม" : "SOCIAL"}</option>
                                            <option value="3">{language === "Thai" ? " สิทธิสตรี" : "FEMALE"}</option>
                                            <option value="4">{language === "Thai" ? " สิทธิเด็ก" : "CHILD"}</option>
                                            <option value="5">{language === "Thai" ? " การเลือกปฏิบัติทางเชื้อชาติ" : "RACE"}</option>
                                            <option value="6">{language === "Thai" ? " การต่อต้านการทรมาน" : "TORTURE"}</option>
                    <option value="7">{language === "Thai" ? " สิทธิคนพิการ" : "CRIPPLE"}</option>*/}
                        {TopicList}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <select
                        name="networkType"
                        id="networkType"
                        className="custom-select"
                        defaultValue="default"
                        ref={networkType}
                      >
                        <option value="">
                          {language === "Thai"
                            ? "ประเภทเครือข่าย"
                            : "Network type"}
                        </option>
                        <option value="ORG">
                          {language === "Thai"
                            ? "องค์กรวิชาชีพ"
                            : "Personal organization network"}
                        </option>
                        <option value="PROCOUN">
                          {language === "Thai"
                            ? "สภาวิชาชีพ"
                            : "Professional council"}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="col-12">
                  <span className="big-text badge badge-info">
                    {networkList.length}
                  </span>
                  <span className="big-text text-gray"> ผลลัพธ์</span>
                </div>
              </div>
              <div className="CardProUi2fellows">{NewsDIV}</div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={networkList.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
