import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import noimage from 'assets/images/no_image.svg'
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewBanner(props) {
    const currentUser = authenticationService.currentUserValue;

    const [bannerName, setBannerName] = useState('');
    const [bannerLink, setBannerLink] = useState('');
    const [bannerImageTH, setBannerImageTH] = useState(null);
    const [bannerImageEN, setBannerImageEN] = useState(null);
    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(new Date());
    const [status, setStatus] = useState(false);

    const [errors, setError] = useState({});
    useEffect(() => {

    }, []); /* <-- add this for run once*/


    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (bannerName.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (bannerLink.trim().length === 0) {
            formIsValid = false;
            serrors["bannerLink"] = "กรุณาระบุลิงก์ป้ายโฆษณา";
        }
        if (bannerImageTH === null) {
            formIsValid = false;
            serrors["bannerImageTH"] = "กรุณาเลือกไฟล์ภาพสำหรับป้ายโฆษณาภาษาไทย";
        }
        if (bannerImageEN === null) {
            formIsValid = false;
            serrors["bannerImageEN"] = "กรุณาเลือกไฟล์ภาพสำหรับป้ายโฆษณาภาษาอังกฤษ";
        }
        setError(serrors);
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("bannerName", bannerName);
            formData.append("bannerLink", bannerLink);
            formData.append("status", status);
            formData.append("publishstartDate", publishstartDate);
            formData.append("publishendDate", publishendDate);

            formData.append("bannerImageTH", bannerImageTH.file);
            formData.append("bannerImageEN", bannerImageEN.file);
            formData.append("userID", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveBanner', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/setting/Banner" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };

    const WebCoverPreview = (
        <img className="preview-upload" src={bannerImageTH === null ? noimage : bannerImageTH.preview} onChange={(e) => { e.target.src = URL.createObjectURL(bannerImageTH); }} alt="Web Cover Preview" />
    );

    const MobileCoverPreview = (
        <img className="preview-upload" src={bannerImageEN === null ? noimage : bannerImageEN.preview} onChange={(e) => { e.target.src = URL.createObjectURL(bannerImageEN); }} alt="Mobile Cover Preview" />
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setBannerImageTH(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setBannerImageEN(imageFile);
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/setting/Banner" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการป้ายโฆษณา"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/setting/Banner" }}>{"การจัดการป้ายโฆษณา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/setting/NewBanner" }}>{"สร้าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label htmlFor="bannerName" className="required"> ชื่อป้ายโฆษณา</label>
                                    <input type="text" className="form-control" id="bannerName" name="bannerName" placeholder="กรุณากรอก" value={bannerName} onChange={(e) => { setBannerName(e.currentTarget.value) }} />
                                    <SError error={errors["bannerName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="bannerLink" >ลิงก์ป้ายโฆษณา</label>
                                    <input type="text" className="form-control" id="bannerLink" name="bannerLink" placeholder="กรุณากรอก" value={bannerLink} onChange={(e) => { setBannerLink(e.currentTarget.value) }} />
                                    <SError error={errors["bannerLink"]} />
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="published1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="published2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>
                                {status === true && <>
                                    <div className="col-5">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                maxDate={publishendDate}
                                                onChange={SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-5">
                                        <label className="required">วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                minDate={publishstartDate}
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-2"></div>
                                </>}
                                <div className="col-12">
                                    <label className="required">รูปป้ายโฆษณา (TH)</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgWebCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebCoverPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["bannerImageTH"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปป้ายโฆษณา (EN)</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["bannerImageEN"]} />
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
