import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import AttatchmentList from 'components/AttatchmentList';
import VDOFileList from 'components/VDOFileList';
export default function ViewBestPerson(props) {
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [bestPersonData, SetBestPersonData] = useState({});
    const [fileList, setFileList] = useState([]);
    const [countVdoList, setCountVDOList] = useState([]);
    const rewardID = props.location.state.rewardID;

    useEffect(() => {
        //console.log(rewardID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/bestPersonRewardbyID', { params: { id: rewardID } }).then((response) => {
            let data = response.data[0];
            SetBestPersonData(response.data[0])
            setFileList(data.filelist);
    
            if(data.filelist.length > 0){
                const vdolist = data.filelist.filter((v) => {
                    return v.AttachmentType === "VDO"
                  })
                setCountVDOList(vdolist.length);
            }

            setLoading(false);
        })

        

    }, [rewardID]); /* <-- add this for run once*/


    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                    path={<>
                        <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                        <span>/</span><Link to={{ pathname: "/authen/network/SearchBestPersonAward", state: { language: language } }}>{language === "Thai" ? " รางวัลนักสิทธิมนุษยชนดีเด่น" : "Human Rights Best Person Award"}</Link>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/ViewBestPerson", state: { rewardID: rewardID } }}>{bestPersonData.Name}</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className="container ">
                    <section className="edit-profile row ViewBestPerson" >
                        <div className="news-activity-data mb-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header text-center"><span className="orang content-underline">{language === "Thai" ? "รางวัลนักสิทธิมนุษยชนดีเด่น" : "Human Rights Best Person Award"}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-data ViewBestPerson">
                            <div className="row ViewBestPerson">
                                <div className="col-5 logo-img">
                                    {bestPersonData.PersonImgPath !== null && (<span className="logo-circle"><img src={bestPersonData.PersonImgPath} alt="Avatar" /></span>)}
                                </div>
                                <div className="col-7">
                                    <h2 className="text-left text-dark-blue text-title">{bestPersonData.Name}</h2>
                                    <ul className="list-icon">
                                        {Number(bestPersonData.IsPersonType) === 1 && (
                                            <>
                                                <li className="list-icon"><i className="far fa-address-book text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "ตำแหน่ง" : "Position"} :</span> <span className="detail">{language === "Thai" ? bestPersonData.Position : bestPersonData.PositionEN}</span></li>
                                                <li className="list-icon"><i className="fas fa-globe text-dark-blue mr-2"></i><span className="title">หน่วยงาน/องค์กร :</span> <span className="detail">{bestPersonData.Department}</span></li>
                                            </>)}
                                        {Number(bestPersonData.IsPersonType) === 2 && (
                                            <>
                                                <li className="list-icon"><i className="fas fa-map-marker-alt text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "ที่อยู่" : "Address"} :</span> <span className="detail">{language === "Thai" ? bestPersonData.Location : bestPersonData.LocationEN}</span></li>
                                                <li className="list-icon"><i className="fas fa-phone-alt text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "โทรศัพท์" : "Phone Number"} :</span> <span className="detail">{bestPersonData.Tel}</span></li>
                                                <li className="list-icon"><i className="far fa-envelope text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "อีเมล" : "Email"} :</span> <span className="detail">{bestPersonData.Email}</span></li>
                                            </>)}
                                    </ul>
                                </div>
                                <div className="subheader col-12 mt-4">{language === "Thai" ? "ประเภทรางวัล" : "Type of Reward"}</div>
                                <div className="detail text" dangerouslySetInnerHTML={{ __html: language === "Thai" ? bestPersonData.TypeOfRewardDesc : bestPersonData.TypeOfRewardDescEN }}></div>
                                <div className="subheader col-12 mt-4">{language === "Thai" ? "ผลงาน" : "Performance"}</div>
                                <div className="detail text" dangerouslySetInnerHTML={{ __html: language === "Thai" ? bestPersonData.Portfolio : bestPersonData.PortfolioEN }}></div>
                                {
                                    bestPersonData.filelist.length > 0 && (<>
                                        <div className="subheader col-12 mt-4">{language === "Thai" ? "เอกสารแนบ" : "Attatchment"}</div>
                                        <div className="tab-pane col-12" >
                                            <AttatchmentList fileList={bestPersonData.filelist} />
                                        </div>
                                    </>)
                                }
                              
                                {
                                    countVdoList > 0 && (<>
                                        <div className="subheader col-12 mt-4">{language === "Thai" ? "VDO แนบ " : " Video attachments"}</div>
                                        <div className="tab-pane col-12" >
                                            <VDOFileList fileList={fileList} />
                                        </div>
                                    </>)
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
