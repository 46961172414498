import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import AdminBanner from 'components/Banner/AdminBanner';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import expressions from 'angular-expressions';
import assign from "lodash/assign";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

export default function ViewNetworkAppeal(props) {
    const networkid = props.match.params.networkid;
    const networkappealid = props.match.params.networkappealid;
    const [errors, setError] = useState({});
    const [organization, SetOrganization] = useState('');
    const [netHouseNo, SetNetHouseNo] = useState('');
    const [provinceCode, SetProvinceCode] = useState(0);
    const [districtCode, SetDistrictCode] = useState(0);
    const [subDistricCode, SetSubDistricCode] = useState(0);
    const [phone, SetPhone] = useState('');
    const [fax, SetFax] = useState('');
    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [coTitleID, SetCoTitleID] = useState(0);
    const [coFirstName, SetCoFirstName] = useState('');
    const [coLastName, SetCoLastName] = useState('');
    const [coPosition, SetCoPosition] = useState('');
    const [coOrganize, SetCoOrganize] = useState('');
    const [titleList, SetTitleList] = useState([]);
    const [coOtherTitle, SetCoOtherTitle] = useState('');

    const [documentID, SetDocumentID] = useState('');
    const [documentTitle, SetDocumentTitle] = useState('');
    const [documentDate, SetDocumentDate] = useState(new Date());
    const [appealDesc, SetAppealDesc] = useState('');

    const [networkAppealFiles, setNetworkAppealFile] = useState([]);
    const [filespreview, setFilesPreview] = useState([]);

    useEffect(() => {
        //console.log(props.position.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/corporateNetworkAppealbyID', { params: { id: networkid, networkappealid: networkappealid } }).then((response) => {
            //console.log(response.data[0])
            SetOrganization(response.data[0].NetOrganization);
            SetCoOrganize(response.data[0].NetOrganization);
            SetNetHouseNo(response.data[0].NetHouseNo);
            SetProvinceCode(response.data[0].NetProvinceID);
            SetDistrictCode(response.data[0].NetDistrictID);
            SetSubDistricCode(response.data[0].NetSubDistrictID);
            SetPhone(response.data[0].NetPhone);
            if (response.data[0].NetFax != null)
                SetFax(response.data[0].NetFax);

            SetCoTitleID(response.data[0].CoTitleID);
            SetCoFirstName(response.data[0].CoFirstName);
            SetCoLastName(response.data[0].CoLastName);
            SetCoPosition(response.data[0].CoPosition);

            SetDocumentID(response.data[0].DocumentID);
            SetDocumentTitle(response.data[0].DocumentTitle);
            SetDocumentDate(new Date(response.data[0].DocumentDate))
            SetAppealDesc(response.data[0].AppealDesc);

            const newFiles = [...networkAppealFiles];
            for (let i = 0; i < response.data[0].filelist.length; i += 1) {
                //console.log(response.data[0].filelist[i])
                var filename = response.data[0].filelist[i].FilenameOld.substring(response.data[0].filelist[i].FilenameOld.lastIndexOf('/') + 1);
                const filedata = {
                    name: filename,
                    preview: response.data[0].filelist[i].AttachmentPath,
                    size: utils.getFileSize(response.data[0].filelist[i].AttachmentPath)
                };
                newFiles[i] = filedata;
            }
            setNetworkAppealFile(newFiles);
        });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getTitle')
            .then((response) => {
                SetTitleList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
            .then((response) => {
                SetProvinceList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
            .then((response) => {
                SetDistrictList(response.data);
                SetAllDistrictList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
            .then((response) => {
                SetSubDistrictList(response.data);
                SetAllSubDistrictList(response.data);
            });
    }, []); /* <-- add this for run once*/

    const handleNetworkEdit = params => {
        props.history.push({ pathname: "/admin/network/EditNetwork", state: { id: networkid } });
    };

    const TitleSelect = titleList.map((title, i) => (
        <option
            key={i}
            value={title.TitleID}
        >
            {title.TitleNameTH}
        </option>
    ));
    const handleChangeProvince = ProvinceCode => {
        SetDistrictList(allDistrictList.filter(item => item['ProvinceNCode'] === ProvinceCode));
        SetSubDistrictList(allSubDistrictList.filter(item => item['ProvinceNCode'] === ProvinceCode));
    };
    const handleChangeDistrict = DistrictCode => {
        SetSubDistrictList(allSubDistrictList.filter(item => item['DistrictNCode'] === DistrictCode));
    };
    const ProvinceSelect = provinceList.map((province, i) => (
        <option
            key={i}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district, i) => (
        <option
            key={i}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict, i) => (
        <option
            key={i}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    const handleRemoveNetworkAppealFile = filename => {
        for (let file of networkAppealFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched completeDocFilesList
        }
        setNetworkAppealFile(networkAppealFiles.filter(item => item['name'] !== filename));
    };
    const NetAppealFilesList = networkAppealFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveNetworkAppealFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));

    expressions.filters.lower = function (input) {
        // This condition should be used to make sure that if your input is
        // undefined, your output will be undefined as well and will not
        // throw an error
        if (!input) return input;
        return input.toLowerCase();
    };
    function angularParser(tag) {
        tag = tag
            .replace(/^\.$/, "this")
            .replace(/(’|')/g, "'")
            .replace(/(“|”)/g, '"');
        const expr = expressions.compile(tag);
        return {
            get: function (scope, context) {
                let obj = {};
                const scopeList = context.scopeList;
                const num = context.num;
                for (let i = 0, len = num + 1; i < len; i++) {
                    obj = assign(obj, scopeList[i]);
                }
                return expr(scope, obj);
            },
        };
    }
    async function printWord() {
        var nowDate = utils.MariatoThaiDateOnly(new Date);
        var docDate = utils.MariatoThaiDateOnly(documentDate);
        let curDate = nowDate.split(" ");
        let templatefile = process.env.REACT_APP_SITEURL + "/template/appeal.docx";

        loadFile(
            templatefile,
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    parser: angularParser,
                    paragraphLoop: true,
                    linebreaks: true,
                });
                let subdistrict = allSubDistrictList.find((data) => {
                    return data.SubDistrictID === subDistricCode
                })
                let district = allDistrictList.find((data) => {
                    return data.DistrictID === districtCode
                })
                let province = provinceList.find((data) => {
                    return data.ProvinceID === provinceCode
                })
                let titletxt = titleList.find((data) => {
                    return data.TitleID === coTitleID
                })
                let address = "เลขที่ " + netHouseNo + " แขวง/ตำบล " + subdistrict.SubDistrictTH + " เขต/อำเภอ " + district.DistrictTH + " จังหวัด " + province.ProvinceTH;
                doc.render({
                    organization: organization,
                    date: curDate[0],
                    month: curDate[1],
                    year: curDate[2],
                    address: address,
                    phone: phone.trim().length === 0 ? '-' : phone,
                    fax: fax.trim().length === 0 ? '-' : fax,
                    titletxt: titletxt.TitleNameTH,
                    coFirstName: coFirstName,
                    coLastName: coLastName,
                    coPosition: coPosition,
                    coOrganize: coOrganize,
                    documentID: documentID,
                    documentTitle: documentTitle,
                    documentDate: docDate,
                    appealDesc: appealDesc,
                });
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let outputname = 'คำอุทธรณ์';
                saveAs(out, organization + "_" + outputname + ".docx");
            }
        );
    }

    async function printPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            THSarabunNew: {
                normal: 'THSarabunNew.ttf',
                bold: 'THSarabunNew-Bold.ttf',
                italics: 'THSarabunNew-Italic.ttf',
                bolditalics: 'THSarabunNew-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            FontAwesome: {
                normal: 'fontawesome-webfont.ttf'
            },
            Symbol: {
                normal: 'Symbol.ttf'
            },
            wingding: {
                normal: 'wingding.ttf'
            },
            WINGDNG2: {
                normal: 'WINGDNG2.TTF'
            },
        }

        var nowDate = utils.MariatoThaiDateOnly(new Date);
        var docDate = utils.MariatoThaiDateOnly(documentDate);
        let curDate = nowDate.split(" ");

        let subdistrict = allSubDistrictList.find((data) => {
            return data.SubDistrictID === subDistricCode
        })
        let district = allDistrictList.find((data) => {
            return data.DistrictID === districtCode
        })
        let province = provinceList.find((data) => {
            return data.ProvinceID === provinceCode
        })
        let titletxt = titleList.find((data) => {
            return data.TitleID === coTitleID
        })
        let address = "เลขที่ " + netHouseNo + " แขวง/ตำบล " + subdistrict.SubDistrictTH + " เขต/อำเภอ " + district.DistrictTH + " จังหวัด " + province.ProvinceTH;

        let phonetxt = phone.trim().length === 0 ? '-' : phone;
        let faxtxt = fax.trim().length === 0 ? '-' : fax;

        var docDefinition = {
            header: [{ text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tเอกสารแนบ 3', fontSize: 16, bold: true, alignment: 'left', preserveLeadingSpaces: true }, { canvas: [{ type: 'rect', x: 170, y: 32, w: 170, h: 40, lineColor: 'black', }] }],
            content: [
                {
                    text: 'คำอุทธรณ์', fontSize: 16, bold: true, alignment: 'center', preserveLeadingSpaces: true
                },
                {
                    text: organization, fontSize: 16, bold: true, alignment: 'center', preserveLeadingSpaces: true
                },
                { text: [{ text: '\nที่อยู่ ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: address, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }], },
                {
                    text: [{ text: 'โทรศัพท์ ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: phone, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true },
                    { text: 'โทรสาร ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: fax, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }],
                },
                {
                    text: [{ text: 'วันที่ ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: curDate[0], fontSize: 16, alignment: 'right', preserveLeadingSpaces: true },
                    { text: 'เดือน ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: curDate[1], fontSize: 16, alignment: 'right', preserveLeadingSpaces: true },
                    { text: 'พ.ศ. ', bold: true, fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }, { text: curDate[2], fontSize: 16, alignment: 'right', preserveLeadingSpaces: true }],
                },
                { text: '\nกราบเรียน ประธานกรรมการสิทธิมนุษยชนแห่งชาติ ', fontSize: 15, preserveLeadingSpaces: true, alignment: 'left' },
                /*{
                    text: 'ใบรับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชน', fontSize: 14, preserveLeadingSpaces: true, absolutePosition: {
                        x: 40,
                        y: 100
                    },
                },*/
                {
                    text: [{
                        text: '\n\t\t\t\tข้าพเจ้า ' + titletxt + coFirstName + ' ' + coLastName + ' ตำแหน่ง ' + coPosition + ' สังกัด ' + coOrganize + ' มีความประสงค์ขออุทธรณ์คำสั่ง/ประกาศ/หนังสือ/สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ ที่ ' + documentID +
                            ' เรื่อง' + documentTitle + ' ลงวันที่ ' + documentDate + ' โดยมีรายละเอียดข้อเท็จจริงและข้ออุทธรณ์ ดังนี้', alignment: 'left', fontSize: 16, preserveLeadingSpaces: true
                    }],
                },
                {
                    text: [{
                        text: '\n\t\t\t\t(การกระทำทั้งหลายที่เป็นเหตุแห่งการอุทธรณ์ และแสดงข้อเท็จจริงและเหตุผลในการอุทธรณ์ ให้เห็นว่าได้ไม่ถูกต้อง ไม่เหมาะสม หรือไม่เป็นธรรม อย่างไร)' + appealDesc +
                            'เป็นเหตุให้ ' + organization + ' (ไม่ได้รับการจดแจ้ง/ถูกเพิกถอนในการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ)', alignment: 'left', fontSize: 16, preserveLeadingSpaces: true
                    }]
                },
                {
                    text: [{
                        text: '\n\t\t\t\tทั้งนี้ ตามความในข้อ ๑๙ แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. ๒๕๖๑' +
                            ' และที่แก้ไขเพิ่มเติม “องค์กรเอกชนหรือสภาวิชาชีพที่ไม่เห็นด้วยกับการไม่รับจดแจ้งตามข้อ ๑๑ วรรคสอง หรือไม่เห็นด้วยกับคำสั่งเพิกถอนการจดแจ้งตามข้อ ๑๘ วรรคสอง มีสิทธิอุทธรณ์ต่อคณะกรรมการภายในสามสิบวันนับแต่วันที่ได้รับแจ้ง”ในการนี้' +
                            ' ข้าพเจ้าจึงมีความประสงค์ขอให้ท่านพิจารณาคำอุทธรณ์ ในการประชุมคณะกรรมการสิทธิมนุษยชนแห่งชาติ ทั้งนี้ หากคณะกรรมการสิทธิมนุษยชนแห่งชาติมีมติ ไปในทางใดให้ถือว่าเป็นที่สุดในชั้นการอุทธรณ์ต่อคณะกรรมการ', alignment: 'left',
                        fontSize: 16, preserveLeadingSpaces: true
                    }]
                },

                /*{text:'\t\t\t\tขอรับรองว่า '+organization+' มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 8 แห่งระเบียบนี้', fontSize: 15, preserveLeadingSpaces: true, alignment: 'justify'},
                {
                    text: 'สำหรับเจ้าหน้าที่ในการตรวจเอกสาร ' ,
                    fontSize: 9,
                    bold:true,
                    decoration: 'underline',
                    decorationColor: 'black',
                    absolutePosition: {
                      x: 40,
                      y: 520
                    },
                },
                {
                    text: ["\t\t\t\t",{ text: "\u00F4", style: 'WINGDNG2',fontSize: 9, }, { text: ' หลักฐานการเป็นสภาวิชาชีพตามกฎหมายไทย', alignment: 'left', preserveLeadingSpaces: false }] ,
                    fontSize: 9,
                    absolutePosition: {
                      x: 40,
                      y: 540
                    },
                },
                {
                    text: ["\t\t\t\t",{ text: "\u00F4", style: 'WINGDNG2',fontSize: 9, }, { text: ' สำเนาหนังสือรับรองหรือคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }] ,
                    fontSize: 9,
                    absolutePosition: {
                      x: 40,
                      y: 555
                    },
                },
                {
                    text: ["\t\t\t\t",{ text: "\u00F4", style: 'WINGDNG2',fontSize: 9, }, { text: ' หลักฐานการให้ความเห็นชอบในการยื่นคาขอจดแจ้งสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }] ,
                    fontSize: 9,
                    absolutePosition: {
                      x: 40,
                      y: 570
                    },
                },
                {
                    text: 'ตรวจสอบแล้ว เอกสารถูกต้องครบถ้วน ............................................................ ผู้ตรวจสอบเอกสาร ' ,
                    fontSize: 9,
                    bold:true,
                    absolutePosition: {
                      x: 40,
                      y: 595
                    },
                },
                {
                    text: 'วันที่รับเอกสาร.................................................................................................................................' ,
                    fontSize: 9,
                    bold:true,
                    absolutePosition: {
                      x: 40,
                      y: 610
                    },
                },*/
                { text: '\n\n \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t (ลายมือชื่อ)  ..........................................................', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t (...................................................................)', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ผู้อุทธรณ์', preserveLeadingSpaces: true },
            ],
            defaultStyle: {
                font: 'THSarabunNew',
                fontSize: 15, alignment: 'justify', preserveLeadingSpaces: true
            },
            styles: {
                center:
                {
                    alignment: 'center'
                },
                WINGDNG2: {
                    'font': 'WINGDNG2',
                    'color': "#656565",
                    fontSize: 16
                }
            }
        };
        pdfMake.createPdf(docDefinition).open()
    };
    return (

        <section className="row" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={
                <>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>จัดการองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link id="profile-btn" to={{ pathname: "/admin/network/NetworkORGList" }}>{"เอกชนด้านสิทธิมนุษยชน"}</Link>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link id="profile-btn" className="active" to={{ pathname: "/admin/network/ViewNetworkAppeal/" + networkid + "/" + networkappealid }}>{"อุทธรณ์"}</Link>
                </>
            }
            />
            <div className="col-12 custom-admin-input">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >

                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12 text-center" >
                                    <h4>คำอุทธรณ์</h4>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="organization" className="required">ชื่อองค์กร</label>
                                    <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <span className="error">{errors["organization"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="netHouseNo" className="required">ที่ตั้งเลขที่</label>
                                    <input type="text" className="form-control " id="netHouseNo" value={netHouseNo} name="netHouseNo" placeholder="กรุณากรอก" onChange={(e) => { SetNetHouseNo(e.currentTarget.value) }} />
                                    <span className="error">{errors["netHouseNo"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="provinceCode" className="required">จังหวัด</label>
                                    <select className="form-control mb-2 mr-2" id="provinceCode" name="provinceCode" value={provinceCode} onChange={(e) => { SetProvinceCode(e.currentTarget.value); handleChangeProvince(e.currentTarget.value); }} >
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ProvinceSelect}
                                    </select>
                                    <span className="error">{errors["provinceCode"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="districtCode" className="required">เขต/อำเภอ</label>
                                    <select className="form-control mb-2 mr-2" id="districtCode" name="districtCode" value={districtCode} onChange={(e) => { SetDistrictCode(e.currentTarget.value); handleChangeDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {DistrictSelect}
                                    </select>
                                    <span className="error">{errors["districtCode"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="subDistricCode" className="required">แขวง/ตำบล</label>
                                    <select className="form-control mb-2 mr-2" id="subDistricCode" name="subDistricCode" value={subDistricCode} onChange={(e) => { SetSubDistricCode(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <span className="error">{errors["subDistricCode"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="phone" className="required">หมายเลขโทรศัพท์</label>
                                    <input type="text" className="form-control " id="coPhone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { SetPhone(e.currentTarget.value) }} />
                                    <span className="error">{errors["phone"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="fax" className="required">โทรสาร</label>
                                    <input type="text" className="form-control " id="fax" value={fax} name="fax" placeholder="กรุณากรอก" onChange={(e) => { SetFax(e.currentTarget.value) }} />
                                    <span className="error">{errors["fax"]}</span>
                                </div>

                                <div className="col-12 mt-2" >
                                    <h4>ข้าพเจ้า</h4>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coTitleID" className="required">คำนำหน้า</label>
                                    <select className="form-control mb-2 mr-2" id="coTitleID" name="coTitleID" value={coTitleID} onChange={(e) => { SetCoTitleID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {TitleSelect}
                                    </select>
                                    <span className="error">{errors["coTitleID"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coOtherTitle" className="required">ระบุคำนำหน้าอื่น ๆ</label>
                                    <input type="text" className="form-control " id="coOtherTitle" value={coOtherTitle} name="coOtherTitle" placeholder="โปรดระบุเมื่อไม่มีคำนำหน้าที่ต้องการให้เลือก" onChange={(e) => { SetCoOtherTitle(e.currentTarget.value) }} />
                                    <span className="error">{errors["coOtherTitle"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coFirstName" className="required">ชื่อ</label>
                                    <input type="text" className="form-control " id="coFirstName" value={coFirstName} name="coFirstName" placeholder="กรุณากรอก" onChange={(e) => { SetCoFirstName(e.currentTarget.value) }} />
                                    <span className="error">{errors["coFirstName"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coLastName" className="required">นามสกุล</label>
                                    <input type="text" className="form-control " id="coLastName" value={coLastName} name="coLastName" placeholder="กรุณากรอก" onChange={(e) => { SetCoLastName(e.currentTarget.value) }} />
                                    <span className="error">{errors["coLastName"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coPosition" className="required">ตำแหน่ง</label>
                                    <input type="text" className="form-control " id="coPosition" value={coPosition} name="coPosition" placeholder="กรุณากรอก" onChange={(e) => { SetCoPosition(e.currentTarget.value) }} />
                                    <span className="error">{errors["coPosition"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coOrganize" className="required">สังกัด (หน่วยงานที่ยื่นคำขอ) </label>
                                    <input type="text" className="form-control " id="coOrganize" value={coOrganize} name="coOrganize" placeholder="กรุณากรอก" onChange={(e) => { SetCoOrganize(e.currentTarget.value) }} />
                                    <span className="error">{errors["coOrganize"]}</span>
                                </div>

                                <div className="col-12 mt-2" >
                                    <label>มีความประสงค์ขออุทธรณ์คำสั่ง/ประกาศ/หนังสือ/สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</label>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="documentID" className="required">ที่</label>
                                    <input type="text" className="form-control " id="documentID" value={documentID} name="documentID" placeholder="กรุณากรอก" onChange={(e) => { SetDocumentID(e.currentTarget.value) }} />
                                    <span className="error">{errors["documentID"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="documentTitle" className="required">เรื่อง </label>
                                    <input type="text" className="form-control " id="documentTitle" value={documentTitle} name="documentTitle" placeholder="กรุณากรอก" onChange={(e) => { SetDocumentTitle(e.currentTarget.value) }} />
                                    <span className="error">{errors["documentTitle"]}</span>
                                </div>
                                <div className="col-6">
                                    <label className="required">ลงวันที่</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={documentDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                            {SetDocumentDate} name="documentDate" id="documentDate" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-6"></div>

                                <div className="col-12 mt-2" >
                                    <label>โดยมีรายละเอียดข้อเท็จจริงและข้ออุทธรณ์ ดังนี้</label>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="appealDesc" >(การกระทำทั้งหลายที่เป็นเหตุแห่งการอุทธรณ์ และแสดงข้อเท็จจริงและเหตุผลในการอุทธรณ์ ให้เห็นว่าได้ไม่ถูกต้อง ไม่เหมาะสม หรือไม่เป็นธรรม อย่างไร)</label>
                                    <div>
                                        <textarea className="form-control" name="appealDesc" id="appealDesc" rows="8" value={appealDesc} onChange={e => { SetAppealDesc(e.target.value) }}></textarea>
                                        <span className="error">{errors["appealDesc"]}</span>
                                    </div>
                                </div>

                                <div className="col-12 mt-2" >
                                    <h4>ข้าพเจ้า</h4>
                                </div>
                                <div className="col-12">
                                    {/*<label htmlFor="organization" className="required">ชื่อองค์กร</label>
                                <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                <span className="error">{errors["organization"]}</span>*/}
                                    <h4>{organization}</h4>
                                </div>
                                <div className="col-12 mt-2" >
                                    <label>(ไม่ได้รับการจดแจ้ง/ถูกเพิกถอนในการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ)</label>
                                </div>
                                <div className="col-12 mt-2" >
                                    <label>ทั้งนี้ ตามความในข้อ ๑๙ แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. ๒๕๖๑
                                        และที่แก้ไขเพิ่มเติม “องค์กรเอกชนหรือสภาวิชาชีพที่ไม่เห็นด้วยกับการไม่รับจดแจ้งตามข้อ ๑๑ วรรคสอง หรือไม่เห็นด้วยกับคำสั่งเพิกถอนการจดแจ้งตามข้อ ๑๘ วรรคสอง
                                        มีสิทธิอุทธรณ์ต่อคณะกรรมการภายในสามสิบวันนับแต่วันที่ได้รับแจ้ง”ในการนี้ ข้าพเจ้าจึงมีความประสงค์ขอให้ท่านพิจารณาคำอุทธรณ์ในการประชุมคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                                        ทั้งนี้ หากคณะกรรมการสิทธิมนุษยชนแห่งชาติมีมติไปในทางใดให้ถือว่าเป็นที่สุดในชั้นการอุทธรณ์ต่อคณะกรรมการ</label>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    เอกสารเพิ่มเติม
                                </div>
                                <div className="col-12">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setNetworkAppealFile([...networkAppealFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetAppealFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40" >
                                        <button type="button" className="btn btn-outline-primary" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printWord(false); }}><i className="far fa-file-word"></i> ปริ้น</button>
                                        <button type="button" className="btn btn-outline-danger" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printPDF(false); }}><i className="far fa-file-pdf"></i> ปริ้น</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); handleNetworkEdit(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </section>

    );
}