import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import 'moment/locale/th';
import moment from "moment";
//import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//const swal = withReactContent(Swal)


export default function QuestionaireReport(props) {
    const language = localStorage.getItem("language");
    //const currentUser = authenticationService.currentUserValue;
    const [publishstartDate, SetPublishStartDate] = useState(null);
    const [publishendDate, SetPublishEndDate] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [excelDataset, setExcelDataSet] = useState([]);
    const [selectActivityCat, setSelectActivityCat] = useState([]);
    const [selectCourseType, setSelectCourseType] = useState([]);
    const [selectCourseCategory, setSelectCourseCategory] = useState([]);
    const [activeElerningList, setActiveElerningList] = useState([]);
    const [allElerningList, setAllElerningList] = useState([]);
    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    useEffect(() => {

        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningCourseType', { params: { status: 1 } })
            .then((response) => {
                setSelectCourseType(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElearningCategories', { params: { status: 1 } })
            .then((response) => {
                setSelectCourseCategory(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningQuestionaireReport', { params: { status: 1 } }).then((response) => {
            //console.log(response.data)

            setAllElerningList(response.data)
            const set = getUniqueListBy(response.data, 'ELearnID');
            setActiveElerningList(set);
        })

    }, []); /* <-- add this for run once*/
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
        if (publishendDate === null) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
        if (publishstartDate === null) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);
    useMemo(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElibraryCat', { params: { status: 1 } })
            .then((response) => {
                setSelectActivityCat(response.data);
            });
    }, []); /* <-- add this for run once*/
    const borders = {
        top: { style: "thin", color: { rgb: "FF000000" } },
        bottom: { style: "thin", color: { rgb: "FF000000" } },
        left: { style: "thin", color: { rgb: "FF000000" } },
        right: { style: "thin", color: { rgb: "FF000000" } }
    }
    const centerAlignment = {
        horizontal: "center",
        wrapText: true
    }
    const leftAlignment = {
        horizontal: "top",
        wrapText: true
    }
    function SetExcelData(data) {
        let multiDataset = [{ columns: [], data: [] }];
        multiDataset[0].columns = [
            { title: "ลำดับ", width: { wpx: '95' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "ชื่อ-นามสกุล", width: { wpx: '260' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "หลักสูตร", width: { wpx: '240' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "รายวิชา", width: { wpx: '360' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "ข้อสอบ/แบบประเมิน (%)", width: { wpx: '180' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width 
        ];
        multiDataset[0].data = [];
        data.map((item, i) => {
            let row = [];
            let col = { value: (i + 1), style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            col = { value: item.CreateFullname, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.CourseTypeNameTH, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.ELearnTitle, style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            col = { value: item.percentage, style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            multiDataset[0].data.push(row);
        });
        //console.log(multiDataset)
        setExcelDataSet(multiDataset)
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningQuestionaireReport').then((response) => {
            updateData(response.data);
            SetExcelData(response.data);
        })

    };

    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 50;
    };

    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };

    const searchData = () => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningQuestionaireReport', {
            params: {
                search: searchRef.current.value, category: catRef.current.value, type: typeRef.current.value,
                subject: subjectRef.current.value, start: publishstartDate, end: publishendDate
            }
        }).then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };
    const resetsearch = () => {
        searchRef.current.value = "";
        catRef.current.value = "-1";
        typeRef.current.value = "-1";
        subjectRef.current.value = "-1";
        SetPublishStartDate(null);
        SetPublishEndDate(null);

        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningQuestionaireReport').then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };

    const ElerningList = activeElerningList.map((newsCat, i) => (
        <option
            key={"Elearn-" + newsCat.ELearnID + "-" + i}
            value={newsCat.ELearnID}
        >
            {newsCat.ELearnTitle}
        </option>
    ));
    const CourseTypeSelect = selectCourseType.map((item) => (
        <option
            key={item.ELearnCourseTypeID}
            value={item.ELearnCourseTypeID}
        >
            {item.CourseTypeNameTH}
        </option>
    ));
    const CourseCategorySelect = selectCourseCategory.map((item) => (
        <option
            key={item.ELearnCatID}
            value={item.ELearnCatID}
        >
            {item.CategoriesNameTH}
        </option>
    ));
    const searchRef = React.createRef();
    const catRef = React.createRef();
    const typeRef = React.createRef();
    const subjectRef = React.createRef();
    const handleChangeSelectCoruse = () => {
        if (Number(catRef.current.value) > 0 && Number(typeRef.current.value) > 0) {
            const data = allElerningList.filter(item => Number(item.ELearnCourseTypeID) === Number(typeRef.current.value) && Number(item.ELearnCatID) === Number(catRef.current.value));
            const set = getUniqueListBy(data, 'ELearnID');
            setActiveElerningList(set);
            //setActiveElerningList();
        }
        else if (Number(typeRef.current.value) > 0) {
            const data = allElerningList.filter(item => Number(item.ELearnCourseTypeID) === Number(typeRef.current.value));
            //console.log(typeRef.current.value)
            //console.log(allElerningList)
            const set = getUniqueListBy(data, 'ELearnID');
            setActiveElerningList(set);
            //setActiveElerningList(allElerningList.filter(item => Number(item.ELearnCourseTypeID) === Number(typeRef.current.value)));
        }
        else if (Number(catRef.current.value) > 0) {
            const data = allElerningList.filter(item => Number(item.ELearnCatID) === Number(catRef.current.value));
            const set = getUniqueListBy(data, 'ELearnID');
            setActiveElerningList(set);
            //setActiveElerningList(allElerningList.filter(item => Number(item.ELearnCatID) === Number(catRef.current.value)));
        }
        else {
            const set = getUniqueListBy(allElerningList, 'ELearnID');
            setActiveElerningList(set);
            //setActiveElerningList(allElerningList);
        }
        subjectRef.current.value = -1;
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"รายงานและสถิติ"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={(e) => { e.preventDefault(); }}>{"รายงานและสถิติ"}</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/report/QuestionaireReport" }}>{"ข้อมูลข้อสอบ"}</Link></>}

            />
            <div className="col-12">
                <form className="form-inline form-border">
                    <div className="col-3 mb-2 pr-0">
                        <input type="text" ref={searchRef} className="form-control " id="searchfield" name="searchfield" placeholder="ค้นหาที่นี่" style={{ width: 100 + '%' }} />
                    </div>

                    <div className="col-3 mb-2 pr-0">
                        <select name="catRef" className="custom-select" defaultValue="-1" ref={catRef} style={{ width: 100 + '%' }} onChange={(e) => { handleChangeSelectCoruse(); }}>
                            <option value="-1">{language === "Thai" ? "หมวดหมู่ทั้งหมด" : "Select Category"}</option>
                            {CourseCategorySelect}
                        </select>

                    </div>
                    <div className="col-3 mb-2 pr-0">
                        <select name="typeRef" id="typeRef" className="custom-select" defaultValue="-1" ref={typeRef} style={{ width: 100 + '%' }} onChange={(e) => { handleChangeSelectCoruse(); }}>
                            <option value="-1">{language === "Thai" ? "หลักสูตรทั้งหมด" : "Course type"}</option>
                            {CourseTypeSelect}
                        </select>
                    </div>
                    <div className="col-3 mb-2 ">
                        <select name="subjectRef" id="subjectRef" className="custom-select" defaultValue="-1" ref={subjectRef} style={{ width: 100 + '%' }}/*onChange={e => { e.preventDefault(); searchData(); }}*/>
                            <option value="-1">{language === "Thai" ? "วิชาทั้งหมด" : "Subject"}</option>
                            {ElerningList}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="required">วันที่เริ่มต้น</label>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                /*maxDate={publishendDate}*/
                                onChange={SetPublishStartDate} name="startpublishday" id="startpublishday" />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-3">
                        <label className="required">วันที่สิ้นสุด</label>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                /*minDate={publishstartDate}*/
                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="button-group col-6">
                        <button type="button" className="btn btn-info mb-2 mr-2" onClick={e => { e.preventDefault(); searchData(); }}>ค้นหา</button>
                        <button type="submit" className="btn btn-light mb-2" onClick={e => { e.preventDefault(); resetsearch(); }}>ล้างคำค้นหา</button>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="form-inline button-group">
                            <ExcelFile filename="ELearning Questionaire Report" element={<button type="button" className="btn btn-primary mb-2 mr-2" ><i className="far fa-file-excel"></i> Export</button>}>
                                <ExcelSheet dataSet={excelDataset} name="Questionaire" />
                                {/*<ExcelSheet data={rowData} name="Member">
                            <ExcelColumn label="ชื่อ-นามสกุล" value="FullName"  />
                            <ExcelColumn label="ตำแหน่ง" value="Position" />
                            <ExcelColumn label="หน่วยงาน" value="Department" />
                            <ExcelColumn label="หมายเลขโทรศัพท์" value="Telephone" />
                            <ExcelColumn label="อีเมล" value="Email" />
                            <ExcelColumn label="สถานะ"
                                value={(col) => col.IsCanceled ? "ไม่เข้าร่วม" : "เข้าร่วม"} />
                        </ExcelSheet>*/}
                                {/*<ExcelSheet data={dataSet2} name="Leaves">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Total Leaves" value="total" />
                            <ExcelColumn label="Remaining Leaves" value="remaining" />
    </ExcelSheet>*/}
                            </ExcelFile>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'DataName',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}

                                modules={AllCommunityModules}
                                frameworkComponents={{

                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={true}
                                pagination={true}
                                paginationPageSize={25}
                            >
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={95} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="CreateFullname" headerName="ชื่อ-นามสกุล" minWidth={260} />
                                <AgGridColumn field="CourseTypeNameTH" headerName="หลักสูตร" sortable={true} minWidth={240} />
                                <AgGridColumn field="ELearnTitle" headerName="รายวิชา" minWidth={370} />
                                <AgGridColumn field="percentage" headerName="ข้อสอบ/แบบประเมิน (%)" minWidth={200} />
                            </AgGridReact>
                            {<div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={25} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
