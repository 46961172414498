import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/activity.svg";
import Footer from "components/Footer.js";
import { Rating } from "@material-ui/lab";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import utils from "utils/utils";
import AttatchmentList from "components/AttatchmentList";
import CommentList from "components/CommentList";
import GalleryContent from "components/GalleryContent";
import JoinActivityForm from "components/JoinActivityForm";
import ActivityEnrollForFriend from "components/ActivityEnrollForFriend";
import ActivityInviteFriend from "components/ActivityInviteFriend";
import { authenticationService } from "services/authentication.service.js";
//https://docs.google.com/spreadsheets/d/1lImmLxPrE-BIxklVC1b99CKRielkVBxnD36R3zUVamc/edit#gid=1454509991
const swal = withReactContent(Swal);
export default function ReadActivity(props) {
  const currentUser = authenticationService.currentUserValue;

  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [activitydata, SetActivityData] = useState({});
  const [canRating, setCanRating] = useState(true);
  const [canEnroll, setCanEnroll] = useState(false);
  const [canInvite, setCanInvite] = useState(false);
  const [canResignEnroll, setCanResignEnroll] = useState(false);
  const [enrollID, setEnrollID] = useState(true);
  const [gallery, setGallery] = useState([]);
  const [ratingavg, setRatingAVG] = useState(0);
  const activityid = props.location.state.id;
  const [userID, setUserID] = useState(null);
  const currDate = new Date(new Date(Date.now()).toDateString());
  const [activityEndate, setActivityEndate] = useState(new Date());
  useEffect(() => {
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    setUserID(userid);
    
    let regStartDate;
    let regEndDate;
    let useInvite = false;
    let approveStatus = -1;
    let actEnddate;
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/activitybyID", {
        params: { id: activityid, userID: userid },
      })
      .then((response) => {
        SetActivityData(response.data[0]);
        setCanRating(response.data[0].canRate);
        setRatingAVG(response.data[0].Rating);
        const newgallery = [...gallery];
        newgallery[0] = response.data[0].ThumbnailWeb;
        setGallery(newgallery);
        let loop = 1;
        for (let i = 0; i < response.data[0].filelist.length; i += 1) {
          let item = response.data[0].filelist[i];
          if (item.AttachmentType === "IMG") {
            newgallery[loop] = response.data[0].filelist[i].AttachmentPath;
            setGallery(newgallery);
            loop++;
          }
        }
        //console.log(response.data[0].Invite)
        if (response.data[0].Invite !== null) {
          useInvite = response.data[0].Invite;
        }
        
        if (response.data[0].ActivityEndDate !== null) {
          actEnddate = new Date(response.data[0].ActivityEndDate);
          setActivityEndate(new Date(response.data[0].ActivityEndDate));
        }
        if (response.data[0].RegisEndDate !== null) {
          //regStartDate = new Date(response.data[0].RegisStartDate);
          regStartDate = new Date(response.data[0].RegisStartDate);
          regEndDate = new Date(response.data[0].RegisEndDate);
          if (currDate <= regEndDate ) {
            setCanEnroll(true);
          }

          if (currDate >= regStartDate && currDate <= actEnddate) {
            setCanEnroll(true);
            if(useInvite)
              setCanInvite(true);                            
          }
          else{
            setCanInvite(false); 
          }
        }
        else{
          setCanEnroll(true);
          if (useInvite) {            
            if (currDate >= regStartDate && currDate <= actEnddate)
              setCanInvite(true);
            else
              setCanInvite(false);
          }
          regEndDate = actEnddate;
        }
        setLoading(false);
      })
      .then(() => {
        
        if (userid != null) {
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/getActivityEnroll", {
              params: { ActivitiesID: activityid, userID: userid },
            })
            .then((result) => {
              //console.log(result.data[0])
              if (typeof result.data[0] !== "undefined") {  //สมัครแล้ว             
                //ไม่กำหนดวันสมัคร
                approveStatus = Number(result.data[0].Approve);
                //console.log(approveStatus)
                if (regEndDate === null) {//ไม่มีการกำหนดวันสมัคร
                  if (currDate <= activityEndate) {//ยึดจากวันสิ้นสุดกิจกรรม
                    setCanEnroll(false);
                    if (Number(approveStatus) === 2)
                      setCanResignEnroll(true);
                    /*if (useInvite) {
                      if (currDate >= regStartDate)
                        setCanInvite(false);
                      else
                        setCanInvite(true);
                    }*/
                  }
                  else {
                    setCanEnroll(false);
                    /*if (currDate >= regStartDate)
                      setCanInvite(false);
                    else
                      setCanInvite(true);*/
                    setCanResignEnroll(false);
                  }
                }
                else {//มีการกำหนดวันสมัคร
                  if (currDate <= regEndDate && currDate >= regStartDate) {
                    setCanEnroll(false);
                    if (Number(approveStatus) === 2)
                      setCanResignEnroll(true);
                    /*if (useInvite)
                    {
                      if (currDate >= regStartDate)
                        setCanInvite(false);
                      else
                        setCanInvite(true);
                    }*/
                  }
                  else {
                    setCanEnroll(false);
                    /*if (currDate >= regStartDate)
                      setCanInvite(false);
                    else
                      setCanInvite(true);*/
                    setCanResignEnroll(false);
                  }
                }
                setEnrollID(result.data[0].EnID);
              }
              else {//ยังไม่สมัคร
                if (regEndDate === null) {//ไม่กำหนดวันสมัคร
                  if (currDate <= activityEndate) {//ปัจจุบันยังไม่สิ้นสุดกิจกรรม
                    setCanEnroll(true);
                    /*if (useInvite)
                    {
                      if (currDate >= regStartDate)
                        setCanInvite(false);
                      else
                        setCanInvite(true);
                    }*/
                  }
                  else {
                    setCanEnroll(false);
                    //setCanInvite(false);
                  }
                }
                else if (currDate <= regEndDate && currDate >= regStartDate) {//มีการกำหนดวันสมัครและยังไม่สิ้นสุดวันสมัคร
                  setCanEnroll(true);
                  /*if (currDate >= regStartDate)
                    setCanInvite(false);
                  else
                    setCanInvite(true);*/
                }
                else{
                  setCanEnroll(false);
                  //setCanInvite(false);
                }
                //console.log(regEndDate)
                if (Number(approveStatus) !== 2)
                  setCanResignEnroll(false);
              }
            });
        }

      });
  }, []); /* <-- add this for run once*/

  const handleRating = (e, id, rating) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("userID", userID);
    formData.append("rating", rating);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateActivityRating",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setCanRating(false);
        setRatingAVG(response.data.avg);
      });
  };

  const handleJoin = () => {
    let timerInterval;
    swal
      .fire({
        title: "กรอกรายละเอียดเข้าร่วมกิจกรรม",
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: true,
        html: <>{<JoinActivityForm activityid={activityid} userid={userID} />}</>,
        backdrop: true,
        allowOutsideClick: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => { });
        } else if (result.isDenied) {
          //swal.fire('Changes are not saved', '', 'info')
        }
      });
  };
  const saveCancleJoin = () => {
    const formData = new FormData();
    formData.append("endID", enrollID);
    formData.append("userID", userID);

    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/activityCancelEnroll",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        let timerInterval;
        swal
          .fire({
            title: "บันทึกข้อมูลการยกเลิกเรียบร้อย",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
              window.location.reload();
            },
          })
          .then((result) => {
            swal.close();
          });
      });
  };
  const handleCancleJoin = () => {
    let timerInterval;
    swal
      .fire({
        title: "ยืนยันการยกเลิกเข้าร่วมกิจกรรม",
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: false,
        html: (
          <>
            <div className="formContent">
              <input
                type="submit"
                className="fadeIn fourth"
                value="ยืนยัน"
                onClick={(e) => {
                  e.preventDefault();
                  saveCancleJoin();
                }}
              />
            </div>
          </>
        ),
        backdrop: true,
        allowOutsideClick: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              //deleteSingleRow(params);
            });
        } else if (result.isDenied) {
          //swal.fire('Changes are not saved', '', 'info')
        }
      });
  };

  const handleforfriendJoin = () => {
    let timerInterval;
    swal
      .fire({
        title: "ค้นหาเพื่อเข้าร่วมกิจกรรมให้เพื่อน",
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: true,
        allowOutsideClick: false,
        html: (
          <>
            <ActivityEnrollForFriend activityid={activityid} />
          </>
        ),
        confirmButtonText: "เข้าร่วม",
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              //deleteSingleRow(params);
            });
        } else if (result.isDenied) {
          //swal.fire('Changes are not saved', '', 'info')
        }
      });
  };

  const handleShare = () => {
    swal
      .fire({
        title: "แชร์ไปยัง",
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: true,
        html: (
          <>
            <ActivityInviteFriend activitydata={activitydata} />
          </>
        ),
      })
      .then((result) => { });
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}

        <ContentBanner
          language={language}
          theader={"กิจกรรม"}
          eheader={"Recent events"}
          bannerkey="ACTIVITY"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {language === "Thai"
                  ? " ประชาสัมพันธ์และกิจกรรม "
                  : " Information news and Activities "}
              </a>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/activity/ActivityList",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "กิจกรรม" : "Activity"}
              </Link>
            </>
          }
        />
      </div>

      <main className="content-page">
        <div className="container">
          <section className="edit-profile ReadActivity">
            <GalleryContent gallery={gallery} />
            <div className="news-activity-data">
              <div className="contentTop">
                <div className="mt-2 mb-2">
                  <span className="time-ago">
                    <i className="far fa-clock" />
                    <p>
                      {" "}
                      {utils.MariatoThaiDateStringShort(
                        activitydata.PublishedStartDate
                      )}
                    </p>
                  </span>
                  <span className="view-total ">
                    <i className="fas fa-eye text-gray" />
                    <p className="text-gray"> {activitydata.Views}</p>
                  </span>
                </div>
                <h1>
                  {language === "Thai"
                    ? activitydata.TitleTH
                    : activitydata.TitleEN}
                </h1>
              </div>
              <div className="listResponsive">
                <ul className="content-list">
                  <li className="list-item">
                    <span className="title">
                      {
                        activitydata.MaxRegis===null?language === "Thai" ? " ไม่จำกัดจำนวนที่นั่ง " : " Unlimited Seats ":language === "Thai" ? " จำนวน " : " Seating capacity "
                      }
                      {activitydata.MaxRegis===null?"":activitydata.MaxRegis}
                      {activitydata.MaxRegis===null?"":language === "Thai"
                        ? activitydata.MaxRegis + " ที่นั่ง "
                        : " Available "}
                    </span>{" "}
                    <span className="detail">
                      {activitydata.MaxRegis===null?"":language === "Thai"
                        ? "ว่าง" +
                        (activitydata.MaxRegis - activitydata.ReserveRegis) +
                        " ที่นั่ง"
                        : "Avaliable " +
                        (activitydata.MaxRegis - activitydata.ReserveRegis)}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai" ? " ลงทะเบียน " : " Registation "}
                    </span>
                    <span className="detail">
                      {activitydata.RegisStartDate===null?language === "Thai"?"ไม่จำกัดเวลา":"With no time limit":language === "Thai"
                        ? utils.MariatoThaiDateOnly(
                          activitydata.RegisStartDate
                        ) +
                        "- " +
                        utils.MariatoThaiDateOnly(activitydata.RegisEndDate)
                        : utils.MariatoEngDateOnly(
                          activitydata.RegisStartDate
                        ) +
                        "- " +
                        utils.MariatoEngDateOnly(activitydata.RegisEndDate)}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai"
                        ? " วันที่จัดกิจกรรม "
                        : " Event Date "}
                    </span>
                    <span className="detail">
                      {language === "Thai"
                        ? utils.MariatoThaiDateString(
                          activitydata.ActivityStartDate
                        ) +
                        "- " +
                        utils.MariatoThaiDateString(
                          activitydata.ActivityEndDate
                        )
                        : utils.MariatoEngDateString(
                          activitydata.ActivityStartDate
                        ) +
                        "- " +
                        utils.MariatoEngDateString(
                          activitydata.ActivityEndDate
                        )}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai" ? " จัดโดย " : " Event By "}{" "}
                    </span>{" "}
                    <span className="detail"> {activitydata.Organizer} </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai" ? " วิทยากร " : " Lecturer "}{" "}
                    </span>{" "}
                    <span className="detail"> {activitydata.Lecturer==='null'?"":activitydata.Lecturer} </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai" ? " เหมาะสำหรับ " : " Suitable for "}{" "}
                    </span>{" "}
                    <span className="detail"> {activitydata.Suitable==='null'?"":activitydata.Suitable} </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai" ? " พิกัดจัดงาน " : " Venue "}{" "}
                    </span>
                    <span className="detail">
                      {activitydata.Location.trim().length > 0 && (
                        <Link
                          className="active"
                          to={{
                            pathname:
                              "https://www.google.com/maps/search/?api=1&query=" +
                              activitydata.Location,
                          }}
                          target="_blank"
                        >
                          {activitydata.Location}
                        </Link>
                      )}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai"
                        ? " Link แบบสอบถาม "
                        : " Link to questionnaire "}{" "}
                    </span>
                    <span className="detail">
                      {activitydata.QuestionaireLink.trim().length > 0 && (
                        <a
                          className="active"
                          target="_blank"
                          rel="noreferrer"
                          href={activitydata.QuestionaireLink}
                        >
                          {activitydata.QuestionaireLink}
                        </a>
                      )}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="title">
                      {language === "Thai"
                        ? " Link การถ่ายทอดสด "
                        : " Link to live "}{" "}
                    </span>
                    <span className="detail">
                      {activitydata.BroadCastLink.trim().length > 0 && (
                        <a
                          className="active"
                          target="_blank"
                          rel="noreferrer"
                          href={activitydata.BroadCastLink}
                        >
                          {activitydata.BroadCastLink}
                        </a>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="formContent">
                {currentUser !== null && canResignEnroll === true && (
                  // <div className="formContent">
                    <input
                      type="submit"
                      className="fadeIn fourth btn"
                      value={language === "Thai" ? " ไม่เข้าร่วม " : " Cancel "}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCancleJoin();
                      }}
                    />
                  // </div>
                    )}

                {currentUser !== null && canEnroll === true && (
                  <>
                    <input
                      type="submit"
                      className="fadeIn fourth btnPimary"
                      disabled={!canEnroll}
                      value={language === "Thai" ? " เข้าร่วม " : " Join "}
                      onClick={(e) => {
                        e.preventDefault();
                        handleJoin();
                      }}
                    />                    
                  </>
                )}
                {currentUser !== null && canInvite === true && (
                  <>
                    <input
                      type="submit"
                      className="fadeIn fourth btnPimary"
                      value={
                        language === "Thai"
                          ? " เข้าร่วมให้เพื่อน "
                          : " Invite friends to join "
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        handleforfriendJoin();
                      }}
                    />
                  </>
                )}
                {(currentUser !== null && currDate <= activityEndate) && (
                  <input
                    type="submit"
                    className="fadeIn fourth btnPimary"
                    value={language === "Thai" ? " แชร์ " : " Share "}
                    onClick={(e) => {
                      e.preventDefault();
                      handleShare();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="content-data">
              <div className="row">
                <div className="col-12">
                  <div className="tabbable-panel">
                    <div className="tabbable-line">
                      <div className="nav nav-tabs ">
                        <a
                          href="#tab_default_1"
                          className="active"
                          data-toggle="tab"
                        >
                          {language === "Thai"
                            ? " รายละเอียด "
                            : " Desciption "}{" "}
                        </a>
                        <a href="#tab_default_2" data-toggle="tab">
                          {language === "Thai"
                            ? " เอกสารประกอบ "
                            : " Documentation "}{" "}
                        </a>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab_default_1">
                          <div className="cardDetail">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  language === "Thai"
                                    ? activitydata.DescriptionTH
                                    : activitydata.DescriptionEN.trim()
                                      .length === 0
                                      ? activitydata.DescriptionTH
                                      : activitydata.DescriptionEN,
                              }}
                            />
                          </div>
                        </div>
                        <div className="tab-pane" id="tab_default_2">
                          <AttatchmentList fileList={activitydata.filelist} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <span className="rating-text">Ratings :</span>
                  <Rating
                    name="half-rating-read"
                    value={ratingavg}
                    precision={1.0}
                    readOnly={currentUser == null || canRating === false}
                    onChange={(event, newValue) => {
                      handleRating(event, activitydata.ActivitiesID, newValue);
                    }}
                  />
                  <div className="rating-text" style={{ color: '#ffb400', marginLeft: '15px', fontSize: 20 }}>{ratingavg === 0 ? ' - ' : ratingavg.toFixed(2).toString()}</div>
                  {activitydata.Comment !== 2 && (
                    <>
                      <div className="sub-title mt-5">
                        <h2 className="thai-header">
                          <span className="orang content-underline">
                            {language === "Thai" ? "ความคิดเห็น" : "Comment"}
                          </span>
                        </h2>
                      </div>
                      <CommentList
                        sourceid={activitydata.ActivitiesID}
                        sourcetype={"ACTIVITY"}
                        mode={activitydata.Comment}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
