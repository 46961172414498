import React from 'react';
import utils from 'utils/utils';
export default function SingleFileTable(props) {
    //console.log(props);
    const language = localStorage.getItem("language");
    let filename = ".";
    if (props.filedata != null) {
        if (props.filedata.name != null)
            filename = props.filedata.name.substring(props.filedata.name.lastIndexOf('/') + 1)
    }
    if(filename === "."){
        return (<></>);
    }
    return (
        <>
            <h4>Files</h4>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>ลำดับ</th>
                            <th>ชื่อ</th>
                            <th>ขนาดไฟล์</th>
                            <th>ประเภทไฟล์</th>
                            <th>จัดการ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(props.filedata != null) && (<tr key={1}>
                            <td>{1}</td>
                            <td>{filename.split('.')[0]}</td>
                            <td>{utils.Bytes2Size(props.filedata.size)}</td>
                            <td>{filename.split('.').pop()}</td>
                            <td>
                                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={props.filedata.preview} rel="noreferrer" target="_blank">ดู</a>
                                {props.removefn!=null&&(<button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); props.removefn(); }}>ลบ</button>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </>
    );
}