import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import { authenticationService } from "services/authentication.service.js";

export default function ViewNetwork(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [networkdata, SetNetworkData] = useState({});
  const [activityList, setActivity] = useState([]);
  const [activeActivity, SetActiveActivity] = useState({});
  const [activeActivityID, setActiveActivityID] = useState(0);
  const [address, setAddress] = useState("");
  const [interrestTopic, setInterrestTopic] = useState([]);
  /*const [provinceList, SetProvinceList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);*/

  // let interrestTopic = [];
  let provinceList = [];
  let districtList = [];
  let subDistrictList = [];
  const networkID = props.location.state.networkID;

  useEffect(() => {
    //console.log(props.location.state.networkID)
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getProvince")
      .then((response) => {
        provinceList = [...response.data];
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getDistrict")
          .then((response) => {
            districtList = [...response.data];
            axios
              .get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict")
              .then((response) => {
                subDistrictList = [...response.data];
                axios
                  .get(
                    process.env.REACT_APP_APIURL +
                    "/api-web/corporateNetworkbyID",
                    { params: { id: networkID } }
                  )
                  .then((response) => {
                    let data = response.data[0];
                    //console.log(data)
                    SetNetworkData(data);
                    setActivity(data.networkactivity);
                    if (data.networkactivity.length > 0) {
                      setActiveActivityID(
                        data.networkactivity[0].CorporateNetworkActivityID
                      );
                      SetActiveActivity(data.networkactivity[0]);
                      //console.log(data.networkactivity[0])
                    }
                    const province = provinceList.find((item) => {
                      return (
                        Number(item.ProvinceID) === Number(data.NetProvinceID)
                      );
                    });
                    const district = districtList.find((item) => {
                      return (
                        Number(item.DistrictID) === Number(data.NetDistrictID)
                      );
                    });
                    const subDistrict = subDistrictList.find((item) => {
                      return (
                        Number(item.SubDistrictID) ===
                        Number(data.NetSubDistrictID)
                      );
                    });
                    let tmpaddress = "เลขที่ " + data.NetHouseNo;
                    if (Number(data.NetProvinceID) === 1) {
                      tmpaddress += " แขวง" + subDistrict.SubDistrictTH;
                      tmpaddress += " เขต" + district.DistrictTH;
                      tmpaddress += province.ProvinceTH;
                    } else {
                      tmpaddress += " ตำบล" + subDistrict.SubDistrictTH;
                      tmpaddress += " อำเภอ" + district.DistrictTH;
                      tmpaddress += " จังหวัด" + province.ProvinceTH;
                    }
                    tmpaddress += " " + data.NetPostCode;
                    setAddress(tmpaddress);
                    axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberInterestTopic')
                      .then((response) => {
                        setInterrestTopic(response.data)
                        setLoading(false);
                      });
                  });
              });
          });
      });
  }, []); /* <-- add this for run once*/
  
  const ActivityOptionList = activityList.map((item, i) => (
    <option value={item.CorporateNetworkActivityID} key={i}>
      {"ปี " + String(Number(item.Year))}
    </option>
  ));

  const UpdateActivityDetail = () => {
    const activeActivity = activityList.find((item) => {
      return (
        Number(item.CorporateNetworkActivityID) === Number(activeActivityID)
      );
    });
    SetActiveActivity(activeActivity);
  };

  function checkHasTopic(topicstr, targetid) {
    if(!topicstr) return false;
    const myArray = topicstr.split(",");
    if (myArray.indexOf(targetid.toString()) >= 0) return true;
    else return false;
  }


  const ActivityDetail = typeof activeActivity !== "undefined" && (
    <>
      <div className="subheader">ประเด็นการดำเนินงานด้านสิทธิมนุษยชน</div>
      <div className="formMaxwidth mb-3">
        <ol style={{ paddingLeft: 25 }}>
        {interrestTopic.map(function (item) {
          return checkHasTopic(activeActivity.ToppicTypeIDList, item.InterestTopicID)
            ? <li className="detail" key={item.InterestTopicID}>{item.TextTH}</li>
            : "";
        })}
      </ol>
      </div>
      <div className="subheader col-12">ชื่อผลการดำเนินงาน</div>
      <div className="formMaxwidth mb-4">
        <div className="detail">{activeActivity.ActivityName}</div>
      </div>
      <div className="subheader col-12">รายละเอียดผลงาน ( โดยย่อ )</div>
      <div className="formMaxwidth mb-4">
        <div className="detail">{activeActivity.ActivityDetail}</div>
      </div>
    </>
  );
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/network/NetworkList",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ"
                  : "Cooperationnetwork and Profesional Council"}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/ViewNetwork",
                  state: { networkID: networkID },
                }}
              >
                {networkdata.NetOrganization}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile ViewEducationCord">
            <div className="news-activity-data">
              <div className="sub-title">
                <h2 className="thai-header text-center">
                  <span className="orang content-underline">
                    {networkdata.NetOrganization}
                  </span>
                </h2>
              </div>
            </div>
            <div className="content-data">
              <div className="topDetailNetwork">
                <div className="row">
                  <div className="col-md-5">
                    <div className="logo-img">
                      {networkdata.LogoImagePath !== null && (
                        <img src={networkdata.LogoImagePath} alt="logo" />
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h2 className="header text-dark-blue">
                      {networkdata.NetOrganization}
                    </h2>
                    <ul className="list-icon listnoMargin">
                      <li className="list-icon">
                        <i className="fas fa-map-marker-alt text-dark-blue mr-2"></i>
                        <span className="title">ที่อยู่ :</span>{" "}
                        <span className="detail">{address}</span>
                      </li>
                      <li className="list-icon">
                        <i className="fas fa-phone-alt text-dark-blue mr-2"></i>
                        <span className="title">โทรศัพท์ :</span>{" "}
                        <span className="detail">{networkdata.NetPhone}</span>
                      </li>
                      <li className="list-icon">
                        <i className="far fa-envelope text-dark-blue mr-2"></i>
                        <span className="title">อีเมล :</span>{" "}
                        <span className="detail">{networkdata.NetEmail}</span>
                      </li>
                      {/*<li className="list-icon"><i className="fas fa-external-link-alt text-dark-blue mr-2"></i><span className="title">ลิงก์ที่เกี่ยวข้อง :</span> <span className="detail">{networkdata.NetHouseNo}</span></li>
                                    <li className="list-icon"><i className="far fa-map text-dark-blue mr-2"></i><span className="title">แผนที่ </span> <span className="detail">{networkdata.NetHouseNo}</span></li>*/}
                    </ul>
                    {activityList.length > 0 && (
                      <div className="formContent maxWidth270">
                        <select
                          name="activeActivityID"
                          id="activeActivityID"
                          className="custom-select"
                          value={activeActivityID}
                          onChange={(e) => {
                            setActiveActivityID(e.currentTarget.value);
                            UpdateActivityDetail();
                          }}
                        >
                          {ActivityOptionList}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {activityList.length > 0 && (
                <div className="">{ActivityDetail}</div>
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
