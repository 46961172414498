import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import networkcorpico from "assets/images/icon/networkcorp.svg";
import networkMOUico from "assets/images/icon/networkMOU.svg";
import networkEducoreico from "assets/images/icon/networkEducore.svg";
import utils from "utils/utils";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { authenticationService } from "services/authentication.service.js";
import noimage from 'assets/images/no_image.svg';
import $ from 'jquery';
import { FakeHorizontalScrollController } from "@ag-grid-community/core/dist/cjs/gridBodyComp/fakeHorizontalScrollController";

export default function HomeContent(props) {
  const history = useHistory();
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  const [newsList, SetNewsList] = useState([]);
  const [activityList, SetActivityList] = useState([]);
  const [elibList, setELibList] = useState([]);
  const [eLearningList, setELearningList] = useState([]);
  /*const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [banner, setBanner] = useState([]);*/
  const [popupList, setPopupList] = useState([]);
  const [popupIndex, setPopupIndex] = useState(0);
  const [imgWebCover, setIMGWebCover] = useState([]);
  const [thname, setNameTH] = useState('');
  const [thcontent, setTHContent] = useState("");
  const [enname, setNameEN] = useState('');
  const [encontent, setENContent] = useState("");
  const [urlLink, setURLLink] = useState('');
  const monthNamesTH = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  const monthNamesEN = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayTH = [
    "อาทิตย์",
    "จันทร์",
    "อังคาร",
    "พุธ",
    "พฤหัสบดี",
    "ศุกร์",
    "เสาร์",
  ];
  const dayEN = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  useEffect(() => {
    const formData = new FormData();
    formData.append("search", "");
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/newsListforFirstPage",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        SetNewsList(response.data);
      });
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/activityListforFirstPage",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        SetActivityList(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getElibraryforFirstPage")
      .then((response) => {
        setELibList(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/ESubjectforFirstPage")
      .then((response) => {
        setELearningList(response.data);
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getPopupPublic', { params: { popupType: 1 } }).then((response) => {
          setPopupList(response.data);
          //console.log(response.data)
          if(response.data.length > 0){
            let data = response.data[0];
            setNameTH(data.TitleTH);
            setTHContent(data.DescriptionTH);
            setNameEN(data.TitleEN);
            setENContent(data.DescriptionEN);
            setURLLink(data.URL);
            if (data.ImageWeb !== null) {
              if (data.ImageWeb.trim().length > 0) {
                const webimageFile = {
                  file: null,
                  name: null,
                  preview: data.ImageWeb,
                  size: null
                };
                setIMGWebCover(webimageFile);
              }
              else {
                const webimageFile = {
                  file: null,
                  name: null,
                  //preview: 'undefined',
                  size: null
                };
                setIMGWebCover(webimageFile);
              }
            }
            if (currentUser === null) {
              $('#ModalCarousel').modal('show');
            }
          }
        })
        //console.log(response.data)
      });
    /*test */
    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/searchBanner', { params: { status: 1 } })
      .then((response) => {
        setBanner(response.data);
        //console.log(response.data);
      });*/
  }, []); /* <-- add this for run once*/
  const WebCoverPreview = (
    <div className="col-12 text-center">
        <img className="preview-upload" src={typeof imgWebCover.preview === 'undefined' ? noimage : imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt={thname} style={{height:'550px'}}/>
    </div>
);
  const readNewsData = (id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateNewsViews",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        history.push({ pathname: "/news/ReadNews/" + id, state: { id: id } });
      });
  };
  const NewList = () => {
    let div = <></>;
    let subdiv = <></>;
    for (let i = 0; i < newsList.length && i < 4; i++) {
      let item = newsList[i];

      if (i === 0) {
        div = (
          <div className=" col-md-6 col-sm-12 col-xs-12">
            <div id="new-hilight" className="card">
              <div className="cardImg">
                <a href="">
                  <img
                    className="card-img-top"
                    src={item.ThumbnailWeb}
                    alt="New Cover"
                    onClick={(e) => {
                      e.preventDefault();
                      readNewsData(item.NewsID);
                    }}
                  />
                </a>
              </div>
              <div className="card-body">
                <h5
                  className="card-title"
                  onClick={(e) => {
                    e.preventDefault();
                    readNewsData(item.NewsID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {language === "Thai" ? item.TitleTH : item.TitleEN}
                </h5>
                <div className="content-info mb-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        language === "Thai"
                          ? item.ShortDetailTH
                          : item.ShortDetailEN,
                    }}
                  />
                </div>
                <div>
                  <span className="time-ago">
                    <i className="far fa-clock" />
                    <p style={{ fontStyle: "italic" }}>
                      {" "}
                      {utils.getTimeAgo(new Date(item.PublishedStartDate))}
                    </p>
                  </span>
                  <span className="view-total float-right">
                    <i className="fas fa-eye" />
                    <p style={{ fontSize: 19 + "px", color: "#333" }}>
                      {" "}
                      {item.Views}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        //console.log(item)
        let newdiv = (
          <li className="news-list">
            <div className="img-headline">
              <img src={item.ThumbnailWeb} alt="New Cover" />
            </div>
            <div className="news-content">
              <p
                className="news-title"
                onClick={(e) => {
                  e.preventDefault();
                  readNewsData(item.NewsID);
                }}
                style={{ cursor: "pointer" }}
              >
                {language === "Thai" ? item.TitleTH : item.TitleEN}
              </p>
              <p className="news-date">
                {utils.MariatoThaiDateStringShort(
                  new Date(item.PublishedStartDate)
                )}
              </p>
            </div>
          </li>
        );
        subdiv = (
          <>
            {subdiv}
            {newdiv}
          </>
        );
      }
    }
    div = (
      <>
        {div}
        <div className=" col-md-6 col-sm-12 col-xs-12">
          <ul id="news-list">{subdiv}</ul>
        </div>
      </>
    );
    return div;
  };
  function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  const readActivityData = (id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateActivityViews",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        history.push({
          pathname: "/activity/ReadActivity/" + id,
          state: { id: id },
        });
      });
  };
  const ActivityList = () => {
    let div = <></>;
    for (let i = 0; i < activityList.length && i < 3; i++) {
      let item = activityList[i];
      let now = new Date(Date.now());
      let startDate = new Date(item.ActivityStartDate);
      let endDate = new Date(item.ActivityEndDate);
      let classStr = "blue";
      let bgclassStr = "blue";
      let strText = "มีกิจกรรมวันนี้";      
      if (now > endDate) {
        classStr = "blue";
        bgclassStr = "bg-gray";
        strText = language === "Thai" ? "เสร็จสิ้นแล้ว" : "COMPLETE";
      } else {
        classStr = "gray";
        bgclassStr = "bg-cyan text-white";
        strText = language === "Thai" ? "มีกิจกรรมวันนี้" : "TODAY";
      }
      if (now < startDate) {
        classStr = "orange";
        bgclassStr = "bg-orange";
        strText = language === "Thai" ? "เร็ว ๆ นี้" : "SOON";
      }
      if (startDate < now && endDate >= now)
        startDate = now;
      let monthName =
        language === "Thai"
          ? monthNamesTH[startDate.getMonth()]
          : monthNamesEN[startDate.getMonth()];
      let dayName =
        language === "Thai"
          ? dayTH[startDate.getDay()]
          : dayEN[startDate.getDay()];
      let yearName = language === "Thai"
      ? (Number(startDate.getFullYear())+543).toString()
      : startDate.getFullYear();
      let numOfDay = padLeadingZeros(startDate.getDate(), 2);
      let shour = startDate.getHours().toString().padStart(2, "0");
      let sminutes = startDate.getMinutes().toString().padStart(2, "0");
      let ehour = endDate.getHours().toString().padStart(2, "0");
      let eminutes = endDate.getMinutes().toString().padStart(2, "0");

      let newdiv = (
        <li className={classStr + " activity-item"}>
          <div className="activity-content">
            <div className="activity-calendar">
              <div className={classStr + " calendar-leftborder"}></div>
              <h5 style={{ fontSize: 16, marginTop: 7 }}>{monthName}</h5>
              <div className="activity-day">{numOfDay}</div>
              <div className="plus-icon">
                <i className="fas fa-plus" />
              </div>
              <p className="activity-date">{dayName+' '+yearName}</p>
              <span className="time-ago">
                <i className="far fa-clock" />
                <p>
                  {" "}
                  {shour}:{sminutes}-{ehour}:{eminutes}
                </p>
              </span>
            </div>
            <div className="activity-data">
              <p
                className="activity-title mb-2"
                onClick={(e) => {
                  e.preventDefault();
                  readActivityData(item.ActivitiesID);
                }}
                style={{ cursor: "pointer" }}
              >
                {language === "Thai" ? item.TitleTH : item.TitleEN.trim().length===0?item.TitleTH:item.TitleEN}
              </p>
              <label className={bgclassStr}>{strText}</label>
            </div>
          </div>
        </li>
      );
      div = (
        <>
          {div}
          {newdiv}
        </>
      );
    }
    return div;
  };
  const readElibData = (id) => {
    let userID = null;
    if (currentUser !== null) userID = currentUser.UserID;
    history.push({
      pathname: "/authen/elibrary/Read/" + id,
      state: { id: id, userID: userID },
    });
  };
  const ELibList = () => {
    let div = <></>;
    for (let i = 0; i < elibList.length && i < 4; i++) {
      let item = elibList[i];
      //let now = new Date(Date.now());
      let startDate = new Date(item.PublishedStartDate);
      //let endDate = new Date(item.PublishedEndDate);
      let newdiv = (
        <div className="col-md-6 col-xs-12">
          <div className="card knowladge-list">
            <img
              className="card-img-top"
              src={item.ThumnailWeb}
              alt="ELibrary Cover"
            />
            <div className="card-body">
              <p
                className="card-text"
                onClick={(e) => {
                  e.preventDefault();
                  readElibData(item.BookID);
                }}
                style={{ cursor: "pointer" }}
              >
                {language === "Thai" ? item.BookName : item.BookNameEN}
              </p>
              <span className="time-ago">
                <i className="far fa-clock" />
                <p style={{ fontStyle: "italic" }}>
                  {" "}
                  {utils.getTimeAgo(startDate)}
                </p>
              </span>
              <div style={{ marginTop: -15 }}>
                {/*<span className="view-total float-right"><i className="fas fa-share-alt"></i><p> 89</p></span>
                            <span className="view-total float-right"><i className="fas fa-heart" /><p> 128</p></span>*/}
                <span className="view-total float-right">
                  <i className="fas fa-eye" />
                  <p> {item.Views}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      div = (
        <>
          {div}
          {newdiv}
        </>
      );
    }
    return div;
  };
  const readELearningData = (id) => {
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    history.push({
      pathname: "/authen/elearning/ViewElearning/" + id,
      state: { id: id, userID: userid },
    });
  };
  function selectPopup(item){
    let data = item;
    setNameTH(data.TitleTH);
    setTHContent(data.DescriptionTH);
    setURLLink(data.URL);
    if (data.ImageWeb !== null) {
      if (data.ImageWeb.trim().length > 0) {
        const webimageFile = {
          file: null,
          name: null,
          preview: data.ImageWeb,
          size: null
        };
        setIMGWebCover(webimageFile);
      }
      else {
        const webimageFile = {
          file: null,
          name: null,
          //preview: 'undefined',
          size: null
        };
        setIMGWebCover(webimageFile);
      }
    }      
  }
  const popupContent = popupList.map((popupItem, index) => (
    <li key={"popup-"+index} data-target="#carousel-example-generic" data-slide-to="0" onClick={(e)=>{e.preventDefault();setPopupIndex(index);selectPopup(popupItem);}} className={Number(popupIndex) === Number(index) ? "active" : ""}></li>    
  ));
  const ELearningList = () => {
    let subdata1 = eLearningList.map((item) => (
      <div className="col-md-4 mt-3 mb-3" key={item.ELearnID}>
        <div className="card subject">
          {Number(item.CoverType) === 1 && (
            <div className="subject-cover">
              <img
                src={item.ThumnailWeb}
                className="cover"
                alt="Subject cover"
              />
            </div>
          )}
          {Number(item.CoverType) === 2 && (
            <div className="subject-cover">
              <div className="player-wrapper">
                <ReactPlayer
                  url={item.ThumnailWeb}
                  width="100%"
                  height="260px"
                  controls={true}
                />
              </div>
            </div>
          )}
          <div className="subject-data">
            <div
              className="card-body"
              onClick={(e) => {
                e.preventDefault();
                readELearningData(item.ELearnID);
              }}
              style={{ cursor: "pointer" }}
            >
              <h4 className="subject-title">
                {language === "Thai" ? item.ELearnTitle : item.ELearnTitleEN}
              </h4>
              <div className="subject-detail text-gray">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "Thai"
                        ? item.CourseDetail
                        : item.CourseDetailEN,
                  }}
                ></div>
              </div>
              {/*<div className="progress">
                                <div className="progress-bar bg-warning" style={{ width: ((item.MaxEnroll-item.CurrentEnroll) * 100 / item.MaxEnroll) + '%' }}></div>
                            </div>
                            <div className="subject-enroll text-center">
                                <label>จำนวนที่ว่าง  <span style={{ fontSize: 3.5 + 'rem', color: '#FAAB3E', fontWeight: 'bold' }}>{(item.MaxEnroll-item.CurrentEnroll)}</span>  / {item.MaxEnroll}</label>
                </div>
                            <div className="card-footer bg-transparent">
                                <div className="subjectbutton"> </div>
                                <span style={{display: 'inline-flex', lineHeight: 1+'rem', verticalAlign: 'bottom'}}><p> {item.CourseTypeNameTH}</p></span>
                            </div>*/}
              <p
                className="card-text"
                onClick={(e) => {
                  e.preventDefault();
                  readELearningData(item.ELearnID);
                }}
                style={{ cursor: "pointer" }}
              >
                {item.BookName}
              </p>
              <span className="time-ago">
                <i className="far fa-clock" />
                <p> {utils.getTimeAgo(new Date(item.PublishedStartDate))}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
    return <div className="overflow-chapter">{subdata1}</div>;
  };
  return (
    <div className="container">
      <section data-section="news">        
        <div id="news-list-wrap" className="row">
          <div className="col-lg-12 col-xl-8 col-md-12 col-xs-12 just768pxDisplay">
            <div style={{ marginLeft: 0, position: "relative" }}>
              <h1 className="thai-header">ข่าวประชาสัมพันธ์</h1>
              <h1 className="eng-header">
                <span className="orang underline">
                  Information <b>news</b>
                </span>
              </h1>
            </div>
            <div>
              <div
                className="viewall"
                onClick={(e) => {
                  history.push({
                    pathname: "/news/NewsList",
                    state: { language: language },
                  });
                }}
              >
                <span>{language === "Thai" ? 'ดูทั้งหมด' : 'All Content'}</span>
              </div>
            </div>
            <div className="row mt-5">
              <NewList />
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 col-md-12 col-xs-12 homeColActivity">
            <div className="NotcssInline">
              <h1 className="thai-header">กิจกรรม</h1>
              <h1 className="eng-header">
                <span className="text-gray orang underline">Recent</span>
                <span className="text-blue"> events</span>
              </h1>
            </div>
            <div
              className="viewall"
              onClick={(e) => {
                history.push({
                  pathname: "/activity/ActivityList",
                  state: { language: language },
                });
              }}
            >
              <span>{language === "Thai" ? 'ดูทั้งหมด' : 'All Content'}</span>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <ul id="activity-list">
                  <ActivityList />
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>

      <section data-section="knowladge">
        <div id="knowladge-lib" className="colorlib-narrow-content">
          <div className="container">
            <div className="controlKnowladge">
              <div id="knowkadge-wrap" className="row">
                <div id="knowladge-tool" className="col-12 col-lg-3">
                  <h1 className="thai-header text-white">คลังความรู้</h1>
                  <h1 className="eng-header text-white orang underline">
                    Knowledge <br />
                    center
                  </h1>
                  {/* <div style={{ position: "relative" }}>
                    <h1 className="eng-header text-white">
                      <span className="orang underline">center</span>
                    </h1>
                  </div> */}
                  <div
                    className="viewall text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({ pathname: "/authen/elibrary/ELibrary" });
                    }}
                  >
                    <span>{language === "Thai" ? 'ดูทั้งหมด' : 'All Content'}</span>
                  </div>
                  <ul>
                    <li>
                      <a
                        className="knowladge-button"
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: "/authen/elibrary/ELibrary",
                            state: { language: language, BookCatID: 46 },
                          });
                        }}
                      >
                        {language === "Thai" ? "สื่อสิ่งพิมพ์" : "Publications"}
                      </a>
                    </li>
                    <li>
                      <a
                        className="knowladge-button"
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: "/authen/elibrary/ELibrary",
                            state: { language: language, BookCatID: 57 },
                          });
                        }}
                      >
                        {language === "Thai" ? "มัลติมีเดีย" : "Multimedia"}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="row" id="knowladge-list">
                    <ELibList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-section="knowladge">
        <div className="colorlib-narrow-content">
          <div className="container">
            <div id="knowkadge-wrap" className="row">
              <div id="elearning-tool" className="col-12 col-lg-3">
                <div style={{ position: "relative", marginTop: "70%" }}>
                  <h1 className="thai-header text-dark">อีเลิร์นนิง</h1>
                  <h1 className="eng-header text-darkblue orang underline">
                    E-learning
                    <br />
                    center
                  </h1>
                  {/* <h1
                    className="eng-header text-darkblue Noinline"
                    style={{ marginTop: -30 }}
                  >
                    <span className="orang underline">center</span>
                  </h1> */}
                </div>
                <div
                  className="viewall-gray text-gray"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({ pathname: "/authen/elearning/ELearning" });
                  }}
                >
                  <span>{language === "Thai" ? 'ดูทั้งหมด' : 'All Content'}</span>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="row" id="knowladge-list">
                  <ELearningList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-section="knowladge">
        <div className="colorlib-narrow-content">
          <div className="container">
            <div id="knowkadge-wrap">
              <h1 className="text-center formMargin">
                เครือข่ายด้านสิทธิมนุษยชน
              </h1>
              <div className="listKnowledge row">
                <div
                  className="col-md-4 col-xs-12"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({ pathname: "/authen/network/NetworkList" });
                  }}
                >
                  <div className="boxListKnowledge">
                    <div className="icon">
                      <img
                        src={networkcorpico}
                        alt="องค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ"
                      />
                    </div>
                    <h2>องค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ</h2>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div
                    className="boxListKnowledge"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({ pathname: "/authen/network/SearchMOU" });
                    }}
                  >
                    <div className="icon">
                      <img
                        src={networkMOUico}
                        alt="หน่วยงานตามบันทึกข้อตกลงความร่วมมือ MOU"
                      />
                    </div>
                    <h2>หน่วยงานตามบันทึกข้อตกลงความร่วมมือ MOU</h2>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div
                    className="boxListKnowledge"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: "/authen/network/EducationCoordinate",
                      });
                    }}
                  >
                    <div className="icon">
                      <img
                        src={networkEducoreico}
                        alt="สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค"
                      />
                    </div>
                    <h2>สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติในภูมิภาค</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      
      <>
        <div className="modal fade" id="ModalCarousel" tabIndex="-1" role="dialog" aria-labelledby="ModalCarouselLabel" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title col-12 text-center">{language === "Thai" ?thname:enname}</h1>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '-2rem' }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ">
                {WebCoverPreview}
                <div className="col-12 ">

                  <div className="customUIPro"
                    dangerouslySetInnerHTML={{
                      __html: language === "Thai" ?thcontent:encontent,
                    }}
                  />
                  <Link
                    id="profile-btn"
                    className="active"
                    to={{
                      pathname: urlLink,
                    }}
                  >
                    {urlLink}
                  </Link>
                </div>
              </div>
              <div className="modal-footer" style={{ minHeight: '40px' }}>
                <ol className="popup-indicators">
                  {popupContent}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </>
    </div >
  );
}
