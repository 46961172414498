import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { scroller } from "react-scroll";
import "moment/locale/th";
import "react-summernote/dist/react-summernote.css"; // import styles
import { authenticationService } from "services/authentication.service.js";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AdminBanner from "components/Banner/AdminBanner";
import SError from "components/SError";
import { Button } from "@material-ui/core";
const swal = withReactContent(Swal);

export default function EditContactUsType(props) {
  const currentUser = authenticationService.currentUserValue;
  const [nameth, setNameTH] = useState("");
  const [nameen, setNameEN] = useState("");
  const [status, setStatus] = useState(-1);
  const [errors, setError] = useState({});
  const [email, setEmail] = useState([""]);
  const newData = [""];
  useEffect(() => {
    //console.log(props.location.state.id)
    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/contactUsTypebyID', { params: { id: typeID } })
            .then((response) => {
                let data = response.data[0];
                console.log(data)
                setNameTH(data.ContactTypeNameTH);
                if (data.ContactTypeNameEN !== null)
                    setNameEN(data.ContactTypeNameEN);
                setStatus(data.Status === true ? 1 : 0);
                //console.log(data.Status)
            });*/
  }, []); /* <-- add this for run once*/
  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    if (nameth.trim().length === 0) {
      formIsValid = false;
      serrors["nameth"] = "กรุณากรอกข้อมูล";
    }
    if (Number(status) === -1) {
      formIsValid = false;
      serrors["status"] = "กรุณากำหนดสถานะการเผยแพร่ข้อมูล";
    }
    setError(serrors);
    console.log(status);
    return formIsValid;
  };
  const saveData = (open) => {
    if (handleValidation()) {
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/savecontactUsType",
          {
            titleth: nameth,
            titleen: nameen,
            status: status,
            userID: currentUser.UserID,
            email: email,
          },
          {}
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              if (open) {
                window.location.reload();
              } else {
                props.history.push({ pathname: "/admin/contact/ContactType" });
              }
            });
        });
    } else {
      scroller.scrollTo("error", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };
  const cancel = () => {
    props.history.push({ pathname: "/admin/contact/ContactType" });
  };

  const handleAdd = () => {
    setEmail([...email, ...newData]);
  };
  const removeItem = (index) => {
    let temp = email.filter((item, key) => {
      if (key !== index) {
        return item;
      }
    });
    if (temp.length === 0) {
      setEmail([""]);
    } else {
      setEmail([...temp]);
    }
  };

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"จัดการการติดต่อ"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              to={{ pathname: "/admin/contact/ContactType" }}
            >
              {"จัดการการติดต่อ"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/contact/NewContactUsType" }}
            >
              {"สร้าง"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="row">
          <div className="col-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="tab1-tab"
                  data-toggle="tab"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  TH
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab2-tab"
                  data-toggle="tab"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  EN
                </a>
              </li>
            </ul>
            <div className="tab-content pl-4 pr-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <div className="form-group">
                  <label htmlFor="nameth" className="required">
                    ชื่อกลุ่ม (ภาษาไทย)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameth"
                    name="nameth"
                    placeholder="กรุณากรอก"
                    value={nameth}
                    onChange={(e) => {
                      setNameTH(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["nameth"]} />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="tab2"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <div className="form-group">
                  <label htmlFor="enname">ชื่อกลุ่ม (ภาษาอังกฤษ)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="enname"
                    name="enname"
                    placeholder="กรุณากรอก"
                    value={nameen}
                    onChange={(e) => {
                      setNameEN(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="pl-4 pr-4">
              <label htmlFor="status" className="required mr-2">
                สถานะ
              </label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="status1"
                  value="1"
                  checked={Number(status) === 1}
                  onChange={(e) => {
                    setStatus(1);
                  }}
                />
                <label className="form-check-label" htmlFor="status1">
                  เผยแพร่
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="status2"
                  value="0"
                  checked={Number(status) === 0}
                  onChange={(e) => {
                    setStatus(0);
                  }}
                />
                <label className="form-check-label" htmlFor="status2">
                  ไม่เผยแพร่
                </label>
              </div>
              <SError error={errors["status"]} />
            </div>
          </div>

          {email.map((item, index) => {
            return (
              <div className="col-8" key={index}>
                <div className="pl-4 pr-4">
                  <div className="form-group">
                    <label htmlFor="enname">E-mail</label>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="กรุณากรอก"
                        value={item}
                        onChange={(e) => {
                          const newItems = [...email];
                          newItems[index] = e.target.value;
                          setEmail(newItems);
                        }}
                      />
                      {index === 0 ? (
                        <div className="col-4">
                          <Button
                            variant="contained"
                            onClick={() => handleAdd()}
                          >
                            add
                          </Button>
                        </div>
                      ) : (
                        <div className="col-4">
                          <Button
                            variant="contained"
                            onClick={() => removeItem(index)}
                          >
                            remove
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="col-12">
            <div className="pl-4 pr-4 text-center margintop40">
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(false);
                }}
              >
                <i className="fas fa-save"></i> บันทึก
              </button>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
              >
                <i className="fas fa-save"></i> บันทึกและเปิด
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => {
                  e.preventDefault();
                  cancel();
                }}
              >
                <i className="far fa-times-circle"></i> ยกเลิก
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
