import React, { useCallback, useEffect, useState, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { authenticationService } from 'services/authentication.service.js';
import '../../assets/styles/navigation.css';
import logo from '../../assets/images/logo.svg';
import avatar from '../../assets/images/icon/user.svg';
import searchicon from '../../assets/images/icon/searchbutton.svg';
import NavbarDropdown from "./NavbarDropdown.js";
import utils from 'utils/utils';

const searchstyle = {
  width: 220,
  height: 40,
};
const iconstyles = {
  display: 'inline-flex',
  textAlign: 'center',
  marginLeft: 15,
  marginRight: 15,
  position: 'relative',
  fontSize: 29
};
const bellnotify = {
  marginTop: "9px",
};

let newitem = false;
let olditem = false;
const ReadNotify = (notificationID) => {
  //alert(notificationID);
  const formData = new FormData();
  formData.append("notificationID", notificationID);
  axios
    .post(
      process.env.REACT_APP_APIURL + "/api-web/ReadNotify",
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    )
    .then((response) => {
      //window.location.reload();
    });
};
function CickNotifyItem(initialIsVisible, NotificationStatus, newcount, parentCallback) {
  const [notiID, setNotiID] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  /*const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };*/

  const handleClickOutside = event => {
    //
    if (ref.current && !ref.current.contains(event.target)) {
      //console.log('yyyyy')
      //ReadNotify(false);
    }
    else {
      //setID();
      
      ReadNotify(notiID);
      if (Number(NotificationStatus) === 0) {
        if (newcount > 0) {
          parentCallback(newcount - 1);
          newitem = false;
      olditem = false;
        }
      }
      //console.log('xxxxx')
    }
  };

  useEffect(() => {
    //document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      //document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, notiID, setNotiID };
}
function NotificationItem({ item, newcount, parentCallback }) {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = CickNotifyItem(item.NotificationID, item.NotificationStatus, newcount, parentCallback);
  let notidate = new Date(item.NotificationDate).valueOf();

  if (Number(item.NotificationStatus) === 0) {
    if (newitem === false) {
      newitem = true;
      //console.log(newitem)
      return (
        <><li className="notifications-part">ใหม่</li>
          <li ref={ref} className="notify-item" >
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
            <div className="notify-item-content" >
              <h4 className="title">{item.Title}</h4>
              <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
              <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li></>);

    }
    else {
      return (
        <li ref={ref} className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
          <div className="notify-item-content" >
            <h4 className="title">{item.Title}</h4>
            <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
            <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  }
  else {
    if (olditem === false) {
      olditem = true;
      return (
        <><li className="notifications-part">ก่อนหน้านี้</li>
          <li ref={ref} className="notify-item">
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
            <div className="notify-item-content">
              <h4 className="title">{item.Title}</h4>
              <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
              <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li></>);

    }
    else {
      return (
        <li ref={ref} className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
          <div className="notify-item-content">
            <h4 className="title">{item.Title}</h4>
            <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
            <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  }
}

function Notifypopup(props) {
  //let user;// = JSON.parse(localStorage.getItem('user'));
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [newnotiCount, setNewNotiCount] = useState(0);
  const callback = (count) => {
    setNewNotiCount(count);
    newitem = false;
            olditem = false;
  };
  useEffect(() => {
    utils.asyncLocalStorage.getItem('user').then(function (value) {
      let user = JSON.parse(value);
      //console.log(user);
      //console.log(user.UserID);
      if (user !== null) {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/notify', { params: { userid: user.UserID } })
          .then((response) => {
            setNotifications(response.data);
            const newnotifyitem = response.data.filter(item => Number(item.NotificationStatus) === 0);
            setNewNotiCount(newnotifyitem.length);
            
            //console.log(newnotifyitem.length)
            setLoading(false);
          })
      }
      else {
        //setNotifications()
        setLoading(false);
      }
      //props.onChange(true)
    })
  }, [newnotiCount]);
  
  if (isLoading) {
    return <div className="App">...</div>;
  }
  return (
    <a id="dLabel" className="nav-notify mt-0" role="button" data-toggle="dropdown" style={bellnotify} data-target="#" href="#" >
      <i className="far fa-bell"></i>
      {(newnotiCount > 0) && (<div className="icon_count"><span>{newnotiCount}</span></div>)}
      <ul className="dropdown-menu notifications" role="menu" aria-labelledby="dLabel" >
        <div className="notification-heading">
          <h3 className="notification-heading-title">การแจ้งเตือน</h3>
        </div>
        <div className="notifications-wrapper scrollbar">
          {
            notifications.map((i, index) => (
              <NotificationItem item={i} newcount={newnotiCount} parentCallback={callback} key={i.NotificationID} />
            ))
          }

        </div>
      </ul>
    </a>

  );
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
  }, [])
  return size
}


export default function Navbar(props) {
  const history = useHistory();
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const [profilelink, setProfileLink] = useState('/authen/Profile');
  const [width] = useWindowSize();

  useEffect(() => {
    if (currentUser != null) {
      /*if (currentUser.GroupID === 1)
        profilelink = '/admin/Profile';*/
      axios.get(process.env.REACT_APP_APIURL + '/api-web/isNHRCUser', { params: { userid: currentUser.UserID } })
        .then((response) => {
          //console.log(response.data.status)
          if (response.data.status === true) {
            setProfileLink('/admin/Profile');
          }
          setLoading(false)

        });
    }
    else {
      setLoading(false)
    }
  });

  let usermenu = <>
    <li className="nav-item">
      <Link id="login-btn" to={{ pathname: "/authen/login", state: { language: language } }}>{language === "Thai" ? "เข้าใช้งาน/สมัครสมาชิก" : "Login/Register"}</Link>
    </li>
  </>;
  if (currentUser) {
    usermenu = <>
      <li className="nav-item">
        <div id="header-avatar">
          <Link id="profile-btn" to={{ pathname: profilelink, state: { language: language } }}><img className="img " src={currentUser.Avatar === null ? avatar : currentUser.Avatar.trim().length == 0 ? avatar : currentUser.Avatar} alt="User Avatar" /></Link>
        </div>
      </li>

      <li className="nav-item">
        <div style={iconstyles}>
          <Notifypopup />
        </div>
      </li>
    </>
    /*if(firstHideNoti===false)
    setFirstHideNoti(true)*/
  }
  const searchRef = React.createRef();
  const searchData = () => {
    /*axios.get(process.env.REACT_APP_APIURL+'/api-web/searchSite', { params: { search: searchRef.current.value } }).then((response) => {
        //setRowData(response.data);
    })*/
    if (searchRef.current.value.trim().length > 0) {
      const formData = new FormData();
      let userid = null;
      if (currentUser !== null) {
        userid = currentUser.UserID;
      }
      formData.append("search", searchRef.current.value);
      formData.append("userID", userid);
      axios.post(process.env.REACT_APP_APIURL + '/api-web/AddSearch', formData, {
        headers: {
          'Content-Type': `multipart/form-data`
        }
      })
        .then((response) => {
          history.push({ pathname: "/authen/SearchResult/" + searchRef.current.value, state: { search: searchRef.current.value } });
        });
    }
  };
  //
  if (isLoading) {
    return (<></>);
  }
  /*if(firstHideNoti===false)
  setFirstHideNoti(true)*/
  const smallDisplay = () => {
    return (
      <div className="navbar-collapse collapse" id="dropdownNav">
        <div >
          <ul className="navbar-nav custom nav-top ml-auto">
            <li className="nav-item active">
              <a className="navbar-brand" href="/">{language === "Thai" ? "หน้าแรก" : "Home"}</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link waves-effect waves-light" to={{ pathname: "/contact/ContactUs", state: { language: language } }}>{language === "Thai" ? "ติดต่อเรา" : "Contact Us"}</Link>
            </li>
            <div className="d-flex">
              <li className="nav-item">
                <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeReduce}>-</a>
              </li>
              <li className="nav-item ml-2 mr-2">
                <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeNormal}>{language === "Thai" ? "ก" : "A"}</a>
              </li>
              <li className="nav-item mr-2">
                <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeEnlarge}>+</a>
              </li>
              <li className="nav-item ml-auto mr-0">
                <form className="form-inline">
                  <div className="md-form my-0">
                    <input className="form-control mr-sm-0" type="text" ref={searchRef} style={searchstyle} placeholder="Search" aria-label="Search" maxLength={128} onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); searchData(); } }} />
                    <a href="/" onClick={e => { e.preventDefault(); searchData(); }}>
                      <img src={searchicon} id="searchicon" alt="search" />
                    </a>
                  </div>
                </form>
              </li>
            </div>
          </ul>
          <NavbarDropdown />
        </div>
      </div>
    )
  }

  const defaultDisplay = () => {
    return (
      <div className="collapse navbar-collapse" id="w-100">
        <div >
          <ul className="navbar-nav nav-top ml-auto">
            <li className="nav-item active">
              <a className="navbar-brand" href="/">{language === "Thai" ? "หน้าแรก" : "Home"}</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link waves-effect waves-light" to={{ pathname: "/contact/ContactUs", state: { language: language } }}>{language === "Thai" ? "ติดต่อเรา" : "Contact Us"}</Link>
            </li>

            <li className="nav-item">
              <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeReduce}>-</a>
            </li>
            <li className="nav-item">
              <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeNormal}>{language === "Thai" ? "ก" : "A"}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link waves-effect waves-light" href="/" onClick={utils.OnFontSizeEnlarge}>+</a>
            </li>

            <li className="nav-item">
              <form className="form-inline">
                <div className="md-form my-0">
                  <input className="form-control mr-sm-2" type="text" ref={searchRef} style={searchstyle} placeholder="Search" aria-label="Search" maxLength={128} onKeyDown={e => { if (e.key === 'Enter') { e.preventDefault(); searchData(); } }} />
                  <a href="/" onClick={e => { e.preventDefault(); searchData(); }}>
                    <img src={searchicon} id="searchicon" alt="search" />
                  </a>
                </div>
              </form>
            </li>

            {usermenu}

            <li className="nav-item">
              <a id="switch-lng-btn" href="/" rel="noopener noreferrer" onClick={e => { language === "Thai" ? utils.storeLanguageInLocalStorage("English") : utils.storeLanguageInLocalStorage("Thai"); window.location.reload(); e.preventDefault(); }}>{language === "Thai" ? "EN" : "TH"}</a>
            </li>

          </ul>
        </div>
        <div className="float-right">
          <NavbarDropdown />
        </div>
      </div>
    )
  }

  return (
    <div id="index-header">
      <div className="navigation-wrap start-header start-style">
        <div className="container navbar">
          <div className="row width-100 nav-inline">
            <div id="logo-img" className="ax_default placeholder">
              <a href="/">
                <img className="img " src={logo} alt="Merights Logo" />
              </a>
            </div>
            {width <= 991 &&
              <div className="nav-small">
                <ul className="navbar-nav nav-top ml-auto d-flex">
                  {usermenu}
                  <li className="nav-item">
                    <a id="switch-lng-btn" href="/" rel="noopener noreferrer" onClick={e => { language === "Thai" ? utils.storeLanguageInLocalStorage("English") : utils.storeLanguageInLocalStorage("Thai"); window.location.reload(); e.preventDefault(); }}>{language === "Thai" ? "EN" : "TH"}</a>
                  </li>
                </ul>
              </div>
            }
            <nav className="navbar navbar-expand-lg" style={{ paddingRight: "0" }}>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#dropdownNav" aria-controls="dropdownNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              {width <= 991 ? smallDisplay() : defaultDisplay()}
            </nav>
          </div>
        </div>
      </div>

    </div>

  );
}
