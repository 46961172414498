import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { scroller } from "react-scroll";
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewQuestionItem(props) {
    const currentUser = authenticationService.currentUserValue;
    const elerningID = props.location.state.elerningID;
    const chapterID = props.location.state.chapterID;
    const topParentChapterID = props.location.state.parentChapterID;
    const [topParentTitle, setTopParentTitle] = useState('');
    const questionnaireID = props.location.state.questionnaireID;
    const questionType = props.location.state.questionType;
    //const [eLearnTitle, setELearnTitle] = useState('');
    const [errors, setError] = useState({});
    const [parentChapter, setParentChapter] = useState([]);
    const [itemTitle, setItemTitle] = useState('');
    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState(0);
    const [score, setScore] = useState(1);
    const [shuffle, setShuffle] = useState(false)
    //console.log(props.location.state)
    function reLoadData() {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getChapterbyID', { params: { chapterID: topParentChapterID } })
            .then((response) => {
                let data = response.data[0];
                //console.log(data)
                setTopParentTitle(data.ChapterTitle);
            })
        axios.get(process.env.REACT_APP_APIURL + '/api-web/ESubjectChapter', { params: { id: chapterID } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            setParentChapter(data)

            //setELearnTitle(data.ELearnTitle);
        })
        /*axios.get(process.env.REACT_APP_APIURL + '/api-web/getQuestionItem', { params: { id: questionID } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            setParentChapter(data)
            setItemTitle(data.ItemTitle)
            setAnswer1(data.Answer1)
            setAnswer2(data.Answer2)
            setAnswer3(data.Answer3)
            setAnswer4(data.Answer4)
            setCorrectAnswer(data.CorrectAnswer)
            setScore(data.Score)
            setShuffle(data.Shuffle)
        })*/
    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (itemTitle.trim().length === 0) {
            formIsValid = false;
            serrors["itemTitle"] = "กรุณากรอกข้อมูล";
        }
        if (answer1.trim().length === 0) {
            formIsValid = false;
            serrors["answer1"] = "กรุณากรอกข้อมูล";
        }
        if (answer2.trim().length === 0) {
            formIsValid = false;
            serrors["answer2"] = "กรุณากรอกข้อมูล";
        }
        if (answer3.trim().length === 0) {
            formIsValid = false;
            serrors["answer3"] = "กรุณากรอกข้อมูล";
        }
        if (answer4.trim().length === 0) {
            formIsValid = false;
            serrors["answer4"] = "กรุณากรอกข้อมูล";
        }
        if (Number(correctAnswer) === 0) {
            formIsValid = false;
            serrors["correctAnswer"] = "กรุณากำหนดคำตอบที่ถูกต้อง";
        }
        if (Number(score) <= 0) {
            formIsValid = false;
            serrors["score"] = "กรุณากำหนดคะแนนที่เหมาะสม";
        }

        setError(serrors);
        return formIsValid;
    }
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("questionnairID", questionnaireID);
            formData.append("itemTitle", itemTitle);
            formData.append("answer1", answer1);
            formData.append("answer2", answer2);
            formData.append("answer3", answer3);
            formData.append("answer4", answer4);
            formData.append("correctAnswer", correctAnswer);
            formData.append("score", score);
            formData.append("shuffle", shuffle);
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveQuestionItem', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: elerningID, parentChapterID: topParentChapterID, chapterID: chapterID, QuestionnaireID: questionnaireID, questionType: questionType } });
                        }
                    })
                });
        }
        else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }
    };
    const rx_int = /^\d+$/;
    const handleScore = (e) => {
        if (rx_int.test(e.target.value)) {
            setScore(parseInt(e.target.value));
        }
    }
    function cancel() {
        props.history.push({ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: elerningID, parentChapterID: topParentChapterID, chapterID: chapterID, QuestionnaireID: questionnaireID, questionType: questionType } });
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: elerningID } }}>วิชา {parentChapter.ELearnTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/SubChapterList", state: { chapterID: topParentChapterID } }}>{topParentTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: elerningID, parentChapterID: topParentChapterID, chapterID: chapterID, QuestionnaireID: questionnaireID, questionType: questionType } }}>บท {parentChapter.ChapterTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elearning/NewQuestionItem", state: { elerningID: elerningID, chapterID: chapterID, questionnaireID: questionnaireID, questionType: questionType } }}>สร้างคำถาม</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>วิชา {parentChapter.ELearnTitle}</h1 >
                                    <div className="alert alert-dark col-12" role="alert">ทั่วไป</div>
                                    <div className="col-12">
                                        <label htmlFor="itemTitle" className="required">ชื่อคำถาม</label>
                                        <input type="text" className="form-control " id="itemTitle" value={itemTitle} name="itemTitle" placeholder="กรุณากรอก" onChange={(e) => { setItemTitle(e.currentTarget.value) }} />
                                        <SError error={errors["itemTitle"]} />
                                    </div>
                                    <div className="row m-1">
                                        <div className="col-6">
                                            <label htmlFor="score" className="required">คะแนน</label>
                                            <input type="text" className="form-control" id="score" name="score" placeholder="กรุณากรอก" value={score} onChange={(e) => { handleScore(e); }} />
                                            <SError error={errors["score"]} />
                                        </div>
                                        <div className="col-6" style={{ marginTop: 30 }}>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="shuffle" name="shuffle" checked={shuffle === true} onChange={(e) => { setShuffle(!shuffle) }} />
                                                <label className="custom-control-label" htmlFor="shuffle">สลับตำแหน่งตัวเลือกเมื่อแสดงผล</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">คำตอบ</div>
                                    <div className="col-12">
                                        <label htmlFor="itemTitle" className="required">ตัวเลือก 1</label>
                                        <input type="text" className="form-control " id="answer1" value={answer1} name="answer1" placeholder="กรุณากรอก" onChange={(e) => { setAnswer1(e.currentTarget.value) }} />
                                        <SError error={errors["answer1"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="itemTitle" className="required">ตัวเลือก 2</label>
                                        <input type="text" className="form-control " id="answer2" value={answer2} name="answer2" placeholder="กรุณากรอก" onChange={(e) => { setAnswer2(e.currentTarget.value) }} />
                                        <SError error={errors["answer2"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="itemTitle" className="required">ตัวเลือก 3</label>
                                        <input type="text" className="form-control " id="answer3" value={answer3} name="answer3" placeholder="กรุณากรอก" onChange={(e) => { setAnswer3(e.currentTarget.value) }} />
                                        <SError error={errors["answer3"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="itemTitle" className="required">ตัวเลือก 4</label>
                                        <input type="text" className="form-control " id="answer4" value={answer4} name="answer4" placeholder="กรุณากรอก" onChange={(e) => { setAnswer4(e.currentTarget.value) }} />
                                        <SError error={errors["answer4"]} />
                                    </div>
                                    <div className="col-12">
                                        <label className="required mr-2">คำตอบที่ถูกต้อง</label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="correctAnswer" id="correctAnswer1" value="1" checked={Number(correctAnswer) === 1} onChange={(e) => { setCorrectAnswer(1); }} />
                                            <label className="form-check-label" htmlFor="correctAnswer1"> คำตอบ 1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="correctAnswer" id="correctAnswer2" value="2" checked={Number(correctAnswer) === 2} onChange={(e) => { setCorrectAnswer(2); }} />
                                            <label className="form-check-label" htmlFor="correctAnswer2"> คำตอบ 2</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="correctAnswer" id="correctAnswer3" value="3" checked={Number(correctAnswer) === 3} onChange={(e) => { setCorrectAnswer(3); }} />
                                            <label className="form-check-label" htmlFor="correctAnswer3"> คำตอบ 3</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="correctAnswer" id="correctAnswer4" value="4" checked={Number(correctAnswer) === 4} onChange={(e) => { setCorrectAnswer(4); }} />
                                            <label className="form-check-label" htmlFor="correctAnswer4"> คำตอบ 4</label>
                                        </div>
                                        <span className="error">{errors["correctAnswer"]}</span>

                                    </div>

                                    <div className="col-12">
                                        <div className="pl-4 pr-4 text-center margintop40" >
                                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}