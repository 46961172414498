import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { authenticationService } from "services/authentication.service.js";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import AdminBanner from "components/Banner/AdminBanner";
import SError from "components/SError";
import moment from "moment";
const swal = withReactContent(Swal);
export default function NewSurvey(props) {
  //console.log("Edit ID = "+props.location.state.id);
  const currentUser = authenticationService.currentUserValue;
  const [nameTH, setNameTH] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [detailTH, setDetailTH] = useState("");
  const [detailEN, setDetailEN] = useState("");
  const [publishstartDate, SetPublishStartDate] = useState(new Date());
  const [publishendDate, SetPublishEndDate] = useState(new Date());
  const [published, setPublished] = useState(false);
  const [tmpAnswerID, setTmpAnswerID] = useState(0);
  const [questionSet, setQuestionSet] = useState([]);
  const [linkType, setLinkType] = useState("internal");
  const [link, setLink] = useState("");
  const [errors, setError] = useState({});

  useEffect(() => {
    AddQuestionSet(null);
  }, []); /* <-- add this for run once*/
  useEffect(() => {
    if (
      moment(publishstartDate).format("YYYY-MM-DD") >
      moment(publishendDate).format("YYYY-MM-DD")
    ) {
      SetPublishEndDate(publishstartDate);
    }
  }, [publishstartDate]);
  useEffect(() => {
    if (
      moment(publishendDate).format("YYYY-MM-DD") <
      moment(publishstartDate).format("YYYY-MM-DD")
    ) {
      SetPublishStartDate(publishendDate);
    }
  }, [publishendDate]);
  const rx_int = /^\d+$/;
  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    if (nameTH.trim().length === 0) {
      formIsValid = false;
      serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
    }
    /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุชื่อภาษาอังกฤษ";
        }*/
    if (detailTH.trim().length === 0) {
      formIsValid = false;
      serrors["detailTH"] = "กรุณาระบุชื่อภาษาไทย";
    }
    /*if (detailEN.trim().length === 0) {
            formIsValid = false;
            serrors["detailEN"] = "กรุณาระบุชื่อภาษาอังกฤษ";
        }*/
    let questionerror = "";
    let questionvertify = true;

    if (questionSet.length > 0) {
      if (questionSet[0].QuestionType !== 0) {
        for (let i = 0; i < questionSet.length; i++) {
          let item = questionSet[i];
          let tmperror = "";

          if (Number(item.QuestionType) === 0) {
            tmperror += "\r\nกรุณาเลือกประเภทตัวเลือกของคำถาม";
          }
          if (item.QuestionTitle.trim().length === 0) {
            tmperror += "\r\nกรุณากรอกคำถาม";
          }
          if (
            Number(item.QuestionType) > 0 &&
            Number(item.QuestionType) < 4 &&
            item.AnswerList.length === 0
          ) {
            tmperror += "\r\nกรุณาระบุจำนวนคำตอบ";
          }
          if (
            Number(item.QuestionType) > 0 &&
            Number(item.QuestionType) < 4 &&
            item.AnswerList.length > 0
          ) {
            for (let i = 0; i < item.AnswerList.length; i++) {
              let ans = item.AnswerList[i];
              if (ans.AnswerTitle.trim().length === 0) {
                tmperror +=
                  "\r\nกรุณากรอกข้อมูลคำตอบ ลำดับที่ " + (i + 1).toString();
              }
            }
          }
          if (tmperror.trim().length > 0) {
            questionvertify = false;
            questionerror +=
              "\r\nผบข้อผิดพลาดในคำถามลำดับที่ " +
              (i + 1).toString() +
              tmperror;
          }
        }
      }
    }

    if (questionvertify === false) {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: questionerror,
      });
      return;
    }
    if (formIsValid === false) {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    }
    setError(serrors);
    return formIsValid;
  };
  const saveData = (open) => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("titleth", nameTH);
      formData.append("titleen", nameEN);
      formData.append("detailTH", detailTH);
      formData.append("detailEN", detailEN);
      formData.append("published", published);
      formData.append("publishstartDate", publishstartDate);
      formData.append("publishendDate", publishendDate);
      formData.append("userID", currentUser.UserID);
      if (link !== "") {
        formData.append("Link_Type", "external");
      }
      formData.append("Link_Question", link);
      for (var i = 0; i < questionSet.length; i++) {
        //console.log(questionSet[i])
        formData.append("questionSet[]", JSON.stringify(questionSet[i]));
      }
      axios
        .post(process.env.REACT_APP_APIURL + "/api-web/saveSurvey", formData, {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              if (open) {
                window.location.reload();
              } else {
                props.history.push({ pathname: "/admin/survey/Survey" });
              }
            });
        });
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };
  const handeleRemoveQuestionSet = (index) => {
    if (questionSet.length > 1) {
      const removedata = questionSet.filter(
        (item) => Number(item.SurveyQuestionID) === Number(index)
      );
      //console.log(removedata);
      if (Number(removedata[0].SurveyQuestionID) > 0) {
        //console.log(removedata[0].ListOfContentID)
        const formData = new FormData();
        formData.append("id", removedata[0].SurveyQuestionID);
        axios.post(
          process.env.REACT_APP_APIURL + "/api-web/deleteSurveyQuestionItem",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        );
      }
      const arr = questionSet.filter(
        (item) => Number(item.SurveyQuestionID) !== Number(index)
      );
      setQuestionSet(arr);
    } else {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "ต้องมีคำถามอย่างน้อยหนึ่งคำถาม",
      });
    }
  };
  const AddQuestionSet = (item) => {
    if (item !== null) {
      let vertify = true;
      let error = "กรุณากรอกข้อมูลคำถามให้ครบถ้วน ดังรายละเอียดต่อไปนี้\r\n";
      if (Number(item.QuestionType) === 0) {
        error += "\r\nกรุณาเลือกประเภทตัวเลือกของคำถาม";
        vertify = false;
      }
      if (item.QuestionTitle.trim().length === 0) {
        error += "\r\nกรุณากรอกคำถาม";
        vertify = false;
      }
      if (
        Number(item.QuestionType) > 0 &&
        Number(item.QuestionType) < 4 &&
        item.AnswerList.length === 0
      ) {
        error += "\r\nกรุณาระบุจำนวนคำตอบ";
        vertify = false;
      }
      if (
        Number(item.QuestionType) > 0 &&
        Number(item.QuestionType) < 4 &&
        item.AnswerList.length > 0
      ) {
        for (let i = 0; i < item.AnswerList.length; i++) {
          let ans = item.AnswerList[i];
          if (ans.AnswerTitle.trim().length === 0) {
            error += "\r\nกรุณากรอกข้อมูลคำตอบ ลำดับที่ " + (i + 1).toString();
            vertify = false;
          }
        }
      }
      if (vertify === false) {
        Swal.fire({
          icon: "error",
          title: "ผิดพลาด",
          text: error,
        });
      } else {
        const data = [...questionSet];
        setQuestionSet((prevQuestions) => [
          ...prevQuestions,
          {
            SurveyQuestionID: Number(questionSet.length) * -1,
            SurveyID: "",
            QuestionType: 0,
            QuestionTitle: "",
            AnswerNum: 0,
            AnswerList: [],
          },
        ]);
      }
    } else {
      const data = [...questionSet];
      setQuestionSet((prevQuestions) => [
        ...prevQuestions,
        {
          SurveyQuestionID: Number(questionSet.length) * -1,
          SurveyID: "",
          QuestionType: 0,
          QuestionTitle: "",
          AnswerNum: 0,
          AnswerList: [],
        },
      ]);
    }
  };
  const handeleRemoveAnswerSet = (index, ansID) => {
    const data = [...questionSet];
    data.map((value, i) => {
      if (value.SurveyQuestionID === index) {
        const tmpdata = [...value.AnswerList];
        value.AnswerList = [];
        for (let j = 0; j < tmpdata.length; j++) {
          if (Number(tmpdata[j].SurveyAnswerID) !== Number(ansID)) {
            //console.log(ansID+":"+tmpdata[j].SurveyAnswerID)
            value.AnswerList.push({
              SurveyAnswerID: tmpdata[j].SurveyAnswerID,
              AnswerTitle: tmpdata[j].AnswerTitle,
            });
          } else {
            if (Number(tmpdata[j].SurveyAnswerID) > 0) {
              const formData = new FormData();
              formData.append("id", tmpdata[j].SurveyAnswerID);
              axios.post(
                process.env.REACT_APP_APIURL + "/api-web/deleteAnswerItem",
                formData,
                {
                  headers: {
                    "Content-Type": `multipart/form-data`,
                  },
                }
              );
            }
          }
        }
        value.AnswerNum = value.AnswerList.length;

        //console.log(value)
      }
      return value;
    });
    setQuestionSet(data);
    //console.log(questionSet)
  };
  const AddAnswerSet = (item) => {
    const data = [...questionSet];
    data.map((value, i) => {
      if (value.SurveyQuestionID === item.SurveyQuestionID) {
        const tmpdata = [...value.AnswerList];
        let tmpid = tmpAnswerID;
        tmpid--;
        value.AnswerList.push({ SurveyAnswerID: tmpid, AnswerTitle: "" });
        setTmpAnswerID(tmpid);
        value.AnswerNum = value.AnswerList.length;

        //console.log(value)
      }
      return value;
    });
    setQuestionSet(data);
  };
  const cancel = () => {
    props.history.push({ pathname: "/admin/survey/Survey" });
  };
  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการแบบสอบถาม"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/survey/Survey" }}
            >
              {"การจัดการแบบสอบถาม"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/survey/NewSurvey" }}
            >
              {"สร้าง"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="row">
          <div className="col-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="tab1-tab"
                  data-toggle="tab"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  TH
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab2-tab"
                  data-toggle="tab"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  EN
                </a>
              </li>
            </ul>
            <div className="tab-content pl-4 pr-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="thname" className="required">
                        ชื่อแบบสอบถาม (ภาษาไทย)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="thname"
                        name="thname"
                        placeholder="กรุณากรอก"
                        value={nameTH}
                        onChange={(e) => {
                          setNameTH(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["thname"]} />
                    </div>
                    <div className="form-group">
                      <label className="required">รายละเอียด (ภาษาไทย)</label>
                      <ReactSummernote
                        value={detailTH}
                        options={{
                          //lang: 'th-TH',
                          height: 350,
                          dialogsInBody: true,
                          toolbar: [
                            ["style", ["style"]],
                            ["font", ["bold", "italic", "underline", "clear"]],
                            ["color", ["color"]],
                            /*['fontname', ['fontname']],*/
                            ["para", ["ul", "ol", "paragraph"]],
                            ["table", ["table"]],
                            ["insert", ["link", "video"]],
                            ["view", ["fullscreen"]],
                          ],
                        }}
                        onChange={setDetailTH}
                      />
                      <SError error={errors["detailTH"]} />
                    </div>
                    {/* <div className="col-12">
                      <label>ประเภท Link</label>{" "}
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="linkType"
                          id="linkType"
                          value="true"
                          checked={linkType === "internal"}
                          onChange={(e) => {
                            setLinkType("internal");
                          }}
                        />
                        <label className="form-check-label" htmlFor="linkType">
                          {" "}
                          ภายใน
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="linkType"
                          id="linkType2"
                          value="false"
                          checked={linkType === "external"}
                          onChange={(e) => {
                            setLinkType("external");
                          }}
                        />
                        <label className="form-check-label" htmlFor="linkType2">
                          {" "}
                          ภายนอก
                        </label>
                      </div>
                      <SError error={errors["linkType"]} />
                    </div> */}
                    <div className="form-group">
                      <label>Link URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="link"
                        name="link"
                        placeholder="กรุณากรอก"
                        value={link}
                        onChange={(e) => {
                          setLink(e.currentTarget.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label className="required mr-2">สถานะ</label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published"
                          id="published1"
                          value="true"
                          checked={published === true}
                          onChange={(e) => {
                            setPublished(true);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="published1"
                        >
                          {" "}
                          เผยแพร่
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published"
                          id="published2"
                          value="false"
                          checked={published === false}
                          onChange={(e) => {
                            setPublished(false);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="published2"
                        >
                          {" "}
                          ไม่เผยแพร่
                        </label>
                      </div>
                      <SError error={errors["published"]} />
                    </div>
                    {published === true && (
                      <>
                        <div className="col-5">
                          <label className="required">วันที่เผยแพร่</label>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"th"}
                          >
                            <DatePicker
                              value={publishstartDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              yearOffset={543}
                              /*maxDate={publishendDate}*/
                              onChange={SetPublishStartDate}
                              name="startpublishday"
                              id="startpublishday"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-5">
                          <label className="required">วันที่สิ้นสุด</label>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"th"}
                          >
                            <DatePicker
                              value={publishendDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              yearOffset={543}
                              /*minDate={publishstartDate}*/
                              onChange={SetPublishEndDate}
                              name="endpublishday"
                              id="endpublishday"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-12">
                          <SError error={errors["publishday"]} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="tab2"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="enname">ชื่อแบบสอบถาม (ภาษาอังกฤษ)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="enname"
                        name="enname"
                        placeholder="กรุณากรอก"
                        value={nameEN}
                        onChange={(e) => {
                          setNameEN(e.currentTarget.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>รายละเอียด (ภาษาอังกฤษ)</label>
                      <ReactSummernote
                        value={detailEN}
                        options={{
                          //lang: 'th-TH',
                          height: 350,
                          dialogsInBody: true,
                          toolbar: [
                            ["style", ["style"]],
                            ["font", ["bold", "italic", "underline", "clear"]],
                            ["color", ["color"]],
                            /*['fontname', ['fontname']],*/
                            ["para", ["ul", "ol", "paragraph"]],
                            ["table", ["table"]],
                            ["insert", ["link", "video"]],
                            ["view", ["fullscreen"]],
                          ],
                        }}
                        onChange={setDetailEN}
                      />
                      <SError error={errors["detailEN"]} />
                    </div>
                    <div className="col-12">
                      <label className="required mr-2">ประเภท Link</label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="linkType1"
                          id="linkType"
                          value="true"
                          checked={linkType === "internal"}
                          onChange={(e) => {
                            setLinkType("internal");
                          }}
                        />
                        <label className="form-check-label" htmlFor="linkType">
                          {" "}
                          ภายใน
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="linkType1"
                          id="linkType2"
                          value="false"
                          checked={linkType === "external"}
                          onChange={(e) => {
                            setLinkType("external");
                          }}
                        />
                        <label className="form-check-label" htmlFor="linkType2">
                          {" "}
                          ภายนอก
                        </label>
                      </div>
                      <SError error={errors["linkType"]} />
                    </div>
                    <div className="form-group">
                      <label>Link URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="link"
                        name="link"
                        placeholder="กรุณากรอก"
                        value={link}
                        onChange={(e) => {
                          setLink(e.currentTarget.value);
                        }}
                      />
                    </div>

                    <div className="col-12">
                      <label className="required mr-2">สถานะ</label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published1"
                          id="published1"
                          value="true"
                          checked={published === true}
                          onChange={(e) => {
                            setPublished(true);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="published1"
                        >
                          {" "}
                          เผยแพร่
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published1"
                          id="published2"
                          value="false"
                          checked={published === false}
                          onChange={(e) => {
                            setPublished(false);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="published2"
                        >
                          {" "}
                          ไม่เผยแพร่
                        </label>
                      </div>
                      <SError error={errors["published"]} />
                    </div>
                    {published === true && (
                      <>
                        <div className="col-5">
                          <label className="required">วันที่เผยแพร่</label>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"th"}
                          >
                            <DatePicker
                              value={publishstartDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              yearOffset={543}
                              /*maxDate={publishendDate}*/
                              onChange={SetPublishStartDate}
                              name="startpublishday"
                              id="startpublishday"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-5">
                          <label className="required">วันที่สิ้นสุด</label>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={"th"}
                          >
                            <DatePicker
                              value={publishendDate}
                              format="D MMMM YYYY"
                              pickerHeaderFormat="ddd D MMM"
                              yearOffset={543}
                              /*minDate={publishstartDate}*/
                              onChange={SetPublishEndDate}
                              name="endpublishday"
                              id="endpublishday"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-12">
                          <SError error={errors["publishday"]} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-content pl-4 pr-4">
            <div className="card col-12">
              <div className="card-body row">
                <div className="alert alert-dark col-12" role="alert">
                  ชุดคำถาม
                </div>
                <div className="col-12 mt-2">
                  <div className="row">
                    <div className="col-12">
                      {questionSet.map((item, i) => (
                        <div
                          key={"question-" + item.SurveyQuestionID}
                          className="row mt-2 question-item"
                        >
                          <div className="col-6">
                            <label htmlFor="bookCatID">ประเภทตัวเลือก</label>
                            <select
                              className="form-control mb-2 mr-2"
                              id="QuestionType"
                              name="QuestionType"
                              value={item.QuestionType}
                              onChange={(e) => {
                                setQuestionSet(
                                  questionSet.map((value, j) => {
                                    if (i === j) {
                                      value.QuestionType = e.target.value;
                                      if (
                                        Number(e.target.value) === 0 ||
                                        Number(e.target.value) === 4 ||
                                        Number(e.target.value) === 5
                                      ) {
                                        value.AnswerList = [];
                                        value.AnswerNum = 0;
                                      }
                                    }
                                    return value;
                                  })
                                );
                              }}
                            >
                              <option value="0">กรุณาเลือก</option>
                              <option value="1">ปรนัย (Multiple choice)</option>
                              <option value="2">
                                ช่องทำเครื่องหมาย (Checkboxs)
                              </option>
                              <option value="3">Dropdown</option>
                              <option value="4">คำตอบสั้น ๆ </option>
                              <option value="5">ย่อหน้า</option>
                            </select>
                            <SError error={errors["bookCatID"]} />
                          </div>
                          {Number(item.QuestionType) != 0 &&
                            Number(item.QuestionType) != 4 &&
                            Number(item.QuestionType) != 5 && (
                              <div className="col-3">
                                <label htmlFor="bookCatID" className="required">
                                  จำนวนตัวเลือก
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="AnswerNum"
                                  value={item.AnswerNum}
                                  name="AnswerNum"
                                  placeholder="กรุณากรอก"
                                  onChange={(e) => {
                                    if (rx_int.test(e.target.value)) {
                                      setQuestionSet(
                                        questionSet.map((value, j) => {
                                          if (i === j) {
                                            value.AnswerNum = Number(
                                              e.target.value
                                            );
                                            value.AnswerList = [];
                                            let tmpid = tmpAnswerID;
                                            for (
                                              let j = 0;
                                              j < value.AnswerNum;
                                              j++
                                            ) {
                                              tmpid--;
                                              value.AnswerList.push({
                                                SurveyAnswerID: tmpid,
                                                AnswerTitle: "",
                                              });
                                              setTmpAnswerID(tmpid);
                                            }
                                          }
                                          return value;
                                        })
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          <span
                            className={
                              Number(item.QuestionType) != 0 &&
                              Number(item.QuestionType) != 4 &&
                              Number(item.QuestionType) != 5
                                ? "col-3"
                                : "col-6"
                            }
                            style={{ marginTop: 30 }}
                          >
                            <a
                              className="float-right"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                handeleRemoveQuestionSet(item.SurveyQuestionID);
                              }}
                            >
                              <i className="text-danger fas fa-minus-circle"></i>{" "}
                              ลบคำถาม
                            </a>
                            <a
                              className="float-right mr-2"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                AddQuestionSet(item);
                              }}
                            >
                              <i className="fas fa-plus-circle"></i> เพิ่มคำถาม
                            </a>
                          </span>
                          <div className="col-2">คำถามข้อที่ {i + 1}</div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-control "
                              id="QuestionTitle"
                              value={item.QuestionTitle}
                              name="QuestionTitle"
                              placeholder="กรุณากรอก"
                              onChange={(e) => {
                                setQuestionSet(
                                  questionSet.map((value, j) => {
                                    if (i === j) {
                                      value.QuestionTitle = e.target.value;
                                    }
                                    return value;
                                  })
                                );
                              }}
                            />
                          </div>
                          {typeof item.AnswerList !== "undefined" &&
                            item.AnswerList.map((ansItem, k) => (
                              <div
                                key={"key" + ansItem.SurveyAnswerID + k}
                                className="col-12"
                              >
                                <div className="row">
                                  <div className="col-2 text-right">
                                    ตัวเลือก {k + 1}
                                  </div>
                                  <div className="col-10">
                                    <div
                                      className="row"
                                      style={{ marginLeft: 0 }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control col-9"
                                        value={ansItem.AnswerTitle}
                                        name="AnswerTitle"
                                        placeholder="กรุณากรอก"
                                        onChange={(e) => {
                                          setQuestionSet(
                                            questionSet.map((value, j) => {
                                              if (i === j) {
                                                value.AnswerList =
                                                  item.AnswerList.map(
                                                    (value, l) => {
                                                      if (k === l) {
                                                        value.AnswerTitle =
                                                          e.target.value;
                                                      }
                                                      return value;
                                                    }
                                                  );
                                              }
                                              return value;
                                            })
                                          );
                                        }}
                                      />
                                      <span className="col-3">
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handeleRemoveAnswerSet(
                                              item.SurveyQuestionID,
                                              ansItem.SurveyAnswerID
                                            );
                                          }}
                                        >
                                          <i className="text-danger fas fa-minus-circle"></i>{" "}
                                          ลบคำตอบ
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {(Number(item.QuestionType) === 1 ||
                            Number(item.QuestionType) === 2 ||
                            Number(item.QuestionType) === 3) && (
                            <>
                              <div className="col-2"></div>
                              <div className="col-10">
                                <a
                                  className="mr-2"
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    AddAnswerSet(item);
                                  }}
                                >
                                  <i className="fas fa-plus-circle"></i>{" "}
                                  เพิ่มคำตอบ
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="pl-4 pr-4 text-center margintop40">
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(false);
                }}
              >
                <i className="fas fa-save"></i> บันทึก
              </button>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
              >
                <i className="fas fa-save"></i> บันทึกและเปิด
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => {
                  e.preventDefault();
                  cancel();
                }}
              >
                <i className="far fa-times-circle"></i> ยกเลิก
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
