import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
//import { saveAs } from "file-saver";
//import { Document, ImageRun, Packer, Paragraph, AlignmentType, UnderlineType} from "docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import expressions from 'angular-expressions';
import assign from "lodash/assign";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AlternateEmailRounded } from "@material-ui/icons";
const swal = withReactContent(Swal)
function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}
export default function EditNetwork(props) {
    const networkid = props.location.state.id;
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [errors, setError] = useState({});
    const [netType, SetNetType] = useState('');
    const [organization, SetOrganization] = useState('');
    const [netHouseNo, SetNetHouseNo] = useState('');
    const [provinceCode, SetProvinceCode] = useState(0);
    const [districtCode, SetDistrictCode] = useState(0);
    const [subDistricCode, SetSubDistricCode] = useState(0);
    const [postCode, SetPostCode] = useState('');
    const [phone, SetPhone] = useState('');
    const [fax, SetFax] = useState('');
    //const [position, SetPosition] = useState('');
    //const [email, SetEmail] = useState('');
    const [coTitleID, SetCoTitleID] = useState(0);
    const [coFirstName, SetCoFirstName] = useState('');
    const [coLastName, SetCoLastName] = useState('');
    const [coPosition, SetCoPosition] = useState('');
    const [coPhone, SetCoPhone] = useState('');
    const [coFax, SetCoFax] = useState('');
    const [coEmail, SetCoEmail] = useState('');

    const [coProperty1, SetCoProperty1] = useState(false);
    const [coProperty2, SetCoProperty2] = useState(false);
    const [coProperty3, SetCoProperty3] = useState(false);
    const [coProperty4, SetCoProperty4] = useState(false);
    const [coProperty1Extra, SetCoProperty1Extra] = useState('');
    const [acceptStatus, SetAcceptStatus] = useState(false);
    const [approveStatus, SetApproveStatus] = useState(false);
    const [State, SetState] = useState('');

    const [titleList, SetTitleList] = useState([]);
    const [coOtherTitleTH, SetCoOtherTitleTH] = useState('');
    const [coOtherTitleEN, SetCoOtherTitleEN] = useState('');
    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [networkActivities, setnetworkActivities] = useState([]);

    const [completeDocRemark, SetCompleteDocRemark] = useState('');
    const [completeDocFiles, setCompleteDocFiles] = useState(null);

    const [uncompleteDocInput, setUncompleteDocInput] = useState([]);
    const [uncompleteImageInput, setUncompleteImageInput] = useState([]);
    const [uncompleteDocRemark, SetUncompleteDocRemark] = useState('');
    const [uncompleteDocFiles, setUncompleteDocFiles] = useState(null);

    const [certifyRemark, SetCertifyRemark] = useState('');
    const [certifyFiles, setCertifyFiles] = useState(null);
    const [memberAnnounceFile, setMemberAnnounceFile] = useState(null);
    const [publishstartDate, SetPublishStartDate] = useState(new Date());

    const [uncertifyRemark, SetUncertifyRemark] = useState('');
    const [uncertifyFiles, setUncertifyFiles] = useState(null);

    const [declineRemark, setDeclineRemark] = useState('');
    const [terminateRemark, setTerminateRemark] = useState('');
    const [revokeRemark, setRevokeRemark] = useState('');
    const [revokeFiles, setRevokeFiles] = useState(null);

    const [actionhistory, setActionHistory] = useState([]);
    const [bookNo, setBookNo] = useState('');
    const [regNo, setRegNo] = useState('');
    const [regDate, setRegDate] = useState(new Date());
    const [userEnroll, setUserEnroll] = useState(0);
    const [logoFile, SetLogoFile] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [attactmentList, setAttactmentList] = useState([]);

    const [CompleteDocFilePath, setCompleteDocFilePath] = useState('');
    const [MemberAnnounceFilePath, setMemberAnnounceFilePath] = useState('');
    const [MemberResultFilePath, setMemberResultFilePath] = useState('');
    const [NotCertifyResultFilePath, setNotCertifyResultFilePath] = useState('');
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
            .then((response) => {
                SetProvinceList(response.data);
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
                    .then((response) => {
                        SetDistrictList(response.data);
                        SetAllDistrictList(response.data);
                        const alldistrict = [...response.data];
                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
                            .then((response) => {
                                SetSubDistrictList(response.data);
                                SetAllSubDistrictList(response.data);
                                const allsubdistrict = [...response.data];
                                axios.get(process.env.REACT_APP_APIURL + '/api-web/corporateNetworkbyID', { params: { id: networkid } }).then((response) => {
                                    let data = response.data[0];
                                    //console.log(data)
                                    setUserEnroll(data.CreatedBy);
                                    SetNetType(data.NetType);
                                    SetOrganization(data.NetOrganization);
                                    SetNetHouseNo(data.NetHouseNo);
                                    SetProvinceCode(data.NetProvinceID);
                                    SetDistrictCode(data.NetDistrictID);
                                    SetSubDistricCode(data.NetSubDistrictID);
                                    SetDistrictList(alldistrict.filter(item => item['ProvinceID'] === Number(data.NetProvinceID)));
                                    SetSubDistrictList(allsubdistrict.filter(item => item['DistrictID'] === Number(data.NetDistrictID)));
                                    SetPostCode(data.NetPostCode);
                                    SetPhone(data.NetPhone);
                                    const LogoImagePath = {
                                        file: null,
                                        name: null,
                                        preview: data.LogoImagePath,
                                        size: null
                                    };
                                    SetLogoFile(LogoImagePath);
                                    if (data.NetFax != null)
                                        SetFax(data.NetFax);

                                    SetCoTitleID(data.CoTitleID);
                                    SetCoFirstName(data.CoFirstName);
                                    SetCoLastName(data.CoLastName);
                                    SetCoPosition(data.CoPosition);
                                    SetCoPhone(data.CoPhone);
                                    if (data.CoFax != null)
                                        SetCoFax(data.CoFax);
                                    SetCoEmail(data.CoEmail);

                                    SetCoProperty1(data.CoProperty1);
                                    SetCoProperty2(data.CoProperty2)
                                    SetCoProperty3(data.CoProperty3)
                                    SetCoProperty4(data.CoProperty4);
                                    if (data.CoProperty1Extra !== null)
                                        SetCoProperty1Extra(data.CoProperty1Extra)
                                    SetAcceptStatus(data.AcceptStatus)
                                    SetApproveStatus(data.ApproveStatus);
                                    SetState(data.State)
                                    if (data.State === 'NEED_DOC') {
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } }).then((response) => {
                                            if (response.data[0].UncompleteDoc !== null) {
                                                const docarr = response.data[0].UncompleteDoc.split(",");
                                                setUncompleteDocInput(docarr);
                                            }
                                            if (response.data[0].UncompleteImage !== null) {
                                                const imgarr = response.data[0].UncompleteImage.split(",");
                                                setUncompleteImageInput(imgarr);
                                            }
                                            console.log(response.data[0]);
                                        });
                                    }
                                    else if(data.State === 'COMPLETE_DOC') {
                                        setCompleteDocFilePath(data.CompleteDocFilePath);
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } }).then((response) => {
                                            SetCompleteDocRemark(response.data[0].Remark);
                                        });
                                    }
                                    else if(data.State === 'DECLINE')
                                    {                                        
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } }).then((response) => {
                                            setDeclineRemark(response.data[0].Remark);
                                        });
                                    }
                                    else if(data.State === 'CERTIFY'){
                                        //console.log(data)
                                        setMemberAnnounceFilePath(data.MemberAnnounceFilePath);
                                        setMemberResultFilePath(data.MemberResultFilePath);
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } }).then((response) => {
                                            //console.log(response.data[0])
                                            SetCertifyRemark(response.data[0].Remark);
                                        });
                                    }
                                    else if (data.State === 'NOT_CERTIFY') {
                                        setNotCertifyResultFilePath(data.NotCertifyResultFilePath);
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } }).then((response) => {
                                            //console.log(response.data[0])
                                            SetUncertifyRemark(response.data[0].Remark);
                                        });
                                    }

                                    setBookNo(data.maxBookID);
                                    setRegNo(data.regBookID);
                                    setRegDate(data.regDate);

                                    const attachmentFiles = [...attactmentList];
                                    for (let i = 0; i < data.attatchment.length; i += 1) {
                                        const filedata = {
                                            id: data.attatchment[i].AttachmentID,
                                            path: data.attatchment[i].FilenameNew,
                                            name: data.attatchment[i].FilenameOld,
                                            preview: data.attatchment[i].AttachmentPath,
                                            size: data.attatchment[i].Filesize
                                        };
                                        attachmentFiles[i] = filedata;
                                    }

                                    setAttactmentList(attachmentFiles);

                                    const newFiles = [...networkActivities];

                                    for (let i = 0; i < data.networkactivity.length; i += 1) {
                                        //console.log(data.networkactivity[i].Images)
                                        const filedata = {
                                            uuid: data.networkactivity[i].CorporateNetworkActivityUUID,
                                            organization: data.NetOrganization,
                                            year: data.networkactivity[i].Year,
                                            ActivityName: typeof data.networkactivity[i].ActivityName === 'undefined' ? '' : data.networkactivity[i].ActivityName,
                                            ActivityDetail: typeof data.networkactivity[i].ActivityDetail === 'undefined' ? '' : data.networkactivity[i].ActivityDetail,
                                            ToppicTypeIDList: data.networkactivity[i].ToppicTypeIDList,
                                            Images: JSON.parse(data.networkactivity[i].Images)
                                        };
                                        newFiles[i] = filedata;
                                    }
                                    //console.log(newFiles)
                                    setnetworkActivities(newFiles);
                                    axios.get(process.env.REACT_APP_APIURL + '/api-web/getcorporateNetworkAction', { params: { networkID: networkid } })
                                        .then((response) => {
                                            setActionHistory(response.data);
                                            setLoading(false);
                                        });
                                    axios.get(process.env.REACT_APP_APIURL + '/api-web/getTitle')
                                        .then((response) => {
                                            SetTitleList(response.data);
                                        });
                                });
                            });
                    });
            });
    }, []); /* <-- add this for run once*/
    const handleChangeProvince = ProvinceID => {
        SetDistrictList(allDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList(allSubDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetDistrictCode(0);
        SetSubDistricCode(0);
    };
    const handleChangeDistrict = DistrictID => {
        SetSubDistrictList(allSubDistrictList.filter(item => item['DistrictID'] === Number(DistrictID)));
        SetSubDistricCode(0);
    };
    const handleChangeSubDistrict = SubDistrictID => {
        const subdistrictitem = allSubDistrictList.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            SetPostCode(subdistrictitem[0].PostCode);
        }
        else {
            SetPostCode('');
        }
    };
    const TitleSelect = titleList.map((title) => (
        <option
            key={title.TitleID}
            value={title.TitleID}
        >
            {title.TitleNameTH}
        </option>
    ));
    const ProvinceSelect = provinceList.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    const handleRemoveCompleteDocClick = index => {
        const arr = uncompleteDocInput.filter((item) => item !== index);
        setUncompleteDocInput(arr);
    };
    const handleRemoveCompleteImageClick = index => {
        const arr = uncompleteImageInput.filter((item) => item !== index);
        setUncompleteImageInput(arr);
    };
    const getBase64ImageFromURL = (url, left, top, width, height) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = () => {
                var canvas = document.createElement("canvas");
                var ratio = img.naturalWidth / img.naturalHeight;
                var theight = height;
                var twidth = height * ratio;
                if (twidth > width) {
                    twidth = width;
                    theight = width / ratio;
                }
                canvas.left = left;
                canvas.top = top;
                canvas.width = twidth;
                canvas.height = theight;
                /*canvas.left = left;
                canvas.top = top;
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;*/
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height,     // source rectangle
                    0, 0, canvas.width, canvas.height);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    };
    expressions.filters.lower = function (input) {
        // This condition should be used to make sure that if your input is
        // undefined, your output will be undefined as well and will not
        // throw an error
        if (!input) return input;
        return input.toLowerCase();
    };
    function angularParser(tag) {
        tag = tag
            .replace(/^\.$/, "this")
            .replace(/(’|')/g, "'")
            .replace(/(“|”)/g, '"');
        const expr = expressions.compile(tag);
        return {
            get: function (scope, context) {
                let obj = {};
                const scopeList = context.scopeList;
                const num = context.num;
                for (let i = 0, len = num + 1; i < len; i++) {
                    obj = assign(obj, scopeList[i]);
                }
                return expr(scope, obj);
            },
        };
    }
    async function printDraftORGWord() {
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = bookNo + '/' + year;
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = regNo + '/' + regyear
        var reciveDate = utils.MariatoThaiDateOnly(regDate);
        var docDate = utils.MariatoThaiDateOnly(new Date);
        let templatefile = process.env.REACT_APP_SITEURL + "/template/draftcorporate.docx";
        if (netType !== 'ORG') {
            templatefile = process.env.REACT_APP_SITEURL + "/template/draftprocouncil.docx";
        }

        let activityArray = [];
        let hrcArray = [];
        let arr = [];
        let imgdata = [];
        const promises = networkActivities.map(async (data, index) => {

            if (data.Images !== null) {
                //console.log(data.Images)
                imgdata[index] = [];
                for (let i = 0; i < data.Images.length; i++) {
                    const imgpreview = await getBase64ImageFromURL(
                        data.Images[i], 0,
                        0,
                        200,
                        200
                    )
                    imgdata[index].push(imgpreview);
                    //console.log(imgpreview);
                }
            }
            //console.log(index);
        });
        const numimage = await Promise.all(promises)
        /*networkActivities.map((data, index) => {
            //console.log(data)
            if (data.ToppicTypeIDList !== null) {
                arr = data.ToppicTypeIDList.split(',')
                //hrcArray = hrcArray.concat(arr)
                arr.forEach((element) => {
                    if (!hrcArray.includes(Number(element))) {
                        hrcArray.push(Number(element));
                    }
                });
            }
            activityArray.push({ ActivityName: data.ActivityName, ActivityDetail: data.ActivityDetail, year: data.year })
        });*/

        for (let j = 0; j < networkActivities.length; j++) {
            let data = networkActivities[j];
            if (data.ToppicTypeIDList !== null) {
                arr = data.ToppicTypeIDList.split(',')
                //hrcArray = hrcArray.concat(arr)
                arr.forEach((element) => {
                    if (!hrcArray.includes(Number(element))) {
                        hrcArray.push(Number(element));
                    }
                });
            }
            let chktopiclist = [];
            topicList.map((data, index) => {
                //console.log(data)           
                if (hrcArray.indexOf(data.InterestTopicID) >= 0) {
                    chktopiclist.push({ check: true, title: data.TextTH });
                }
                else {
                    chktopiclist.push({ check: false, title: data.TextTH });
                }
            });
            activityArray.push({ ActivityName: data.ActivityName, ActivityDetail: data.ActivityDetail, year: data.year, topicList: chktopiclist });
            /*for(let i=0;i<imgdata[j].length;i++){
                //console.log(imgdata[j][i])
                activityArray.push(
                    {
                        image: imgdata[j][i],alignment: 'center',margin:[0,30,0,0]
                    })
            }*/
        }

        //console.log(chktopiclist)
        //console.log(activityArray)
        let testdata = [
            {
                "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QIJBywfp3IOswAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAkUlEQVQY052PMQqDQBREZ1f/d1kUm3SxkeAF/FdIjpOcw2vpKcRWCwsRPMFPsaIQSIoMr5pXDGNUFd9j8TOn7kRW71fvO5HTq6qqtnWtzh20IqE3YXtL0zyKwAROQLQ5l/c9gHjfKK6wMZjADE6s49Dver4/smEAc2CuqgwAYI5jU9NcxhHEy60sni986H9+vwG1yDHfK1jitgAAAABJRU5ErkJggg=="
            },
            {
                "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AcDDQ8FHr772QAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAo0lEQVQY02P8++/vfwYiABMDkYAFmeNfocXw6t5XBgYGBgYxJW6GjR3X4HKMMKv9K7QY3jz6hmKKqAI3w4a2q6gmvnn4lYGNi4WBhY2JgYWNiYGVgxm71aKKPAxsnMwMzFCFbJxMDEzMjJgK9T0kGV4/+MLAysHMwMLGyMDKycIgyi+B6UYGBgaGGecyGN58e8LAxsTBIM6jxBCv14VdIVXCEQAMjCktnpOJWwAAAABJRU5ErkJggg=="
            }
        ]
        loadFile(
            templatefile,
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    parser: angularParser,
                    paragraphLoop: true,
                    linebreaks: true,
                });
                let subdistrict = allSubDistrictList.find((data) => {
                    return data.SubDistrictID === subDistricCode
                })
                let district = allDistrictList.find((data) => {
                    return data.DistrictID === districtCode
                })
                let province = provinceList.find((data) => {
                    return data.ProvinceID === provinceCode
                })
                let titletxt = titleList.find((data) => {
                    return data.TitleID === coTitleID
                })
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                //console.log(coProperty1Extra)                
                doc.render({
                    docNo: docNo,
                    organization: organization,
                    reciveAppendixNo: reciveAppendixNo,
                    reciveDate: reciveDate,
                    docDate: docDate,
                    netHouseNo: netHouseNo,
                    subdistrict: subdistrict.SubDistrictTH,
                    district: district.DistrictTH,
                    province: province.ProvinceTH,
                    phone: phone.trim().length === 0 ? '-' : phone,
                    fax: fax.trim().length === 0 ? '-' : fax,
                    coTitleID: Number(coTitleID),
                    titletxt: Number(coTitleID) > 3 ? titletxt.TitleNameTH : '\t\t',
                    coFirstName: coFirstName,
                    coLastName: coLastName,
                    coPosition: coPosition,
                    coPhone: coPhone,
                    coFax: coFax,
                    coEmail: coEmail,
                    coProperty1: coProperty1,
                    coProperty2: coProperty2,
                    coProperty3: coProperty3,
                    coProperty4: coProperty4,
                    coProperty1Extra: coProperty1Extra,
                    activityArray: activityArray,
                    images: testdata,
                });
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let outputname = 'คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน';
                if (netType !== 'ORG') {
                    outputname = 'คำขอรับการจดแจ้งเป็นสภาวิชาชีพ';
                }
                saveAs(out, organization + "_" + outputname + ".docx");
                //var pdfBuffer = toPdf(out)
            }
        );
    }

    async function printDraftORGPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            THSarabunNew: {
                normal: 'THSarabunNew.ttf',
                bold: 'THSarabunNew-Bold.ttf',
                italics: 'THSarabunNew-Italic.ttf',
                bolditalics: 'THSarabunNew-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            FontAwesome: {
                normal: 'fontawesome-webfont.ttf'
            },
            Symbol: {
                normal: 'Symbol.ttf'
            },
            wingding: {
                normal: 'wingding.ttf'
            },
            WINGDNG2: {
                normal: 'WINGDNG2.TTF'
            },
        }

        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = bookNo + '/' + year;
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = regNo + '/' + regyear
        var reciveDate = utils.MariatoThaiDateOnly(regDate);
        var docDate = utils.MariatoThaiDateOnly(new Date);
        let subdistrict = allSubDistrictList.find((data) => {
            return data.SubDistrictID === subDistricCode
        })
        let district = allDistrictList.find((data) => {
            return data.DistrictID === districtCode
        })
        let province = provinceList.find((data) => {
            return data.ProvinceID === provinceCode
        })
        let titletxt = titleList.find((data) => {
            return data.TitleID === coTitleID
        })
        let titlename = [];
        if (Number(coTitleID) === 1)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นาย ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นาย ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) === 2)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นาง ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นาง ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) === 3)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นางสาว ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นางสาว ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) > 3)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'อื่นๆ ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'อื่นๆ ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        let activityArray = [];
        let hrcArray = [];
        let arr = [];
        let imgdata = [];/*await getBase64ImageFromURL(
            "https://images.pexels.com/photos/209640/pexels-photo-209640.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=300", 0,
            0,
            841,
            595
        )*/
        const promises = networkActivities.map(async (data, index) => {

            if (data.Images !== null) {
                //console.log(data.Images)
                imgdata[index] = [];
                for (let i = 0; i < data.Images.length; i++) {
                    const imgpreview = await getBase64ImageFromURL(
                        data.Images[i], 0,
                        0,
                        200,
                        200
                    )
                    imgdata[index].push(imgpreview);
                    //console.log(imgpreview);
                }
            }
            //console.log(index);
        });
        const numimage = await Promise.all(promises)
        //console.log(imgdata);
        /*networkActivities.map((data, index) => {
            //console.log(data)
            if (data.ToppicTypeIDList !== null) {
                arr = data.ToppicTypeIDList.split(',')
                //hrcArray = hrcArray.concat(arr)
                arr.forEach((element) => {
                    if (!hrcArray.includes(Number(element))) {
                        hrcArray.push(Number(element));
                    }
                });
            }
            activityArray.push({ text: '\n พ.ศ ' + data.year + '\n \t 1) ' + data.ActivityName + '\n \t 2) รายละเอียดผลงาน (โดยย่อ)' + data.ActivityDetail + '\n', bold: false, fontSize: 15, preserveLeadingSpaces: true });
            for(let i=0;i<imgdata[index].length;i++){
                console.log(imgdata[index][i])
                activityArray.push(
                    {
                        image: imgdata[index][i]
                    })
            }
        });*/
        for (let j = 0; j < networkActivities.length; j++) {
            let data = networkActivities[j];
            if (data.ToppicTypeIDList !== null) {
                arr = data.ToppicTypeIDList.split(',')
                //hrcArray = hrcArray.concat(arr)
                arr.forEach((element) => {
                    if (!hrcArray.includes(Number(element))) {
                        hrcArray.push(Number(element));
                    }
                });
            }
            let chktopiclist = [];
            //console.log(hrcArray)
            topicList.map((data, index) => {
                //console.log(hrcArray.indexOf(data.InterestTopicID))           
                if (hrcArray.indexOf(data.InterestTopicID) >= 0) {
                    chktopiclist.push({ check: true, title: data.TextTH });
                }
                else {
                    chktopiclist.push({ check: false, title: data.TextTH });
                }
            });
            const nhrclist = chktopiclist.map((data, index) => {
                if (data.check)
                    return { text: ["\t\t\t\t", { text: "\u00F9 ", style: 'WINGDNG2' }, { text: data.title, bold: false, fontSize: 15, preserveLeadingSpaces: false }] }
                else
                    return { text: ["\t\t\t\t", { text: "\u00F4 ", style: 'WINGDNG2' }, { text: data.title, bold: false, fontSize: 15, preserveLeadingSpaces: false }] }
            });

            activityArray.push({ text: '\n พ.ศ ' + data.year + '\n', alignment: 'center', bold: true, fontSize: 15, preserveLeadingSpaces: true });
            activityArray.push({ text: '1. ประเด็นการดำเนินงานด้านสิทธิมนุษยชน', bold: true, fontSize: 15, preserveLeadingSpaces: true });
            activityArray.push(nhrclist);
            activityArray.push({ text: [{ text: '2. ผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนอย่างต่อเนื่อง เป็นระยะเวลา\nไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง และมีผลการดำเนินงานเป็นที่ประจักษ์\n', bold: true, fontSize: 15, preserveLeadingSpaces: false }] });
            activityArray.push({ text: '\t1) ' + data.ActivityName, bold: false, fontSize: 15, preserveLeadingSpaces: true });
            activityArray.push({ text: '\t2) รายละเอียดผลงาน (โดยย่อ)', bold: false, fontSize: 15, preserveLeadingSpaces: true });
            activityArray.push({ text: data.ActivityDetail, bold: false, fontSize: 15, preserveLeadingSpaces: true });
            /*for(let i=0;i<imgdata[j].length;i++){
                //console.log(imgdata[j][i])
                activityArray.push(
                    {
                        image: imgdata[j][i],alignment: 'center',margin:[0,30,0,0]
                    })
            }*/
        }
        //console.log(activityArray)

        //console.log(chktopiclist)



        let phonetxt = phone.trim().length === 0 ? '-' : phone;
        let faxtxt = fax.trim().length === 0 ? '-' : fax;
        let othertitlename = '';
        othertitlename = Number(coTitleID) > 3 ? titletxt.TitleNameTH : '....................';
        let propoertieslist = [];
        if (coProperty1 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' เป็นองค์กรที่จัดตั้งขึ้นตามกฎหมายไทย \n\t\t\t\t\t โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง ' + coProperty1Extra, alignment: 'left', preserveLeadingSpaces: true }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' เป็นองค์กรที่จัดตั้งขึ้นตามกฎหมายไทย \n\t\t\t\t\t โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง ', alignment: 'left', preserveLeadingSpaces: true }] });
        if (coProperty2 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' มีวัตถุประสงค์ และการดําเนินการเกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน ', alignment: 'left', preserveLeadingSpaces: false }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' มีวัตถุประสงค์ และการดําเนินการเกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน ', alignment: 'left', preserveLeadingSpaces: false }] });
        if (coProperty3 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' มีการดำเนินการอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้งและมีผลการดำเนินงาน \n\t\t\t\t\t เป็นที่ประจักษ์ ', alignment: 'left', preserveLeadingSpaces: true }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' มีการดำเนินการอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้งและมีผลการดำเนินงาน \n\t\t\t\t\t เป็นที่ประจักษ์ ', alignment: 'left', preserveLeadingSpaces: true }] });
        if (coProperty4 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' ไม่มีวัตถุประสงค์ในทางการเมือง หรือแสวงหากําไรจากการดําเนินการ ', alignment: 'left', preserveLeadingSpaces: false }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' ไม่มีวัตถุประสงค์ในทางการเมือง หรือแสวงหากําไรจากการดําเนินการ ', alignment: 'left', preserveLeadingSpaces: false }] });
        var docDefinition = {
            content: [
                {
                    text: 'คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน\nตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560', fontSize: 15, bold: true, alignment: 'center', preserveLeadingSpaces: true
                },
                {
                    text: '\nเขียนที่ ...................................................................................', alignment: 'right', fontSize: 15, preserveLeadingSpaces: true
                },

                { text: '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tวันที่ ' + docDate, fontSize: 15, preserveLeadingSpaces: true, alignment: 'justify' },
                { text: 'ใบรับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชน', fontSize: 14, preserveLeadingSpaces: true },
                { text: 'และสภาวิชาชีพ เลขที่ สม. 0004.02.01/' + docNo, fontSize: 14, preserveLeadingSpaces: true },
                { text: [{ text: '\nเรียน', bold: true, fontSize: 15, preserveLeadingSpaces: true }, { text: '\t เลขาธิการคณะกรรมการสิทธิมนุษยชนแห่งชาติ', fontSize: 15, preserveLeadingSpaces: true }], },
                { text: '1. ข้อมูลทั่วไป', bold: true, fontSize: 15, preserveLeadingSpaces: true },
                {
                    text: [{
                        text: '\t\t\t\tด้วยองค์กร ' + organization + ' ที่ตั้งเลขที่ ' + netHouseNo + ' แขวง/ตำบล ' + subdistrict.SubDistrictTH + ' เขต/อำเภอ ' + district.DistrictTH +
                            ' จังหวัด ' + province.ProvinceTH + ' หมายเลขโทรศัพท์ ' + phonetxt + ' โทรสาร ' + faxtxt +
                            ' มีความประสงค์ให้สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ รับจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน ตามพระราชบัญญัติประกอบรัฐธรรมนูญ' +
                            ' ว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560 และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไข การจดแจ้ง' +
                            ' การรับจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม (ฉบับที่ 2) พ.ศ. 2563 ' +
                            'โดยมีผู้ประสานงาน คือ '
                    }, titlename[0], titlename[1], titlename[2], titlename[3], titlename[4], titlename[5], titlename[6], titlename[7], ' ระบุ ' + othertitlename + ' ชื่อ ' +
                    coFirstName + ' นามสกุล ' + coLastName + 'ตำแหน่ง ' + coPosition + ' โทรศัพท์ ' + coPhone + ' โทรสาร ' + coFax + ' EMAIL ' + coEmail]
                },
                { text: [{ text: '2. การรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร (โปรดใส่เครื่องหมาย √ ในช่อง   ', alignment: 'left', bold: true, fontSize: 15, preserveLeadingSpaces: true }, { text: "\u00F4 ", style: 'WINGDNG2' }, { text: 'ตามความเป็นจริงเกี่ยวกับองค์กร)', alignment: 'left', bold: true, fontSize: 15, preserveLeadingSpaces: true }] },

                { text: '\t\t\t\tขอรับรองว่า ' + organization + ' มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 7 แห่งระเบียบนี้', fontSize: 15, preserveLeadingSpaces: true, alignment: 'justify' },
                propoertieslist,
                {
                    text: 'สำหรับเจ้าหน้าที่ในการตรวจเอกสาร ',
                    fontSize: 9,
                    bold: true,
                    decoration: 'underline',
                    decorationColor: 'black',
                    absolutePosition: {
                        x: 40,
                        y: 580
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' สำเนาหนังสือรับรองหรือหนังสือสำคัญแสดงการจัดตั้ง', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 600
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' สำเนาข้อบังคับหรือระเบียบขององค์กรที่ระบุวัตถุประสงค์ และโครงสร้างขององค์กร', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 615
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' หลักฐานแสดงการแต่งตั้งกรรมการขององค์กร', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 630
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' รายงานผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน\n \t\t\t\t\t อย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง', alignment: 'left', preserveLeadingSpaces: true }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 645
                    },
                },
                {
                    text: 'ตรวจสอบแล้ว เอกสารถูกต้องครบถ้วน ............................................................ ผู้ตรวจสอบเอกสาร ',
                    fontSize: 9,
                    bold: true,
                    absolutePosition: {
                        x: 40,
                        y: 680
                    },
                },
                {
                    text: 'วันที่รับเอกสาร.................................................................................................................................',
                    fontSize: 9,
                    bold: true,
                    absolutePosition: {
                        x: 40,
                        y: 700
                    },
                },
                { text: '\n\n \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ขอแสดงความนับถือ', preserveLeadingSpaces: true },
                { text: '\n \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ลงชื่อ  (.........................................................)', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ผู้ยื่นคำขอ', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t (.........' + coFirstName + ' ' + coLastName + '..........)', alignment: 'center', preserveLeadingSpaces: true, pageBreak: 'after' },
                { text: 'รายละเอียดเอกสารเพิ่มเติม\nผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนเป็นระยะเวลา\nไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง และมีผลการดำเนินงานเป็นที่ประจักษ์', fontSize: 16, bold: true, alignment: 'center', preserveLeadingSpaces: true },
                { text: '\n' + organization, fontSize: 16, bold: true, alignment: 'center', preserveLeadingSpaces: true },

                //nhrclist,

                activityArray,
            ],
            defaultStyle: {
                font: 'THSarabunNew',
                fontSize: 15, alignment: 'justify', preserveLeadingSpaces: true
            },
            styles: {
                center:
                {
                    alignment: 'center'
                },
                WINGDNG2: {
                    'font': 'WINGDNG2',
                    'color': "#656565",
                    fontSize: 16
                }
            }
        };
        pdfMake.createPdf(docDefinition).open()
        /*
        var docDefinition = {
            content: [
                { text: "สร้าง PDF ภาษาไทยด้วย pdfmake ", fontSize: 15 },
                { text: "\uf046   ", style: 'fontawesome' },
            ],
            defaultStyle: {
                font: 'THSarabunNew'
            },
            styles: {
                fontawesome: {
                    'font': 'FontAwesome',
                    'color': "#656565"
                }
            }
        };
        pdfMake.createPdf(docDefinition).open()*/
    };
    async function printDraftPROWord() {
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = bookNo + '/' + year;
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = regNo + '/' + regyear
        var reciveDate = utils.MariatoThaiDateOnly(regDate);
        var docDate = utils.MariatoThaiDateOnly(new Date);
        let templatefile = process.env.REACT_APP_SITEURL + "/template/draftcorporate.docx";
        if (netType !== 'ORG') {
            templatefile = process.env.REACT_APP_SITEURL + "/template/draftprocouncil.docx";
        }

        //console.log(chktopiclist)
        //console.log(activityArray)
        loadFile(
            templatefile,
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    parser: angularParser,
                    paragraphLoop: true,
                    linebreaks: true,
                });
                let subdistrict = allSubDistrictList.find((data) => {
                    return data.SubDistrictID === subDistricCode
                })
                let district = allDistrictList.find((data) => {
                    return data.DistrictID === districtCode
                })
                let province = provinceList.find((data) => {
                    return data.ProvinceID === provinceCode
                })
                let titletxt = titleList.find((data) => {
                    return data.TitleID === coTitleID
                })
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                //console.log(coProperty1Extra)                
                doc.render({
                    docNo: docNo,
                    organization: organization,
                    reciveAppendixNo: reciveAppendixNo,
                    reciveDate: reciveDate,
                    docDate: docDate,
                    netHouseNo: netHouseNo,
                    subdistrict: subdistrict.SubDistrictTH,
                    district: district.DistrictTH,
                    province: province.ProvinceTH,
                    phone: phone.trim().length === 0 ? '-' : phone,
                    fax: fax.trim().length === 0 ? '-' : fax,
                    coTitleID: Number(coTitleID),
                    titletxt: Number(coTitleID) > 3 ? titletxt.TitleNameTH : '\t\t',
                    coFirstName: coFirstName,
                    coLastName: coLastName,
                    coPosition: coPosition,
                    coPhone: coPhone,
                    coFax: coFax,
                    coEmail: coEmail,
                    coProperty1: coProperty1,
                    coProperty2: coProperty2,
                    coProperty3: coProperty3,
                    coProperty4: coProperty4,
                    coProperty1Extra: coProperty1Extra,
                    //topicList: chktopiclist,
                    //activityArray: activityArray,
                });
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let outputname = 'คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน';
                if (netType !== 'ORG') {
                    outputname = 'คำขอรับการจดแจ้งเป็นสภาวิชาชีพ';
                }
                saveAs(out, organization + "_" + outputname + ".docx");
                //var pdfBuffer = toPdf(out)
            }
        );
    }

    async function printDraftPROPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            THSarabunNew: {
                normal: 'THSarabunNew.ttf',
                bold: 'THSarabunNew-Bold.ttf',
                italics: 'THSarabunNew-Italic.ttf',
                bolditalics: 'THSarabunNew-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            FontAwesome: {
                normal: 'fontawesome-webfont.ttf'
            },
            Symbol: {
                normal: 'Symbol.ttf'
            },
            wingding: {
                normal: 'wingding.ttf'
            },
            WINGDNG2: {
                normal: 'WINGDNG2.TTF'
            },
        }

        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = bookNo + '/' + year;
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = regNo + '/' + regyear
        var reciveDate = utils.MariatoThaiDateOnly(regDate);
        var docDate = utils.MariatoThaiDateOnly(new Date);
        let subdistrict = allSubDistrictList.find((data) => {
            return data.SubDistrictID === subDistricCode
        })
        let district = allDistrictList.find((data) => {
            return data.DistrictID === districtCode
        })
        let province = provinceList.find((data) => {
            return data.ProvinceID === provinceCode
        })
        let titletxt = titleList.find((data) => {
            return data.TitleID === coTitleID
        })
        let titlename = [];
        if (Number(coTitleID) === 1)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นาย ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นาย ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) === 2)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นาง ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นาง ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) === 3)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'นางสาว ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'นางสาว ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        if (Number(coTitleID) > 3)
            titlename.push({ text: "\u0052", style: 'WINGDNG2' }, { text: 'อื่นๆ ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        else
            titlename.push({ text: "\u00A3", style: 'WINGDNG2' }, { text: 'อื่นๆ ', bold: false, fontSize: 15, preserveLeadingSpaces: false });
        let activityArray = [];
        let hrcArray = [];
        let arr = [];
        networkActivities.map((data, index) => {
            //console.log(data)
            if (data.ToppicTypeIDList !== null) {
                arr = data.ToppicTypeIDList.split(',')
                //hrcArray = hrcArray.concat(arr)
                arr.forEach((element) => {
                    if (!hrcArray.includes(Number(element))) {
                        hrcArray.push(Number(element));
                    }
                });
            }
            activityArray.push({ text: '\n พ.ศ ' + data.year + '\n \t 1) ' + data.ActivityName + '\n \t 2) รายละเอียดผลงาน (โดยย่อ)' + data.ActivityDetail + '\n', bold: false, fontSize: 15, preserveLeadingSpaces: true })
        });
        let chktopiclist = [];
        //console.log(hrcArray)
        topicList.map((data, index) => {
            //console.log(hrcArray.indexOf(data.InterestTopicID))           
            if (hrcArray.indexOf(data.InterestTopicID) >= 0) {
                chktopiclist.push({ check: true, title: data.TextTH });
            }
            else {
                chktopiclist.push({ check: false, title: data.TextTH });
            }
        });
        //console.log(chktopiclist)

        const nhrclist = chktopiclist.map((data, index) => {
            if (data.check)
                return { text: ["\t\t\t\t", { text: "\u00F9 ", style: 'WINGDNG2' }, { text: data.title, bold: false, fontSize: 15, preserveLeadingSpaces: false }] }
            else
                return { text: ["\t\t\t\t", { text: "\u00F4 ", style: 'WINGDNG2' }, { text: data.title, bold: false, fontSize: 15, preserveLeadingSpaces: false }] }
        });

        let phonetxt = phone.trim().length === 0 ? '-' : phone;
        let faxtxt = fax.trim().length === 0 ? '-' : fax;
        let othertitlename = '';
        othertitlename = Number(coTitleID) > 3 ? titletxt.TitleNameTH : '....................';
        let propoertieslist = [];
        if (coProperty1 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' เป็นสภาวิชาชีพตามกฎหมายไทย', alignment: 'left', preserveLeadingSpaces: true }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' เป็นสภาวิชาชีพตามกฎหมายไทย', alignment: 'left', preserveLeadingSpaces: true }] });
        if (coProperty2 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' มีหลักฐานการรับรองหรือมีคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' มีหลักฐานการรับรองหรือมีคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }] });
        if (coProperty3 === true)
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F9", style: 'WINGDNG2' }, { text: ' สภาวิชาชีพนี้มีการให้ความเห็นชอบในการยื่นคำขอจดแจ้งสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: true }] });
        else
            propoertieslist.push({ text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2' }, { text: ' สภาวิชาชีพนี้มีการให้ความเห็นชอบในการยื่นคำขอจดแจ้งสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: true }] });
        var docDefinition = {
            content: [
                {
                    text: 'คำขอรับการจดแจ้งเป็นสภาวิชาชีพ \nตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560', fontSize: 15, bold: true, alignment: 'center', preserveLeadingSpaces: true
                },
                {
                    text: '\nเขียนที่ ...................................................................................', alignment: 'right', fontSize: 15, preserveLeadingSpaces: true
                },

                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tวันที่ ' + docDate, fontSize: 15, preserveLeadingSpaces: true, alignment: 'justify' },
                {
                    text: 'ใบรับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชน', fontSize: 14, preserveLeadingSpaces: true, absolutePosition: {
                        x: 40,
                        y: 100
                    },
                },
                {
                    text: 'และสภาวิชาชีพ เลขที่ สม. 0004.02.01/' + docNo, fontSize: 14, preserveLeadingSpaces: true, absolutePosition: {
                        x: 40,
                        y: 115
                    },
                },
                { text: [{ text: '\nเรียน', bold: true, fontSize: 15, preserveLeadingSpaces: true }, { text: '\t เลขาธิการคณะกรรมการสิทธิมนุษยชนแห่งชาติ', fontSize: 15, preserveLeadingSpaces: true }], },
                { text: '1. ข้อมูลทั่วไป', bold: true, fontSize: 15, preserveLeadingSpaces: true },
                {
                    text: [{
                        text: '\t\t\t\tด้วยองค์กร ' + organization + ' ที่ตั้งเลขที่ ' + netHouseNo + ' แขวง/ตำบล ' + subdistrict.SubDistrictTH + ' เขต/อำเภอ ' + district.DistrictTH +
                            ' จังหวัด ' + province.ProvinceTH + ' หมายเลขโทรศัพท์ ' + phonetxt + ' โทรสาร ' + faxtxt +
                            ' มีความประสงค์ให้สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ รับจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน ตามพระราชบัญญัติประกอบรัฐธรรมนูญ' +
                            ' ว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560 และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไข การจดแจ้ง' +
                            ' การรับจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม (ฉบับที่ 2) พ.ศ. 2563 ' +
                            'โดยมีผู้ประสานงาน คือ '
                    }, titlename[0], titlename[1], titlename[2], titlename[3], titlename[4], titlename[5], titlename[6], titlename[7], ' ระบุ ' + othertitlename + ' ชื่อ ' +
                    coFirstName + ' นามสกุล ' + coLastName + 'ตำแหน่ง ' + coPosition + ' โทรศัพท์ ' + coPhone + ' โทรสาร ' + coFax + ' EMAIL ' + coEmail]
                },
                { text: [{ text: '2. การรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร (โปรดใส่เครื่องหมาย √ ในช่อง   ', alignment: 'left', bold: true, fontSize: 15, preserveLeadingSpaces: true }, { text: "\u00F4 ", style: 'WINGDNG2' }, { text: 'ตามความเป็นจริงเกี่ยวกับองค์กร)', alignment: 'left', bold: true, fontSize: 15, preserveLeadingSpaces: true }] },

                { text: '\t\t\t\tขอรับรองว่า ' + organization + ' มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 8 แห่งระเบียบนี้', fontSize: 15, preserveLeadingSpaces: true, alignment: 'justify' },
                propoertieslist,
                {
                    text: 'สำหรับเจ้าหน้าที่ในการตรวจเอกสาร ',
                    fontSize: 9,
                    bold: true,
                    decoration: 'underline',
                    decorationColor: 'black',
                    absolutePosition: {
                        x: 40,
                        y: 520
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' หลักฐานการเป็นสภาวิชาชีพตามกฎหมายไทย', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 540
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' สำเนาหนังสือรับรองหรือคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 555
                    },
                },
                {
                    text: ["\t\t\t\t", { text: "\u00F4", style: 'WINGDNG2', fontSize: 9, }, { text: ' หลักฐานการให้ความเห็นชอบในการยื่นคาขอจดแจ้งสภาวิชาชีพ', alignment: 'left', preserveLeadingSpaces: false }],
                    fontSize: 9,
                    absolutePosition: {
                        x: 40,
                        y: 570
                    },
                },
                {
                    text: 'ตรวจสอบแล้ว เอกสารถูกต้องครบถ้วน ............................................................ ผู้ตรวจสอบเอกสาร ',
                    fontSize: 9,
                    bold: true,
                    absolutePosition: {
                        x: 40,
                        y: 595
                    },
                },
                {
                    text: 'วันที่รับเอกสาร.................................................................................................................................',
                    fontSize: 9,
                    bold: true,
                    absolutePosition: {
                        x: 40,
                        y: 610
                    },
                },
                { text: '\n\n \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ขอแสดงความนับถือ', preserveLeadingSpaces: true },
                { text: '\n \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ลงชื่อ  (.........................................................)', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ผู้ยื่นคำขอ', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t (...................................................................)', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ตำแหน่ง', preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t ..................................................................', preserveLeadingSpaces: true },
            ],
            defaultStyle: {
                font: 'THSarabunNew',
                fontSize: 15, alignment: 'justify', preserveLeadingSpaces: true
            },
            styles: {
                center:
                {
                    alignment: 'center'
                },
                WINGDNG2: {
                    'font': 'WINGDNG2',
                    'color': "#656565",
                    fontSize: 16
                }
            }
        };
        pdfMake.createPdf(docDefinition).open()
        /*
        var docDefinition = {
            content: [
                { text: "สร้าง PDF ภาษาไทยด้วย pdfmake ", fontSize: 15 },
                { text: "\uf046   ", style: 'fontawesome' },
            ],
            defaultStyle: {
                font: 'THSarabunNew'
            },
            styles: {
                fontawesome: {
                    'font': 'FontAwesome',
                    'color': "#656565"
                }
            }
        };
        pdfMake.createPdf(docDefinition).open()*/
    };
    function printDraftWord() {
        if (netType === 'ORG') {
            printDraftORGWord();
        }
        else {
            printDraftPROWord();
        }
    }
    function printDraftPDF() {
        if (netType === 'ORG') {
            printDraftORGPDF();
        }
        else {
            printDraftPROPDF();
        }
    }
    async function printCompletWord() {
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        console.log(bookNo)
        var docNo = utils.thaiNumber(bookNo) + '/' + utils.thaiNumber(year);
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = 'สม ๐๐๐๔.๐๒.๐๑/' + utils.thaiNumber(regNo) + '/' + utils.thaiNumber(regyear);
        var reciveDate = utils.MariatoThaiDateNumberOnly(regDate);
        var docDate = utils.MariatoThaiDateNumberOnly(new Date);
        loadFile(
            process.env.REACT_APP_SITEURL + "/template/complete.docx",
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render({
                    docNo: docNo,
                    organization: organization,
                    reciveAppendixNo: reciveAppendixNo,
                    reciveDate: reciveDate,
                    docDate: docDate,
                });
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                saveAs(out, organization + "_เอกสารครบถ้วน.docx");
            }
        );
    }

    async function printCompletPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            THSarabunNew: {
                normal: 'THSarabunNew.ttf',
                bold: 'THSarabunNew-Bold.ttf',
                italics: 'THSarabunNew-Italic.ttf',
                bolditalics: 'THSarabunNew-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            FontAwesome: {
                normal: 'fontawesome-webfont.ttf'
            },
            Symbol: {
                normal: 'Symbol.ttf'
            }
        }
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = utils.thaiNumber(bookNo) + '/' + utils.thaiNumber(year);
        //console.log(year)
        //var organization='ชื่อองค์กรที่ขอ';
        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = 'สม ๐๐๐๔.๐๒.๐๑/' + utils.thaiNumber(regNo) + '/' + utils.thaiNumber(regyear);

        var reciveDate = utils.MariatoThaiDateNumberOnly(regDate);
        //var reciveNo = utils.thaiNumber(bookNo);
        var docDate = utils.MariatoThaiDateNumberOnly(new Date);
        var docDefinition = {
            content: [
                { image: await getBase64ImageFromURL(process.env.REACT_APP_SITEURL + '/images/garuda.jpg', 200, 10, 90, 100), style: 'center' },
                { text: 'ที่ สม ๐๐๐๔.๐๒.๐๑/' + docNo + '\n', fontSize: 16, margin: [0, -15, 0, 16] },
                { text: 'ถึง ' + organization + '\n', fontSize: 16, margin: [0, 0, 0, 16] },
                {
                    text: '\n\t\t\t\tตามที่ ' + organization + ' ได้ยื่นคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ ไปยังสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ ' +
                        'ตามพระราชยัญญัติประกอบรัฐธรรมนูญว่าด้วย คณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. ๒๕๖๐ และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติ ว่าด้วยหลักเกณฑ์ ' +
                        'วิธีการ และเงื่อนไขการจดแจ้ง การรับจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. ๒๕๖๑ และที่แก้ไขเพิ่มเติม (ฉบับที่ ๒) ' +
                        'พ.ศ. ๒๕๖๓ นั้น', fontSize: 16, alignment: 'justify', preserveLeadingSpaces: true
                },
                {
                    text: '\n\t\t\t\tสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ ได้รับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนดังกล่าวแล้ว ตามเอกสารใบรับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชี เลขที่ ' + reciveAppendixNo +
                        ' ลงวันที่ ' + reciveDate, fontSize: 16, preserveLeadingSpaces: true
                },
                { text: '\t\t\t\tจึงเรียนมาเพื่อโปรดทราบ', fontSize: 16, preserveLeadingSpaces: true, alignment: 'justify' },
                { text: '\n\n\n\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ', fontSize: 16, preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t' + docDate + '', fontSize: 16, preserveLeadingSpaces: true },
                { text: '\n\n\nสำนักส่งเสริมการเคารพสิทธิมนุษยชน', fontSize: 16, preserveLeadingSpaces: true },
                { text: 'กลุ่มงานพัฒนาความร่วมมือเครือข่าย', fontSize: 16 },
                { text: 'โทร. ๐ ๒๑๔๑ ๓๙๒๓ - ๗', fontSize: 16 },
                { text: 'โทรสาร ๐ ๒๑๔๓ ๙๕๗๖', fontSize: 16 },
                { text: '\n\n\n\nหมายเหตุ :', fontSize: 14 },
                { text: 'เลขที่เอกสารฉบับนี้ ใช้เป็นหลักฐานอ้างอิงสำหรับการยื่นคำขอการจดแจ้งเป็นองค์กรเอกชน ด้านสิทธิมนุษยชนและสภาวิชาชีพในระบบออนไลน์เท่านั้น', fontSize: 14 },
            ],
            defaultStyle: {
                font: 'THSarabunNew'
            },
            styles: {
                center:
                {
                    alignment: 'center'
                }

            }
        };
        pdfMake.createPdf(docDefinition).open()
    };

    async function printUncompletWord() {
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = utils.thaiNumber(bookNo) + '/' + utils.thaiNumber(year);

        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = 'ที่ สม ๐๐๐๔.๐๒.๐๑/' + utils.thaiNumber(regNo) + '/' + utils.thaiNumber(regyear);
        var reciveDate = utils.MariatoThaiDateNumberOnly(regDate);
        var docDate = utils.MariatoThaiDateNumberOnly(new Date);
        var maxid = 0;
        let paragraphArray = []
        uncompleteDocInput.filter(function (ids) {
            return ids.trim().length > 0;
        }).map((title, index) => (
            paragraphArray.push({ thainumber: utils.thaiNumber(maxid = maxid + 1) + '. ', title: title })
        ));
        uncompleteImageInput.filter(function (ids) {
            return ids.trim().length > 0;
        }).map((title, index) => (
            paragraphArray.push({ thainumber: utils.thaiNumber(maxid = maxid + 1) + '. ', title: title })
        ));
        loadFile(
            process.env.REACT_APP_SITEURL + "/template/uncomplete.docx",
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render({
                    docNo: docNo,
                    organization: organization,
                    reciveAppendixNo: reciveAppendixNo,
                    reciveDate: reciveDate,
                    docDate: docDate,
                    require: paragraphArray
                });
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                saveAs(out, organization + "_เอกสารไม่ครบถ้วน.docx");
            }
        );
    }
    async function printUncompletPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            THSarabunNew: {
                normal: 'THSarabunNew.ttf',
                bold: 'THSarabunNew-Bold.ttf',
                italics: 'THSarabunNew-Italic.ttf',
                bolditalics: 'THSarabunNew-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            FontAwesome: {
                normal: 'fontawesome-webfont.ttf'
            },
            Symbol: {
                normal: 'Symbol.ttf'
            }
        }
        const d = new Date();
        let year = Number(d.getFullYear()) + 543;
        var docNo = utils.thaiNumber(bookNo) + '/' + utils.thaiNumber(year);

        const e = new Date(regDate);
        let regyear = Number(e.getFullYear()) + 543;
        var reciveAppendixNo = 'ที่ สม ๐๐๐๔.๐๒.๐๑/' + utils.thaiNumber(regNo) + '/' + utils.thaiNumber(regyear);
        var reciveDate = utils.MariatoThaiDateNumberOnly(regDate);
        var docDate = utils.MariatoThaiDateNumberOnly(new Date);
        var maxid;
        const doclist = uncompleteDocInput.filter(function (ids) {
            return ids.trim().length > 0;
        }).map((title, index) => (
            { text: '\t\t\t\t\uf046 ' + utils.thaiNumber(maxid = index + 1) + '. ' + title, fontSize: 16, preserveLeadingSpaces: true }
        ));
        const imglist = uncompleteImageInput.filter(function (ids) {
            return ids.trim().length > 0;
        }).map((title, index) => (
            { text: '\t\t\t\t\uf046 ' + utils.thaiNumber(maxid = maxid + 1) + '. ' + title, fontSize: 16, preserveLeadingSpaces: true }
        ));
        var docDefinition = {
            content: [
                { image: await getBase64ImageFromURL(process.env.REACT_APP_SITEURL + '/images/garuda.jpg', 200, 10, 90, 100), style: 'center' },
                { text: 'ที่ สม ๐๐๐๔.๐๒.๐๑/' + docNo + '\n', fontSize: 16, margin: [0, -15, 0, 16] },
                { text: 'ถึง ' + organization + '\n', fontSize: 16, margin: [0, 0, 0, 16] },
                {
                    text: '\n\t\t\t\tตามที่ ' + organization + ' ได้ยื่นคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ ไปยังสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ ' +
                        'ตามพระราชยัญญัติประกอบรัฐธรรมนูญว่าด้วย คณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. ๒๕๖๐ และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติ ว่าด้วยหลักเกณฑ์ ' +
                        'วิธีการ และเงื่อนไขการจดแจ้ง การรับจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. ๒๕๖๑ และที่แก้ไขเพิ่มเติม (ฉบับที่ ๒) ' +
                        'พ.ศ. ๒๕๖๓ นั้น', fontSize: 16, alignment: 'justify', preserveLeadingSpaces: true
                },
                {
                    text: '\t\t\t\tสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ ได้รับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชน ดังกล่าวแล้ว ตามเอกสารใบรับคำขอจดแจ้งการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ เลขที่ ' + reciveAppendixNo +
                        ' ลงวันที่ ' + reciveDate + ' และได้ตรวจสอบคำขอและเอกสารประกอบการพิจารณาดังกล่าวแล้วพบว่ายังมีเอกสารซึ่งต้องให้มีการชี้แจงเพิ่มเติม ' +
                        'เพื่อให้ ครบถ้วนตามหลักเกณฑ์ที่กำหนด จึงขอความกรุณาให้ท่านจัดส่งเอกสารเพิ่มเติมไปยังสำนักงานคณะกรรมการสิทธิมนุษยชน แห่งชาติ ดังนี้', fontSize: 16, alignment: 'justify', preserveLeadingSpaces: true
                },
                doclist,
                imglist,
                {
                    text: '\t\t\t\tอนึ่ง หากไม่ดำเนนการส่งเอกสารภายใน ๑๕ วัน นับแต่วันที่ได้รับหนังสือฉบับนี้ สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติจะพิจารณาไม่รับคำขอดังกล่าว แต่ไม่ตัดสิทธิให้ ' +
                        organization + 'สามารถยื่นคำขอใหม่ได้ภายหลัง', fontSize: 16, preserveLeadingSpaces: true
                },
                { text: '\t\t\t\tจึงเรียนมาเพื่อโปรดพิจารณาดำเนินการต่อไปด้วย จักขอบคุณมาก', fontSize: 16, alignment: 'justify', preserveLeadingSpaces: true },
                { text: '\n\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ', fontSize: 16, preserveLeadingSpaces: true },
                { text: '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t' + docDate, fontSize: 16, preserveLeadingSpaces: true },
                { text: 'สำนักส่งเสริมการเคารพสิทธิมนุษยชน', fontSize: 16, preserveLeadingSpaces: true },
                { text: 'กลุ่มงานพัฒนาความร่วมมือเครือข่าย', fontSize: 16 },
                { text: 'โทร. ๐ ๒๑๔๑ ๓๙๒๓ - ๗', fontSize: 16 },
                { text: 'โทรสาร ๐ ๒๑๔๓ ๙๕๗๖', fontSize: 16 },
                {
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        'หมายเหตุ :\n',
                                        { text: 'เลขที่เอกสารฉบับนี้ ใช้เป็นหลักฐานอ้างอิงสำหรับการยื่นคำขอการจดแจ้งเป็นองค์กรเอกชน ด้านสิทธิมนุษยชนและสภาวิชาชีพในระบบออนไลน์เท่านั้น', fontSize: 14, bold: false },
                                    ], fontSize: 16, bold: true
                                }
                            ],
                        ]
                    },
                    layout: {
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'green' : 'green';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'green' : 'green';
                        },
                        paddingLeft: function (i, node) { return 5; },
                        paddingRight: function (i, node) { return 5; },
                        paddingTop: function (i, node) { return 5; },
                        paddingBottom: function (i, node) { return 5; }
                    }
                }
            ],
            defaultStyle: {
                font: 'THSarabunNew',
            },
            styles: {
                center:
                {
                    alignment: 'center'
                }

            }
        };
        pdfMake.createPdf(docDefinition).open()
    };
    const printPDF = (complete) => {
        //console.log(complete)
        if (complete) {
            printCompletPDF();
        }
        else {
            if (uncompleteDocInput.length === 1 && uncompleteImageInput.length === 1) {
                if (uncompleteDocInput[0].length === 0 && uncompleteImageInput[0].length === 0) {
                    let serrors = {};
                    serrors["uncompleteImageInput"] = "กรุณากรอกรายละเอียดในส่วนของเอกสารหรือรูปภาพที่ต้องการเพิ่มเติม";
                    setError(serrors);
                    //console.log(errors)
                }
                else {
                    setError({});
                    printUncompletPDF();

                }
            }
            else {
                printUncompletPDF();
            }
        }
    }
    const printWord = (complete) => {
        //console.log(complete)
        if (complete) {
            printCompletWord();
        }
        else {
            if (uncompleteDocInput.length === 1 && uncompleteImageInput.length === 1) {
                if (uncompleteDocInput[0].length === 0 && uncompleteImageInput[0].length === 0) {
                    let serrors = {};
                    serrors["uncompleteImageInput"] = "กรุณากรอกรายละเอียดในส่วนของเอกสารหรือรูปภาพที่ต้องการเพิ่มเติม";
                    setError(serrors);
                    //console.log(errors)
                }
                else {
                    setError({});
                    printUncompletWord();

                }
            }
            else {
                printUncompletWord();
            }
        }
    }
    const handleCompleteDocFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setCompleteDocFiles(imageFile);
    };
    const handleUncompleteDocFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setUncompleteDocFiles(imageFile);
    };
    const handleMemberAnnouceFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setMemberAnnounceFile(imageFile);
    };
    const handleCertifyFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setCertifyFiles(imageFile);
    };
    const handleUncertifyFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setUncertifyFiles(imageFile);
    };
    const handleRemoveCompleteDocFile = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", completeDocFiles.file);
        formData.append("fieldName", 'CompleteDocFilePath');
        formData.append("networkID", networkid);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteCorporateNetworkFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setCompleteDocFiles(null);
    };
    const handleRemoveUncompleteDocFile = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", uncompleteDocFiles.file);
        formData.append("fieldName", 'UncompleteDocFilePath');
        formData.append("networkID", networkid);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteCorporateNetworkFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setUncompleteDocFiles(null);
    };
    const handleRevokeFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setRevokeFiles(imageFile);
    };
    const RenderCertifyAction = (State) => {
        switch (State) {
            case 'COMPLETE_DOC':
                return (
                    <>
                        <div className="col-12">
                            <label htmlFor="completeDocRemark" >หมายเหตุ</label>
                            <div>
                                <textarea className="form-control" name="completeDocRemark" id="completeDocRemark" rows="8" value={completeDocRemark} onChange={e => { SetCompleteDocRemark(e.target.value) }}></textarea>
                                <SError error={errors["completeDocRemark"]} />
                            </div>
                        </div>

                        <div className="col-12">
                            <a href={CompleteDocFilePath} target='_blank'> หนังสือประทับตรารับเอกสาร</a>
                            <Dropzone
                                className="input-dropzone dropzone"
                                multiple={false}
                                onDrop={handleCompleteDocFilesOnDrop}
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                            >

                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <SError error={errors["completeDocFiles"]} />
                                        <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                        </div>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                        </div>
                                        <aside>
                                            <SingleFileTable filedata={completeDocFiles} removefn={handleRemoveCompleteDocFile} />
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="col-12 mt-4">
                            <button type="button" className="btn btn-outline-danger float-right" onClick={e => { e.preventDefault(); printPDF(true); }}><i className="far fa-file-pdf"></i> ปริ้น</button>
                            <button type="button" className="btn btn-outline-primary float-right" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printWord(true); }}><i className="far fa-file-word"></i> ปริ้น</button>
                        </div>

                    </>
                );
            case 'NEED_DOC':
                return (
                    <>
                        <div className="col-12 mt-2">
                            <div className="row">
                                {/*<h4 className="ml-2 col-12">เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา</h4>*/}
                                <div className="col-9">
                                    {uncompleteDocInput.map((value, i) => (
                                        <div key={i} className="row mt-2">
                                            <div className="col-10">
                                                <input type="text" className="form-control " id="uncompleteDocInput" value={value} name="uncompleteDocInput" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setUncompleteDocInput(
                                                        uncompleteDocInput.map((value, j) => {
                                                            if (i === j) value = e.target.value;
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <span className="col-2"><a onClick={(e) => { e.preventDefault(); handleRemoveCompleteDocClick(value) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                        </div>
                                    ))
                                    }
                                </div>
                                <div className="col-3"><a href="/" onClick={(e) => { e.preventDefault(); setUncompleteDocInput(uncompleteDocInput.concat("")) }}><i className="fas fa-plus-circle"></i> เพิ่มหัวข้อเอกสาร</a></div>
                            </div>

                        </div>
                        {/*<div className="col-12 mt-2">
                            <div className="row">
                                <h4 className="ml-2 col-12">รูปถ่ายประกอบการพิจารณา</h4>
                                <div className="col-9">
                                    {uncompleteImageInput.map((value, i) => (
                                        <div key={i} className="row mt-2">
                                            <div className="col-10">
                                                <input type="text" className="form-control " id="uncompleteImageInput" value={value} name="uncompleteImageInput" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setUncompleteImageInput(
                                                        uncompleteImageInput.map((value, j) => {
                                                            if (i === j) value = e.target.value;
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <span className="col-2"><a onClick={(e) => { e.preventDefault(); handleRemoveCompleteImageClick(value) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                        </div>
                                    ))
                                    }
                                </div>
                                <div className="col-3"><a href="/" onClick={(e) => { e.preventDefault(); setUncompleteImageInput(uncompleteImageInput.concat("")) }}><i className="fas fa-plus-circle"></i> เพิ่มหัวข้อรูปถ่าย</a></div>
                            </div>
                            <SError error={errors["uncompleteImageInput"]} />
                                </div>*/}
                        <div className="col-12 mt-2">
                            <div className="comment-box">
                                <label htmlFor="uncompleteDocRemark" >หมายเหตุ</label>
                                <textarea className="form-control" name="uncompleteDocRemark" id="uncompleteDocRemark" rows="8" value={uncompleteDocRemark} onChange={e => { SetUncompleteDocRemark(e.target.value) }}></textarea>
                                <SError error={errors["uncompleteDocRemark"]} />
                            </div>
                        </div>
                        <div className="col-12">
                            <label>หนังสือประทับตรารับเอกสาร -กรณีเอกสารไม่ครบถ้วน</label>
                            <Dropzone
                                className="input-dropzone dropzone"
                                multiple={false}
                                onDrop={handleUncompleteDocFilesOnDrop}
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <SError error={errors["uncompleteDocFiles"]} />
                                        <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                        </div>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                        </div>
                                        <aside>
                                            <SingleFileTable filedata={uncompleteDocFiles} removefn={handleRemoveUncompleteDocFile} />
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <div className="col-12 mt-4">
                            <button type="button" className="btn btn-outline-danger float-right" onClick={e => { e.preventDefault(); printPDF(false); }}><i className="far fa-file-pdf"></i> ปริ้น</button>
                            <button type="button" className="btn btn-outline-primary float-right" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printWord(false); }}><i className="far fa-file-word"></i> ปริ้น</button>
                        </div>
                    </>
                );
            case 'DECLINE':
                return (<>
                    <div className="col-12">
                        <label htmlFor="declineRemark" >หมายเหตุ</label>
                        <div>
                            <textarea className="form-control" name="declineRemark" id="declineRemark" rows="8" value={declineRemark} onChange={e => { setDeclineRemark(e.target.value) }}></textarea>
                            <SError error={errors["declineRemark"]} />
                        </div>
                    </div>
                </>);
            case 'CERTIFY':
                return (
                    <>
                        <div className="col-12">
                            {(MemberAnnounceFilePath.trim().length > 0) && (<a href={MemberAnnounceFilePath} target='_blank'> ไฟล์ประกาศรับจดแจ้ง</a>)}
                            {(MemberAnnounceFilePath.trim().length == 0) && (<label> ไฟล์ประกาศรับจดแจ้ง</label>)}
                            <Dropzone
                                className="input-dropzone dropzone"
                                multiple={false}
                                onDrop={handleMemberAnnouceFilesOnDrop}
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <SError error={errors["memberAnnounceFile"]} />
                                        <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                        </div>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                        </div>
                                        <aside>
                                            <SingleFileTable filedata={memberAnnounceFile} removefn={setMemberAnnounceFile} />
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <div className="col-12">
                            {(MemberResultFilePath.trim().length > 0) && (<a href={MemberResultFilePath} target='_blank'> หนังสือแจ้งผล</a>)}
                            {(MemberResultFilePath.trim().length == 0) && (<label> หนังสือแจ้งผล</label>)}
                            <Dropzone
                                className="input-dropzone dropzone"
                                multiple={false}
                                onDrop={handleCertifyFilesOnDrop}
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <SError error={errors["certifyFiles"]} />
                                        <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                        </div>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                        </div>
                                        <aside>
                                            <SingleFileTable filedata={certifyFiles} removefn={setCertifyFiles} />
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="col-5">
                            <label className="required">วันที่ประกาศ</label>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                    pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                    {SetPublishStartDate} name="publishstartDate" id="publishstartDate" />
                            </MuiPickersUtilsProvider>
                            <SError error={errors["publishstartDate"]} />
                        </div>

                        <div className="col-12">
                            <label htmlFor="certifyRemark" >หมายเหตุ</label>
                            <div>
                                <textarea className="form-control" name="certifyRemark" id="certifyRemark" rows="8" value={certifyRemark} onChange={e => { SetCertifyRemark(e.target.value) }}></textarea>
                                <SError error={errors["certifyRemark"]} />
                            </div>
                        </div>

                    </>
                );
            case 'NOT_CERTIFY':
                return (
                    <>
                        <div className="col-12">
                            {(NotCertifyResultFilePath.trim().length > 0) && (<a href={NotCertifyResultFilePath} target='_blank'> หนังสือแจ้งผลการพิจารณา</a>)}
                            {(NotCertifyResultFilePath.trim().length == 0) && (<label> หนังสือแจ้งผลการพิจารณา</label>)}
                            <Dropzone
                                className="input-dropzone dropzone"
                                multiple={false}
                                onDrop={handleUncertifyFilesOnDrop}
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <SError error={errors["uncertifyFiles"]} />
                                        <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                        </div>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                        </div>
                                        <aside>
                                            <SingleFileTable filedata={uncertifyFiles} removefn={setUncertifyFiles} />
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="col-12">
                            <label htmlFor="uncertifyRemark" >หมายเหตุ</label>
                            <div>
                                <textarea className="form-control" name="uncertifyRemark" id="uncertifyRemark" rows="8" value={uncertifyRemark} onChange={e => { SetUncertifyRemark(e.target.value) }}></textarea>
                                <SError error={errors["uncertifyRemark"]} />
                            </div>
                        </div>

                    </>
                );
            case 'TERMINATE':
                return (<>
                    <div className="col-12">
                        <label htmlFor="terminateRemark" >หมายเหตุ</label>
                        <div>
                            <textarea className="form-control" name="terminateRemark" id="terminateRemark" rows="8" value={terminateRemark} onChange={e => { setTerminateRemark(e.target.value) }}></textarea>
                            <SError error={errors["terminateRemark"]} />
                        </div>
                    </div>
                </>);
            case 'REVOKE':
                return (<>
                    <div className="col-12">
                        <label>หนังสือแจ้งเพิกถอนการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชนและสภาพวิชาชีพ</label>
                        <Dropzone
                            className="input-dropzone dropzone"
                            multiple={false}
                            onDrop={handleRevokeFilesOnDrop}
                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.jpg,.png,.zip,.rar"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                    <SError error={errors["revokeFiles"]} />
                                    <div className="noteText text-right ">
                                        หมายเหตุ: รองรับสกุลไฟล์ .docx .xls .pdf .pptx .jpg .png .zip มีขนาดไม่เกิน 10 MB
                                    </div>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                    </div>
                                    <aside>
                                        <SingleFileTable filedata={revokeFiles} removefn={setRevokeFiles} />
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="col-12">
                        <label htmlFor="revokeRemark" >หมายเหตุ</label>
                        <div>
                            <textarea className="form-control" name="revokeRemark" id="revokeRemark" rows="8" value={revokeRemark} onChange={e => { setRevokeRemark(e.target.value) }}></textarea>
                            <SError error={errors["revokeRemark"]} />
                        </div>
                    </div>
                </>);
            default:
                return (<div className="col-12">กรุณาเลือกสถานะการจัดการ การยื่นคำขอ</div>);
        }
    };
    const handleLogoOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        SetLogoFile(imageFile);
    };
    const LogoPreview = (
        (typeof logoFile.preview !== 'undefined') && (<img className="preview-upload" src={logoFile.preview} onChange={(e) => { e.target.src = URL.createObjectURL(logoFile); }} alt="Web Cover" />)
    );
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (organization.trim().length === 0) {
            formIsValid = false;
            serrors["organization"] = "กรุณาระบุชื่อ";
        }
        if (netHouseNo.trim().length === 0) {
            formIsValid = false;
            serrors["netHouseNo"] = "กรุณาระบุรายละเอียด";
        }
        if (Number(provinceCode) === 0) {
            formIsValid = false;
            serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
        }
        if (Number(districtCode) === 0) {
            formIsValid = false;
            serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
        }
        if (Number(subDistricCode) === 0) {
            formIsValid = false;
            serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
        }
        //postCode
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณาระบุรายละเอียด";
        }
        //fax
        if (Number(coTitleID) === 0) {
            formIsValid = false;
            serrors["coTitleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
        }
        if (Number(coTitleID) === 100) {
            if (coOtherTitleTH.trim().length === 0) {
                formIsValid = false;
                serrors["coOtherTitleTH"] = "กรุณาระบุคำนำหน้าชื่อภาษาไทยที่ต้องการ";
            }
            if (coOtherTitleEN.trim().length === 0) {
                formIsValid = false;
                serrors["coOtherTitleEN"] = "กรุณาระบุคำนำหน้าชื่อภาษาอังกฤษที่ต้องการ";
            }
        }
        if (coFirstName.trim().length === 0) {
            formIsValid = false;
            serrors["coFirstName"] = "กรุณาระบุรายละเอียด";
        }
        if (coLastName.trim().length === 0) {
            formIsValid = false;
            serrors["coLastName"] = "กรุณาระบุรายละเอียด";
        }
        if (coPosition.trim().length === 0) {
            formIsValid = false;
            serrors["coPosition"] = "กรุณาระบุรายละเอียด";
        }
        if (coPhone.trim().length === 0) {
            formIsValid = false;
            serrors["coPhone"] = "กรุณาระบุรายละเอียด";
        }
        //coFax
        if (coEmail.trim().length === 0) {
            formIsValid = false;
            serrors["coEmail"] = "กรุณาระบุรายละเอียด";
        }
        if (coProperty1 === false && coProperty2 === false && coProperty3 === false && coProperty4 === false) {
            formIsValid = false;
            serrors["coProperty"] = "กรุณายอมรับการรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
        }
        if (logoFile.length === 0) {
            formIsValid = false;
            serrors["logoFile"] = "กรุณาเลือกไฟล์ภาพโลโก้สำหรับองค์กร";
        }
        //coProperty1Extra
        if (State === 'WAITING') {
            formIsValid = false;
            serrors["State"] = "กรุณากำหนดสถานะในการให้การรับรองก่อนบันทึกข้อมูล";
        }
        if (State === 'COMPLETE_DOC') {
            if (completeDocFiles === null) {
                formIsValid = false;
                serrors["completeDocFiles"] = "กรุณาอัปโหลดเอกสาร";
            }
        }
        if (State === 'NEED_DOC') {
            if (uncompleteDocFiles === null) {
                formIsValid = false;
                serrors["uncompleteDocFiles"] = "กรุณาอัปโหลดเอกสาร";
            }
            /*if (uncompleteDocInput.length === 1 && uncompleteImageInput.length === 1) {
                if (uncompleteDocInput[0].length === 0 && uncompleteImageInput[0].length === 0) {
                    formIsValid = false;
                    serrors["uncompleteImageInput"] = "กรุณากรอกรายละเอียดในส่วนของเอกสารหรือรูปภาพที่ต้องการเพิ่มเติม";
                }
            }*/
        }
        if (State === 'DECLINE') {

        }
        if (State === 'CERTIFY') {
            if (certifyFiles === null) {
                formIsValid = false;
                serrors["certifyFiles"] = "ต้องแนบเอกสาร หนังสือแจ้งผลการพิจารณา";
            }
            if (memberAnnounceFile === null) {
                formIsValid = false;
                serrors["memberAnnounceFile"] = "ต้องแนบเอกสาร หนังสือประกาศรับจดแจ้ง";
            }
        }
        if (State === 'NOT_CERTIFY') {
            if (uncertifyFiles === null) {
                formIsValid = false;
                serrors["certifyFiles"] = "ต้องแนบเอกสาร หนังสือแจ้งผลการพิจารณา";
            }
        }
        if (State === 'TERMINATE') {

        }
        if (State === 'REVOKE') {
            if (revokeFiles === null) {
                formIsValid = false;
                serrors["revokeFiles"] = "ต้องแนบเอกสาร หนังสือแจ้งผลการพิจารณา";
            }
        }

        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", networkid);
            formData.append("userEnroll", userEnroll);
            formData.append("userID", currentUser.UserID);
            formData.append("bookNo", bookNo);
            formData.append("state", State);

            formData.append("organization", organization);
            formData.append("netHouseNo", netHouseNo);
            formData.append("provinceCode", provinceCode);
            formData.append("districtCode", districtCode);
            formData.append("subDistricCode", subDistricCode);
            formData.append("postCode", postCode);
            formData.append("phone", phone);
            formData.append("fax", fax);
            formData.append("coTitleID", coTitleID);
            formData.append("coOtherTitleTH", coOtherTitleTH);
            formData.append("coOtherTitleEN", coOtherTitleEN);
            formData.append("coFirstName", coFirstName);
            formData.append("coLastName", coLastName);
            formData.append("coPosition", coPosition);
            formData.append("coPhone", coPhone);
            formData.append("coFax", coFax);
            formData.append("coEmail", coEmail);
            formData.append("coProperty1", coProperty1);
            formData.append("coProperty2", coProperty2);
            formData.append("coProperty3", coProperty3);
            formData.append("coProperty4", coProperty4);
            formData.append("coProperty1Extra", coProperty1Extra);
            formData.append("logoFile", logoFile.file);

            if (State === 'COMPLETE_DOC') {
                formData.append("completeDocRemark", completeDocRemark);
                formData.append("completeDocFiles", completeDocFiles.file);
            }
            if (State === 'NEED_DOC') {
                formData.append("uncompleteDocFiles", uncompleteDocFiles.file);
                formData.append("uncompleteDoc", uncompleteDocInput.join());
                //formData.append("uncompleteImage", uncompleteImageInput.join());
                formData.append("uncompleteDocRemark", uncompleteDocRemark);
            }
            if (State === 'DECLINE') {
                formData.append("declineRemark", declineRemark);
            }
            if (State === 'CERTIFY') {
                formData.append("certifyFiles", certifyFiles.file);
                formData.append("memberAnnounceFile", memberAnnounceFile.file);
                formData.append("certifyRemark", certifyRemark);
                formData.append("publishstartDate", publishstartDate);
            }
            if (State === 'NOT_CERTIFY') {
                formData.append("uncertifyFiles", uncertifyFiles.file);
                formData.append("uncertifyRemark", uncertifyRemark);
            }
            if (State === 'TERMINATE') {
                formData.append("terminateRemark", terminateRemark);
            }
            if (State === 'REVOKE') {
                formData.append("revokeFiles", revokeFiles.file);
                formData.append("revokeRemark", revokeRemark);
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateCorporateNetwork', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            if ((netType === 'ORG')) {
                                props.history.push({ pathname: "/admin/network/NetworkORGList" });
                            }
                            else if (netType === 'PROCOUN') {
                                props.history.push({ pathname: "/admin/network/NetworkPROCOUNList" });
                            }
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const cancel = () => {
        if ((netType === 'ORG')) {
            props.history.push({ pathname: "/admin/network/NetworkORGList" });
        }
        else if (netType === 'PROCOUN') {
            props.history.push({ pathname: "/admin/network/NetworkPROCOUNList" });
        }
    };

    const networkActivitiesList = networkActivities.map((activity, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{activity.organization}</td>
            <td>{activity.year}</td>
            <td>{activity.ActivityName}</td>
            <td>
                <Link id="profile-btn" className="btn btn-sm btn-primary mr-2 controlbtn" target="_blank" to={{ pathname: "/admin/network/ViewNetworkActivity/" + networkid + "/" + activity.uuid }}>{"ดูรายละเอียด"}</Link>
            </td>
        </tr>
    ));
    function returnState(State) {
        if (State === 'COMPLETE_DOC')
            return 'เอกสารครบถ้วน';
        else if (State === 'NEED_DOC')
            return 'เอกสารไม่ครบถ้วน';
        else if (State === 'DECLINE')
            return 'ไม่รับพิจารณาคำขอ';
        else if (State === 'CERTIFY')
            return 'ให้การรับรอง';
        else if (State === 'NOT_CERTIFY')
            return 'ไม่ให้การรับรอง';
        else if (State === 'TERMINATE')
            return 'ยุติ';
        else if (State === 'CANCELLATION_REQUEST')
            return 'ขอยุติ';
        else if (State === 'REVOKE')
            return 'เพิกถอน';
        return 'ยื่นคำขอ';
    }
    const HistoryList = actionhistory.map((action, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{action.BookNo}</td>
            <td>{returnState(action.StateName)}</td>
            <td>{action.Remark}</td>
            <td>{utils.MariatoThaiDateString(action.CreatedDate)}</td>
        </tr>
    ));

    const handleEdit = params => {
        props.history.push({ pathname: "/admin/network/EditNetwork", state: { id: networkid } });
    };
    const FilesList = attactmentList.map((file, i) => (
        <tr key={"attatchment-" + i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                {/*<button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>*/}
            </td>
        </tr>
    ));
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>จัดการองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ</a>
                <span><i className="fas fa-angle-right"></i></span>
                {(netType === 'ORG') && (<Link id="profile-btn" to={{ pathname: "/admin/network/NetworkORGList" }}>{"เอกชนด้านสิทธิมนุษยชน"}</Link>)}
                {(netType === 'PROCOUN') && (<Link id="profile-btn" to={{ pathname: "/admin/network/NetworkPROCOUNList" }}>{"วิชาชีพ"}</Link>)}
                <span><i className="fas fa-angle-right"></i></span>
                <Link onClick={e => { e.preventDefault(); handleEdit(); }} id="profile-btn" className="active" to={{ pathname: "/admin/network/EditNetwork" }}>{"แก้ไข"}</Link></>}

            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        {
                            (netType === 'ORG') && (
                                <>
                                    <h4 className="text-center">คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน</h4>
                                    <h4 className="text-center">ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560</h4>
                                </>)
                        }
                        {
                            (netType === 'PROCOUN') && (
                                <>
                                    <h4 className="text-center">คำขอรับการจดแจ้งเป็นสภาวิชาชีพ ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560</h4>
                                </>)
                        }
                    </div>

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="alert alert-dark col-12" role="alert">
                                    ข้อมูลทั่วไป
                                </div>
                                <div className="col-12">
                                    <h4>{netType === 'ORG' ? 'ด้วยองค์กร' : 'ด้วยสภาวิชาชีพ'}</h4>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="organization" className="required">ชื่อองค์กร</label>
                                    <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <SError error={errors["organization"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="netHouseNo" className="required">เลขที่ตั้ง/หมู่/ซอย/ถนน</label>
                                    <input type="text" className="form-control " id="netHouseNo" value={netHouseNo} name="netHouseNo" placeholder="กรุณากรอก" onChange={(e) => { SetNetHouseNo(e.currentTarget.value) }} />
                                    <SError error={errors["netHouseNo"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="provinceCode" className="required">จังหวัด</label>
                                    <select className="form-control mb-2 mr-2" id="provinceCode" name="provinceCode" value={provinceCode} onChange={(e) => { SetProvinceCode(e.currentTarget.value); handleChangeProvince(e.currentTarget.value); }} >
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ProvinceSelect}
                                    </select>
                                    <SError error={errors["provinceCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="districtCode" className="required">เขต/อำเภอ</label>
                                    <select className="form-control mb-2 mr-2" id="districtCode" name="districtCode" value={districtCode} onChange={(e) => { SetDistrictCode(e.currentTarget.value); handleChangeDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {DistrictSelect}
                                    </select>
                                    <SError error={errors["districtCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="subDistricCode" className="required">แขวง/ตำบล</label>
                                    <select className="form-control mb-2 mr-2" id="subDistricCode" name="subDistricCode" value={subDistricCode} onChange={(e) => { SetSubDistricCode(e.currentTarget.value); handleChangeSubDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <SError error={errors["subDistricCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="orgName" className="required">รหัสไปรษณีย์</label>
                                    <input type="text" className="form-control " id="postCode" value={postCode} name="postCode" placeholder="กรุณากรอก" onChange={(e) => { SetPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["postCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="phone" className="required">หมายเลขโทรศัพท์</label>
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { SetPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="fax" >โทรสาร</label>
                                    <input type="text" className="form-control " id="fax" value={fax} name="fax" placeholder="กรุณากรอก" onChange={(e) => { SetFax(e.currentTarget.value) }} />
                                    <SError error={errors["fax"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพโลโก้สำหรับองค์กร</label>
                                    <div className="noteText text-right ">
                                            หมายเหตุ: รองรับสกุลไฟล์ .jpg .png .zip ขนาดที่เหมาะสมคือ 453X250 px
                                        </div>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoOnDrop}
                                        accept="image/png, image/jpg"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div className="noteText col-12 text-right">
                                                    หมายเหตุ: รองรับสกุลไฟล์ .jpg .png มีขนาดไม่เกิน 10 MB
                                                </div>
                                                <SError error={errors["logoFile"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label>มีความประสงค์ให้สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ รับจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน ตามพระราชบัญญัติประกอบรัฐธรรมนูญ ว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560 และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไข การจดแจ้ง การรับจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม (ฉบับที่ 2) พ.ศ. 2563</label>
                                </div>
                                <div className="col-12">
                                    <h4 >โดยมีผู้ยื่นคำขอ คือ</h4>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coTitleID" className="required">คำนำหน้า</label>
                                    <select className="form-control mb-2 mr-2" id="coTitleID" name="coTitleID" value={coTitleID} onChange={(e) => { SetCoTitleID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {TitleSelect}
                                        <option key="100" value="100" >ระบุคำนำหน้าอื่นๆ</option>
                                    </select>
                                    <SError error={errors["coTitleID"]} />
                                </div>
                                {
                                    (Number(coTitleID) === 100) && (
                                        <>
                                            <div className="col-3">
                                                <label htmlFor="coOtherTitleTH" className="required">ระบุคำนำหน้าอื่น ๆ ภาษาไทย</label>
                                                <input type="text" className="form-control " id="coOtherTitleTH" value={coOtherTitleTH} name="coOtherTitleTH" placeholder="กรุณากรอก" onChange={(e) => { SetCoOtherTitleTH(e.currentTarget.value) }} />
                                                <SError error={errors["coOtherTitleTH"]} />
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="coOtherTitleEN" className="required">ระบุคำนำหน้าอื่น ๆ ภาษาอังกฤษ</label>
                                                <input type="text" className="form-control " id="coOtherTitleEN" value={coOtherTitleEN} name="coOtherTitleEN" placeholder="กรุณากรอก" onChange={(e) => { SetCoOtherTitleEN(e.currentTarget.value) }} />
                                                <SError error={errors["coOtherTitleEN"]} />
                                            </div>
                                        </>)
                                }
                                <div className="col-6">
                                    <label htmlFor="coFirstName" className="required">ชื่อ</label>
                                    <input type="text" className="form-control " id="coFirstName" value={coFirstName} name="coFirstName" placeholder="กรุณากรอก" onChange={(e) => { SetCoFirstName(e.currentTarget.value) }} />
                                    <SError error={errors["coFirstName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coLastName" className="required">นามสกุล</label>
                                    <input type="text" className="form-control " id="coLastName" value={coLastName} name="coLastName" placeholder="กรุณากรอก" onChange={(e) => { SetCoLastName(e.currentTarget.value) }} />
                                    <SError error={errors["coLastName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coPosition" className="required">ตำแหน่ง</label>
                                    <input type="text" className="form-control " id="coPosition" value={coPosition} name="coPosition" placeholder="กรุณากรอก" onChange={(e) => { SetCoPosition(e.currentTarget.value) }} />
                                    <SError error={errors["coPosition"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coPhone" className="required">โทรศัพท์</label>
                                    <input type="text" className="form-control " id="coPhone" value={coPhone} name="coPhone" placeholder="กรุณากรอก" onChange={(e) => { SetCoPhone(e.currentTarget.value) }} />
                                    <SError error={errors["coPhone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coFax" >โทรสาร</label>
                                    <input type="text" className="form-control " id="coFax" value={coFax} name="coFax" placeholder="กรุณากรอก" onChange={(e) => { SetCoFax(e.currentTarget.value) }} />
                                    <SError error={errors["coFax"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coEmail" className="required">อีเมลหน่วยงาน</label>
                                    <input type="text" className="form-control " id="coEmail" value={coEmail} name="coEmail" placeholder="กรุณากรอก" onChange={(e) => { SetCoEmail(e.currentTarget.value) }} />
                                    <SError error={errors["coEmail"]} />
                                </div>
                                <label className="col-12 mt-2">{netType === 'ORG' ? '' : 'โดยเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพที่จัดตั้งขึ้นตามกฎหมายไทย'}</label>

                                <div className="alert alert-dark col-12" role="alert">
                                    การรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร
                                </div>
                                <div className="col-12">
                                    <h4>{netType === 'ORG' ? 'ขอรับรองว่า' : 'ขอรับรองว่าสภาวิชาชีพ'}</h4>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="organization" className="required">{netType === 'ORG' ? 'ชื่อองค์กร' : 'ชื่อสภาวิชาชีพ'}</label>
                                    <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <SError error={errors["organization"]} />
                                </div>
                                <div className="col-6 my-bottom">
                                    <label>{netType === 'ORG' ? 'มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 7 แห่งระเบียบนี้' : 'มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 8 แห่งระเบียบนี้'}</label>
                                </div>
                                {
                                    (netType === 'ORG') && (
                                        <>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty1" name="coProperty1" checked={coProperty1 === true} onChange={(e) => { SetCoProperty1(!coProperty1) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty1">เป็นองค์กรที่จัดตั้งขึ้นตามกฎหมายไทย โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" className="form-control " id="coProperty1Extra" value={coProperty1Extra} name="coProperty1Extra" placeholder="กรุณากรอก" onChange={(e) => { SetCoProperty1Extra(e.currentTarget.value) }} />
                                                <SError error={errors["coProperty1Extra"]} />
                                            </div>
                                            <div className="col-6"></div>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty2" name="coProperty2" checked={coProperty2 === true} onChange={(e) => { SetCoProperty2(!coProperty2) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty2">มีวัตถุประสงค์ และการดําเนินการเกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty3" name="coProperty3" checked={coProperty3 === true} onChange={(e) => { SetCoProperty3(!coProperty3) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty3">มีการดำเนินการอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง และมีผลการดำเนินงานเป็นที่ประจักษ์</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty4" name="coProperty4" checked={coProperty4 === true} onChange={(e) => { SetCoProperty4(!coProperty4) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty4">ไม่มีวัตถุประสงค์ในทางการเมือง หรือแสวงหากําไรจากการดําเนินการ</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <SError error={errors["coProperty"]} />
                                            </div>
                                        </>)
                                }
                                {
                                    (netType === 'PROCOUN') && (
                                        <>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty1" name="coProperty1" checked={coProperty1 == true} onChange={(e) => { SetCoProperty1(!coProperty1) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty1">เป็นสภาวิชาชีพตามกฎหมายไทย</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty2" name="coProperty2" checked={coProperty2 == true} onChange={(e) => { SetCoProperty2(!coProperty2) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty2">มีหลักฐานการรับรองหรือมีคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty3" name="coProperty3" checked={coProperty3 == true} onChange={(e) => { SetCoProperty3(!coProperty3) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty3">สภาวิชาชีพนี้มีการให้ความเห็นชอบในการยื่นคำขอจดแจ้งสภาวิชาชีพ</label>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="coProperty4" name="coProperty4" checked={coProperty4 == true} onChange={(e) => { SetCoProperty4(!coProperty4) }} />
                                                    <label className="custom-control-label" htmlFor="coProperty4">ข้าพเจ้าขอยอมรับว่าข้อมูลดังกล่าวเป็นความจริงทุกประการ</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <SError error={errors["coProperty"]} />
                                            </div>
                                        </>)
                                }
                                <div className="col-12">
                                    <h4>ไฟล์เอกสาร</h4>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ลำดับ</th>
                                                    <th>ชื่อ</th>
                                                    <th>ขนาดไฟล์</th>
                                                    <th>ประเภทไฟล์</th>
                                                    <th>จัดการ</th>
                                                </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                                {FilesList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(netType === 'ORG') && (<div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h4 className="text-center">รายละเอียดเอกสารเพิ่มเติม</h4>
                                    <h4 className="text-center">ผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน</h4>
                                    <h4 className="text-center">เป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง และมีผลการดำเนินงานเป็นที่ประจักษ์</h4>
                                </div>
                                <div className="col-12">
                                    <aside>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>ลำดับ</th>
                                                        <th>ชื่อองค์กร</th>
                                                        <th>พ.ศ.</th>
                                                        <th>ชื่อผลการดำเนินงาน</th>
                                                        <th>รายละเอียด</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="your-table-body-id">
                                                    {networkActivitiesList}
                                                </tbody>
                                            </table>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}

                    <div className="col-12"><h4 className="text-left ml-4">สำหรับเจ้าหน้าที่</h4></div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h4 className="text-left">ประวัติ</h4>
                                </div>
                                <div className="col-12">
                                    <aside>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>ลำดับ</th>
                                                        <th>เลขที่เอกสาร</th>
                                                        <th>สถานะ</th>
                                                        <th>หมายเหตุ</th>
                                                        <th>วันที่บันทึก</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="your-table-body-id">
                                                    {HistoryList}
                                                </tbody>
                                            </table>
                                        </div>
                                    </aside>
                                </div>

                                <div className="col-12">
                                    <hr />
                                    <div className="col-6">
                                        <label htmlFor="State" className="required">สถานะ</label>
                                        <select className="form-control mb-2 mr-2" id="State" name="State" value={State} onChange={(e) => { SetState(e.currentTarget.value); }} >
                                            <option key="WAITING" value="WAITING" >กรุณาเลือก</option>
                                            <option key="COMPLETE_DOC" value="COMPLETE_DOC" >เอกสารครบถ้วน</option>
                                            <option key="NEED_DOC" value="NEED_DOC" >เอกสารไม่ครบถ้วน</option>
                                            <option key="DECLINE" value="DECLINE" >ไม่รับพิจารณาคำขอ</option>
                                            <option key="CERTIFY" value="CERTIFY" >ให้การรับรอง</option>
                                            <option key="NOT_CERTIFY" value="NOT_CERTIFY" >ไม่ให้การรับรอง</option>
                                            <option key="TERMINATE" value="TERMINATE" >ยุติ</option>
                                            {/*(State==='CERTIFY')&&(<option key="REVOKE" value="REVOKE" >เพิกถอน</option>)*/}
                                            <option key="REVOKE" value="REVOKE" >เพิกถอน</option>
                                        </select>
                                        <SError error={errors["State"]} />

                                    </div>
                                    <div className="col-6"></div>
                                    {RenderCertifyAction(State)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40" >
                            <button type="button" className="btn btn-outline-primary" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printDraftWord(false); }}><i className="far fa-file-word"></i> ปริ้น</button>
                            <button type="button" className="btn btn-outline-danger" style={{ marginRight: '5px' }} onClick={e => { e.preventDefault(); printDraftPDF(false); }}><i className="far fa-file-pdf"></i> ปริ้น</button>
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
