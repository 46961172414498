import React, { useCallback, useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/advertise.svg';
import { Rating } from '@material-ui/lab';
import utils from 'utils/utils';
import HTMLFlipBook from "react-pageflip";
import { Document, Outline, Page as ReactPdfPage, View, Text, pdfjs } from 'react-pdf';
import { authenticationService } from 'services/authentication.service.js';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import ReactPlayer from 'react-player'
import { useDebounce } from "use-debounce";
import addbookmark from "assets/images/icon/addbookmark.svg";
import viewbookmark from "assets/images/icon/viewbookmark.svg";
import CustomIframe from "components/CustomIframe.js"
import PDFView from "components/PDFView.js"
import { convertNeSwToNwSe } from "google-map-react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
const width = 600;
const height = 870;

const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage pageNumber={pageNumber} height={height} />
        </div>
    );
});

function highlightPattern(text, pattern) {
    const splitText = text.split(pattern);

    if (splitText.length <= 1) {
        return text;
    }

    const matches = text.match(pattern);

    return splitText.reduce((arr, element, index) => (matches[index] ? [
        ...arr,
        element,
        <mark key={index}>
            {matches[index]}
        </mark>,
    ] : [...arr, element]), []);
}



export default function ViewELibContent(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [elibData, SetElibData] = useState({});
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [useID, setUserID] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [debouncedSearchString] = useDebounce(searchText, 250);
    const [foundPages, setFoundPages] = useState([]);
    const [searchResultList, setSearchResultList] = useState([]);
    const [resultindex, setResultIndex] = useState(0);
    const [searchFoundPageText, setSearchFoundPageText] = useState("");
    const [bookmarkpage, setBookmarkPage] = useState(null);
    const [pdfbase64, setPDFBase64] = useState(null);
    const flipbook = React.useRef(null);
    //let resultText = "";

    function Search() {
        //console.log('xxxxx')
        pdfjs.getDocument(elibData.FilePath).promise.then((docData) => {
            const pageCount = docData._pdfInfo.numPages;

            const pagePromises = Array.from(
                { length: pageCount },
                (_, pageNumber) => {
                    return docData.getPage(pageNumber + 1).then((pageData) => {
                        return pageData.getTextContent().then((textContent) => {
                            return textContent.items.map(({ str }) => str).join(" ");
                        });
                    });
                }
            );
            /*resultText =
                pagePromises.length === 1
                    ? "Results found on 1 page"
                    : `Results found on ${pagePromises.length} pages`;*/
            //console.log("ไม่พบข้อมูล "+pagePromises.length)
            if (pagePromises.length === 0 || pagePromises.length === pageCount) {
                //resultText = "no results found";
                //console.log("ไม่พบข้อมูล")
                setSearchFoundPageText(language === "Thai" ? "ไม่พบข้อมูล" : "no results found");
            }
            setFoundPages([]);
            return Promise.all(pagePromises).then((pages) => {
                setFoundPages(pages);

            });
        });
    }
    useEffect(() => {
        setSearchResultList([]);
        setResultIndex(0);
        if (!searchText || !searchText.length) {
            setSearchResultList([]);
            return;
        }
        var newsearch = '';
        newsearch = searchText.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');//searchText.replace(/[^a-zA-Z 0-9]+/g, '');
        //console.log(newsearch)
        const regex = new RegExp(`${newsearch}*`, "i");
        const updatedResults = [];

        foundPages.forEach((text, index) => {
            if (regex.test(text)) {
                updatedResults.push(index + 1);
            }
        });
        if (updatedResults.length > 0) {
            setPageNumber(updatedResults[0]);
            setResultIndex(0);
            setSearchFoundPageText(language === "Thai" ? " พบ 1/" + updatedResults.length + " หน้า" : "found on 1/" + updatedResults.length + " Pages");
        }
        setSearchResultList(updatedResults);

        //console.log(updatedResults)
    }, [foundPages, searchText]);

    const textRenderer = useMemo((textItem) => {
        //console.log(textItem)
        if (typeof textItem !== 'undefined') {
            //console.log(textItem.str)
            return highlightPattern(textItem.str, searchText);
        }
        else {
            return;
        }
    }, [searchText]);

    function findWordPrevious() {
        var nextIndex = resultindex - 1;
        if (nextIndex >= 0 && searchResultList.length > 0) {
            setResultIndex(nextIndex);
            //setPageNumber(searchResultList[nextIndex]);    
            const pageFlipObj = flipbook.current.pageFlip();
            pageFlipObj.flip(searchResultList[nextIndex]);
            setSearchFoundPageText(language === "Thai" ? "พบ " + (nextIndex + 1) + "/" + searchResultList.length + " หน้า" : "found on "(nextIndex + 1) + "/" + searchResultList.length + " Pages");
        }
    }
    function findWordNext() {
        var nextIndex = resultindex + 1;
        if (nextIndex < searchResultList.length) {
            setResultIndex(nextIndex);
            //setPageNumber(searchResultList[nextIndex]);    
            const pageFlipObj = flipbook.current.pageFlip();
            pageFlipObj.flip(searchResultList[nextIndex]);
            //console.log(searchResultList[nextIndex]);
            setSearchFoundPageText(language === "Thai" ? "พบ " + (nextIndex + 1) + "/" + searchResultList.length + " หน้า" : "found on "(nextIndex + 1) + "/" + searchResultList.length + " Pages");
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);

    }

    const elibID = props.location.state.id;
    function onChange(event) {

        setSearchText(event.target.value);
        Search(event.target.value);
    }
    const reader = new FileReader();

    async function LoadBook(url) {
        let blob = await fetch(url).then(r => r.blob());
        //console.log(blob);
        let file = null;
        reader.onload = function (fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            // Print data in console
            setPDFBase64(file);
            //console.log(pdfbase64);
        };
        await reader.readAsDataURL(blob);
        //console.log(reader);

    }
    useEffect(() => {
        if (currentUser != null) {
            setUserID(currentUser.UserID);
        }
        axios.get(process.env.REACT_APP_APIURL + '/api-web/elibrarybyID', { params: { id: elibID, userID: useID } }).then((response) => {
            SetElibData(response.data[0]);
            LoadBook(response.data[0].FilePath).then(() => { setLoading(false) });

        })

    }, []);

    var makeTextRenderer = searchText => textItem => highlightPattern(textItem.str, searchText);
    const pageList = () => {
        let content = [];
        for (let i = 0; i < numPages; i++) {
            //const item = animals[i];
            content.push(<Page key={"Page" + i} pageNumber={i + 1} renderInteractiveForms={true} customTextRenderer={makeTextRenderer(searchText)} />);
        }
        //const bookmarkpage = localStorage.getItem(props.location.state.id);
        //console.log(bookmarkpage)
        return content;
    };
    const setPageBookmark = (bookID, page) => {
        localStorage.setItem(bookID, JSON.stringify(page));
    };
    const openBookmark = () => {
        const page = localStorage.getItem(props.location.state.id);
        if (page !== null) {
            flipbook.current.pageFlip().turnToPage(Number(page));
            setPageNumber(page);
        }
    }
    const onFlip = useCallback((e) => {
        setPageNumber(e.data);
        //setPageBookmark(props.location.state.id, e.data);
        //console.log('Current page: ' + e.data);
    }, []);
    const customEnterAnimation = {
        from: { transform: 'scale(0.5, 1)' },
        to: { transform: 'scale(1, 1)' }
    };
    if (isLoading) {
        return (<></>);
    }

    return (
        <>
            <div id="header-wrapper">
                {
                    <Navbar />
                }
                <ContentBanner language={language} theader={"คลังความรู้"} eheader={"E-library"} bannerkey="ELIB" banner={banner}
                    path={
                        <>
                            <span>/</span><Link to={{ pathname: "/authen/elibrary/ELibrary", state: { language: language } }} >{language === "Thai" ? "คลังความรู้" : "knowledge base"}</Link>
                            <span>/</span>
                            <Link id="profile-btn" className="active" to={{ pathname: "/authen/elibrary/Read/" + elibData.BookID, state: { id: elibData.BookID, userID: useID } }}>
                                {elibData.BookName}</Link>
                        </>
                    } />
            </div>
            <main className="content-page">
                <section>
                <div className="container">
                            <div className="sub-title">
                                <h2 className="thai-header text-center"><span className="orang content-underline">{elibData.BookName}</span></h2>
                            </div>
                        </div>
                    <div className="content-data">
                        <div className="row">
                            {(Number(elibData.DisplayType) === 1 || Number(elibData.DisplayType) === 2) && (<>
                                {/*<CustomIframe >
                                    <PDFView bookid={elibID} />
                            </CustomIframe>*/}
                                <div className="col-12 book-content">
                                    <Document file={/*"data:application/pdf;base64,"*/pdfbase64} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                                        <HTMLFlipBook ref={flipbook} width={width} height={height} showCover={true} size={"fixed"} autoSize={true}
                                            minWidth={315}
                                            maxWidth={1000}
                                            minHeight={400}
                                            maxHeight={1533}
                                            maxShadowOpacity={0.5}
                                            mobileScrollSupport={true}
                                            onFlip={onFlip}
                                            enterAnimation={customEnterAnimation}>
                                            {pageList()}
                                        </HTMLFlipBook>
                                    </Document>
                                    <div className="viewer-toolbar" id="toolbar">
                                        <img src={addbookmark} className="mr-2 bookmarkbutton" style={{ width: '32px', cursor: 'pointer', float: 'right' }} onClick={(e) => { e.preventDefault(); setPageBookmark(props.location.state.id, pageNumber) }} />
                                        <img src={viewbookmark} className="mr-2 bookmarkbutton" style={{ width: '32px', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); openBookmark() }} />
                                    </div>
                                    {/*<div className="text-center" style={{ marginTop: '150px', minWidth: '600px' }}>
                                    <div className="row" style={{ margin: '0 auto', display: 'flex' }}>
                                        <div className="col-2 col-xl-1" style={{ marginLeft: 'auto' }}><i className="fas fa-arrow-circle-left" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); findWordPrevious(); }}></i></div>
                                        <div className="col-8 col-xl-2" >

                                            <input
                                                className="form-control mr-sm-2 search"
                                                type="text"
                                                placeholder={language === "Thai" ? "ค้นหา" : "Search"}
                                                aria-label="Search"
                                                value={searchText} onChange={onChange}
                                            />

                                        </div>
                                        <div className="col-2 col-xl-1" style={{ marginRight: 'auto' }}><i className="fas fa-arrow-circle-right" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); findWordNext(); }}></i></div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center" ><p>{searchFoundPageText}</p></div>
                                    </div>
                                </div>*/}
                                    {/*<Document
                                    file={elibData.FilePath}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Outline></Outline>
                                    <Page pageNumber={pageNumber} height={760} renderInteractiveForms={true} customTextRenderer={makeTextRenderer(searchText)} />
                                    <div className="page-controls">
                                        <button type="button" disabled={pageNumber === 1} onClick={(e) => { if (pageNumber > 1) { let curpage = pageNumber - 1; setPageNumber(curpage); } }}>‹</button>
                                        <span>{pageNumber} of {numPages}</span>
                                        <button type="button" disabled={pageNumber === numPages} onClick={(e) => { if (pageNumber < numPages) { let curpage = pageNumber + 1; setPageNumber(curpage); } }}>›</button>
                                    </div>
                                </Document>
                                <div className="row text-center mt-2">
                                    <div className="col-12 col-xl-4" style={{ margin: '0 auto', display: 'flex' }}>
                                        <div className="col-2 col-xl-1" style={{ marginLeft: 'auto' }}><i className="fas fa-arrow-circle-left" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); findWordPrevious(); }}></i></div>
                                        <div className="col-8 col-xl-2" >

                                            <input
                                                className="form-control mr-sm-2 search"
                                                type="text"
                                                placeholder={language === "Thai" ? "ค้นหา" : "Search"}
                                                aria-label="Search"
                                                value={searchText} onChange={onChange}

                                            />

                                        </div>
                                        <div className="col-2 col-xl-1" style={{ marginRight: 'auto' }}><i className="fas fa-arrow-circle-right" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); findWordNext(); }}></i></div>

                                    </div>
                                    <div className="col-12 text-center" ><p>{searchFoundPageText}</p></div>
                                </div>*/}

                                </div></>)}
                            {(Number(elibData.DisplayType) === 3 || Number(elibData.DisplayType) === 4) && (<div className="col-12">
                                <>
                                    <div className='player-wrapper'>
                                        <ReactPlayer url={elibData.FilePath} width='100%' height='100%' controls={true} />
                                    </div>
                                </>

                            </div>)}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
    /*const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState('');

    const textRenderer = useMemo((textItem) => {
        if(typeof textItem!=='undefined')
        return highlightPattern(textItem.str, searchText);
       else{
           console.log('No')
       }

    }, [searchText]);
    var makeTextRenderer = searchText => textItem => highlightPattern(textItem.str, searchText);
    function onChange(event) {
        setSearchText(event.target.value);
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
        console.log('xxxxxx')
      }

    return (
        <>
            <Document
                file={samplePDF}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={1}
                    customTextRenderer={makeTextRenderer(searchText)}
                />
            </Document>
            <div>
                <label htmlFor="search">Search:</label>
                <input type="search" id="search" value={searchText} onChange={onChange} />
            </div>
        </>
    );*/
}
