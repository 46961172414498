import React, { useState, useMemo } from "react";
//import { Link } from "react-router-dom";
import axios from 'axios';
import Pagination from 'utils/Pagination';
/*import {
    FacebookShareButton,
    EmailShareButton,
    LineShareButton,
    FacebookIcon,
    EmailIcon,
    LineIcon,
} from "react-share";*/
import Navbar from "components/Navbar/Navbar.js";
//import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';

export default function SearchResult(props) {
    const language = localStorage.getItem("language");
    const currentUser = authenticationService.currentUserValue;
    const [dataList, setDataList] = useState([]);
     const PageSize = 12;
    const [currentPage, setCurrentPage] = useState(0);
    const search = props.location.state.search;    
    useMemo(() => {
        setDataList([]);
        const formData = new FormData();
        //console.log(search)
        formData.append("search", search);
        formData.append("bookname", search);
        //formData.append("auther", search);
        
        axios.post(process.env.REACT_APP_APIURL + '/api-web/newsList', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {                
                //console.log(response.data);
                let itemlist = response.data;
                //console.log(questionlist)
                for (let i = 0; i < itemlist.length; i++) {
                    let item = itemlist[i];
                    //console.log(question)
                    setDataList((prevData) => [
                        ...prevData,
                        {
                            type: 'NEWS',
                            id: item.NewsID,
                            title: language === "Thai" ? item.TitleTH : item.TitleEN,
                            shortdetail: language === "Thai" ? item.ShortDetailTH : item.ShortDetailEN,
                            img: item.ThumbnailWeb,
                            path: "/news/ReadNews/" + item.NewsID,
                        },
                    ]);
                }
                axios.post(process.env.REACT_APP_APIURL + '/api-web/activityList', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`
                    }
                })
                    .then((response) => {
                        let itemlist = response.data;
                        for (let i = 0; i < itemlist.length; i++) {
                            let item = itemlist[i];
                            setDataList((prevData) => [
                                ...prevData,
                                {
                                    type: 'ACTIVITY',
                                    id: item.ActivitiesID,
                                    title: language === "Thai" ? item.TitleTH : item.TitleEN,
                                    shortdetail: language === "Thai" ? item.DescriptionTH : item.DescriptionEN,
                                    img: item.ThumbnailWeb,
                                    path: "/activity/ReadActivity/" + item.ActivitiesID,
                                },
                            ]);
                        }
                        axios.post(process.env.REACT_APP_APIURL + '/api-web/searchELibraryList', formData, {
                            headers: {
                                'Content-Type': `multipart/form-data`
                            }
                        })
                            .then((response) => {
                                let itemlist = response.data;
                                for (let i = 0; i < itemlist.length; i++) {
                                    let item = itemlist[i];
                                    setDataList((prevData) => [
                                        ...prevData,
                                        {
                                            type: 'ELIBRARY',
                                            id: item.BookID,
                                            title: item.BookName,
                                            shortdetail: item.ShortDetail,
                                            img: item.ThumnailWeb,
                                            path: "/authen/elibrary/Read/" + item.BookID,
                                        },
                                    ]);
                                }
                                axios.get(process.env.REACT_APP_APIURL + '/api-web/searchESubject', { params: { search: search, status: 1, ispublish: true } })
                                    .then((response) => {

                                        let itemlist = response.data;
                                        for (let i = 0; i < itemlist.length; i++) {
                                            let item = itemlist[i];
                                            setDataList((prevData) => [
                                                ...prevData,
                                                {
                                                    type: 'ELEARNING',
                                                    id: item.ELearnID,
                                                    title: item.ELearnTitle,
                                                    shortdetail: item.CourseDetail,
                                                    img: item.ThumnailWeb,
                                                    path: "/authen/elearning/ViewElearning/" + item.ELearnID,
                                                },
                                            ]);
                                        }
                                        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchFile', { params: { search: search } })
                                            .then((response) => {

                                                let itemlist = response.data;
                                                for (let i = 0; i < itemlist.length; i++) {
                                                    let item = itemlist[i];
                                                    setDataList((prevData) => [
                                                        ...prevData,
                                                        {
                                                            type: 'FILES',
                                                            id: item.AttachmentID,
                                                            title: item.FilenameOld,
                                                            shortdetail: '',
                                                            img: '',
                                                            path: item.AttachmentPath,
                                                        },
                                                    ]);
                                                }
                                                setCurrentPage(0);
                                                setCurrentPage(1);
                                            });
                                    });
                            });

                    });
            });
    }, [search]);
    const readData = (item) => {
        const formData = new FormData();
        formData.append("id", item.id);
        if (item.type === 'NEWS') {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNewsViews', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    props.history.push({ pathname: "/news/ReadNews/" + item.id, state: { id: item.id } });
                });
        }
        else if (item.type === 'ACTIVITY') {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateActivityViews', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    props.history.push({ pathname: "/activity/ReadActivity/" + item.id, state: { id: item.id } });
                });
        }
        else if (item.type === 'ELIBRARY') {
            props.history.push({ pathname: "/authen/elibrary/Read/" + item.id, state: { id: item.id, userID: currentUser.UserID } });
        }
        else if (item.type === 'ELEARNING') {
            props.history.push({ pathname: "/authen/elearning/ViewElearning/" + item.id, state: { id: item.id, userID: currentUser.UserID } });
        }
        else if (item.type === 'FILES') {
            //props.history.push({ pathname: "/authen/elearning/ViewElearning/" + item.id, state: { id: item.id, userID: currentUser.UserID } });
        }
    }
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dataList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);
    const DataDIV = currentTableData.map((item) => (
        <div className="col-md-12 mt-3 mb-3" key={item.type + "-" + item.id}>
            {(item.type !== 'FILES') && (<>
                {(item.img.trim().length > 0) && (<img src={item.img} className="search-cover" alt="cover" />)}
                <h4 className="content-title"><a href="/" onClick={e => { e.preventDefault(); readData(item); }}>{item.title}</a></h4>
                <div dangerouslySetInnerHTML={{ __html: item.shortdetail }} className="search-info" />
            </>)}
            {(item.type === 'FILES') && (<>
                <h4 className="content-title"><a href={item.path} >{item.title}</a></h4>
                <div className="search-info" >คลิกที่ชื่อไฟล์เพื่อดาวน์โหลด</div>
            </>)}
        </div>
    ));
    return (
        <>
            <Navbar />
            <main className="content-page">
                <section className="edit-profile row" >
                {DataDIV}

                <div className="col-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={dataList.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                </section>
            </main>
        </>
    );
}