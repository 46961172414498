import React, { useState, useEffect } from 'react';
import axios from 'axios';
import utils from 'utils/utils';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TableFileReorder = (props) => {
    const { data } = props;
    const [items, setItems] = useState('');

    useEffect(() => {
        if(data.length > 0){
           
            const array = data.map((v, index) => {
                console.log(index, v);
                return { 
                    ...v,
                    order: (+index + 1),
                    path: v.path.split('.')[0],
                    size: utils.Bytes2Size(v.size),
                    ext: v.path.split('.').pop()
                }
               
            })
            if(array.length > 0 )  setItems(array);
        
        }
    }, [data]);

    const handleRemoveFile = filename => {
        items.filter(item => item['name'] !== filename);
    };

    const ordering = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (e) => {
        if (!e.destination) return;
        
        const reOrder = ordering(items, e.source.index, e.destination.index);
        setItems(reOrder);

    };
    
    return (
    
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="Table">
                    {provided => (
                    <table className="table table-bordered" {...provided.droppableProps} ref={provided.innerRef}>
                        <thead>
                            <tr>
                                <th>ลำดับ</th>
                                <th>ชื่อ</th>
                                <th>ขนาดไฟล์</th>
                                <th>ประเภทไฟล์</th>
                                <th>จัดการ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.length > 0 && items.map((item, index) => (
                            <Draggable
                            key={index}
                            draggableId={`drag_${index}`}
                            index={index}
                            >
                            {provided => ( 
                             <tr
                             key={item.path}
                             ref={provided.innerRef}
                             {...provided.draggableProps}
                             {...provided.dragHandleProps}
                             >
                                 <td>{item.order}</td>
                                 <td>{item.path}</td>
                                 <td>{item.size}</td>
                                 <td>{item.ext}</td>
                                 <td>
                                     <a className="btn btn-sm btn-primary mr-2 controlbtn" href={item.preview} rel="noreferrer" target="_blank">ดู</a>
                                     <button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveFile(item.name); }}>ลบ</button>
                                 </td>
                             </tr>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </tbody>
                    </table>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default TableFileReorder
