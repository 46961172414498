import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import utils from 'utils/utils';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function MenuBanner(props) {
    const currentUser = authenticationService.currentUserValue;
    const [bannerList, setBannerList] = useState([]);
    const [bannerID, SetBannerID] = useState(0);
    const [activeBanner, SetActiveBanner] = useState({});
    const [bannerFile, setBannerFile] = useState(null);

    const [errors, setError] = useState({});
    const contactID = 1;
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMenuBanner')
            .then((response) => {
                setBannerList(response.data);
            });
    }, []); /* <-- add this for run once*/

    const handleBannerGroupChange = (value) => {
        SetBannerID(value);
        const activebaner = bannerList.find(e => Number(e.BannerID) === Number(value))
        SetActiveBanner(activebaner);
        if (typeof activebaner !== 'undefined') {
            if (activebaner.BannerImage.trim().length > 0) {
                const bannerimageFile = {
                    file: activebaner.BannerImage,
                    name: activebaner.BannerImage.substring(activebaner.BannerImage.lastIndexOf('/') + 1),
                    preview: activebaner.BannerImage,
                    size: utils.getFileSize(activebaner.BannerImage)
                };
                setBannerFile(bannerimageFile);
            }
            else
                setBannerFile(null);
        }
        else {
            setBannerFile(null);
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (Number(bannerID) === 0) {
            formIsValid = false;
            serrors["bannerID"] = "กรุณาเลือกเมนูป้ายโฆษณา";
        }
        if (bannerFile === null) {
            formIsValid = false;
            serrors["bannerFile"] = "กรุณาเลือกรูปภาพเพื่ออัปโหลด";
        }

        setError(serrors);
        //console.log(status)
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("bannerID", bannerID);
            formData.append("bannerFile", bannerFile.file);
            formData.append("userID", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMenuBanner', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/setting/MenuBanner" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const handleLogoimgOnDrop = (newImageFile, onChange) => {
        const imgFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setBannerFile(imgFile);
    };
    const handleRemoveLogoImgFile = () => {
        /*const formData = new FormData();
        formData.append("folder", 'images');
        formData.append("url", bannerFile.file);
        formData.append("fieldName", 'BannerImage');
        formData.append("id", contactID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteMenuBannerFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });*/
        setBannerFile(null);
    };
    const LogoImgPreview = (
        (bannerFile !== null) && (<img className="preview-upload" src={bannerFile.preview} onChange={(e) => { e.target.src = URL.createObjectURL(bannerFile); }} alt="Site logo" />)
    );
    const BannerSelect = bannerList.map((item) => (
        <option
            key={item.BannerID}
            value={item.BannerID}
        >
            {item.BannerName}
        </option>
    ));
    const cancel = () => {
        props.history.push({ pathname: "/admin/Profile" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การตั้งค่าเว็บไซต์"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเว็บไซต์</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/setting/SettingContactUs" }}>{"การจัดการเมนูป้ายโฆษณา"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <select id="IDCardHRI" name="IDCardHRI" className="custom-select" value={bannerID} onChange={(e) => { handleBannerGroupChange(e.currentTarget.value); }}>
                                        <option value="0">เลือกเมนูป้ายโฆษณา</option>
                                        {BannerSelect}
                                    </select>
                                    <span className="error d-block">{errors["bannerID"]}</span>
                                </div>
                                {(Number(bannerID) > 0) && (<>
                                    <div className="alert alert-dark col-12" role="alert">
                                        ป้ายโฆษณาเมนู{activeBanner.BannerName}
                                    </div>
                                    <div className="col-12">
                                        <label className="required">รูปป้ายโฆษณา (TH)</label>
                                        <Dropzone
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            onDrop={handleLogoimgOnDrop}
                                            accept="image/*"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container">
                                                    <SError error={errors["bannerFile"]} />
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                    </div>
                                                    {LogoImgPreview}
                                                    {(bannerFile !== null) && (<SingleFileTable filedata={bannerFile} removefn={handleRemoveLogoImgFile} />)}
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className="col-12">
                                        <div className="pl-4 pr-4 text-center margintop40" >
                                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                        </div>
                                    </div>
                                </>)
                                }

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
