import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewActivityEnroll(props) {
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});

    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [position, setPosition] = useState('');
    const [department, setDepartment] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [otherInfo, setOtherInfo] = useState("");
    const [activityList, setActivityList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getActiveactivity').then((response) => {

            //console.log(response.data);
            setActivityList(response.data)
            setLoading(false);

        })
    }, []); /* <-- add this for run once*/

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //console.log(approve);
        //Name
        if (fullName.trim().length === 0) {
            formIsValid = false;
            serrors["fullName"] = "กรุณาระบุชื่อ นามสกุล";
        }
        else {
            let name = fullName.split(" ");
            //console.log(name.length)
            if (name.length < 2) {
                serrors["fullName"] = "ชื่อและนามสกุลต้องเว้นวรรค";
            }
        }

        if (gender.trim().length === 0) {
            formIsValid = false;
            serrors["gender"] = "กรุณาเลือกเพศ";
        }
        if (position.trim().length === 0) {
            formIsValid = false;
            serrors["position"] = "กรุณากรอกข้อมูล";
        }
        if (department.trim().length === 0) {
            formIsValid = false;
            serrors["department"] = "กรุณากรอกข้อมูล";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณากรอกข้อมูล";
        }
        if (otherInfo.trim().length === 0) {
            formIsValid = false;
            serrors["otherInfo"] = "กรุณาระบุรายละเอียด";
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            let name = fullName.split(" ");
            formData.append("activitiesID", activityRef.current.value);
            formData.append("firstName", name[0]);
            formData.append("lastName", name[1]);
            formData.append("gender", gender);
            formData.append("birthDate", birthDate);
            formData.append("department", department);
            formData.append("position", position);
            formData.append("phone", telephone);
            formData.append("email", email);
            formData.append("otherInfo", otherInfo);
            formData.append("userID", currentUser.UserID);
            formData.append("registerUserID", 0);
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/activityEnroll', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/activity/ActivityMember" });
                        }
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);

        }
    };
    const activityRef = React.createRef();
    const ActivitySelect = activityList.map((item) => (
        <option
            key={item.ActivitiesID}
            value={item.ActivitiesID}
        >
            {item.TitleTH}
        </option>
    ));
    const cancel = () => {
        props.history.push({ pathname: "/admin/activity/ActivityMember" });
    };

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการกิจกรรม</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/activity/ActivityMember" }}>{"จัดการรายชื่อผู้เข้าร่วมกิจกรรม"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/activity/NewActivityEnroll" }}>{"สมัคร"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label htmlFor="activityID" className="required">กิจกรรม</label>
                                    <select className="form-control mb-2 mr-2" id="activityID" name="activityID" ref={activityRef} >
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ActivitySelect}
                                    </select>
                                    <SError error={errors["activityID"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="fullName" className="required">ชื่อ-นามสกุล</label>
                                    <input type="text" className="form-control" id="fullName" name="fullName" placeholder="กรุณากรอก" value={fullName} onChange={(e) => { setFullName(e.currentTarget.value) }} />
                                    <SError error={errors["fullName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="gender" className="required">เพศ</label>
                                    <select className="form-control mb-2 mr-2" id="gender" name="gender" value={gender} onChange={(e) => { setGender(e.currentTarget.value); }} >
                                        <option value="" >กรุณาเลือก</option>
                                        <option value="M" >ชาย</option>
                                        <option value="F" >หญิง</option>
                                    </select>
                                    <SError error={errors["gender"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="registerstart" className="required">วันเกิด</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={birthDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            onChange={setBirthDate} name="registerstart" id="registerstart" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="position" className="required">ตำแหน่ง</label>
                                    <input type="text" className="form-control" id="position" name="position" placeholder="กรุณากรอก" value={position} onChange={(e) => { setPosition(e.currentTarget.value) }} />
                                    <SError error={errors["position"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="department" className="required">ตำแหน่ง</label>
                                    <input type="text" className="form-control" id="department" name="department" placeholder="กรุณากรอก" value={department} onChange={(e) => { setDepartment(e.currentTarget.value) }} />
                                    <SError error={errors["department"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="telephone" className="required">หมายเลขโทรศัพท์</label>
                                    <input type="text" className="form-control" id="phone" name="telephone" placeholder="กรุณากรอก" maxLength={10} value={telephone}
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setTelephone(e.currentTarget.value)
                                            }
                                        }} />
                                    <SError error={errors["telephone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="กรุณากรอก" value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="otherInfo" className="required">รายละเอียด</label>
                                    <div>
                                        <textarea className="form-control" name="displayAddress" id="displayAddress" rows="3" value={otherInfo} onChange={e => { setOtherInfo(e.target.value) }}></textarea>
                                        <SError error={errors["otherInfo"]} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40 margintop40" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
