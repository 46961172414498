import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const swal = withReactContent(Swal)
export default function Elerning(props) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [activeElearning, setActiveElearning] = useState([]);
    const [oldElearning, setOldElearning] = useState([]);
    const history = useHistory();
    useEffect(() => {
        const formData = new FormData();
        formData.append("userID", currentUser.UserID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/UserElearning', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                const activityItemPrepair = [...response.data];
                /*เพิ่มฟิลด์คำตอบที่ผู้ใช้เลือก*/
                const active = [];
                const old = [];
                let now = new Date(Date.now());
                activityItemPrepair.forEach(function (item) {
                    if (new Date(item.RegisteredEndDate) >= now) {
                        active.push(item);
                    }
                    else {
                        old.push(item);
                    }
                });
                setActiveElearning(active);
                setOldElearning(old);
                if (response.data.length === 0) {
                    Swal.fire({
                        icon: 'info',
                        title: language==='Thai'?'แจ้งให้ทราบ':'Information',
                        text: language === "Thai" ? " ไม่พบข้อมูล " : " No information found ",
                    })
                }
            });
    }, []); /* <-- add this for run once*/
    const readData = (id) => {
        let userid=null;
        if(currentUser!==null){
            userid = currentUser.UserID;
        }
        history.push({ pathname: "/authen/elearning/ViewElearning/" + id, state: { id: id, userID: userid } });
    }
    const ActiveElearningList = activeElearning.map((item, i) => (
        <div className="col-md-4" key={item.ELearnID}>
        <div className="card">
            <img src={item.ThumnailWeb} className="cover" alt="Elearning cover" />
            <div className="card-body">
                <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(new Date(item.PublishedStartDate))}</p></span>
                <h4 className="card-content-title">{item.ELearnTitle}</h4>
                <div className="progress" style={{height:"8px"}}>
                    <div className="progress-bar" style={{width:((item.MaxEnroll-item.CurrentEnroll) * 100 / item.MaxEnroll)+"%"}}></div>
                </div>
                <div className="class-info">จำนวนที่ว่าง  <span>{item.MaxEnroll-item.CurrentEnroll}</span> /{item.MaxEnroll}</div>
                <a href="/" className="btn btn-primary" onClick={(e) => { e.preventDefault(); readData(item.ELearnID) }}>{language==="Thai"?"เข้าเรียน":"Take a Lesson"}</a>
            </div>
        </div>
    </div>
    ));
    const OldElearningList = oldElearning.map((item, i) => (
        <div className="col-md-4" key={item.ELearnID}>
            <div className="card">
                <img src={item.ThumnailWeb} className="cover" alt="Old Elearning cover" />
                <div className="card-body">
                    <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(new Date(item.PublishedStartDate))}</p></span>
                    <h4 className="card-content-title" style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault(); readData(item.ELearnID) }}>{item.ELearnTitle}</h4>
                </div>
            </div>
        </div>
    ));
    return (
        <div id="elerning-list" className="row">
            <div className="col-12">
                <ul className="tab nav">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#now-elerning">{language==="Thai"?"ปัจจุบัน":"New Content"}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#old-elerning">{language==="Thai"?"ย้อนหลัง":"Old Content"}</a>
                    </li>
                </ul>
                <div className="tab tab-content">
                    <div className="tab-pane active" id="now-elerning">
                        <div className="row same-height">

                            {ActiveElearningList}

                        </div>
                    </div>
                    <div className="tab-pane fade" id="old-elerning">
                        <div className="row same-height">
                            {OldElearningList}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}