import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from "utils/utils";
import { authenticationService } from "services/authentication.service.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import AdminBanner from "components/Banner/AdminBanner";
import SingleFileTable from "components/SingleFileTable";
import SError from "components/SError";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {
  REWARD_PERSON_AVATAR_REMARK,
  REWARD_PERSON_ATTACH_REMARK,
  REWARD_PERSON_MEDIA_REMARK,
} from "utils/text";

const swal = withReactContent(Swal);
const animatedComponents = makeAnimated();

export default function NewBestPersonReward(props) {
  const history = useHistory();
  const currentUser = authenticationService.currentUserValue;
  const [errors, setError] = useState({});
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const [department, setDepartment] = useState("");
  const [typeOfRewardDesc, setTypeOfRewardDesc] = useState("");
  const [portfolio, setPortfolio] = useState("");

  const [nameEN, setNameEN] = useState("");
  const [locationEN, setLocationEN] = useState("");
  const [positionEN, setPositionEN] = useState("");
  const [departmentEN, setDepartmentEN] = useState("");
  const [typeOfRewardDescEN, setTypeOfRewardDescEN] = useState("");
  const [portfolioEN, setPortfolioEN] = useState("");

  const [provinceID, SetProvinceID] = useState(0);
  const [districtID, setDistrictID] = useState(0);
  const [subDistricID, setSubDistricID] = useState(0);
  const [postCode, setPostCode] = useState("");

  const [areas, setareas] = useState([]);
  const [link, setLink] = useState("");
  const [target, setTarget] = useState("");
  const [targetEN, setTargetEN] = useState("");

  const [provinceList, SetProvinceList] = useState([]);
  const [allDistrictList, SetAllDistrictList] = useState([]);
  const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
  const [districtList, SetDistrictList] = useState([]);
  const [subDistrictList, SetSubDistrictList] = useState([]);

  const [isPersonType, setIsPersonType] = useState("");
  const [rewardYear, setRewardYear] = useState(2564);
  const [toppicTypeID, setToppicTypeID] = useState("");
  //const [phone, setPhone] = useState('');
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [status, setStatus] = useState(true);
  const [filespreview, setFilesPreview] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [logoimg, setLogoImg] = useState(null);
  const [topicList, setTopicList] = useState([]);
  const [selecttopicList, setSelectTopicList] = useState([]);

  const [AreaOptions, setAreaOptions] = useState([]);

  let seltopiclist = [];
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_APIURL + "/api-web/searchMemberInterestTopic",
        { params: { status: 1 } }
      )
      .then((response) => {
        setTopicList(response.data);
      });
  }, []); /* <-- add this for run once*/


  const TopicSelect = topicList.map((item) => (
    <option key={item.InterestTopicID} value={item.InterestTopicID}>
      {item.TextTH}
    </option>
  ));
  

  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;

    if (Number(isPersonType) === 0) {
      formIsValid = false;
      serrors["isPersonType"] = "กรุณาระบุรายละเอียด";
    }
    if (Number(rewardYear) < 2500) {
      formIsValid = false;
      serrors["rewardYear"] = "กรุณาระบุปี พ.ศ. ให้ถูกต้อง";
    }
    /*if (toppicTypeID.trim().length === 0) {
            formIsValid = false;
            serrors["toppicTypeID"] = "กรุณาระบุประเด็นสิทธิ";
        }*/
    if (name.trim().length === 0) {
      formIsValid = false;
      serrors["name"] = "กรุณาระบุรายละเอียด";
    }
    if (typeOfRewardDesc.trim().length === 0) {
      formIsValid = false;
      serrors["typeOfRewardDesc"] = "กรุณาระบุรายละเอียด";
    }
    if (portfolio.trim().length === 0) {
      formIsValid = false;
      serrors["portfolio"] = "กรุณาระบุรายละเอียด";
    }

    if (target.trim().length === 0) {
      formIsValid = false;
      serrors["target"] = "กรุณาระบุรายละเอียด";
    }
    /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุรายละเอียด";
        }
        if (typeOfRewardDescEN.trim().length === 0) {
            formIsValid = false;
            serrors["typeOfRewardDescEN"] = "กรุณาระบุรายละเอียด";
        }
        if (portfolioEN.trim().length === 0) {
            formIsValid = false;
            serrors["portfolioEN"] = "กรุณาระบุรายละเอียด";
        }*/
    if (Number(isPersonType) === 2) {
      if (location.trim().length === 0) {
        formIsValid = false;
        serrors["location"] = "กรุณาระบุรายละเอียด";
      }
      /*if (locationEN.trim().length === 0) {
                formIsValid = false;
                serrors["locationEN"] = "กรุณาระบุรายละเอียด";
            }*/

      if (Number(provinceID) === 0) {
        formIsValid = false;
        serrors["provinceID"] = "กรุณาเลือกจังหวัด";
      }
      if (Number(districtID) === 0) {
        formIsValid = false;
        serrors["districtID"] = "กรุณาเลือกเขต/อำเภอ";
      }
      if (Number(subDistricID) === 0) {
        formIsValid = false;
        serrors["subDistricID"] = "กรุณาเลือกแขวง/ตำบล";
      }
      if (postCode.trim().length === 0) {
        formIsValid = false;
        serrors["postCode"] = "กรุณากรอกข้อมูล";
      }

      if (tel.trim().length === 0) {
        formIsValid = false;
        serrors["tel"] = "กรุณาระบุรายละเอียด";
      }
      if (email.trim().length === 0) {
        formIsValid = false;
        serrors["email"] = "กรุณาระบุรายละเอียด";
      }
    }
    if (Number(isPersonType) === 1) {
      if (department.trim().length === 0) {
        formIsValid = false;
        serrors["department"] = "กรุณาระบุรายละเอียด";
      }
      if (position.trim().length === 0) {
        formIsValid = false;
        serrors["position"] = "กรุณาระบุรายละเอียด";
      }

      /*if (departmentEN.trim().length === 0) {
                formIsValid = false;
                serrors["departmentEN"] = "กรุณาระบุรายละเอียด";
            }
            if (positionEN.trim().length === 0) {
                formIsValid = false;
                serrors["positionEN"] = "กรุณาระบุรายละเอียด";
            }*/
    }

    if (areas.length === 0) {
      formIsValid = false;
      serrors["areas"] = "กรุณาเลือกพื้นที่";
    }

    if (selecttopicList.length === 0) {
      formIsValid = false;
      //console.log(selecttopicList)
      serrors["seltopiclist"] =
        "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
    }
    if (formIsValid === false) {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    }
    setError(serrors);
    return formIsValid;
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getProvince")
      .then((response) => {
        SetProvinceList(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getDistrict")
      .then((response) => {
        SetDistrictList(response.data);
        SetAllDistrictList(response.data);
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict")
      .then((response) => {
        SetSubDistrictList(response.data);
        SetAllSubDistrictList(response.data);
      });
  }, []); /* <-- add this for run once*/

  const handleChangeProvince = (ProvinceID) => {
    SetDistrictList(
      allDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    setDistrictID(0);
    setSubDistricID(0);
  };
  const handleChangeDistrict = (DistrictID) => {
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["DistrictID"] === Number(DistrictID)
      )
    );
    setSubDistricID(0);
  };
  const handleChangeSubDistrict = (SubDistrictID) => {
    const subdistrictitem = allSubDistrictList.filter(
      (item) => item["SubDistrictID"] === Number(SubDistrictID)
    );
    if (subdistrictitem[0] != null) {
      setPostCode(subdistrictitem[0].PostCode);
    } else {
      setPostCode("");
    }
  };

  const ProvinceSelect = provinceList.map((province) => (
    <option key={province.ProvinceID} value={province.ProvinceID}>
      {province.ProvinceTH}
    </option>
  ));
  const DistrictSelect = districtList.map((district) => (
    <option key={district.DistrictID} value={district.DistrictID}>
      {district.DistrictTH}
    </option>
  ));
  const SubDistrictSelect = subDistrictList.map((subdistrict) => (
    <option key={subdistrict.SubDistrictID} value={subdistrict.SubDistrictID}>
      {subdistrict.SubDistrictTH}
    </option>
  ));

  const changePersonType = (e) => {
    e.preventDefault();
    setIsPersonType(e.currentTarget.value);
    setareas([])
    fetchArea(e.currentTarget.value);
}

const fetchArea = (isPersonType) => {
    let queryString = '';
    if(isPersonType || isPersonType !== undefined) {
        queryString = "?type="+isPersonType;
    }
    const fetchDataArea = [];
    axios.get(process.env.REACT_APP_APIURL + '/api-web/master/area'+queryString, {}).then((res) => {
       
        if(res.data.length > 0){
            res.data.map((v) => {
                fetchDataArea.push({ value: v.AreaID, label: v.Name })
            })
        }
        setAreaOptions(fetchDataArea);
    })
    // let fakeArea;
    // if(isPersonType === 1){
    //     fakeArea = [
    //         { value: '1', label: 'พื้นที่ 1' },
    //         { value: '2', label: 'พื้นที่ 2' },
    //         { value: '3', label: 'พื้นที่ 3' }
    //     ]
    // }else{
    //     fakeArea = [
    //         { value: '4', label: 'พื้นที่ 4' },
    //         { value: '5', label: 'พื้นที่ 5' },
    //         { value: '6', label: 'พื้นที่ 6' }
    //     ]
    // }
    // console.log(isPersonType, fakeArea);
    // setAreaOptions(fakeArea);
   
}

const multipleRender = () => {
  let defaultValue;
  if(areas.length > 0){
      defaultValue = areas;
  }else{
      defaultValue = [{ value: 0, label: "กรุณาเลือก"}]
  }
  return (
            <div className="col-6">
                <label htmlFor="area" className="required">พื้นที่ทำงาน</label>
                <Select 
                    value={defaultValue}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={AreaOptions} 
                    onChange={(e) => { setareas(e)}}
                />
                <SError error={errors["area"]} />
            </div>
        )
  }

  const saveData = (open) => {
    if (handleValidation()) {
      const formData = new FormData();
      console.log(provinceID, districtID, subDistricID, postCode);
      formData.append("isPersonType", isPersonType);
      formData.append("rewardYear", rewardYear);
      // formData.append("toppicTypeID", toppicTypeID);
      formData.append("name", name);
      formData.append("location", location);
      formData.append("department", department);
      formData.append("position", position);
      formData.append("typeOfRewardDesc", typeOfRewardDesc);
      formData.append("portfolio", portfolio);
      formData.append("nameEN", nameEN);
      formData.append("locationEN", locationEN);
      formData.append("departmentEN", departmentEN);
      formData.append("positionEN", positionEN);

      formData.append("provinceID", provinceID);
      formData.append("districtID", districtID);
      formData.append("subDistrictID", subDistricID);
      formData.append("postCode", postCode);

      if (areas.length > 0) {
        const joinArea = areas.map((v) => {
          if(v.value !== 0) return v.value
        })
        formData.append("area", joinArea.join(","));
      }
      formData.append("link", link);

      formData.append("target", target);
      formData.append("targetEN", targetEN);

      formData.append("typeOfRewardDescEN", typeOfRewardDescEN);
      formData.append("portfolioEN", portfolioEN);
      formData.append("tel", tel);
      formData.append("status", status);
      formData.append("email", email);
      formData.append("seltopiclist", selecttopicList);

      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i += 1) {
          formData.append("fileList[]", fileList[i]);
        }
      }
      if (mediaList.length > 0) {
        for (let i = 0; i < mediaList.length; i += 1) {
          formData.append("fileList[]", mediaList[i]);
        }
      }
      if (documentsList.length > 0) {
        for (let i = 0; i < documentsList.length; i += 1) {
          formData.append("documentList[]", documentsList[i]);
        }
      }

      if (logoimg?.file !== undefined) {
        formData.append("logofile", logoimg.file);
      }

      formData.append("userID", currentUser.UserID);

      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/savePersonBestReward",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              if (open) {
                window.location.reload();
              } else {
                props.history.push({
                  pathname: "/admin/network/BestPersonRewardList",
                });
              }
            });
        });
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };
  const cancel = () => {
    history.push({ pathname: "/admin/network/BestPersonRewardList" });
  };
  const rx_int = /^\d+$/;
  const handleYearChange = (e) => {
    if (rx_int.test(e.target.value)) {
      setRewardYear(parseInt(e.target.value));
    }
  };
  const handleRemoveFile = (filename) => {
    for (let file of fileList) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios
            .post(
              process.env.REACT_APP_APIURL + "/api-web/deleteAttachmentFile",
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data`,
                },
              }
            )
            .then((response) => {});
        }
      } // Add only file name not matched completeDocFilesList
    }
    setFileList(fileList.filter((item) => item["name"] !== filename));
  };
  const FilesList = fileList.map((file, i) => (
    <tr key={file.path}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveFile(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const handleRemoveMediaFile = (filename) => {
    for (let file of mediaList) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios
            .post(
              process.env.REACT_APP_APIURL + "/api-web/deleteAttachmentFile",
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data`,
                },
              }
            )
            .then((response) => {});
        }
      } // Add only file name not matched completeDocFilesList
    }
    setMediaList(mediaList.filter((item) => item["name"] !== filename));
  };
  const MediaList = mediaList.map((file, i) => (
    <tr key={file.path}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveMediaFile(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const handleRemoveDocumentFile = (filename) => {
    for (let file of documentsList) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios
            .post(
              process.env.REACT_APP_APIURL + "/api-web/deleteAttachmentFile",
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data`,
                },
              }
            )
            .then((response) => {});
        }
      } // Add only file name not matched completeDocFilesList
    }
    setDocumentsList(documentsList.filter((item) => item["name"] !== filename));
  };
  const DocumentsList = documentsList.map((file, i) => (
    <tr key={file.path}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveDocumentFile(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const handleLogoImgOnDrop = (newImageFile, onChange) => {
    const imageFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size,
    };
    setLogoImg(imageFile);
  };

  const handlePushareas = (e) => {
    const values = [];
    e.map((v) => {
      values.push(v);
    });

    setareas(values);
  };

  const LogoImgPreview = logoimg !== null && (
    <img
      className="preview-upload"
      src={logoimg.preview}
      onChange={(e) => {
        e.target.src = URL.createObjectURL(logoimg);
      }}
      alt="MOU logo"
    />
  );
  const handleRemoveLogoFile = () => {
    setLogoImg(null);
  };
  const TopicCheckbox = topicList.map((item) => (
    <div className="col-12" key={item.InterestTopicID}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"operation" + item.InterestTopicID}
          name={"operation" + item.InterestTopicID}
          checked={selecttopicList.indexOf(Number(item.InterestTopicID)) > -1}
          onChange={(e) => {
            var checked = e.target.checked;
            seltopiclist = [...selecttopicList];
            if (checked) {
              if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                seltopiclist.push(item.InterestTopicID);
              }
            } else {
              if (seltopiclist.indexOf(item.InterestTopicID) > -1) {
                let index = seltopiclist.indexOf(item.InterestTopicID);
                seltopiclist = seltopiclist
                  .slice(0, index)
                  .concat(seltopiclist.slice(index + 1));
              }
            }
            setSelectTopicList(seltopiclist);
            //console.log(seltopiclist)
          }}
        />
        <label
          className="custom-control-label"
          htmlFor={"operation" + item.InterestTopicID}
        >
          {item.TextTH}
        </label>
      </div>
    </div>
  ));

  useEffect(() => {
    setareas([]);
  }, [isPersonType]);

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link to={{ pathname: "/admin/network/BestPersonRewardList" }}>
              {"การจัดการรางวัลนักสิทธิมนุษยชนดีเด่น"}
            </Link>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              className="active"
              to={{ pathname: "/admin/network/NewBestPersonReward" }}
            >
              {"สร้าง"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form method="post" encType="multipart/form-data" className="row">
          <div className="col-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="tab1-tab"
                  data-toggle="tab"
                  href="#tab1"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  TH
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab2-tab"
                  data-toggle="tab"
                  href="#tab2"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  EN
                </a>
              </li>
            </ul>
            <div className="tab-content pl-4 pr-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <div className="card">
                  <div className="card-body row">
                    <div className="col-6">
                      <label htmlFor="name" className="required">
                        ประเภท
                      </label>
                      <select
                        className="form-control mb-2 mr-2"
                        value={isPersonType}
                        onChange={(e) => {
                          changePersonType(e);
                        }}
                      >
                        <option value="0">ประเภท</option>
                        <option value="1">บุคคล</option>
                        <option value="2">องค์กร</option>
                      </select>
                      <SError error={errors["isPersonType"]} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="rewardYear" className="required">
                        ปี พ.ศ.
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        id="rewardYear"
                        value={rewardYear}
                        name="rewardYear"
                        placeholder="กรุณากรอก"
                        onChange={(e) => {
                          handleYearChange(e);
                        }}
                      />
                      <SError error={errors["rewardYear"]} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="toppicTypeID" className="required">
                        ประเภทรางวัล
                      </label>
                      {TopicCheckbox}
                      <SError error={errors["toppicTypeID"]} />
                    </div>
                    <div className="col-12">
                      <label htmlFor="name" className="required">
                        ชื่อ
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        id="name"
                        value={name}
                        name="name"
                        placeholder="กรุณากรอก"
                        onChange={(e) => {
                          setName(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["name"]} />
                    </div>
                    {Number(isPersonType) === 2 && (
                      <>
                        <div className="col-12">
                          <label htmlFor="location" className="required">
                            ที่อยู่
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="location"
                            value={location}
                            name="location"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setLocation(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["location"]} />
                        </div>

                        <div className="col-6">
                          <label htmlFor="provinceID" className="required">
                            จังหวัด
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="provinceID"
                            name="provinceID"
                            value={provinceID}
                            onChange={(e) => {
                              SetProvinceID(e.currentTarget.value);
                              handleChangeProvince(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {ProvinceSelect}
                          </select>
                          <SError error={errors["provinceID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="districtID" className="required">
                            เขต/อำเภอ
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="districtID"
                            name="districtID"
                            value={districtID}
                            onChange={(e) => {
                              setDistrictID(e.currentTarget.value);
                              handleChangeDistrict(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {DistrictSelect}
                          </select>
                          <SError error={errors["districtID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="subDistricID" className="required">
                            แขวง/ตำบล
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="subDistricID"
                            name="subDistricID"
                            value={subDistricID}
                            onChange={(e) => {
                              setSubDistricID(e.currentTarget.value);
                              handleChangeSubDistrict(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {SubDistrictSelect}
                          </select>
                          <SError error={errors["subDistricID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="orgName" className="required">
                            รหัสไปรษณีย์
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="postCode"
                            value={postCode}
                            name="postCode"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setPostCode(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["postCode"]} />
                        </div>

                        <div className="col-6">
                          <label htmlFor="tel" className="required">
                            เบอร์โทร
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="tel"
                            value={tel}
                            name="tel"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setTel(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["tel"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="email" className="required">
                            อีเมล
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="email"
                            value={email}
                            name="email"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setEmail(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["email"]} />
                        </div>
                      </>
                    )}

                    {Number(isPersonType) === 1 && (
                      <>
                        <div className="col-6">
                          <label htmlFor="position" className="required">
                            ตำแหน่ง
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="position"
                            name="position"
                            placeholder="กรุณากรอก"
                            value={position}
                            onChange={(e) => {
                              setPosition(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["position"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="department" className="required">
                            หน่วยงาน/องค์กร
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="department"
                            value={department}
                            name="department"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setDepartment(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["department"]} />
                        </div>
                      </>
                    )}

                    {multipleRender()}
                    <div className="col-6">
                      <label htmlFor="link">Link เชื่อมโยง</label>
                      <input
                        type="text"
                        className="form-control "
                        id="link"
                        name="link"
                        placeholder="กรุณากรอก link เชื่อมโยง"
                        value={link}
                        onChange={(e) => {
                          setLink(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["link"]} />
                    </div>

                    <div className="col-12">
                      <label htmlFor="typeOfRewardDesc" className="required">
                        ประเภทรางวัล
                      </label>
                      <div>
                        <ReactSummernote
                          value={typeOfRewardDesc}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setTypeOfRewardDesc}
                        />
                        <SError error={errors["typeOfRewardDesc"]} />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="portfolio" className="required">
                        ผลงาน
                      </label>
                      <div>
                        <ReactSummernote
                          value={portfolio}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setPortfolio}
                        />
                        <SError error={errors["portfolio"]} />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="target" className="required">
                        กลุ่มเป้าหมาย
                      </label>
                      <div>
                        <ReactSummernote
                          value={target}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setTarget}
                        />
                        <SError error={errors["target"]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="tab2"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <div className="card">
                  <div className="card-body row">
                    <div className="col-6">
                      <label htmlFor="name">ประเภท</label>
                      <select
                        className="form-control mb-2 mr-2"
                        value={isPersonType}
                        onChange={(e) => {
                          setIsPersonType(e.currentTarget.value);
                        }}
                      >
                        <option value="0">ประเภท</option>
                        <option value="1">บุคคล</option>
                        <option value="2">องค์กร</option>
                      </select>
                      <SError error={errors["isPersonType"]} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="rewardYear">ปี พ.ศ.</label>
                      <input
                        type="text"
                        className="form-control "
                        id="rewardYear"
                        value={rewardYear}
                        name="rewardYear"
                        placeholder="กรุณากรอก"
                        onChange={(e) => {
                          handleYearChange(e);
                        }}
                      />
                      <SError error={errors["rewardYear"]} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="toppicTypeID">ประเภทรางวัล</label>
                      {TopicCheckbox}
                    </div>
                    <div className="col-12">
                      <label htmlFor="nameEN">ชื่อ</label>
                      <input
                        type="text"
                        className="form-control "
                        id="nameEN"
                        value={nameEN}
                        name="nameEN"
                        placeholder="กรุณากรอก"
                        onChange={(e) => {
                          setNameEN(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["nameEN"]} />
                    </div>
                    {Number(isPersonType) === 2 && (
                      <>
                        <div className="col-12">
                          <label htmlFor="locationEN">ที่อยู่</label>
                          <input
                            type="text"
                            className="form-control "
                            id="locationEN"
                            value={locationEN}
                            name="locationEN"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setLocationEN(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["locationEN"]} />
                        </div>

                        <div className="col-6">
                          <label htmlFor="provinceID" className="required">
                            จังหวัด
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="provinceID"
                            name="provinceID"
                            value={provinceID}
                            onChange={(e) => {
                              SetProvinceID(e.currentTarget.value);
                              handleChangeProvince(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {ProvinceSelect}
                          </select>
                          <SError error={errors["provinceID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="districtID" className="required">
                            เขต/อำเภอ
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="districtID"
                            name="districtID"
                            value={districtID}
                            onChange={(e) => {
                              setDistrictID(e.currentTarget.value);
                              handleChangeDistrict(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {DistrictSelect}
                          </select>
                          <SError error={errors["districtID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="subDistricID" className="required">
                            แขวง/ตำบล
                          </label>
                          <select
                            className="form-control mb-2 mr-2"
                            id="subDistricID"
                            name="subDistricID"
                            value={subDistricID}
                            onChange={(e) => {
                              setSubDistricID(e.currentTarget.value);
                              handleChangeSubDistrict(e.currentTarget.value);
                            }}
                          >
                            <option key="0" value="0">
                              กรุณาเลือก
                            </option>
                            {SubDistrictSelect}
                          </select>
                          <SError error={errors["subDistricID"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="orgName" className="required">
                            รหัสไปรษณีย์
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="postCode"
                            value={postCode}
                            name="postCode"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setPostCode(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["postCode"]} />
                        </div>

                        <div className="col-6">
                          <label htmlFor="tel" className="required">
                            เบอร์โทร
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="tel"
                            value={tel}
                            name="tel"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setTel(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["tel"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="email" className="required">
                            อีเมล
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="email"
                            value={email}
                            name="email"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setEmail(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["email"]} />
                        </div>
                      </>
                    )}

                    {Number(isPersonType) === 1 && (
                      <>
                        <div className="col-6">
                          <label htmlFor="positionEN">ตำแหน่ง</label>
                          <input
                            type="text"
                            className="form-control "
                            id="positionEN"
                            name="positionEN"
                            placeholder="กรุณากรอก"
                            value={positionEN}
                            onChange={(e) => {
                              setPositionEN(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["positionEN"]} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="departmentEN">หน่วยงาน/องค์กร</label>
                          <input
                            type="text"
                            className="form-control "
                            id="departmentEN"
                            value={departmentEN}
                            name="departmentEN"
                            placeholder="กรุณากรอก"
                            onChange={(e) => {
                              setDepartmentEN(e.currentTarget.value);
                            }}
                          />
                          <SError error={errors["departmentEN"]} />
                        </div>
                      </>
                    )}

                    {multipleRender()}
                    <div className="col-6">
                      <label htmlFor="link">Link เชื่อมโยง</label>
                      <input
                        type="text"
                        className="form-control "
                        id="link"
                        name="link"
                        placeholder="กรุณากรอก link เชื่อมโยง"
                        value={link}
                        onChange={(e) => {
                          setLink(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["link"]} />
                    </div>

                    <div className="col-12">
                      <label htmlFor="typeOfRewardDescEN">ประเภทรางวัล</label>
                      <div>
                        <ReactSummernote
                          value={typeOfRewardDescEN}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setTypeOfRewardDescEN}
                        />
                        <SError error={errors["typeOfRewardDescEN"]} />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="portfolioEN">ผลงาน</label>
                      <div>
                        <ReactSummernote
                          value={portfolioEN}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setPortfolioEN}
                        />
                        <SError error={errors["portfolioEN"]} />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="targetEN" className="required">
                        กลุ่มเป้าหมาย
                      </label>
                      <div>
                        <ReactSummernote
                          value={targetEN}
                          options={{
                            //lang: 'th-TH',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["color", ["color"]],
                              /*['fontname', ['fontname']],*/
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "video"]],
                              ["view", ["fullscreen"]],
                            ],
                          }}
                          onChange={setTargetEN}
                        />
                        <SError error={errors["targetEN"]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-content pl-4 pr-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-12">
                  <label className="required">
                    {Number(isPersonType) === 1 ? "ภาพบุคคล" : "ภาพโลโก้องค์กร"}
                  </label>
                  <p className="remark smalltext">
                    {REWARD_PERSON_AVATAR_REMARK}
                  </p>
                  <Dropzone
                    className="dropzone"
                    activeClassName="active-dropzone"
                    multiple={false}
                    onDrop={handleLogoImgOnDrop}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <SError error={errors["logoimg"]} />
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <div>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </div>
                        </div>
                        {LogoImgPreview}
                        <SingleFileTable
                          filedata={logoimg}
                          removefn={handleRemoveLogoFile}
                        />
                      </section>
                    )}
                  </Dropzone>
                </div>

                <div className="col-12">
                  <label>เอกสารแนบ</label>
                  <p className="remark smalltext">
                    {REWARD_PERSON_ATTACH_REMARK}
                  </p>
                  <Dropzone
                    className="input-dropzone dropzone"
                    onDrop={(acceptedFiles) => {
                      setFileList([...fileList, ...acceptedFiles]);
                      setFilesPreview(
                        acceptedFiles.map((file) =>
                          Object.assign(file, {
                            id: 0,
                            preview: URL.createObjectURL(file),
                          })
                        )
                      );
                    }}
                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>ลำดับ</th>
                                  <th>ชื่อ</th>
                                  <th>ขนาดไฟล์</th>
                                  <th>ประเภทไฟล์</th>
                                  <th>จัดการ</th>
                                </tr>
                              </thead>
                              <tbody id="your-table-body-id">{FilesList}</tbody>
                            </table>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>

                <div className="col-12">
                  <label>วิดีโอ</label>
                  <p className="remark smalltext">
                    {REWARD_PERSON_MEDIA_REMARK}
                  </p>
                  <Dropzone
                    className="input-dropzone dropzone"
                    onDrop={(acceptedFiles) => {
                      setMediaList([...mediaList, ...acceptedFiles]);
                      setFilesPreview(
                        acceptedFiles.map((file) =>
                          Object.assign(file, {
                            id: 0,
                            preview: URL.createObjectURL(file),
                          })
                        )
                      );
                    }}
                    accept=".mp3,.mp4,.avi,.fla,.wma,.wmv,.mpeg,.mpeg4"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>ลำดับ</th>
                                  <th>ชื่อ</th>
                                  <th>ขนาดไฟล์</th>
                                  <th>ประเภทไฟล์</th>
                                  <th>จัดการ</th>
                                </tr>
                              </thead>
                              <tbody id="your-table-body-id">{MediaList}</tbody>
                            </table>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>

                <div className="col-12">
                  <label>แนบไฟล์ใบสมัคร</label>
                  <p className="remark smalltext">
                    {REWARD_PERSON_ATTACH_REMARK}
                  </p>
                  <Dropzone
                    className="input-dropzone dropzone"
                    onDrop={(acceptedFiles) => {
                      setDocumentsList([...documentsList, ...acceptedFiles]);
                      setFilesPreview(
                        acceptedFiles.map((file) =>
                          Object.assign(file, {
                            id: 0,
                            preview: URL.createObjectURL(file),
                          })
                        )
                      );
                    }}
                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>ลำดับ</th>
                                  <th>ชื่อ</th>
                                  <th>ขนาดไฟล์</th>
                                  <th>ประเภทไฟล์</th>
                                  <th>จัดการ</th>
                                </tr>
                              </thead>
                              <tbody id="your-table-body-id">
                                {DocumentsList}
                              </tbody>
                            </table>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>

                <div className="col-12">
                  <label className="required mr-2">สถานะ</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="status1"
                      value="1"
                      checked={status === true}
                      onChange={(e) => {
                        setStatus(true);
                      }}
                    />
                    <label className="form-check-label" htmlFor="status1">
                      {" "}
                      เผยแพร่
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="status2"
                      value="0"
                      checked={status === false}
                      onChange={(e) => {
                        setStatus(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor="status2">
                      {" "}
                      ไม่เผยแพร่
                    </label>
                  </div>
                  <SError error={errors["status"]} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="pl-4 pr-4 text-center margintop40">
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(false);
                }}
              >
                <i className="fas fa-save"></i> บันทึก
              </button>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
              >
                <i className="fas fa-save"></i> บันทึกและเปิด
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => {
                  e.preventDefault();
                  cancel();
                }}
              >
                <i className="far fa-times-circle"></i> ยกเลิก
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
