import React, { useState, useEffect } from "react";
import utils from 'utils/utils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from 'axios';
import Swal from 'sweetalert2';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const FileReorder = (props) => {
    const { files, editRemark } = props;
    const [items, setItems] = useState([])
    const [remarks, setRemarks] = useState([]);

    useEffect(() => {
        let dataRemark = [];
        setItems(files);
        files.map((v) => {
            dataRemark[v.id] = { ...v }
        })
        setRemarks(dataRemark);
    }, [files])

    const onDragEnd = async (result) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
        const newItems = reorder( items, result.source.index, result.destination.index)
        setItems(newItems)
        await handleReOrdering(newItems);
    }

    const handleAddRemark = (payload) => {
        Swal.fire({
            input: 'textarea',
            inputLabel: 'หมายเหตุ',
            inputPlaceholder: 'เพิ่มหมายเหตุให้ไฟล์',
            inputAttributes: {
                'aria-label': 'เพิ่มหมายเหตุให้ไฟล์'
            },
            inputValue: remarks[payload.id].remark,
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            showLoaderOnConfirm: true,
            preConfirm: (param) => {
                axios.post(process.env.REACT_APP_APIURL + '/api-web/files/remark', { id: payload.id, remark: param})
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error(response.statusText)
                    }
                    remarks[payload.id] = { ...remarks[payload.id], remark: param};
                    setRemarks(remarks)
                }).catch(error => {
                    Swal.showValidationMessage(
                        `Request failed: ${error}`
                    )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                console.log( remarks[payload.id]);
                Swal.fire('บันทึกสำเร็จ')
            }
        })
    }

    const handleReOrdering = async (payload) => {
        const ids = payload.map(item => item.id);
        await axios.post(process.env.REACT_APP_APIURL + '/api-web/reorder/files', { ids }).catch((response) => console.warn(response))
    }
    
    const handleRemoveFile = filename => {
        Swal.fire({
            title: 'ต้องการลบไฟล์นี้หรือไม่',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            denyButtonText: `ไม่ใช่`,
        }).then((result) => {
            if (result.isConfirmed) {
                for (let file of items) {
                    if (file.name === filename) {
                        if (file.id !== 0) {
                            const formData = new FormData();
                            formData.append("id", file.id);
                            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                                headers: {
                                    'Content-Type': `multipart/form-data`
                                }
                            }).catch((response) => console.warn(response))
                        }
                    }
                }
                setItems(items.filter(item => item['name'] !== filename));
            } 
        })  
    };

    const makeId = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    return (
        <div className="table-responsive">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>ลำดับ</th>
                                        <th>ชื่อ</th>
                                        <th>ขนาดไฟล์</th>
                                        <th>ประเภทไฟล์</th>
                                        <th>จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody id="your-table-body-id">
                                    {items && items.map((item, index) =>
                                    <Draggable draggableId={item.id.toString()} key={`${makeId(4)}${item.id.toString()}`} index={index}>
                                        {(provided) => (
                                        <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <td width="5%">{(Number(index) + 1)}</td>
                                            <td width="60%">{item.name.split('.')[0]}</td>
                                            <td >{utils.Bytes2Size(item.size)}</td>
                                            <td >{item.path.split('.').pop()}</td>
                                        
                                            <td >
                                                { 
                                                    editRemark !== undefined && editRemark === true ? (
                                                        <><button style={{ marginRight: "10px"}} className="btn btn-sm btn-info controlbtn" onClick={(e) => { e.preventDefault(); handleAddRemark(item); }}>เพิ่มหมายเหตุ</button></>
                                                    ) : <></>
                                                }
                                                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={item.preview} rel="noreferrer" target="_blank">ดู</a>
                                                <button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveFile(item.name); }}>ลบ</button>
                                            </td>
                                        </tr>
                                        )}
                                    </Draggable>)}
                                </tbody>
                            </table>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
        
    )
}                 

export default FileReorder