import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import AdminBanner from 'components/Banner/AdminBanner';
import { authenticationService } from 'services/authentication.service.js';
const swal = withReactContent(Swal)

export default function ViewNetworkActivity(props) {
    const currentUser = authenticationService.currentUserValue;
    const networkid = props.match.params.networkid;
    const networkactivityid = props.match.params.networkactivityid;
    const [errors, setError] = useState({});
    const [organization, SetOrganization] = useState('');
    /*const [operation1, SetOperation1] = useState(false);
    const [operation2, SetOperation2] = useState(false);
    const [operation3, SetOperation3] = useState(false);
    const [operation4, SetOperation4] = useState(false);
    const [operation5, SetOperation5] = useState(false);
    const [operation6, SetOperation6] = useState(false);
    const [operation7, SetOperation7] = useState(false);*/
    const [year, SetYear] = useState(2564);
    const [activityName, SetActivityName] = useState('');
    const [activityDetail, SetActivityDetail] = useState('');
    const [networkActivityFiles, setNetworkActivityFiles] = useState([]);
    const [filespreview, setFilesPreview] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [selecttopicList, setSelectTopicList] = useState([]);
    let seltopiclist = [];
    const rx_int = /^\d+$/;
    const handleYearChange = (e) => {
        if (rx_int.test(e.target.value)) {
            if (e.target.value.length < 5) {
                SetYear(parseInt(e.target.value));
            }
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (organization.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (selecttopicList.length === 0) {
            formIsValid = false;
            //console.log(selecttopicList)
            serrors["seltopiclist"] = "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            //console.log(imageFiles)
            formData.append("networkid", networkid);
            formData.append("networkactivityid", networkactivityid);
            /*formData.append("operation1", operation1);
            formData.append("operation2", operation2);
            formData.append("operation3", operation3);
            formData.append("operation4", operation4);
            formData.append("operation5", operation5);
            formData.append("operation6", operation6);
            formData.append("operation7", operation7);*/
            formData.append("year", year);
            formData.append("activityName", activityName);
            formData.append("activityDetail", activityDetail);
            formData.append("selecttopicList", selecttopicList);
            formData.append("userID", currentUser.UserID);
            for (let i = 0; i < networkActivityFiles.length; i += 1) {
                //console.log(myFiles[i])
                formData.append("networkActivityFiles[]", networkActivityFiles[i]);
            }
            let Images = [];
            for (let i = 0; i < imageFiles.length; i += 1) {
                //console.log(imageFiles[i].path);
                if (typeof imageFiles[i].path != 'undefined') {
                    formData.append("imageFiles[]", imageFiles[i]);
                }
                else {
                    Images.push('"' + imageFiles[i].preview + '"');
                }
            }
            formData.append("Images", Images);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNetworkActivity', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            props.history.push({ pathname: "/admin/network/ViewNetworkActivity/" + networkid + "/" + networkactivityid });
                        }
                        else {
                            window.location.reload();
                        }
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }
    };

    const handleNetworkEdit = params => {
        props.history.push({ pathname: "/admin/network/EditNetwork", state: { id: networkid } });
    };
    useEffect(() => {
        //console.log(networkactivityid)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/corporateNetworkActivitybyID', { params: { id: networkid, networkactivityid: networkactivityid } }).then((response) => {
            //console.log(response.data)
            SetOrganization(response.data.NetOrganization);
            const newFiles = [...networkActivityFiles];
            var activity = response.data.networkactivity;
            if (activity.ToppicTypeIDList !== null) {
                var array = activity.ToppicTypeIDList.split(",").map(Number);
                for (let i = 0; i < array.length; i++) {
                    seltopiclist.push(array[i]);
                }
                setSelectTopicList(seltopiclist);
            }

            SetYear(activity.Year);
            SetActivityName(activity.ActivityName);
            SetActivityDetail(activity.ActivityDetail);
            let loop = 0;
            for (let j = 0; j < activity.filelist.length; j += 1) {

                let item = activity.filelist[j];
                var filename = item.AttachmentPath.substring(item.AttachmentPath.lastIndexOf('/') + 1);
                if (filename.trim().length > 0) {
                    const filedata = {
                        id: item.AttachmentID,
                        path: item.FilenameNew,
                        name: filename,
                        preview: item.AttachmentPath,
                        size: utils.getFileSize(item.AttachmentPath)
                    };
                    newFiles[loop] = filedata;
                    loop++;
                }
            }
            setNetworkActivityFiles(newFiles);
            const newimgFiles = [...imageFiles];
            if (activity.Images !== null) {
                const myarr = activity.Images.replaceAll('"', '').replaceAll('[', '').replaceAll(']', '').split(",");
                //console.log(myarr)
                loop = 0;
                for (let j = 0; j < myarr.length; j++) {
                    var filename = myarr[j].substring(myarr[j].lastIndexOf('/') + 1);
                    //console.log(filename)
                    if (filename.trim().length > 0) {
                        const filedata = {
                            name: filename,
                            preview: myarr[j],
                            size: utils.getFileSize(myarr[j])
                        };
                        newimgFiles[loop] = filedata;
                        loop++;
                    }
                }
                setImageFiles(newimgFiles);
            }
        });

    }, []); /* <-- add this for run once*/
    const TopicCheckbox = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={"operation" + item.InterestTopicID} name={"operation" + item.InterestTopicID} checked={selecttopicList.indexOf(Number(item.InterestTopicID)) > -1}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist = [...selecttopicList];
                        if (checked) {
                            if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist.push(item.InterestTopicID);
                            }
                        }
                        else {
                            if (seltopiclist.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist.indexOf(item.InterestTopicID);
                                seltopiclist = seltopiclist.slice(0, index).concat(seltopiclist.slice(index + 1));
                            }
                        }
                        setSelectTopicList(seltopiclist);
                        //console.log(seltopiclist)
                    }} />
                <label className="custom-control-label" htmlFor={"operation" + item.InterestTopicID}>{item.TextTH}</label>
            </div>
        </div>
    ));
    const NetActivityFilesList = networkActivityFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                {/*<button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>*/}
            </td>
        </tr>
    ));
    const NetImageFilesList = imageFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
            </td>
        </tr>
    ));
    return (

        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={
                <>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>จัดการองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link id="profile-btn" to={{ pathname: "/admin/network/NetworkORGList" }}>{"เอกชนด้านสิทธิมนุษยชน"}</Link>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link id="profile-btn" onClick={e => { e.preventDefault(); handleNetworkEdit(); }} to={{ pathname: "/admin/network/EditNetwork" }}>{"แก้ไข"}</Link>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link id="profile-btn" className="active" to={{ pathname: "/admin/network/ViewNetworkActivity/" + networkid + "/" + networkactivityid }}>{"ดูผลงาน"}</Link>
                </>
            }
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >

                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label htmlFor="organization" className="required">ชื่อองค์กร</label>
                                    <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <span className="error">{errors["organization"]}</span>
                                    {/*<h4>{organization}</h4>*/}
                                </div>
                                <div className="alert alert-dark col-12" role="alert">
                                    ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                                </div>
                                {TopicCheckbox}
                                <div className="col-12">
                                    <span className="error">{errors["seltopiclist"]}</span>
                                </div>
                                <div className="alert alert-dark col-12" role="alert">
                                    ผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนอย่างต่อเนื่อง เป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง และมีผลการดำเนินงานเป็นที่ประจักษ์ (ไม่เกิน 3 เรื่อง)
                                </div>
                                <div className="col-3">
                                    <label htmlFor="year" className="required">พ.ศ.</label>
                                    <input type="text" className="form-control " id="year" value={year} name="year" placeholder="กรุณากรอก" onChange={(e) => { handleYearChange(e) }} />
                                    <span className="error">{errors["year"]}</span>
                                </div>
                                <div className="col-9">
                                    <label htmlFor="activityName" className="required">ชื่อผลการดำเนินงาน</label>
                                    <input type="text" className="form-control " id="activityName" value={activityName} name="activityName" placeholder="กรุณากรอก" onChange={(e) => { SetActivityName(e.currentTarget.value) }} />
                                    <span className="error">{errors["activityName"]}</span>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="activityDetail" >รายละเอียดผลงาน (โดยย่อ)</label>
                                    <div>
                                        <textarea className="form-control" name="activityDetail" id="activityDetail" rows="8" value={activityDetail} onChange={e => { SetActivityDetail(e.target.value) }}></textarea>
                                        <span className="error">{errors["activityDetail"]}</span>
                                    </div>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                </div>
                                <div className="col-12">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setNetworkActivityFiles([...networkActivityFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetActivityFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                                </div>
                                <div className="col-12">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setImageFiles([...imageFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".jpg,.jpeg,.gif,.png"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetImageFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center margintop40" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); handleNetworkEdit(); }}><i className="far fa-times-circle"></i> ย้อนกลับ</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </section>

    );
}