import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/elearning.svg";
import utils from "utils/utils";
import { authenticationService } from "services/authentication.service.js";
/*import { useHistory } from "react-router-dom";
import { duration } from "moment";*/

const swal = withReactContent(Swal);

export default function ElearningTestResult(props) {
  const currentUser = authenticationService.currentUserValue;
  const eLearningID = props.location.state.eLearningID;
  const chapterID = props.location.state.chapterID;
  const questionnaireID = props.location.state.questionnaireID;
  //const [elearningChapterUser, setElearningChapterUser] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [userID, setUserID] = useState(null);
  const [chapterQuestion, setChapterQuestion] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [testData, setTestData] = useState({});
  //const [userAnswerList, setUserAnswerList] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const language = localStorage.getItem("language");

  useEffect(() => {
    //console.log(props.location.state)
    //history.listen((location)=>{dismissAllFlags(location)})
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    setUserID(userid);
    const formData = new FormData();
    formData.append("eLearningID", eLearningID);
    formData.append("chapterID", chapterID);
    formData.append("questionnaireID", questionnaireID);
    formData.append("userID", userid);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/getQuestionnairebyID",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setChapterQuestion(response.data);
        console.log(response.data);
        setMaxDuration(response.data.questionnaire.Duration);
        setTestData(response.data.testData[0]);
        const questionItemPrepair = [...response.data.questionItem];
        /*เพิ่มฟิลด์คำตอบที่ผู้ใช้เลือก*/
        questionItemPrepair.forEach(function (item) {
          const found = response.data.useranswer.find(
            (element) =>
              Number(element.QuestionItemID) === Number(item.QuestionItemID)
          );
          item.userAnswer = null;
          if (found) {
            item.userAnswer = found.UserAnswer;
          }
        });
        let questionitem = [...questionItemPrepair];
        setQuestionList(questionitem);
        //setUserAnswerList(response.data.useranswer)
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/

  const QuestionItemPage = () => {
    let result = <></>;
    //console.log(questionList)
    //console.log(userAnswerList)
    for (let i = 0; i < questionList.length; i++) {
      const item = questionList[i];
      //console.log(item);
      /*const found = userAnswerList.find(element => element.QuestionItemID === item.QuestionItemID);
            console.log(found)*/
      const subresult = (
        <div className="card mb-3" key={i}>
          <div className="card-body">
            <div
              className="subheader col-12"
              role="alert"
              style={{ paddingLeft: 20 }}
            >
              {i + 1}. {item.ItemTitle}
            </div>
            <div className="col-12 text-left">
              <div className="custom-control custom-radio custom-control-inline d-block">
                <input
                  type="radio"
                  className="custom-control-input"
                  checked={item.userAnswer === 1}
                  onChange={(e) => {
                    item.userAnswer = 1;
                  }}
                  id={"question" + item.QuestionItemID + "-01"}
                  name={"question" + item.QuestionItemID}
                  value="1"
                />
                <label
                  className="custom-control-label"
                  htmlFor={"question" + item.QuestionItemID + "-01"}
                  style={{ width: 100 + "%", display: "block" }}
                >
                  {item.Answer1}
                  {Number(item.CorrectAnswer) === 1 && (
                    <i className="fas fa-check-circle ml-2 text-success"></i>
                  )}
                  {Number(item.CorrectAnswer) !== 1 &&
                    Number(item.userAnswer) === 1 && (
                      <i className="fas fa-times-circle ml-2 text-danger"></i>
                    )}
                </label>
              </div>
            </div>
            <div className="col-12 text-left">
              <div className="custom-control custom-radio custom-control-inline d-block">
                <input
                  type="radio"
                  className="custom-control-input"
                  checked={item.userAnswer === 2}
                  onChange={(e) => {
                    item.userAnswer = 2;
                  }}
                  id={"question" + item.QuestionItemID + "-02"}
                  name={"question" + item.QuestionItemID}
                  value="2"
                />
                <label
                  className="custom-control-label"
                  htmlFor={"question" + item.QuestionItemID + "-02"}
                  style={{ width: 100 + "%", display: "block" }}
                >
                  {item.Answer2}
                  {Number(item.CorrectAnswer) === 2 && (
                    <i className="fas fa-check-circle ml-2 text-success"></i>
                  )}
                  {Number(item.CorrectAnswer) !== 2 &&
                    Number(item.userAnswer) === 2 && (
                      <i className="fas fa-times-circle ml-2 text-danger"></i>
                    )}
                </label>
              </div>
            </div>
            <div className="col-12 text-left">
              <div className="custom-control custom-radio custom-control-inline d-block">
                <input
                  type="radio"
                  className="custom-control-input"
                  checked={item.userAnswer === 3}
                  onChange={(e) => {
                    item.userAnswer = 3;
                  }}
                  id={"question" + item.QuestionItemID + "-03"}
                  name={"question" + item.QuestionItemID}
                  value="3"
                />
                <label
                  className="custom-control-label"
                  htmlFor={"question" + item.QuestionItemID + "-03"}
                  style={{ width: 100 + "%", display: "block" }}
                >
                  {item.Answer3}
                  {Number(item.CorrectAnswer) === 3 && (
                    <i className="fas fa-check-circle ml-2 text-success"></i>
                  )}
                  {Number(item.CorrectAnswer) !== 3 &&
                    Number(item.userAnswer) === 3 && (
                      <i className="fas fa-times-circle ml-2 text-danger"></i>
                    )}
                </label>
              </div>
            </div>
            <div className="col-12 text-left">
              <div className="custom-control custom-radio custom-control-inline d-block">
                <input
                  type="radio"
                  className="custom-control-input"
                  checked={item.userAnswer === 4}
                  onChange={(e) => {
                    item.userAnswer = 4;
                  }}
                  id={"question" + item.QuestionItemID + "-04"}
                  name={"question" + item.QuestionItemID}
                  value="4"
                />
                <label
                  className="custom-control-label"
                  htmlFor={"question" + item.QuestionItemID + "-04"}
                  style={{ width: 100 + "%", display: "block" }}
                >
                  {item.Answer4}
                  {Number(item.CorrectAnswer) === 4 && (
                    <i className="fas fa-check-circle ml-2 text-success"></i>
                  )}
                  {Number(item.CorrectAnswer) !== 4 &&
                    Number(item.userAnswer) === 4 && (
                      <i className="fas fa-times-circle ml-2 text-danger"></i>
                    )}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      result = (
        <>
          {result}
          {subresult}
        </>
      );
    }

    return result;
  };

  if (isLoading) {
    return <></>;
  }
  const beginTest = () => {
    const formData = new FormData();
    formData.append("eLearningID", eLearningID);
    formData.append("chapterID", chapterID);
    formData.append("questionnaireID", questionnaireID);
    formData.append("maxDuration", maxDuration);
    formData.append("userID", currentUser.UserID);

    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/userBeginTest", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        localStorage.removeItem("test");
        props.history.push({
          pathname: "/authen/elearning/ElearningTest",
          state: {
            eLearningID: eLearningID,
            chapterID: chapterID,
            questionnaireID: questionnaireID,
          },
        });
      });
  };
  function convertSectoTime(seconds) {
    seconds = Number(seconds);
    let weeks = parseInt(seconds / (7 * 24 * 60 * 60));
    let days = parseInt(seconds / (24 * 60 * 60) - 7 * weeks);
    let hours = parseInt(seconds / (60 * 60) - 7 * 24 * weeks - 24 * days);
    let minutes = parseInt(
      seconds / 60 - 7 * 24 * 60 * weeks - 24 * 60 * days - 60 * hours
    );
    seconds = parseInt(
      seconds -
        7 * 24 * 60 * 60 * weeks -
        24 * 60 * 60 * days -
        60 * 60 * hours -
        60 * minutes
    );
    let duration = "";
    if (weeks > 0) {
      duration =
        weeks +
        " สัปดาห์ " +
        days +
        " วัน " +
        hours +
        " ชั่วโมง " +
        minutes +
        " นาที " +
        seconds +
        " วินาที";
    } else if (days > 0) {
      duration =
        days +
        " วัน " +
        hours +
        " ชั่วโมง " +
        minutes +
        " นาที " +
        seconds +
        " วินาที";
    } else if (hours > 0) {
      duration = hours + " ชั่วโมง " + minutes + " นาที " + seconds + " วินาที";
    } else if (minutes > 0) {
      duration = minutes + " นาที " + seconds + " วินาที";
    } else {
      duration = seconds + " วินาที";
    }
    return duration;
  }

  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"อีเลิร์นนิง"}
          eheader={"E-learning"}
          bannerkey="ELEARN"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/elearning/ELearning",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "อีเลิร์นนิง" : "E-learning"}
              </Link>
              <span>/</span>
              {
                <Link
                  className="active"
                  to={{
                    pathname: "/authen/elearning/ViewElearning/" + eLearningID,
                    state: { id: eLearningID, userID: userID },
                  }}
                >
                  {chapterQuestion.ELearnTitle}
                </Link>
              }
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        {chapterQuestion.ELearnTitle}
                      </span>
                    </h2>
                  </div>
                  <div className="sub-title title">
                    <h2 className="thai-header text-blue text-center mt-5">
                      <span style={{ fontSize: 2 + "rem", color: "#36A9AE" }}>
                        {chapterQuestion.ChapterTitle}
                      </span>
                    </h2>
                  </div>
                  <form className="formContent row">
                    <div className="col-12 mb-5">
                      <div className="card">
                        <div className="card-body">
                          <ul className="test-result-list">
                            <li className="list-item">
                              <span className="title">เริ่มเมื่อ</span>{" "}
                              <span className="detail">
                                {" "}
                                {utils.MariatoThaiDateString(
                                  new Date(testData.BeginExamDate)
                                )}
                              </span>
                            </li>
                            <li className="list-item">
                              <span className="title">สถานะ</span>{" "}
                              <span className="detail">
                                {" "}
                                {testData.isExamCompleted === true
                                  ? "เสร็จสิ้น"
                                  : "กำลังดำเนินการ"}
                              </span>
                            </li>
                            <li className="list-item">
                              <span className="title">เมื่อ</span>{" "}
                              <span className="detail">
                                {" "}
                                {utils.MariatoThaiDateString(
                                  new Date(testData.ExamCompletedDate)
                                )}
                              </span>
                            </li>
                            <li className="list-item">
                              <span className="title">เวลาที่ใช้ </span>{" "}
                              <span className="detail">
                                {" "}
                                {convertSectoTime(testData.ExamTimeSec)}{" "}
                              </span>
                            </li>
                            <li className="list-item">
                              <span className="title">คะแนน </span>{" "}
                              <span className="detail">
                                {" "}
                                {testData.ExamGetScore}{" "}
                              </span>
                            </li>
                            <li className="list-item">
                              <span className="title">สรุปคะแนน </span>
                              <span className="detail">
                                {testData.ExamGetScore} จาก{" "}
                                {testData.ExamMaxScore} (
                                {(
                                  (testData.ExamGetScore * 100) /
                                  testData.ExamMaxScore
                                ).toFixed(2)}
                                %)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <QuestionItemPage />
                    </div>

                    <div className="col-12 ">
                      <div className="pl-4 pr-4 text-center margintop40">
                        <input
                          type="submit"
                          className="fadeIn fourth"
                          value="ทำแบบทดสอบใหม่"
                          onClick={(e) => {
                            e.preventDefault();
                            beginTest();
                          }}
                        />
                        <input
                          type="button"
                          className="fadeIn fourth"
                          value="เสร็จสิ้น"
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push({
                              pathname:
                                "/authen/elearning/ViewElearning/" +
                                eLearningID,
                              state: { id: eLearningID, userID: userID },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
