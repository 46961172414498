import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { scroller } from "react-scroll";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import Dropzone from "react-dropzone";
import utils from "utils/utils";
import { authenticationService } from "services/authentication.service.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SError from "components/SError";
import "assets/styles/custompage-network.css";

const swal = withReactContent(Swal);
export default function ViewBestPerson(props) {
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  const [errors, setError] = useState({});
  const [citizenID, SetCitizenID] = useState("");
  const [organization, SetOrganization] = useState(-1);
  const [houseNo, SetHouseNo] = useState("");
  const [provinceCode, SetProvinceCode] = useState(0);
  const [districtCode, SetDistrictCode] = useState(0);
  const [subDistricCode, SetSubDistricCode] = useState(0);
  const [postCode, SetPostCode] = useState("");
  const [phone, SetPhone] = useState("");
  /*const [fax, SetFax] = useState('');
    const [phone2, SetPhone2] = useState('');*/
  const [highestEducation, SetHighestEducation] = useState("");
  //const [email, SetEmail] = useState('');
  const [titleID, SetTitleID] = useState(0);
  const [firstName, SetFirstName] = useState("");
  const [lastName, SetLastName] = useState("");
  const [position, SetPosition] = useState("");
  const [relation, SetRelation] = useState("");
  const [type, SetType] = useState("");

  const [performanceName, SetPerformanceName] = useState("");
  const [performanceDetails, SetPerformanceDetails] = useState("");

  const [networkActivityFiles, setNetworkActivityFiles] = useState([]);
  const [filespreview, setFilesPreview] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageLocationFiles, setImageLocationFiles] = useState({}); //useState([]);

  const [property1, SetProperty1] = useState(false);
  const [property1Extra, SetProperty1Extra] = useState("");

  /*const [typeProperty1, SetTypeProperty1] = useState(false);
    const [typeProperty2, SetTypeProperty2] = useState(false);
    const [typeProperty3, SetTypeProperty3] = useState(false);
    const [typeProperty4, SetTypeProperty4] = useState(false);
    const [typeProperty5, SetTypeProperty5] = useState(false);
    const [typeProperty6, SetTypeProperty6] = useState(false);
    const [typeProperty7, SetTypeProperty7] = useState(false);*/

  const [targetDetail, SetTargetDetail] = useState("");
  const [specificProvinceProperty, SetSpecificProvinceProperty] =
    useState(false);
  const [specificProvincePropertyExtra, SetSpecificProvincePropertyExtra] =
    useState("");
  const [pictureProperty, SetPictureProperty] = useState(false);
  const [note, SetNote] = useState("");
  const [titleList, SetTitleList] = useState([]);
  const [provinceList, SetProvinceList] = useState([]);
  const [allDistrictList, SetAllDistrictList] = useState([]);
  const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
  const [districtList, SetDistrictList] = useState([]);
  const [subDistrictList, SetSubDistrictList] = useState([]);
  const [relationList, SetRelationList] = useState([]);
  const [organizationList, SetOrganizationList] = useState([]);

  const [topicList, setTopicList] = useState([]);
  const [selecttopicList, setSelectTopicList] = useState([]);

  const [otherTitleTH, SetOtherTitleTH] = useState("");
  const [otherTitleEN, SetOtherTitleEN] = useState("");
  let seltopiclist = [];

  function LoginAlert() {
    Swal.fire({
      icon: "error",
      title: "ผิดพลาด",
      text: "กรุณาเข้าสู่ระบบ",
    }).then((result) => {
      props.history.push({ pathname: "/" });
    });
  }
  useEffect(() => {
    if (currentUser === null) {
      LoginAlert();
    } else {
      utils.AccessInfo(
        "เครือข่ายบุคคลด้านสิทธิมนุษยชน",
        "/authen/network/PersonNetwork",
        "F",
        currentUser
      );
      axios
        .get(process.env.REACT_APP_APIURL + "/api-web/memberbyID", {
          params: { id: currentUser.UserID },
        })
        .then((response) => {
          //console.log(response.data)
          SetTitleID(response.data[0].TitleID);
          SetFirstName(response.data[0].FirstnameTH);
          SetLastName(response.data[0].LastnameTH);
          SetPhone(response.data[0].Phone);
          SetCitizenID(response.data[0].CitizenID);
          axios
            .get(
              process.env.REACT_APP_APIURL +
                "/api-web/searchMemberInterestTopic",
              { params: { status: 1 } }
            )
            .then((response) => {
              setTopicList(response.data);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/getTitle")
            .then((response) => {
              SetTitleList(response.data);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/getProvince")
            .then((response) => {
              SetProvinceList(response.data);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/getDistrict")
            .then((response) => {
              SetDistrictList(response.data);
              SetAllDistrictList(response.data);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict")
            .then((response) => {
              SetSubDistrictList(response.data);
              SetAllSubDistrictList(response.data);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/masterRelationship")
            .then((response) => {
              SetRelationList(response.data.results);
            });
          axios
            .get(process.env.REACT_APP_APIURL + "/api-web/masterAffiliation")
            .then((response) => {
              SetOrganizationList(response.data.results);
            });

          setLoading(false);
        });
    }
  }, []); /* <-- add this for run once*/
  const TopicCheckbox = topicList.map((item) => (
    <div className="col-12" key={item.InterestTopicID}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"operation" + item.InterestTopicID}
          name={
            "operation" + item.InterestTopicID
          } /*checked={seltopiclist.indexOf(Number(item.InterestTopicID)) > -1}*/
          onChange={(e) => {
            var checked = e.target.checked;
            seltopiclist = [...selecttopicList];
            if (checked) {
              if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                seltopiclist.push(item.InterestTopicID);
              }
            } else {
              if (seltopiclist.indexOf(item.InterestTopicID) > -1) {
                let index = seltopiclist.indexOf(item.InterestTopicID);
                seltopiclist = seltopiclist
                  .slice(0, index)
                  .concat(seltopiclist.slice(index + 1));
              }
            }
            setSelectTopicList(seltopiclist);
            /*console.log(seltopiclist)*/
          }}
        />
        <label
          className="custom-control-label"
          htmlFor={"operation" + item.InterestTopicID}
        >
          {language === "Thai" ? item.TextTH : item.TextEN}
        </label>
      </div>
    </div>
  ));
  const handleChangeProvince = (ProvinceID) => {
    SetDistrictList(
      allDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    SetDistrictCode(0);
    SetSubDistricCode(0);
  };
  const handleChangeDistrict = (DistrictID) => {
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["DistrictID"] === Number(DistrictID)
      )
    );
    SetSubDistricCode(0);
  };
  const handleChangeSubDistrict = (SubDistrictID) => {
    const subdistrictitem = allSubDistrictList.filter(
      (item) => item["SubDistrictID"] === Number(SubDistrictID)
    );
    if (subdistrictitem[0] != null) {
      SetPostCode(subdistrictitem[0].PostCode);
    } else {
      SetPostCode("");
    }
  };

  const relationSelect = relationList.map((v) => (
    <option key={v.id} value={v.id}>
      {v.title_TH}
    </option>
  ));
  const organizationSelect = organizationList.map((v) => {
    if (v.categories_id === type) {
      return (
        <option key={v.id} value={v.id}>
          {v.title_TH}
        </option>
      );
    }
  });
  const TitleSelect = titleList.map((title) => (
    <option key={title.TitleID} value={title.TitleID}>
      {title.TitleNameTH}
    </option>
  ));
  const ProvinceSelect = provinceList.map((province) => (
    <option key={province.ProvinceID} value={province.ProvinceID}>
      {province.ProvinceTH}
    </option>
  ));
  const DistrictSelect = districtList.map((district) => (
    <option key={district.DistrictID} value={district.DistrictID}>
      {district.DistrictTH}
    </option>
  ));
  const SubDistrictSelect = subDistrictList.map((subdistrict) => (
    <option key={subdistrict.SubDistrictID} value={subdistrict.SubDistrictID}>
      {subdistrict.SubDistrictTH}
    </option>
  ));
  function chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = total + pidcut * (13 - n);
      j++;
    }

    chk = 11 - (total % 11);

    if (chk == 10) {
      chk = 0;
    } else if (chk == 11) {
      chk = 1;
    }
    if (chk == Validchk) {
      //alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      //alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }
  }
  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    //Name
    // if (citizenID.trim().length === 0) {
    //     formIsValid = false;
    //     serrors["citizenID"] = "กรุณากรอกข้อมูล";
    // }
    // else if (chkDigitPid(citizenID.trim()) === false) {
    //     formIsValid = false;
    //     serrors["citizenID"] = "หมายเลขบัตรประชาชนไม่ถูกต้อง";
    // }
    // if (chkDigitPid(citizenID.trim()) === false) {
    //   formIsValid = false;
    //   serrors["citizenID"] = "หมายเลขบัตรประชาชนไม่ถูกต้อง";
    // }
    if (Number(organization) === -1) {
      formIsValid = false;
      serrors["organization"] = "กรุณาระบุชื่อองค์กร";
    }
    if (Number(titleID) === 0) {
      formIsValid = false;
      serrors["titleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
    } else if (Number(titleID) === -1) {
      if (otherTitleTH.trim().length === 0) {
        formIsValid = false;
        serrors["otherTitleTH"] = "กรุณาระบุคำนำหน้าชื่อภาษาไทยที่ต้องการ";
      }
      if (otherTitleEN.trim().length === 0) {
        formIsValid = false;
        serrors["otherTitleEN"] = "กรุณาระบุคำนำหน้าชื่อภาษาอังกฤษที่ต้องการ";
      }
    }
    if (firstName.trim().length === 0) {
      formIsValid = false;
      serrors["firstName"] = "กรุณาระบุรายละเอียด";
    }
    if (lastName.trim().length === 0) {
      formIsValid = false;
      serrors["lastName"] = "กรุณาระบุรายละเอียด";
    }
    if (type.trim().length === 0) {
      formIsValid = false;
      serrors["type"] = "กรุณาระบุประเภทเครือข่าย";
    }
    if (phone.trim().length === 0) {
      formIsValid = false;
      serrors["phone"] = "กรุณาระบุรายละเอียด";
    }

    if (houseNo.trim().length === 0) {
      formIsValid = false;
      serrors["houseNo"] = "กรุณาระบุรายละเอียดภาษาไทย";
    }
    if (Number(provinceCode) === 0) {
      formIsValid = false;
      serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
    }
    if (Number(districtCode) === 0) {
      formIsValid = false;
      serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
    }
    if (Number(subDistricCode) === 0) {
      formIsValid = false;
      serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
    }
    if (postCode.trim().length === 0) {
      formIsValid = false;
      serrors["postCode"] = "กรุณากรอกรหัสไปรษณีย์";
    }
    /*if (phone2.trim().length === 0) {
            formIsValid = false;
            serrors["phone2"] = "กรุณาระบุรายละเอียด";
        }*/
    if (highestEducation.trim().length === 0) {
      formIsValid = false;
      serrors["highestEducation"] = "กรุณาระบุวุฒิการศึกษาสูงสุด";
    }
    if (performanceName.trim().length === 0) {
      formIsValid = false;
      serrors["performanceName"] = "กรุณาระบุรายละเอียด";
    }
    if (performanceDetails.trim().length === 0) {
      formIsValid = false;
      serrors["performanceDetails"] = "กรุณาระบุรายละเอียด";
    }
    if (property1 === true) {
      if (property1Extra.trim().length === 0) {
        formIsValid = false;
        serrors["property1Extra"] = "กรุณาระบุรายละเอียด";
      }
    }
    /*if (typeProperty1 === false && typeProperty2 === false && typeProperty3 === false && typeProperty4 === false && typeProperty5 === false && typeProperty6 === false && typeProperty7 === false) {
            formIsValid = false;
            serrors["typeProperty"] = "กรุณาระบุอย่างน้อยหนึ่งตัวเลือก";
        }*/
    if (targetDetail.trim().length === 0) {
      formIsValid = false;
      serrors["targetDetail"] = "กรุณาระบุรายละเอียด";
    }
    if (specificProvinceProperty === true) {
      if (specificProvincePropertyExtra.trim().length === 0) {
        formIsValid = false;
        serrors["specificProvincePropertyExtra"] = "กรุณาระบุรายละเอียด";
      }
    }
    if (pictureProperty === true) {
      if (imageLocationFiles.length === 0) {
        serrors["pictureProperty"] = "กรุณาเลือกไฟล์ภาพเพื่ออัปโหลด";
      }
    }
    if (selecttopicList.length === 0) {
      formIsValid = false;
      //console.log(selecttopicList)
      serrors["seltopiclist"] =
        "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
    }
    setError(serrors);
    /*if (formIsValid === false) {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                offset: -50,
                smooth: "easeInOutQuart",
            });
        }*/
    /*console.log(serrors)
        console.log(formIsValid)*/
    return formIsValid;
  };

  const confirmSave = (e) => {
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/checkDuplicateName",
        { firstName, lastName },
        {}
      )
      .then((response) => {
        if (response.data.success === true) {
          Swal.fire({
            title:
              "ชื่อ-นามสกุล นี้เคยถูกลงทะเบียนเรื่องติดตามแล้ว ต้องการลงทะเบียนซ้ำอีกครั้งหรือไม่",
            showDenyButton: true,
            confirmButtonText: "ใช่",
            denyButtonText: `ไม่ใช่`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              saveData();
            } else if (result.isDenied) {
              e.preventDefault();
              return false;
            }
          });
        } else {
          saveData();
        }
      });
  };

  const saveData = (open) => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("citizenID", citizenID);
      formData.append("organization", organization);
      formData.append("titleID", titleID);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("type", type);

      formData.append("relation", relation);
      formData.append("position", position);

      formData.append("phone", phone);
      formData.append("houseNo", houseNo);
      formData.append("provinceCode", provinceCode);
      formData.append("districtCode", districtCode);
      formData.append("subDistricCode", subDistricCode);
      formData.append("postCode", postCode);
      //formData.append("phone2", phone2);
      //formData.append("fax", fax);
      formData.append("highestEducation", highestEducation);

      formData.append("performanceName", performanceName);
      formData.append("performanceDetails", performanceDetails);
      formData.append("property1", property1);
      formData.append("property1Extra", property1Extra);

      /*formData.append("typeProperty1", typeProperty1);
            formData.append("typeProperty2", typeProperty2);
            formData.append("typeProperty3", typeProperty3);
            formData.append("typeProperty4", typeProperty4);
            formData.append("typeProperty5", typeProperty5);
            formData.append("typeProperty6", typeProperty6);
            formData.append("typeProperty7", typeProperty7);*/

      formData.append("targetDetail", targetDetail);
      formData.append("specificProvinceProperty", specificProvinceProperty);
      formData.append(
        "specificProvincePropertyExtra",
        specificProvincePropertyExtra
      );
      formData.append("pictureProperty", pictureProperty);
      formData.append("imageLocationFiles", imageLocationFiles.file);
      formData.append("note", note);

      formData.append("seltopiclist", selecttopicList);
      //console.log(selecttopicList)

      for (let i = 0; i < networkActivityFiles.length; i += 1) {
        formData.append("networkActivityFiles[]", networkActivityFiles[i]);
      }
      for (let i = 0; i < imageFiles.length; i += 1) {
        formData.append("imageFiles[]", imageFiles[i]);
      }

      formData.append("otherTitleTH", otherTitleTH);
      formData.append("otherTitleEN", otherTitleEN);

      formData.append("userID", currentUser.UserID);

      // Display the key/value pairs
      /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/savePersonNetwork",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              props.history.push({ pathname: "/" });
              //console.log('xxxxxx')
            });
        });
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };
  const cancel = () => {
    props.history.push({ pathname: "/" });
  };
  const handleRemoveActivityFiles = (filename) => {
    setNetworkActivityFiles(
      networkActivityFiles.filter((item) => item["name"] !== filename)
    );
  };
  const NetActivityFilesList = networkActivityFiles.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityFiles(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));
  const handleRemoveImageFiles = (filename) => {
    setImageFiles(imageFiles.filter((item) => item["name"] !== filename));
  };
  const NetImageFilesList = imageFiles.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2 controlbtn"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger controlbtn"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveImageFiles(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const NetLocationActivityFilesList = (
    <img
      className="preview-upload"
      src={imageLocationFiles.preview}
      onChange={(e) => {
        e.target.src = URL.createObjectURL(imageLocationFiles);
      }}
      alt="Activity Location"
    />
  );

  const rx_phone = /(\d{1})+(\-)?$/;
  const rx_int = /^\d+$/;
  const handlepostCodeChange = (e) => {
    if (rx_int.test(e.target.value)) {
      SetPostCode(e.target.value);
    }
  };
  const handlePhoneChange = (e) => {
    if (rx_int.test(e.target.value)) {
      SetPhone(e.target.value);
    }
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                className="active"
                to={{ pathname: "/authen/network/PersonNetwork" }}
              >
                สมัครเครือข่ายบุคคลด้านสิทธิมนุษยชน
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile row PersonNetwork">
            <div className="news-activity-data mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        สมัครเครือข่ายบุคคลด้านสิทธิมนุษยชน
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-6 columnInput">
                  <input
                    type="text"
                    className="form-control "
                    id="citizenID"
                    defaultValue={citizenID}
                    name="citizenID"
                    placeholder="รหัสบัตรประชาชน"
                    autoComplete="off"
                    maxLength={13}
                    onBlur={(e) => {
                      SetCitizenID(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["citizenID"]} />
                </div>
                <div className="col-6"></div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select"
                    id="titleID"
                    name="titleID"
                    value={titleID}
                    onChange={(e) => {
                      SetTitleID(e.currentTarget.value);
                    }}
                  >
                    <option key="0" value="0">
                      คำนำหน้า
                    </option>
                    {TitleSelect}
                    <option key="-1" value="-1">
                      อื่น ๆ
                    </option>
                  </select>
                  <SError error={errors["titleID"]} />
                </div>
                {Number(titleID) === -1 && (
                  <>
                    <div className="col- requiredFieldWrapper">
                      <input
                        type="text"
                        className="form-control someInput"
                        id="otherTitleTH"
                        defaultValue={otherTitleTH}
                        name="otherTitleTH"
                        placeholder="คำนำหน้าอื่น ๆ ภาษาไทย"
                        autoComplete="off"
                        onBlur={(e) => {
                          SetOtherTitleTH(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["otherTitleTH"]} />
                    </div>
                    <div className="col-3 requiredFieldWrapper">
                      <input
                        type="text"
                        className="form-control someInput"
                        id="otherTitleEN"
                        defaultValue={otherTitleEN}
                        name="firstName"
                        placeholder="คำนำหน้าอื่น ๆ ภาษาอังกฤษ"
                        autoComplete="off"
                        onBlur={(e) => {
                          SetOtherTitleEN(e.currentTarget.value);
                        }}
                      />
                      <SError error={errors["otherTitleEN"]} />
                    </div>
                  </>
                )}
                {Number(titleID) !== -1 && (
                  <>
                    <div className="col-6"></div>
                  </>
                )}
                <div className="col-6 requiredFieldWrapper">
                  <input
                    type="text"
                    className="form-control "
                    id="firstName"
                    defaultValue={firstName}
                    name="firstName"
                    placeholder="ชื่อ"
                    autoComplete="off"
                    onBlur={(e) => {
                      SetFirstName(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["firstName"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <input
                    type="text"
                    className="form-control "
                    id="lastName"
                    defaultValue={lastName}
                    name="lastName"
                    placeholder="นามสกุล"
                    autoComplete="off"
                    onBlur={(e) => {
                      SetLastName(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["lastName"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <input
                    type="text"
                    className="form-control "
                    id="position"
                    defaultValue={position}
                    name="organization"
                    placeholder="ตำแหน่ง"
                    autoComplete="off"
                    onBlur={(e) => {
                      SetPosition(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["position"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="relation"
                    name="relation"
                    value={relation}
                    onChange={(e) => {
                      SetRelation(e.currentTarget.value);
                    }}
                  >
                    <option key="0" value="">
                      ความสัมพันธ์
                    </option>
                    {relationSelect}
                  </select>
                  <SError error={errors["type"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="type"
                    name="type"
                    value={type}
                    onChange={(e) => {
                      SetType(e.currentTarget.value);
                      SetOrganization(-1);
                    }}
                  >
                    <option key="0" value="">
                      ประเภทเครือข่าย
                    </option>
                    <option key="ภาครัฐ" value="ภาครัฐ">
                      ภาครัฐ
                    </option>
                    <option key="ภาคธุรกิจ" value="ภาคธุรกิจ">
                      ภาคธุรกิจ
                    </option>
                    <option key="ภาคประชาสังคม" value="ภาคประชาสังคม">
                      ภาคประชาสังคม
                    </option>
                    <option key="สถาบันการศึกษา" value="สถาบันการศึกษา">
                      สถาบันการศึกษา
                    </option>
                  </select>
                  <SError error={errors["type"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="organization"
                    name="organization"
                    value={organization}
                    onChange={(e) => {
                      SetOrganization(e.currentTarget.value);
                    }}
                  >
                    <option key="-1" value="">
                      สังกัด
                    </option>
                    {organizationSelect}
                  </select>

                  <SError error={errors["organization"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <input
                    type="text"
                    className="form-control "
                    id="phone"
                    value={phone}
                    name="phone"
                    maxLength={10}
                    placeholder="เบอร์โทรศัพท์ที่ติดต่อได้"
                    autoComplete="off"
                    onChange={(e) => {
                      handlePhoneChange(e);
                    }}
                  />
                  <SError error={errors["phone"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <input
                    type="text"
                    className="form-control "
                    id="houseNo"
                    defaultValue={houseNo}
                    name="houseNo"
                    placeholder="ที่อยู่ที่ติดต่อได้"
                    autoComplete="off"
                    onBlur={(e) => {
                      SetHouseNo(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["houseNo"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="provinceCode"
                    name="provinceCode"
                    value={provinceCode}
                    onChange={(e) => {
                      SetProvinceCode(e.currentTarget.value);
                      handleChangeProvince(e.currentTarget.value);
                    }}
                  >
                    <option key="0" value="0">
                      จังหวัด
                    </option>
                    {ProvinceSelect}
                  </select>
                  <SError error={errors["provinceCode"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="districtCode"
                    name="districtCode"
                    value={districtCode}
                    onChange={(e) => {
                      SetDistrictCode(e.currentTarget.value);
                      handleChangeDistrict(e.currentTarget.value);
                    }}
                  >
                    <option key="0" value="0">
                      เขต/อำเภอ
                    </option>
                    {DistrictSelect}
                  </select>
                  <SError error={errors["districtCode"]} />
                </div>
                <div className="col-6 requiredFieldWrapper">
                  <select
                    className="custom-select "
                    id="subDistricCode"
                    name="subDistricCode"
                    value={subDistricCode}
                    onChange={(e) => {
                      SetSubDistricCode(e.currentTarget.value);
                      handleChangeSubDistrict(e.currentTarget.value);
                    }}
                  >
                    <option key="0" value="0">
                      แขวง/ตำบล
                    </option>
                    {SubDistrictSelect}
                  </select>
                  <SError error={errors["subDistricCode"]} />
                </div>
                <div className="col-6 ">
                  <input
                    type="text"
                    className="form-control "
                    id="postCode"
                    value={postCode}
                    name="postCode"
                    maxLength={5}
                    placeholder="รหัสไปรษณีย์"
                    autoComplete="off"
                    onChange={(e) => {
                      handlepostCodeChange(e);
                    }}
                  />
                  <SError error={errors["postCode"]} />
                </div>
                <div className="col-6">
                  <select
                    className="custom-select "
                    id="highestEducation"
                    name="highestEducation"
                    value={highestEducation.trim()}
                    onChange={(e) => {
                      SetHighestEducation(
                        e.currentTarget.value
                      ); /*alert(highestEducation);*/
                    }}
                  >
                    <option key="0" value="">
                      ประวัติการศึกษาสูงสุด
                    </option>
                    <option key="1" value="ประถมศึกษา">
                      ประถมศึกษา
                    </option>
                    <option key="2" value="มัธยมศึกษา">
                      มัธยมศึกษา
                    </option>
                    <option key="3" value="ปริญญาตรี">
                      ปริญญาตรี
                    </option>
                    <option key="4" value="ปริญญาโท">
                      ปริญญาโท
                    </option>
                    <option key="5" value="ปริญญาเอก">
                      ปริญญาเอก
                    </option>
                    <option key="6" value="ไม่ระบุ">
                      ไม่ระบุ
                    </option>
                  </select>
                  <SError error={errors["highestEducation"]} />
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">
              ผลงานที่สำคัญด้านสิทธิมนุษยชน
            </div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12 requiredFieldWrapper">
                  <input
                    type="text"
                    className="width-100 form-control "
                    id="performanceName"
                    style={{ maxWidth: 100 + "% !important" }}
                    defaultValue={performanceName}
                    name="performanceName"
                    autoComplete="off"
                    placeholder="ชื่อผลการดำเนินงาน"
                    onBlur={(e) => {
                      SetPerformanceName(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["performanceName"]} />
                </div>
                <div className="col-12 requiredFieldWrapperTextArea">
                  <label htmlFor="performanceDetails">
                    รายละเอียดผลงาน (โดยย่อ)
                  </label>
                  <div>
                    <textarea
                      className="form-control"
                      name="performanceDetails"
                      id="performanceDetails"
                      rows="8"
                      style={{ maxWidth: 100 + "%" }}
                      value={performanceDetails}
                      onChange={(e) => {
                        SetPerformanceDetails(e.target.value);
                      }}
                    ></textarea>
                    <SError error={errors["performanceDetails"]} />
                  </div>
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">
              โปรดแนบเอกสารประกอบการพิจารณา
            </div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12">
                  <p className="smalltext remark">
                    หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png,
                    .zip มีขนาดไฟล์ไม่เกิน 10 MB
                  </p>
                  <Dropzone
                    className="input-dropzone dropzone"
                    onDrop={(acceptedFiles) => {
                      setNetworkActivityFiles([
                        ...networkActivityFiles,
                        ...acceptedFiles,
                      ]);
                      setFilesPreview(
                        acceptedFiles.map((file) =>
                          Object.assign(file, {
                            id: 0,
                            preview: URL.createObjectURL(file),
                          })
                        )
                      );
                    }}
                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>ลำดับ</th>
                                  <th>ชื่อ</th>
                                  <th>ขนาดไฟล์</th>
                                  <th>ประเภทไฟล์</th>
                                  <th>จัดการ</th>
                                </tr>
                              </thead>
                              <tbody id="your-table-body-id">
                                {NetActivityFilesList}
                              </tbody>
                            </table>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">
              รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
            </div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12">
                  <p className="smalltext remark">
                    หมายเหตุ รองรับสกุลไฟล์ .docx, .xls, pdf, pptx, .jpg, .png,
                    .zip มีขนาดไฟล์ไม่เกิน 10 MB
                  </p>
                  <Dropzone
                    className="input-dropzone dropzone"
                    maxFiles={4}
                    onDrop={(acceptedFiles) => {
                      var filecount = imageFiles.length;
                      if (filecount < 4) {
                        setImageFiles([...imageFiles, ...acceptedFiles]);
                        setFilesPreview(
                          acceptedFiles.map((file) =>
                            Object.assign(file, {
                              id: 0,
                              preview: URL.createObjectURL(file),
                            })
                          )
                        );
                      }
                    }}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                          </p>
                        </div>
                        <aside>
                          <h4>Files</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>ลำดับ</th>
                                  <th>ชื่อ</th>
                                  <th>ขนาดไฟล์</th>
                                  <th>ประเภทไฟล์</th>
                                  <th>จัดการ</th>
                                </tr>
                              </thead>
                              <tbody id="your-table-body-id">
                                {NetImageFilesList}
                              </tbody>
                            </table>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="property1"
                      name="property1"
                      checked={property1 === true}
                      onChange={(e) => {
                        SetProperty1(!property1);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="property1">
                      ไม่เคยมีผลการดำเนินงานด้านสิทธิมนุษยชน
                      แต่มีความเกี่ยวข้องอื่นๆ ในเรื่องสิทธิมนุษยชน (เช่น
                      เคยเป็นยื่นผู้ร้องเรียนต่อ กสม. ฯลฯ)
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="width-100 form-control "
                    id="property1Extra"
                    style={{ maxWidth: 100 + "% !important" }}
                    defaultValue={property1Extra}
                    name="property1Extra"
                    autoComplete="off"
                    placeholder="โปรดระบุ"
                    onChange={(e) => {
                      SetProperty1Extra(e.currentTarget.value);
                    }}
                  />
                  <SError error={errors["property1Extra"]} />
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">ประเด็นสิทธิมนุษยชนหลัก</div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12">
                  <h2>
                    ที่เชี่ยวชาญ/ปฏิบัติงาน/สนใจ ตามกลไกระหว่างประเทศ
                    <span className="required"></span>
                  </h2>
                </div>
                {TopicCheckbox}
                {/*<div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty1" name="typeProperty1" checked={typeProperty1 === true} onChange={(e) => { SetTypeProperty1(!typeProperty1) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty1">สิทธิพลเมืองและสิทธิทางการเมือง</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty2" name="typeProperty2" checked={typeProperty2 === true} onChange={(e) => { SetTypeProperty2(!typeProperty2) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty2">สิทธิทางเศรษฐกิจ สังคม และวัฒนธรรม</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty3" name="typeProperty3" checked={typeProperty3 === true} onChange={(e) => { SetTypeProperty3(!typeProperty3) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty3">สิทธิสตรี</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty4" name="typeProperty4" checked={typeProperty4 === true} onChange={(e) => { SetTypeProperty4(!typeProperty4) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty4">สิทธิเด็ก</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty5" name="typeProperty5" checked={typeProperty5 === true} onChange={(e) => { SetTypeProperty5(!typeProperty5) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty5">การเลือกปฏิบัติทางเชื้อชาติ</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty6" name="typeProperty6" checked={typeProperty6 === true} onChange={(e) => { SetTypeProperty6(!typeProperty6) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty6">การต่อต้านการทรมาน</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="typeProperty7" name="typeProperty7" checked={typeProperty7 === true} onChange={(e) => { SetTypeProperty7(!typeProperty7) }} />
                                    <label className="custom-control-label" htmlFor="typeProperty7">สิทธิคนพิการ</label>
                                </div>
                            </div>*/}
                <div className="col-12">
                  <SError error={errors["seltopiclist"]} />
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">
              รายละเอียดประเด็นสิทธิมนุษยชนกลุ่มย่อยและเป้าหมายเฉพาะที่เชี่ยวชาญ/ปฏิบัติงาน/สนใจ
            </div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12">
                  <div className="requiredFieldWrapperTextArea">
                    <textarea
                      className="form-control"
                      name="targetDetail"
                      id="targetDetail"
                      rows="8"
                      style={{ maxWidth: 100 + "%" }}
                      value={targetDetail}
                      placeholder="อธิบายสั้นๆ  เช่น  (สิทธิแรงงานในกลุ่มเด็ก / สิทธิในกระบวนการยุติธรรมพื้นที่จ.ชายแดนภาคใต้ / การเข้าไม่ถึงสถานะบุคคลของกลุ่มชาติพันธ์  เป็นต้น)"
                      onChange={(e) => {
                        SetTargetDetail(e.target.value);
                      }}
                    ></textarea>
                    <SError error={errors["targetDetail"]} />
                  </div>
                </div>
              </div>
            </div>
            <div className="subheader col-12 mt-4">พื้นที่ปฏิบัติงานเฉพาะ</div>
            <div className="content-data">
              <div className="row formContent PersonNetwork textleft">
                <div className="col-12">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="specificProvinceProperty"
                      id="specificProvinceProperty1"
                      value="0"
                      checked={specificProvinceProperty === false}
                      onChange={(e) => {
                        SetSpecificProvinceProperty(false);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="specificProvinceProperty1"
                    >
                      {" "}
                      ทุกจังหวัด
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="specificProvinceProperty"
                      id="specificProvinceProperty2"
                      value="1"
                      checked={specificProvinceProperty === true}
                      onChange={(e) => {
                        SetSpecificProvinceProperty(true);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="specificProvinceProperty2"
                    >
                      {" "}
                      บางจังหวัด
                    </label>
                  </div>
                  <SError error={errors["specificProvinceProperty"]} />
                </div>
                {specificProvinceProperty === true && (
                  <div className="col-12">
                    <input
                      type="text"
                      className="width-100 form-control "
                      id="specificProvincePropertyExtra"
                      value={specificProvincePropertyExtra}
                      name="specificProvincePropertyExtra"
                      placeholder="ระบุจังหวัด (ได้มากกว่า 1 จังหวัด)"
                      onChange={(e) => {
                        SetSpecificProvincePropertyExtra(e.currentTarget.value);
                      }}
                    />
                    <SError error={errors["specificProvincePropertyExtra"]} />
                  </div>
                )}
              </div>
            </div>
            <div className="subheader col-12 mt-4">รูปถ่าย</div>
            <div className="content-data">
              <div className="row formContent PersonNetwork textleft">
                <div className="col-12">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pictureProperty"
                      id="pictureProperty1"
                      value="1"
                      checked={pictureProperty === true}
                      onChange={(e) => {
                        SetPictureProperty(true);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="pictureProperty1"
                    >
                      {" "}
                      ประสงค์ให้รูปถ่าย
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pictureProperty"
                      id="pictureProperty2"
                      value="0"
                      checked={pictureProperty === false}
                      onChange={(e) => {
                        SetPictureProperty(false);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="pictureProperty2"
                    >
                      {" "}
                      ไม่ประสงค์ให้รูปถ่าย
                    </label>
                  </div>
                  <SError error={errors["pictureProperty"]} />
                </div>
                {pictureProperty === true && (
                  <div className="col-12">
                    <Dropzone
                      className="input-dropzone dropzone"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        const imageFile = {
                          file: acceptedFiles[0],
                          name: acceptedFiles[0].name,
                          preview: URL.createObjectURL(acceptedFiles[0]),
                          size: acceptedFiles[0].size,
                        };
                        setImageLocationFiles(imageFile);
                      }}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="container">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>
                              ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                            </p>
                          </div>
                          <aside>
                            {typeof imageLocationFiles.name !== "undefined" &&
                              NetLocationActivityFilesList}
                          </aside>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
            <div className="subheader col-12 mt-4">หมายเหตุ</div>
            <div className="content-data">
              <div className="row formContent PersonNetwork">
                <div className="col-12">
                  <div>
                    <textarea
                      className="form-control"
                      name="note"
                      id="note"
                      rows="8"
                      style={{ maxWidth: 100 + "%" }}
                      value={note}
                      onChange={(e) => {
                        SetNote(e.target.value);
                      }}
                    ></textarea>
                    <SError error={errors["note"]} />
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="submit"
                    className="fadeIn fourth"
                    value="ตกลง"
                    onClick={(e) => {
                      confirmSave(e);
                    }}
                  />
                  <input
                    type="submit"
                    className="fadeIn fourth"
                    value="ยกเลิก"
                    onClick={(e) => {
                      e.preventDefault();
                      cancel();
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
