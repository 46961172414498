import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { authenticationService } from "services/authentication.service.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import utils from "utils/utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AdminBanner from "components/Banner/AdminBanner";
const swal = withReactContent(Swal);

export default function ELearningList(props) {
  //const currentUser = authenticationService.currentUserValue;
  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [courseTypeList, setCourseTypeList] = useState([]);

  const handleDelete = (params) => {
    swal
      .fire({
        title: "ยืนยันการลบข้อมูล",
        showDenyButton: true,
        /*showCancelButton: true,*/
        confirmButtonText: "ยืนยัน",
        denyButtonText: "ยกเลิก",
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          deleteSingleRow(params);
        } else if (result.isDenied) {
          //swal.fire('Changes are not saved', '', 'info')
        }
      });
  };
  const handleDeleteAll = (params) => {
    let selectedNodes = gridApi.getSelectedNodes();
    if (selectedNodes.length > 0) {
      //let timerInterval;
      swal
        .fire({
          title: "ยืนยันการลบข้อมูลทั้งหมดที่เลือก",
          showDenyButton: true,
          /*showCancelButton: true,*/
          confirmButtonText: "ยืนยัน",
          denyButtonText: "ยกเลิก",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            deleteRowData();
          } else if (result.isDenied) {
            //swal.fire('Changes are not saved', '', 'info')
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "กรุณาเลือกข้อมูลอย่างน้อยหนึ่งชุด",
      });
    }
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    const updateData = (data) => {
      setRowData(data);
    };
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getElearningCourseType")
      .then((response) => {
        setCourseTypeList(response.data);
        //console.log(response.data)
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getESubjectList")
      .then((response) => {
        updateData(response.data);
        //console.log(response.data)
      });
  };
  var checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  var headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const getRowHeight = (params) => {
    //console.log(params.data.rowHeight);
    //return params.data.rowHeight;
    return 80;
  };
  const handleCreate = () => {
    props.history.push("/admin/elearning/NewESubject");
    //window.location.reload();
  };
  const handleEdit = (params) => {
    props.history.push({
      pathname: "/admin/elearning/EditESubject",
      state: { id: params.data.ELearnID },
    });
    //window.location.reload();
  };
  const handleSubject = (params) => {
    props.history.push({
      pathname: "/admin/elearning/ESubjectChapter",
      state: { id: params.data.ELearnID },
    });
    //window.location.reload();
  };
  const pinRenderer = (params) => {
    //console.log(params.data.State)
    if (params.data.Pin === true) return "ปักหมุด";
    return "ไม่ปักหมุด";
  };
  const stateRenderer = (params) => {
    //console.log(params.data.State)
    if (params.data.Published === true) return "เผยแพร่";
    return "ไม่เผยแพร่";
  };
  const createYearCellRenderer = (params) => {
    if (params.value == null)
      return (
        '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' +
        utils.MariatoThaiDateString(params.data.CreatedDate) +
        "</p></span>"
      );
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.CreatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const updateYearCellRenderer = (params) => {
    if (params.value == null) return "";
    return (
      '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' +
      utils.MariatoThaiDateString(params.data.UpdatedDate) +
      '</p><p style="line-height:20px;margin-bottom: -4px;">' +
      params.value +
      "<p></span>"
    );
  };
  const RowTool = (params) => {
    let tool = null;
    tool = (
      <span className="row-tool">
        <button
          type="button"
          className="btn btn-sm mx-0 px-0"
          onClick={() => {
            handleEdit(params);
          }}
          title="แก้ไข"
        >
          <i className="far fa-edit"></i>
        </button>
        <button
          type="button"
          className="btn btn-sm text-danger mx-0 px-0"
          onClick={() => {
            handleDelete(params);
          }}
          title="ลบ"
        >
          <i className="far fa-trash-alt"></i>
        </button>
        <button
          type="button"
          className="btn btn-sm text-info mx-0 px-0"
          onClick={() => {
            handleSubject(params);
          }}
          title="จัดการรายวิชา"
        >
          <i className="fas fa-server"></i>
        </button>
      </span>
    );
    return tool;
  };
  var hashValueGetter = function (params) {
    return params.node.rowIndex + 1;
  };
  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
  };
  const searchRef = React.createRef();
  const courseTypeRef = React.createRef();
  const statusRef = React.createRef();

  const searchData = () => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchESubject", {
        params: {
          search: searchRef.current.value,
          courseType: courseTypeRef.current.value,
          status: statusRef.current.value,
        },
      })
      .then((response) => {
        setRowData(response.data);
      });
  };
  const resetsearch = () => {
    searchRef.current.value = "";
    statusRef.current.value = "-1";
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/getESubjectList")
      .then((response) => {
        setRowData(response.data);
        //console.log(response.data)
      });
  };

  const deleteSingleRow = (params) => {
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/deleteESubject", null, {
        params: { id: params.data.ELearnID },
      })
      .then((response) => {
        if (response.data.success === false) {
          Swal.fire({
            icon: "error",
            title: "ผิดพลาด",
            text: response.data.text,
          });
        } else {
          let timerInterval;
          swal
            .fire({
              title: "ลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              axios
                .get(process.env.REACT_APP_APIURL + "/api-web/getESubjectList")
                .then((response) => {
                  setRowData(response.data);
                });
            });
        }
      });
  };
  const deleteRowData = () => {
    let selectedNodes = gridApi.getSelectedNodes();
    selectedNodes.map((row, i) => {
      axios
        .post(process.env.REACT_APP_APIURL + "/api-web/deleteESubject", null, {
          params: { id: row.data.ELearnID },
        })
        .then((response) => {
          if (response.data.success === false) {
            Swal.fire({
              icon: "error",
              title: "ผิดพลาด",
              text: response.data.text,
            }).then((result) => {
              axios
                .get(process.env.REACT_APP_APIURL + "/api-web/getESubjectList")
                .then((response) => {
                  setRowData(response.data);
                });
            });
          }
        });
      return row;
    });
    let timerInterval;
    swal
      .fire({
        title: "ลบข้อมูลเรียบร้อย",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })
      .then((result) => {
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getESubjectList")
          .then((response) => {
            setRowData(response.data);
          });
      });
  };

  const CourseTypeSelect = courseTypeList.map((item) => (
    <option key={item.ELearnCourseTypeID} value={item.ELearnCourseTypeID}>
      {item.CourseTypeNameTH}
    </option>
  ));

  return (
    <section className="row custom-admin-input">
      <AdminBanner
        title={"การจัดการอีเลิร์นนิง (E-learning)"}
        path={
          <>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              การจัดการอีเลิร์นนิง (E-learning)
            </a>
            <span>
              <i className="fas fa-angle-right"></i>
            </span>
            <Link
              id="profile-btn"
              className="active"
              to={{ pathname: "/admin/elearning/ELearningList" }}
            >
              {"จัดการวิชา"}
            </Link>
          </>
        }
      />
      <div className="col-12">
        <form className="form-inline form-border">
          <div className="form-group mx-sm mb-2 col-5 col-lg-4 col-md-3 pr-0">
            <input
              type="text"
              ref={searchRef}
              className="form-control "
              id="searchfield"
              name="searchfield"
              placeholder="ค้นหาที่นี่"
            />
          </div>
          <div className="form-group mx-sm mb-2 col-3 col-md-2 col-lg-3 pr-0">
            <select className="form-control" ref={courseTypeRef}>
              <option value="0">หลักสูตรทั้งหมด</option>
              {CourseTypeSelect}
            </select>
          </div>
          <div className="form-group mx-sm mb-2 col-2 pr-0">
            <select className="form-control" ref={statusRef}>
              <option value="-1">สถานะทั้งหมด</option>
              <option value="1">เผยแพร่</option>
              <option value="0">ไม่เผยแพร่</option>
            </select>
          </div>
          <div className="button-group col-2">
            <button
              type="button"
              className="btn btn-info mb-2 mr-2"
              onClick={(e) => {
                e.preventDefault();
                searchData();
              }}
            >
              ค้นหา
            </button>
            <button
              type="submit"
              className="btn btn-light mb-2"
              onClick={(e) => {
                e.preventDefault();
                resetsearch();
              }}
            >
              ล้างคำค้นหา
            </button>
          </div>
        </form>
      </div>
      <div className="col-12">
        <div className="form-border">
          <div className="col-12">
            <div className="form-inline button-group">
              <button
                type="button"
                className="btn btn-success mb-2 mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleCreate();
                }}
              >
                {" "}
                สร้าง
              </button>
              <button
                type="button"
                className="btn btn-secondary mb-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteAll();
                }}
              >
                {" "}
                ลบ
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="ag-theme-alpine" style={{ height: 917 }}>
              <AgGridReact
                autoGroupColumnDef={{
                  headerName: "Group",
                  minWidth: 30,
                  field: "CategoriesID",
                  valueGetter: function (params) {
                    if (params.node.group) {
                      return params.node.key;
                    } else {
                      return params.data[params.colDef.field];
                    }
                  },
                  headerCheckboxSelection: true,
                  cellRenderer: "agGroupCellRenderer",
                  cellRendererParams: { checkbox: true },
                }}
                modules={AllCommunityModules}
                frameworkComponents={{
                  rowTool: RowTool,
                }}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  //cellClass: "cell-border cell-vertical-align-text-left",
                }}
                getRowHeight={getRowHeight}
                onGridReady={onGridReady}
                rowData={rowData}
                rowSelection={"multiple"}
                rowDragManaged={true}
                pagination={true}
                paginationPageSize={10}
              >
                <AgGridColumn
                  field=""
                  headerName=""
                  width={50}
                  checkboxSelection={checkboxSelection}
                  headerCheckboxSelection={headerCheckboxSelection}
                  sortable={true}
                  resizable={false}
                />
                <AgGridColumn
                  field=""
                  valueGetter={hashValueGetter}
                  headerName="ลำดับ"
                  width={20}
                  cellClass="cell-border cell-vertical-align-text-left"
                />
                <AgGridColumn
                  field="CourseTypeNameTH"
                  headerName="หลักสูตร"
                  minWidth={100}
                />
                <AgGridColumn
                  field="ELearnTitle"
                  headerName="ชื่อวิชา"
                  minWidth={230}
                />
                <AgGridColumn
                  field="Pin"
                  headerName="ปักหมุด"
                  cellRenderer={pinRenderer}
                  sortable={true}
                  width={80}
                />
                <AgGridColumn
                  field="Published"
                  headerName="สถานะ"
                  cellRenderer={stateRenderer}
                  sortable={true}
                  minWidth={100}
                />
                <AgGridColumn
                  field="CreateFullname"
                  headerName="ผู้สร้าง"
                  cellRenderer={createYearCellRenderer}
                  minWidth={165}
                  wrapText={true}
                  cellClass="cell-border cell-vertical-align-text-left"
                />
                <AgGridColumn
                  field="UpdateFullname"
                  headerName="ผู้แก้ไข"
                  cellRenderer={updateYearCellRenderer}
                  minWidth={165}
                  wrapText={true}
                  cellClass="cell-border cell-vertical-align-text-left"
                />
                <AgGridColumn
                  id="custom"
                  field="ELearnID"
                  headerName="จัดการ"
                  cellRenderer="rowTool"
                  minWidth={250}
                  left
                  sortable={false}
                  filter={false}
                  resizable={false}
                />
              </AgGridReact>
              {
                <div className="page-size formContent">
                  <p>Page size:</p>
                  <select
                    className="custom-select"
                    defaultValue={10}
                    onChange={() => onPageSizeChanged()}
                    id="page-size"
                  >
                    <option value="10">10</option>
                    <option value="100">25</option>
                    <option value="500">50</option>
                    <option value="1000">100</option>
                  </select>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
