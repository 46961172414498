import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import MomentUtils from '@date-io/moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import 'moment/locale/th';
import { scroller } from "react-scroll";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function JoinActivityForm({activityid, userid}) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [errors, setError] = useState({});
    const [fullname, SetFullName] = useState('');
    const [gender, SetGender] = useState('F');
    const [birthDate, SetBirthDate] = useState(new Date());
    const [position, SetPosition] = useState('');
    const [organizer, SetOrganizer] = useState('');
    const [phone, SetPhone] = useState('');
    const [email, SetEmail] = useState('');
    const [detail, SetDetail] = useState('');
    //console.log(activityid)
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/memberbyID', { params: { id: userid } }).then((response) => {
            //console.log(response.data[0])
            SetFullName(response.data[0].FirstnameTH + " " + response.data[0].LastnameTH)
            SetGender(response.data[0].Gender)
            SetPhone(response.data[0].Phone)
            SetEmail(response.data[0].Email)
            SetBirthDate(new Date(response.data[0].BirthDate));
            //console.log(response.data[0].BirthDate)
            //SetBirthDate(new Date(response.data[0].CreatedDate))
        });

    }, []); /* <-- add this for run once*/
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (fullname.trim().length===0) {
            formIsValid = false;
            serrors["fullname"] = "กรุณาระบุชื่อ-นามสกุล";
        }
        if(gender.trim().length===0){
            formIsValid = false;
            serrors["gender"] = "กรุณาระบุเพศ";
        }
        /*if(birthDate!==null){
            formIsValid = false;
            serrors["birthDate"] = "กรุณาระบุวันเดือนปีเกิด";
        }*/
        if(organizer.trim().length===0){
            formIsValid = false;
            serrors["organizer"] = "กรุณาระบุหน่วยงาน";
        }
        if(position.trim().length===0){
            formIsValid = false;
            serrors["position"] = "กรุณาระบุตำแหน่ง";
        }
        if(detail.trim().length===0){
            formIsValid = false;
            serrors["detail"] = "กรุณาใส่รายละเอียด";
        }
        if(phone.trim().length===0){
            formIsValid = false;
            serrors["phone"] = "กรุณาใส่หมายเลขโทรศัพท์";
        }
        if(email.trim().length===0){
            formIsValid = false;
            serrors["email"] = "กรุณาใส่ข้อมูลอีเมล";
        }

        setError(serrors);
        return formIsValid;
    };
    const saveData = () => {
        if (handleValidation()) {
            const formData = new FormData();
            console.log(fullname);
            let getfullname =fullname;
            let name = getfullname.split(" ");
            formData.append("activitiesID", activityid);
            formData.append("firstName", name[0]);
            formData.append("lastName", name[1]);
            formData.append("gender", gender);
            formData.append("birthDate", birthDate);
            formData.append("position", position);
            formData.append("department", organizer);
            formData.append("phone", phone);
            formData.append("email", email);
            
            formData.append("otherInfo", detail);
            formData.append("registerUserID", currentUser.UserID);
            formData.append("userID", userid);

            axios.post(process.env.REACT_APP_APIURL+'/api-web/activityEnroll', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                            window.location.reload();
                        }
                    }).then((result) => {
                        swal.close();
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }
    };
    return (
        <div className="formContent">
            <div className="col-12">
                <input type="text" id="FullName" name="FullName" placeholder="ชื่อ-นามสกุล" autoComplete="off" value={fullname} onChange={(e) => { e.preventDefault(); SetFullName(e.currentTarget.value); }} />
                <span className="error d-block">{errors["IDCardFirstName"]}</span>
            </div>
            <div className="col-12">
                <select id="Gender" name="Gender" className="custom-select" value={gender} onChange={(e) => { e.preventDefault(); SetGender(e.currentTarget.value); }}>
                    <option value="" >{language === "Thai" ? "เลือกเพศ" : "Select Gender"}</option>
                    <option value="M" checked={gender === "M"} >{language === "Thai" ? "ชาย" : "Male"}</option>
                    <option value="F" checked={gender === "F"} >{language === "Thai" ? "หญิง" : "Female"}</option>
                </select>
                <SError error={errors["gender"]} />
            </div>
            <div className="col-12">
                {/* <label className="">วันเกิด</label> */}
                <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                    <DatePicker className="custom-calendar" value={birthDate} format="D MMMM YYYY"
                        pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                        {SetBirthDate} name="birthDate" id="birthDate" />
                </MuiPickersUtilsProvider>
                <SError error={errors["birthDate"]} />
            </div>   
            <div className="col-12">
                <input type="text" id="position" name="position" placeholder="ตำแหน่ง" autoComplete="off" value={position} onChange={(e) => { e.preventDefault(); SetPosition(e.currentTarget.value); }} />
                <SError error={errors["position"]} />
            </div>
            <div className="col-12">
                <input type="text" id="organizer" name="organizer" placeholder="หน่วยงาน" autoComplete="off" value={organizer} onChange={(e) => { e.preventDefault(); SetOrganizer(e.currentTarget.value); }} />
                <SError error={errors["organizer"]} />
            </div>
            <div className="col-12">
                <input type="text" id="phone" name="phone" placeholder="หมายเลขโทรศัพท์" autoComplete="off" value={phone} onChange={(e) => { e.preventDefault(); SetPhone(e.currentTarget.value); }} />
                <SError error={errors["phone"]} />
            </div>
            <div className="col-12">
                <input type="text" id="email" name="email" placeholder="อีเมล" autoComplete="off" value={email} onChange={(e) => { e.preventDefault(); SetEmail(e.currentTarget.value); }} />
                <SError error={errors["email"]} />
            </div>
            <div className="col-12">
                <input type="text" id="detail" name="detail" placeholder="รายละเอียด" autoComplete="off" value={detail} onChange={(e) => { e.preventDefault(); SetDetail(e.currentTarget.value); }} />
                <SError error={errors["detail"]} />
            </div>
            <input type="submit" className="fadeIn fourth" value="เข้าร่วม" onClick={(e) => { e.preventDefault();saveData(); }} />
        </div>
    );
}