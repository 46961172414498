import React from "react";
import { Link } from "react-router-dom";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import Footer from "components/Footer.js";
import Cookies from "../components/Cookies";

export default function PolicyWeb(props) {
    const language = localStorage.getItem("language");

  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}

        <ContentBanner
          language={language}
          theader={"นโนบายการคุ้มครองข้อมูลส่วนบุคคล"}
          eheader={"นโนบายการคุ้มครองข้อมูลส่วนบุคคล"}
          bannerkey="NEWS"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{ pathname: "/PolicyWeb" }}
              >
                {language === "Thai" ? "นโนบายการคุ้มครองข้อมูลส่วนบุคคล " : "Policy"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="PolicyWeb">
            <div className="PolicyWeb-head">
                <h2>ประกาศสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</h2>
                <p>เรื่อง นโยบายการคุ้มครองข้อมูลส่วนบุคคลของสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</p>
            </div>
            <div className="details">
                <p style={{textIndent: '1.5em'}}>
                โดยที่เป็นการสมควรกำหนดให้มีประกาศสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ เรื่อง นโยบายการคุ้มครองข้อมูลส่วนบุคคลของสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ 
                เพื่อเป็นการกำหนดแนวทางในการบริหารจัดการข้อมูลส่วนบุคคลที่อยู่ในความครอบครองของสำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติให้เป็นไปในทิศทางเดียวกัน และช่วยให้การเก็บรวบรวม 
                ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล เป็นไปโดยถูกต้องตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒ รวมทั้งกำหนดมาตรฐานการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล 
                ให้เป็นไปตามมาตรา ๓ วรรคสอง แห่งพระราชกฤษฎีกากำหนดหน่วยงานและกิจการที่ผู้ควบคุมข้อมูลส่วนบุคคลไม่อยู่ภายใต้บังคับแห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. ๒๕๖๒ พ.ศ. ๒๕๖๓ และที่แก้ไขเพิ่มเติม ซึ่งได้กำหนดให้ผู้ควบคุมข้อมูลส่วนบุคคลซึ่งเป็นหน่วยงานหรือกิจการตามบัญชีท้ายพระราชกฤษฎีกาดังกล่าว 
                ต้องจัดให้มีมาตรการรักษาความปลอดภัยของข้อมูลส่วนบุคคลให้เป็นไปตามมาตรฐานที่กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคมกำหนด
                </p>       
                <p style={{textIndent: '1.5em'}}>
                อาศัยอำนาจตามความในมาตรา ๕๓ แห่งพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. ๒๕๖๐ ประกอบกับมาตรา ๓ วรรคสอง 
                แห่งพระราชกฤษฎีกากำหนดหน่วยงานและกิจการที่ผู้ควบคุมข้อมูลส่วนบุคคลไม่อยู่ภายใต้บังคับแห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒ พ.ศ. ๒๕๖๓ 
                และที่แก้ไขเพิ่มเติม สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติจึงออกประกาศไว้ ดังนี้
                </p>    
                <h4>ข้อ ๑ คำนิยาม</h4>
                <ul>
                    <li>“คณะกรรมการ” หมายความว่า คณะกรรมการสิทธิมนุษยชนแห่งชาติ</li>
                    <li>“สำนักงาน” หมายความว่า สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</li>
                    <li>“ข้อมูลส่วนบุคคล” หมายความว่า ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือโดยอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ</li>
                    <li>“ข้อมูลส่วนบุคคลอ่อนไหว” หมายความว่า ข้อมูลส่วนบุคคลตามที่กำหนดไว้ในมาตรา ๒๖ แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒</li>
                    <li>“ผู้ควบคุมข้อมูลส่วนบุคคล” หมายความว่า บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</li>
                    <li>“ผู้ประมวลผลข้อมูลส่วนบุคคล” หมายความว่า บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล ทั้งนี้ บุคคลหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล</li>
                </ul>
                <h4>ข้อ ๒ ประเภทของข้อมูลส่วนบุคคล</h4>
                <p>ข้อมูลส่วนบุคคลที่อยู่ในความครอบครองของสำนักงาน ประกอบด้วย</p>
                <p>(๑) 	ข้อมูลส่วนบุคคลของคณะกรรมการและบุคคลที่เกี่ยวข้องกับคณะกรรมการ ได้แก่</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลของประธานกรรมการและกรรมการสิทธิมนุษยชนแห่งชาติ </li>
                    <li>ข. ข้อมูลส่วนบุคคลของที่ปรึกษาประจำคณะกรรมการ</li>
                    <li>ค. ข้อมูลส่วนบุคคลของเลขานุการและผู้ช่วยเลขานุการประจำประธานกรรมการและกรรมการสิทธิมนุษยชนแห่งชาติ</li>
                    <li>ง. ข้อมูลส่วนบุคคลของผู้ซึ่งเคยปฏิบัติหน้าที่ตาม ก. ข. และ ค. </li>
                </ul>
                <p>(๒)	ข้อมูลส่วนบุคคลเกี่ยวกับการปฏิบัติตามหน้าที่และอำนาจของคณะกรรมการและสำนักงาน</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลที่ได้จากการปฏิบัติภารกิจภายใต้หน้าที่และอำนาจของคณะกรรมการและสำนักงาน ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. ๒๕๖๐  </li>
                    <li>ข. ข้อมูลส่วนบุคคลของคู่ความและผู้ที่เกี่ยวข้องในคดีที่คณะกรรมการ สำนักงาน ประธานกรรมการสิทธิมนุษยชนแห่งชาติ กรรมการสิทธิมนุษยชนแห่งชาติ ข้าราชการหรือเจ้าหน้าที่ของสำนักงาน เป็นคู่กรณีหรือคู่ความในคดีที่เกิดจากการปฏิบัติหน้าที่ </li>
                </ul>
                <p>(๓)	ข้อมูลส่วนบุคคลเกี่ยวกับการขึ้นทะเบียน การรับจดแจ้ง และการแต่งตั้งบุคคลภายนอกเพื่อปฏิบัติงานให้กับคณะกรรมการหรือสำนักงาน ได้แก่</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลของผู้ได้รับการขึ้นทะเบียนเป็นผู้ทรงคุณวุฒิ </li>
                    <li>ข. ข้อมูลส่วนบุคคลของผู้แทนองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพที่ได้รับจดแจ้งจากสำนักงาน</li>
                    <li>ค. ข้อมูลส่วนบุคคลของผู้ที่ได้รับแต่งตั้งให้ปฏิบัติหน้าที่เป็นกรรมการและอนุกรรมการในคณะต่าง ๆ ที่คณะกรรมการแต่งตั้ง</li>
                    <li>ง. ข้อมูลส่วนบุคคลของผู้ที่ได้รับแต่งตั้งให้ปฏิบัติหน้าที่เป็นคณะทำงาน</li>
                    <li>จ. ข้อมูลส่วนบุคคลของเจ้าหน้าที่ของรัฐที่ได้รับแต่งตั้งเป็นพนักงานเจ้าหน้าที่</li>
                    <li>ฉ. ข้อมูลส่วนบุคคลของผู้ซึ่งเคยได้รับการขึ้นทะเบียน รับจดแจ้ง และแต่งตั้ง ตาม ก. ข. ค. ง. และ จ.</li>
                </ul>
                <p>(๔)	ข้อมูลส่วนบุคคลด้านการบริหารงานทรัพยากรบุคคล ได้แก่</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลของข้าราชการ พนักงานราชการ และลูกจ้างของสำนักงาน รวมถึงข้อมูลส่วนบุคคลของผู้สมัครเข้ารับการสรรหาเพื่อบรรจุเป็นข้าราชการหรือเพื่อจ้างเป็นพนักงานราชการและลูกจ้างของสำนักงาน</li>
                    <li>ข. ข้อมูลส่วนบุคคลข้าราชการบำนาญของสำนักงานที่ขอรับสิทธิประโยชน์</li>
                    <li>ค. ข้อมูลส่วนบุคคลของผู้รับบำเหน็จตกทอดของข้าราชการสำนักงาน</li>
                    <li>ง. ข้อมูลส่วนบุคคลของผู้ซึ่งเคยปฏิบัติหน้าที่ให้กับสำนักงานตาม ก.</li>
                </ul>
                <p>(๕) ข้อมูลส่วนบุคคลด้านการบริหารสำนักงาน ได้แก่</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลของผู้เข้าร่วมกระบวนการจัดซื้อจัดจ้าง การบริหารพัสดุ และการคลังของสำนักงาน</li>
                    <li>ข. ข้อมูลส่วนบุคคลของผู้เข้าร่วมประชุม อบรม สัมมนา หรือกิจกรรมอื่นใดของสำนักงานหรือที่สำนักงานได้รับเชิญให้เข้าร่วม </li>
                    <li>ค. ข้อมูลส่วนบุคคลของวิทยากรในการบรรยายและอบรมของสำนักงาน</li>
                </ul>
                <p>(๖) ข้อมูลส่วนบุคคลด้านการสมัครสมาชิกหรือใช้บริการของสำนักงาน</p>
                <ul>
                    <li>ก. ข้อมูลส่วนบุคคลของสมาชิกสวัสดิการภายในสำนักงาน </li>
                    <li>ข. ข้อมูลส่วนบุคคลของสมาชิกศูนย์สารสนเทศสิทธิมนุษยชน และผู้ใช้บริการเว็บไซต์ แอปพลิเคชัน ระบบงานของสำนักงาน หรือช่องทางการสื่อสารอื่นซึ่งควบคุมดูแลโดยสำนักงาน</li>
                </ul>
                <p>ทั้งนี้ ไม่ว่าข้อมูลส่วนบุคคลดังกล่าวจะถูกจัดเก็บไว้ในรูปแบบที่เป็นเอกสาร ฟิล์ม ภาพหรือเสียง ข้อมูลอิเล็กทรอนิกส์ หรือในรูปแบบอื่นใดก็ตาม</p>
                <h4>ข้อ ๓ วัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล</h4>
                <p style={{textIndent: '1.5em'}}>
                สำนักงานดำเนินการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อประโยชน์ในการตรวจสอบความถูกต้องของข้อมูล
                และเพื่อประโยชน์ในการดำเนินงานของคณะกรรมการและสำนักงานภายใต้กรอบหน้าที่และอำนาจตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ 
                พ.ศ. ๒๕๖๐ กฎ ระเบียบ ประกาศ คำสั่ง หรือแนวปฏิบัติที่เกี่ยวข้อง รวมทั้งเพื่อการปฏิบัติตามกฎหมายหรือตามคำสั่งศาล
                </p>
                <h4>ข้อ ๔ การเคารพสิทธิส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล</h4>
                <ul>
                    <li>(๑) สำนักงานตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล และจะเคารพสิทธิและเสรีภาพส่วนบุคคลตามกฎหมายอย่างเคร่งครัด 
                    โดยจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลเฉพาะในกรณีที่มีความจำเป็นต้องดำเนินการตามวัตถุประสงค์ที่กำหนดไว้ในข้อ ๓ เท่านั้น นอกจากนี้ สำนักงานจะดำเนินการเพื่อให้ข้อมูลส่วนบุคคลเหล่านั้นมีความถูกต้อง เป็นปัจจุบัน สมบูรณ์ และ
                    ไม่ก่อให้เกิดความเข้าใจผิด รวมทั้งจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล เพื่อป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาตหรือมิชอบด้วยกฎหมาย
                    </li>
                    <li>(๒) ในกรณีที่สำนักงานมีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์อื่นนอกจากที่กำหนดไว้ในข้อ ๓ สำนักงานจะขอรับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลเสียก่อน และเจ้าของข้อมูลส่วนบุคคลอาจเพิกถอนความยินยอมการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลได้ตามสิทธิของเจ้าของข้อมูลส่วนบุคคลที่กฎหมายกำหนดไว้</li>
                    <li>(๓) สำนักงานอาจมีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อศึกษา วิจัย หรือการจัดทำสถิติที่เป็นไปตามวัตถุประสงค์การดำเนินงานของสำนักงานตามที่กฎหมายกำหนด หรือเพื่อการปรับปรุงคุณภาพการบริหารงานและการให้บริการของสำนักงาน ซึ่งสำนักงานจะได้แจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบวัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลนั้นด้วย</li>
                    <li>(๔) สำนักงานจะจัดให้มีการบันทึกข้อมูลสารสนเทศเกี่ยวกับข้อมูลการจราจรคอมพิวเตอร์ของเจ้าของข้อมูลส่วนบุคคล เช่น หมายเลขไอพี (IP Address) และโปรแกรมบราวเซอร์ (Browser) เพื่อวัตถุประสงค์ในการวิเคราะห์และติดตามการใช้บริการทางเว็บไซต์หรือแอปพลิเคชันของสำนักงาน และเพื่อการตรวจสอบย้อนหลัง
                    ในกรณีที่เกิดปัญหาการใช้งาน ทั้งนี้ สำนักงานอาจดำเนินการเองหรือใช้บริการจากหน่วยงานภายนอกก็ได้
                    </li>
                </ul>
                <h4>ข้อ ๕ การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล</h4>
                <p>(๑) 	การเก็บรวบรวมข้อมูลส่วนบุคคล</p>
                <ul>
                    <li>ก. สำนักงานจะขอความยินยอมจากเจ้าของข้อมูลก่อนหรือในขณะที่เก็บรวบรวมข้อมูลส่วนบุคคล เว้นแต่กรณีมีความจำเป็นตามที่กฎหมายกำหนด พร้อมทั้งแจ้งวัตถุประสงค์ของการเก็บรวบรวมข้อมูลดังกล่าวและรายละเอียดที่เกี่ยวข้อง</li>
                    <li>ข. สำนักงานจะไม่จัดเก็บข้อมูลส่วนบุคคลอ่อนไหว เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลนั้นก่อน หรือเป็นกรณีที่สำนักงานจะต้องปฏิบัติให้เป็นไปตามกฎหมายหรือมีกฎหมายบัญญัติให้กระทำได้</li>
                    <li>ค. สำนักงานจะรวบรวมและจัดเก็บข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคลโดยตรงเท่านั้น เว้นแต่เป็นกรณีที่กฎหมายอนุญาตให้สำนักงานสามารถเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่นได้โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล 
                    นอกจากนี้ สำนักงานอาจนำข้อมูลส่วนบุคคลที่ได้รับมาจากแหล่งอื่นมาผนวกเข้ากับข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลผู้นั้นได้เฉพาะในกรณีที่มีความจำเป็น
                    เพื่อประโยชน์ในการปรับปรุงข้อมูลส่วนบุคคลให้ถูกต้องเป็นปัจจุบัน สมบูรณ์ ไม่ก่อให้เกิดความเข้าใจผิด 
                    และเพื่อการพัฒนาคุณภาพและประสิทธิภาพในการให้บริการของสำนักงาน
                    </li>
                </ul>
                <p>(๒)	การนำข้อมูลไปใช้หรือเปิดเผย</p>
                <ul>
                    <li>ก. สำนักงานจะใช้ประโยชน์หรือเปิดเผยข้อมูลส่วนบุคคลให้แก่ผู้ปฏิบัติงานของสำนักงานหรือผู้ประมวลผลข้อมูลส่วนบุคคลของสำนักงาน ตามที่ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลและวัตถุประสงค์ที่ได้แจ้งไว้เท่านั้น</li>
                    <li>ข. สำนักงานจะกำกับดูแลไม่ให้ผู้ปฏิบัติงานของสำนักงานหรือผู้ประมวลผลข้อมูลส่วนบุคคลนำข้อมูลส่วนบุคคลไปใช้ประโยชน์หรือเปิดเผยแก่บุคคลอื่น 
                    นอกเหนือไปจากวัตถุประสงค์ในการดำเนินงานตามภารกิจของคณะกรรมการหรือสำนักงาน เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อน หรือเป็นกรณีที่สำนักงานจะต้องปฏิบัติให้เป็นไปตามกฎหมายหรือมีกฎหมายบัญญัติให้กระทำได้
                    </li>
                    <li>ค. สำนักงานอาจให้บุคคลหรือหน่วยงานอื่นเข้าถึงหรือใช้ข้อมูลส่วนบุคคลเท่าที่จำเป็นและเพื่อให้เป็นไปตามวัตถุประสงค์และหน้าที่และอำนาจของคณะกรรมการหรือสำนักงาน โดยเป็นไปตามกฎหมายหรือวัตถุประสงค์ที่ได้แจ้งกับเจ้าของข้อมูลส่วนบุคคลตามข้อ ๕ (๑) แล้ว</li>
                    <li>ง. ในกรณีที่ผู้ประมวลผลข้อมูลส่วนบุคคลเป็นบุคคลภายนอก สำนักงานจะจัดให้มีข้อตกลงระหว่างสำนักงานกับผู้ประมวลผลข้อมูลส่วนบุคคลเพื่อกำหนดให้ผู้ประมวลผลข้อมูลส่วนบุคคลปฏิบัติตามข้อกำหนดเกี่ยวกับการรักษาความมั่นคงปลอดภัย การใช้ และการเปิดเผยข้อมูลส่วนบุคคล ตามที่สำนักงานกำหนดเท่านั้น ทั้งนี้ สำนักงานจะจัดให้มีการควบคุมและตรวจสอบการดำเนินงานตามหน้าที่ของผู้ประมวลผลข้อมูลส่วนบุคคลให้เป็นไปตามกฎหมายและข้อตกลงดังกล่าวอย่างเคร่งครัดด้วย</li>
                    <li>จ. สำนักงานจะจัดให้มีการบันทึกการใช้หรือเปิดเผยข้อมูลส่วนบุคคลไว้เป็นหลักฐาน ตามหลักเกณฑ์และวิธีการที่กฎหมายกำหนด</li>
                </ul>
                <h4>ข้อ ๖ การเก็บรักษาและการทำลายข้อมูลส่วนบุคคล</h4>
                <p style={{textIndent: '1.5em'}}>สำนักงานอาจเก็บรักษาข้อมูลส่วนบุคคลในรูปแบบเอกสาร ฟิล์ม ภาพหรือเสียง หรือข้อมูลอิเล็กทรอนิกส์ โดยจะจัดให้มีระบบการตรวจสอบระยะเวลาการเก็บรักษาและการทำลายข้อมูลส่วนบุคคลให้สอดคล้องกับระยะเวลา
                และแนวปฏิบัติที่เกี่ยวข้องกับระเบียบสำนักนายกรัฐมนตรีว่าด้วยงานสารบรรณ 
                </p>
                <h4>ข้อ ๗ การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</h4>
                <p style={{textIndent: '1.5em'}}>สำนักงานจะสร้างเสริมให้ผู้ปฏิบัติงานของสำนักงานตระหนักรู้ด้านความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลตามนโยบายนี้อย่างเคร่งครัด และจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูล
                ส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) 
                ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control) โดยอย่างน้อยต้องประกอบด้วยการดำเนินการ ดังต่อไปนี้
                </p>
                <ul>
                    <li>(๑) การควบคุมการเข้าถึงข้อมูลส่วนบุคคลและอุปกรณ์ในการจัดเก็บและประมวลผลข้อมูลส่วนบุคคลโดยคำนึงถึงการใช้งานและความมั่นคงปลอดภัย</li>
                    <li>(๒) การกำหนดเกี่ยวกับการอนุญาตหรือการกำหนดสิทธิในการเข้าถึงข้อมูลส่วนบุคคล</li>
                    <li>(๓) การบริหารจัดการการเข้าถึงของผู้ใช้งาน (user access management) เพื่อควบคุมการเข้าถึงข้อมูลส่วนบุคคลเฉพาะผู้ที่ได้รับอนุญาตแล้ว</li>
                    <li>(๔) การกำหนดหน้าที่ความรับผิดชอบของผู้ใช้งาน (user responsibilities) เพื่อป้องกันการเข้าถึงข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต การเปิดเผย การล่วงรู้ หรือการลักลอบทำสำเนาข้อมูลส่วนบุคคล การลักขโมยอุปกรณ์จัดเก็บหรือประมวลผลข้อมูลส่วนบุคคล </li>
                    <li>(๕) การจัดให้มีวิธีการเพื่อให้สามารถตรวจสอบย้อนหลังเกี่ยวกับการเข้าถึง แก้ไข เปลี่ยนแปลง ลบ ทำลาย หรือถ่ายโอนข้อมูลส่วนบุคคล ให้สอดคล้องเหมาะสมกับวิธีการและสื่อที่ใช้ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</li>
                </ul>
                <h4>ข้อ ๘ สิทธิเจ้าของข้อมูลส่วนบุคคล</h4>
                <p>เจ้าของข้อมูลส่วนบุคคลมีสิทธิเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของตนเอง ดังต่อไปนี้</p>
                    <ul>
                        <li>(๑) สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับตน หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม</li>
                        <li>(๒)	สิทธิขอรับข้อมูลส่วนบุคคลที่เกี่ยวกับตนจากผู้ควบคุมข้อมูลส่วนบุคคล ในกรณีที่ผู้ควบคุมข้อมูลส่วนบุคคลได้ทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ</li>
                        <li>(๓)	สิทธิขอให้แก้ไขหรือเปลี่ยนแปลงข้อมูลส่วนบุคคลให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และ
                        ไม่ก่อให้เกิดความเข้าใจผิด </li>
                        <li>(๔)	สิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตน</li>
                        <li>(๕)	สิทธิขอให้ดำเนินการลบหรือทำลาย หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้</li>
                        <li>(๖)	สิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลทั้งนี้ การใช้สิทธิดังกล่าวจะต้องอยู่ภายใต้เงื่อนไขตามที่กฎหมายกำหนด</li>
                    </ul>
                <h4>ข้อ ๙ การเชื่อมต่อเว็บไซต์หรือบริการภายนอก</h4>
                <p style={{textIndent: '1.5em'}}>
                บริการของสำนักงานอาจมีการเชื่อมต่อไปยังเว็บไซต์หรือบริการของบุคคลที่สาม ซึ่งเว็บไซต์หรือบริการดังกล่าวอาจมีการประกาศนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่มีเนื้อหาสาระแตกต่างจากนโยบายนี้ ดังนั้น 
                สำนักงานจึงไม่มีความเกี่ยวข้องและไม่มีอำนาจควบคุมถึงมาตรการคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์หรือบริการดังกล่าว และไม่สามารถรับผิดชอบต่อเนื้อหา นโยบาย ความเสียหาย หรือการกระทำอันเกิดจากเว็บไซต์หรือบริการของบุคคลที่สามแต่อย่างใด</p>
                <h4>ข้อ ๑๐ การเปิดเผยนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h4>
                <p style={{textIndent: '1.5em'}}>
                สำนักงานจะเปิดเผยนโยบายการคุ้มครองข้อมูลส่วนบุคคลผ่านทางเว็บไซต์ แอปพลิเคชัน และสื่อประชาสัมพันธ์ของสำนักงานตามความเหมาะสม โดยสำนักงานจะพิจารณาทบทวนนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ให้สอดคล้องกับกฎหมายและมาตรฐานที่เกี่ยวข้องเป็นระยะตามความเหมาะสม ในกรณีที่มี
                การเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่เป็นสาระสำคัญ สำนักงานจะแจ้งให้เจ้าของข้อมูลส่วนบุคคล ผู้ใช้บริการ และผู้มีส่วนได้เสียทราบผ่านช่องทางดังกล่าวโดยเร็ว</p>
                <h4>ข้อ ๑๑ ช่องทางการติดต่อ</h4>
                <div className="contact">
                    <p>สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ </p>
                    <p>ที่อยู่ : ศูนย์ราชการเฉลิมพระเกียรติ ๘๐ พรรษา ๕ ธันวาคม ๒๕๕๐ </p>
                    <p>อาคารรัฐประศาสนภักดี (อาคารบี) ชั้น ๖ - ๗ </p>
                    <p>เลขที่ ๑๒๐ ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ ๑๐๒๑๐ </p>
                    <p>โทรศัพท์ : ๐ ๒๑๔๑ ๓๘๐๐, ๐ ๒๑๔๑ ๓๙๐๐ </p>
                    <p>เว็บไซต์ : https://www.nhrc.or.th</p>
                </div>
                <div className="date">
                    <p>ประกาศ ณ วันที่ ๒๒ ธันวาคม พ.ศ. ๒๕๖๔</p>
                    <p style={{paddingRight: '60px'}}>รตญา กอบศิริกาญจน์</p>
                    <p style={{paddingRight: '40px'}}>รองเลขาธิการ รักษาราชการแทน</p>
                    <p>เลขาธิการคณะกรรมการสิทธิมนุษยชนแห่งชาติ</p>

                </div>
            </div>
          </section>
        </div>
       
      </main>
     
      <Footer />
    </>
  );
}
