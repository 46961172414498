import React, { useEffect } from "react";
import { Link } from "@material-ui/core";
import { isIOS, isAndroid, isBrowser } from "react-device-detect";
export default function QrCode(props) {
  useEffect(() => {
    console.log("isIOS", isIOS);
    if (isIOS === true) {
      window.location.href =
        "https://apps.apple.com/us/app/%E0%B8%A1-%E0%B8%AA-%E0%B8%97%E0%B8%98/id1629029764";
    }
  }, [isIOS]);
  useEffect(() => {
    console.log("isAndroid", isAndroid);
    if (isAndroid === true) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=th.or.nhrc.merights";
    }
  }, [isAndroid]);
  useEffect(() => {
    console.log("isBrowser", isBrowser);
  }, [isBrowser]);

  return (
    <>
      <div className="container">
        <div className="control-box-qr">
          <p>DOWNLOAD THE APP</p>
          <img src={`/images/QRCode.png`} alt="" />
          <div className="box-link">
            <Link href="https://play.google.com/store/apps/details?id=th.or.nhrc.merights">
              <img src={`/images/Android.png`} alt="" />
            </Link>
            <Link href="https://apps.apple.com/us/app/%E0%B8%A1-%E0%B8%AA-%E0%B8%97%E0%B8%98/id1629029764">
              <div>
                <img src={`/images/apple.png`} alt="" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
