import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  FacebookIcon,
  EmailIcon,
  LineIcon,
} from "react-share";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/elibrary.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import searchicon from "assets/images/icon/search.svg";
import { authenticationService } from "services/authentication.service.js";
const swal = withReactContent(Swal);
export default function ELibrary(props) {
  const currentUser = authenticationService.currentUserValue;
  console.log("currentUser", currentUser);
  const PageSize = 9;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [selectActivityCat, SetSelectActivityCat] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [elibList, setELibList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterMode, setFilterMode] = useState(0);
  const [activeModeTitle, setActiveModeTitle] = useState("สื่อสิ่งพิมพ์");
  const [newElibList, setNewELibList] = useState([]);
  const [topViewElibList, setTopViewELibList] = useState([]);
  const [pinElibList, setPinELibList] = useState([]);
  const [useID, setUserID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [popupItem, setPopupItem] = useState([]);
  const [mainCat, setMainCat] = useState(46);
  const [subCat, setSubCat] = useState(0);
  const [hrc, setHRC] = useState(0);

  const [searchType, setSearchType] = useState("all");

  useEffect(() => {
    utils.AccessInfo(
      "คลังความรู้สิทธิมนุษยชน",
      "/authen/elibrary/ELibrary",
      "F",
      currentUser
    );
    if (currentUser != null) {
      setUserID(currentUser.UserID);
    }
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchElibraryCat", {
        params: {
          status: 1,
          UserType: currentUser && currentUser.MemberType ? currentUser.MemberType : "M",
        },
      })
      .then((response) => {
        SetSelectActivityCat(response.data);
        let catnamelist = response.data;
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/searchElibrary", {
            params: { status: 1, ispublish: true },
          })
          .then((response) => {
            setNetworkList(response.data);
            setELibList(response.data);

            const arr = response.data;
            //console.log(arr)
            const sortedDateElib = [...arr].sort(
              (a, b) =>
                new Date(b.PublishedStartDate) - new Date(a.PublishedStartDate)
            );
            setNewELibList(sortedDateElib.splice(0, 3));

            const topview = [...arr].sort(
              (a, b) => Number(b.Views) - Number(a.Views)
            );
            setTopViewELibList(topview.splice(0, 3));

            const pin = [...arr].filter((item) => {
              return item.Pin === true;
            });
            const lastpin = pin.sort((a, b) => a.Ordering - b.Ordering);
            setPinELibList(lastpin.splice(0, 3));

            setCurrentPage(1);
            /*ในกรณีที่ส่งมาจากหน้าแรกของ Page*/
            if (typeof props.location.state !== "undefined") {
              if (typeof props.location.state.BookCatID !== "undefined") {
                setELibList(
                  [...response.data].filter((item) => {
                    return (
                      Number(item.BookCatID) ===
                        Number(props.location.state.BookCatID) ||
                      Number(item.ParentBookCatID) ===
                        Number(props.location.state.BookCatID)
                    );
                  })
                );
                setCurrentPage(0);
                setCurrentPage(1);
                setFilterMode(2);
                let bookCat = catnamelist.find(
                  (e) =>
                    Number(e.BookCatID) ===
                    Number(props.location.state.BookCatID)
                );
                if (typeof bookCat !== "undefined") {
                  setActiveModeTitle(bookCat.CategoriesNameTH);
                  setMainCat(bookCat.BookCatID);
                  //searchfromFirstPage(1, bookCat.BookCatID);
                }
                setLoading(false);
              } else {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          });
      });
  }, []); /* <-- add this for run once*/

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return networkList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const SearchResultDIV = currentTableData.map((item, i) => (
    <div className="col-md-6 col-lg-4 mt-3 mb-3" key={item.BookID}>
      <div className="card book-cover">
        <img src={item.ThumnailWeb} className="cover" alt="Bookmark cover" />
        <div
          className="share-button"
          onClick={(e) => {
            e.preventDefault();
            shareData(item);
          }}
        >
          <i className="fas fa-share-alt"></i>
        </div>
        <div
          className="card-body"
          onClick={(e) => {
            e.preventDefault();
            readData(item.BookID);
          }}
        >
          <h4 className="content-title">
            {/*item.Ordering*/}
            {language === "Thai" ? item.BookName : item.BookName}
          </h4>
          <div className="mt-2 mb-2">
            <span className="time-ago">
              <i className="far fa-clock" />
              <p>
                {" "}
                {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}
              </p>
            </span>
            <span className="view-total float-right">
              <i className="fas fa-eye" />
              <p> {item.Views}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  const ELibByCatDIV = () => {
    if (typeof elibList !== "undefined") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      const currentElibCatData = elibList.slice(firstPageIndex, lastPageIndex);
      const data = currentElibCatData.map((item, i) => (
        <div className="col-md-6 col-lg-4 mt-3 mb-3" key={item.BookID}>
          <div className="card book-cover">
            <img
              src={item.ThumnailWeb}
              className="cover"
              alt="Bookmark cover"
            />
            <div
              className="share-button"
              onClick={(e) => {
                e.preventDefault();
                shareData(item);
              }}
            >
              <i className="fas fa-share-alt"></i>
            </div>
            <div
              className="card-body"
              onClick={(e) => {
                e.preventDefault();
                readData(item.BookID);
              }}
            >
              <h4 className="content-title">
                {language === "Thai" ? item.BookName : item.BookName}
              </h4>
              <div className="mt-2 mb-2">
                <span className="time-ago">
                  <i className="far fa-clock" />
                  <p>
                    {" "}
                    {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}
                  </p>
                </span>
                <span className="view-total float-right">
                  <i className="fas fa-eye" />
                  <p> {item.Views}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      ));
      return data;
    }
    return <></>;
  };
  const bookNameRef = React.createRef();
  const autherRef = React.createRef();
  const searchRef = React.createRef();
  const searchData = (type, value) => {
    const formData = new FormData();
    let mcat = mainCat;
    let scat = subCat;
    let hcat = hrc;
    if (type === 1) {
      mcat = Number(value);
      setMainCat(mcat);
    } else if (type === 2) {
      scat = Number(value);
      setSubCat(scat);
    } else if (type === 3) {
      hcat = Number(value);
      setHRC(hcat);
    }

    if (bookNameRef.current != null)
      if (autherRef.current != null)
        // formData.append("bookname", bookNameRef.current.value);
        // formData.append("auther", autherRef.current.value);
        formData.append("category", mcat);
    formData.append("subcat", scat);
    formData.append("hrcType", hcat);
    formData.append("searchType", searchType);
    if (searchRef.current != null)
      formData.append("search", searchRef.current.value);

    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/searchELibraryList",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setNetworkList(response.data);
        setCurrentPage(0);
        setCurrentPage(1);
        setFilterMode(1);
      });
  };
  const searchfromFirstPage = (type, value) => {
    const formData = new FormData();
    let mcat = mainCat;
    let scat = subCat;
    let hcat = hrc;
    if (type === 1) {
      mcat = Number(value);
      setMainCat(mcat);
    } else if (type === 2) {
      scat = Number(value);
      setSubCat(scat);
    } else if (type === 3) {
      hcat = Number(value);
      setHRC(hcat);
    }
    if (bookNameRef.current != null)
      formData.append("bookname", bookNameRef.current.value);
    if (autherRef.current != null)
      formData.append("auther", autherRef.current.value);
    formData.append("category", mcat);
    formData.append("subcat", scat);
    formData.append("hrcType", hcat);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/searchELibraryList",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setNetworkList(response.data);
        setCurrentPage(0);
        setCurrentPage(1);
        setFilterMode(2);
      });
  };
  const FilterBookbyCat = (catID) => {
    /*setELibList(
      [...networkList].filter((item) => {
        return Number(item.BookCatID) === Number(catID);
      })
    );*/
    const formData = new FormData();
    let scat = subCat;
    let hcat = hrc;
    // formData.append("bookname", bookNameRef.current.value);
    // formData.append("auther", autherRef.current.value);
    if (searchRef.current != null)
      formData.append("search", searchRef.current.value);
    formData.append("category", catID);
    /*formData.append("subcat", scat);
    formData.append("hrcType", hcat);*/
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/searchELibraryList",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setELibList(response.data);
        setCurrentPage(0);
        setCurrentPage(1);
        setFilterMode(2);
      });
    /*setCurrentPage(0);
    setCurrentPage(1);
    setFilterMode(2);*/
  };
  const BookCatSelect = selectActivityCat.map(
    (bookCat) =>
      Number(bookCat.Level) === 0 && (
        <option key={bookCat.BookCatID} value={bookCat.BookCatID}>
          {language === "Thai"
            ? bookCat.CategoriesNameTH
            : bookCat.CategoriesNameEN.trim().length === 0
            ? bookCat.CategoriesNameTH
            : bookCat.CategoriesNameEN}
        </option>
      )
  );
  const SubBookCatSelect = selectActivityCat
    .filter((item) => {
      return Number(item.ParentBookCatID) === Number(mainCat);
    })
    .map(
      (bookCat) =>
        Number(bookCat.Level) === 1 && (
          <option key={bookCat.BookCatID} value={bookCat.BookCatID}>
            {language === "Thai"
              ? bookCat.CategoriesNameTH
              : bookCat.CategoriesNameEN.trim().length === 0
              ? bookCat.CategoriesNameTH
              : bookCat.CategoriesNameEN}
          </option>
        )
    );
  const HRCBookCatSelect = selectActivityCat
    .filter((item) => {
      return Number(item.ParentBookCatID) === Number(subCat);
    })
    .map(
      (bookCat) =>
        Number(bookCat.Level) === 2 && (
          <option key={bookCat.BookCatID} value={bookCat.BookCatID}>
            {language === "Thai"
              ? bookCat.CategoriesNameTH
              : bookCat.CategoriesNameEN.trim().length === 0
              ? bookCat.CategoriesNameTH
              : bookCat.CategoriesNameEN}
          </option>
        )
    );
  const readData = (id) => {
    props.history.push({
      pathname: "/authen/elibrary/Read/" + id,
      state: { id: id, userID: useID },
    });
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  function UpdateShare(id, type) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateElibraryShare",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {});
  }
  const Popup = (props) => {
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>
            x
          </span>
          <div className="share-tool text-center">
            <h4>แชร์ให้เพื่อน</h4>
            <FacebookShareButton
              url={"/authen/elibrary/Read/" + popupItem.BookID}
              quote={
                language === "Thai"
                  ? popupItem.BookName
                  : popupItem.BookNameEN.trim().length === 0
                  ? popupItem.BookName
                  : popupItem.BookNameEN
              }
              className="Demo__some-network__share-button"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.BookID, "Facebook");
              }}
            >
              <FacebookIcon size={50} round />
            </FacebookShareButton>
            <LineShareButton
              url={"/authen/elibrary/Read/" + popupItem.BookID}
              quote={
                language === "Thai"
                  ? popupItem.BookName
                  : popupItem.BookNameEN.trim().length === 0
                  ? popupItem.BookName
                  : popupItem.BookNameEN
              }
              className="Demo__some-network__share-button"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.BookID, "Line");
              }}
            >
              <LineIcon size={50} round />
            </LineShareButton>
            <EmailShareButton
              url={"/authen/elibrary/Read/" + popupItem.BookID}
              quote={
                language === "Thai"
                  ? popupItem.BookName
                  : popupItem.BookNameEN.trim().length === 0
                  ? popupItem.BookName
                  : popupItem.BookNameEN
              }
              className="shareEmail"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.BookID, "Email");
              }}
            >
              <EmailIcon size={50} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    );
  };
  const shareData = (item) => {
    setPopupItem(item);
    togglePopup();
  };
  const ELibsMode0 = () => {
    let data = <></>;
    let subdata1 = <></>;
    let subdata2 = <></>;
    let subdata3 = <></>;
    subdata1 = newElibList.map((item) => (
      <div className="col-lg-4 col-sm-6 col-xs-12 mt-3 mb-3" key={item.BookID}>
        <div className="card book-cover">
          <img src={item.ThumnailWeb} className="cover" alt="Bookmark cover" />
          <div
            className="share-button"
            onClick={(e) => {
              e.preventDefault();
              shareData(item);
            }}
          >
            <i className="fas fa-share-alt"></i>
          </div>
          <div
            className="card-body"
            onClick={(e) => {
              e.preventDefault();
              readData(item.BookID);
            }}
          >
            <h4 className="content-title">
              {language === "Thai"
                ? item.BookName
                : item.BookNameEN.trim().length === 0
                ? item.BookName
                : item.BookNameEN}
            </h4>
            <div className="mt-2 mb-2">
              <span className="time-ago">
                <i className="far fa-clock" />
                <p>
                  {" "}
                  {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}
                </p>
              </span>
              <span className="view-total float-right">
                <i className="fas fa-eye" />
                <p> {item.Views}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
    subdata2 = topViewElibList.map((item) => (
      <div className="col-lg-4 col-sm-6  col-xs-12 mt-3 mb-3" key={item.BookID}>
        <div className="card book-cover">
          <img src={item.ThumnailWeb} className="cover" alt="Bookmark cover" />
          <div
            className="share-button"
            onClick={(e) => {
              e.preventDefault();
              shareData(item);
            }}
          >
            <i className="fas fa-share-alt"></i>
          </div>
          <div
            className="card-body"
            onClick={(e) => {
              e.preventDefault();
              readData(item.BookID);
            }}
          >
            <h4 className="content-title">
              {language === "Thai"
                ? item.BookName
                : item.BookNameEN.trim().length === 0
                ? item.BookName
                : item.BookNameEN}
            </h4>
            <div className="mt-2 mb-2">
              <span className="time-ago">
                <i className="far fa-clock" />
                <p>
                  {" "}
                  {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}
                </p>
              </span>
              <span className="view-total float-right">
                <i className="fas fa-eye" />
                <p> {item.Views}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
    subdata3 = pinElibList.map((item) => (
      <div className="col-lg-4 col-sm-6  col-xs-12 mt-3 mb-3" key={item.BookID}>
        <div className="card book-cover">
          <img src={item.ThumnailWeb} className="cover" alt="Bookmark cover" />
          <div
            className="share-button"
            onClick={(e) => {
              e.preventDefault();
              shareData(item);
            }}
          >
            <i className="fas fa-share-alt"></i>
          </div>
          <div
            className="card-body"
            onClick={(e) => {
              e.preventDefault();
              readData(item.BookID);
            }}
          >
            <h4 className="content-title">
              {language === "Thai"
                ? item.BookName
                : item.BookNameEN.trim().length === 0
                ? item.BookName
                : item.BookNameEN}
            </h4>
            <div className="mt-2 mb-2">
              <span className="time-ago">
                <i className="far fa-clock" />
                <p>
                  {" "}
                  {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}
                </p>
              </span>
              <span className="view-total float-right">
                <i className="fas fa-eye" />
                <p> {item.Views}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
    data = (
      <>
        <div className="mt-4">
          <div className="sub-title">
            <h2 className="thai-header text-left">
              <span className="orang content-underline">ใหม่ล่าสุด</span>
            </h2>
          </div>
        </div>
        <div className="row">{subdata1}</div>

        <div className="mt-4">
          <div className="sub-title">
            <h2 className="thai-header text-left">
              <span className="orang content-underline">สถานการณ์เด่น</span>
            </h2>
          </div>
        </div>
        <div className="row">{subdata3}</div>

        <div className="mt-4">
          <div className="sub-title">
            <h2 className="thai-header text-left">
              <span className="orang content-underline">ยอดนิยม</span>
            </h2>
          </div>
        </div>
        <div className="row">{subdata2}</div>
      </>
    );
    return data;
  };
  const BookCatButtonList = selectActivityCat.map(
    (bookCat) =>
      Number(bookCat.Level) === 0 && (
        <div className="col-lg-2 col-sm-4 col-xs-6" key={bookCat.BookCatID}>
          <Link
            to={{ pathname: "/", state: { language: language } }}
            className="listCustom"
            onClick={(e) => {
              e.preventDefault();
              //alert(bookCat.BookCatID);
              FilterBookbyCat(bookCat.BookCatID);
              setActiveModeTitle(bookCat.CategoriesNameTH);
            }}
          >
            <span
              className="iconCustom"
              style={{
                backgroundImage: `url(${bookCat.ButtonIcon})`,
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundSize: "50px",
                backgroundPosition: "50% 28%, left top",
              }}
            ></span>
            <span className="textCustom">{bookCat.CategoriesNameTH}</span>
          </Link>
        </div>
      )
  );
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"คลังความรู้"}
          eheader={"E-library"}
          bannerkey="ELIB"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/elibrary/ELibrary",
                  state: { language: language },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setFilterMode(0);
                }}
              >
                {language === "Thai" ? "คลังความรู้" : "knowledge base"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              {filterMode === 2 && (
                <>
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        {activeModeTitle}
                      </span>
                    </h2>
                  </div>
                </>
              )}
              <form className="formContent formMargin">
                <div className="row">
                  {/* <div className="col-md-6 col-sm-6 col-xs-12">
                    <input
                      className="form-control mr-sm-2 search"
                      ref={bookNameRef}
                      type="text"
                      placeholder={language === "Thai" ? "ชื่อเรื่อง" : "Bookname"}
                      aria-label="Search"
                    />
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        searchData();
                      }}
                    >
                      <img
                        src={searchicon}
                        className="searchicon"
                        alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                      />
                    </a>
                  </div> */}

                  <div className="col-md-8 col-sm-8 col-xs-12">
                    <input
                      className="form-control mr-sm-2 search"
                      ref={searchRef}
                      type="text"
                      placeholder={language === "Thai" ? "คำค้นหา" : "Search"}
                      aria-label="Search"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          searchData();
                        }
                      }}
                    />
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        searchData();
                      }}
                    >
                      <img
                        src={searchicon}
                        className="searchicon"
                        alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                      />
                    </a>
                  </div>

                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <select
                      name="typeRef"
                      id="typeRef"
                      className="custom-select"
                      value={searchType}
                      onChange={(e) => {
                        setSearchType(e.currentTarget.value);
                      }}
                    >
                      {/* <option value="keyword">
                        {language === "Thai" ? "คำสำคัญ" : "Keyword"}
                      </option> */}
                      <option value="all">
                        {language === "Thai" ? "ค้นหาทั้งหมด" : "ALL"}
                      </option>
                      <option value="author">
                        {language === "Thai" ? "ชื่อผู้แต่ง" : "Author"}
                      </option>
                      <option value="bookname">
                        {language === "Thai" ? "ชื่อเรื่อง" : "Book name"}
                      </option>
                    </select>
                  </div>

                  {/* <div className="col-md-6 col-sm-6 col-xs-12">
                    <input
                      className="form-control mr-sm-2 search"
                      ref={autherRef}
                      type="text"
                      placeholder={
                        language === "Thai" ? "ชื่อผู้แต่ง" : "Auther"
                      }
                      aria-label="Search"
                    />
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        searchData();
                      }}
                    >
                      <img
                        src={searchicon}
                        className="searchicon"
                        alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                      />
                    </a>
                  </div> */}
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <select
                      name="catagoryRef"
                      id="catagoryRef"
                      className="custom-select"
                      value={mainCat}
                      onChange={(e) => {
                        searchData(1, e.currentTarget.value);
                      }}
                    >
                      <option value="0">
                        {language === "Thai" ? "หมวดหมู่หลัก" : "Main Category"}
                      </option>
                      {BookCatSelect}
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <select
                      name="subCategoryRef"
                      id="subCategoryRef"
                      className="custom-select"
                      value={subCat}
                      onChange={(e) => {
                        searchData(2, e.currentTarget.value);
                      }}
                    >
                      <option value="0">
                        {language === "Thai" ? "หมวดหมู่ย่อย" : "Sub Category"}
                      </option>
                      {SubBookCatSelect}
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <select
                      name="hrctypeRef"
                      id="hrctypeRef"
                      className="custom-select"
                      value={hrc}
                      onChange={(e) => {
                        searchData(3, e.currentTarget.value);
                      }}
                    >
                      <option value="0">
                        {language === "Thai"
                          ? "ประเด็นด้านสิทธิมนุษยชน"
                          : "Human rights issues"}
                      </option>
                      {HRCBookCatSelect}
                    </select>
                  </div>
                </div>
              </form>
              {filterMode === 0 && (
                <>
                  <div className="sub-title ">
                    <h2 className="thai-header text-left">
                      <span className="orang content-underline">
                        {language === "Thai" ? "หมวดหมู่" : "Category"}
                      </span>
                    </h2>
                  </div>
                  <div className="row">{BookCatButtonList}</div>
                </>
              )}
              {filterMode === 0 && (
                <>
                  <ELibsMode0 />
                </>
              )}
              {filterMode === 1 && (
                <>
                  <div className="col-12">
                    <span className="big-text badge badge-info">
                      {networkList.length}
                    </span>
                    <span className="big-text text-gray">
                      {" "}
                      {language === "Thai" ? "ผลลัพธ์" : "Results"}
                    </span>
                  </div>
                  <div className="col-12">
                    <div className="row">{SearchResultDIV}</div>
                  </div>
                  <div className="col-12">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={networkList.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </>
              )}
              {filterMode === 2 && (
                <>
                  <div className="col-12">
                    <span className="big-text badge badge-info">
                      {elibList.length}
                    </span>
                    <span className="big-text text-gray">
                      {" "}
                      {language === "Thai" ? "ผลลัพธ์" : "Results"}
                    </span>
                  </div>
                  <div className="row">
                    <ELibByCatDIV></ELibByCatDIV>
                  </div>
                  <div className="col-12">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={elibList.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </main>
      {isOpen && <Popup handleClose={togglePopup} />}
    </>
  );
}
