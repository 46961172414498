import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import { Rating } from "@material-ui/lab";
import CommentList from "components/CommentList";
import { authenticationService } from "services/authentication.service.js";
import {
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  FacebookIcon,
  EmailIcon,
  LineIcon,
} from "react-share";
import utils from "utils/utils";
import wordIcon from "assets/images/icon/word.svg";
import excelIcon from "assets/images/icon/excel.svg";
import pptIcon from "assets/images/icon/ppt.svg";
import pdfIcon from "assets/images/icon/pdf.svg";
import zipIcon from "assets/images/icon/zip.svg";
import unknowIcon from "assets/images/icon/unknowfile.svg";

export default function Read(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [elibData, SetElibData] = useState({});
  const [canRating, setCanRating] = useState(true);
  const [canBookmark, setCanBookmark] = useState(true);
  const [ratingavg, setRatingAVG] = useState(0);
  const [useID, setUserID] = useState(null);
  const [fileList, setFileList] = useState([]);
  const elibID = props.match.params.id;
  const shareUrl =
    /*process.env.REACT_APP_SITEURL*/ "https://merights.nhrc.or.th/authen/elibrary/Read/" +
    elibID;
  useEffect(() => {
    let userid = 0;
    if (currentUser != null) {
      userid = currentUser.UserID;
      setUserID(currentUser.UserID);
    }
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/elibrarybyID", {
        params: { id: elibID, userID: userid },
      })
      .then((response) => {
        SetElibData(response.data[0]);
        setFileList(response.data[0].filelist);
        setCanRating(response.data[0].canRate);
        setRatingAVG(response.data[0].Rating);
        setCanBookmark(response.data[0].canbookMark);

        setLoading(false);
      });
  }, []); /* <-- add this for run once*/
  const bookMark = (id) => {
    if (useID !== null) {
      const formData = new FormData();
      formData.append("id", elibData.BookID);
      formData.append("userID", currentUser.UserID);
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/BookmarkELibrary",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          setCanBookmark(!canBookmark);
        });
    }
  };
  const handleRating = (e, id, rating) => {
    if (useID !== null) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("userID", currentUser.UserID);
      formData.append("rating", rating);
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/updateElibraryRating",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          setCanRating(false);
          setRatingAVG(response.data.avg);
        });
    }
  };
  const readData = (id) => {
    const formData = new FormData();
    formData.append("id", elibData.BookID);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateElibraryViews",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        props.history.push({
          pathname: "/authen/elibrary/ViewELibContent",
          state: { id: elibData.BookID },
        });
      });
  };
  const BookContentList = () => {
  
    if (typeof elibData.listofContent !== "undefined") {
      return elibData.listofContent.map((content) => (
        <li className="list-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={content.ListOfContentID}>
          <span className="title" style={{ width: 80 + "%", border: 0 }}>
            {content.ContentTitle}
          </span>
          <span className="detail float-right" style={{ margin: 15 }}>
            {" "}
            {content.Page}
          </span>
        </li>
      ));
    } else return <></>;
  };
  function UpdateDownloadsCount() {
    const formData = new FormData();
    formData.append("id", elibData.BookID);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateElibraryDownload",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        props.history.push({
          pathname: "/authen/elibrary/ViewELibContent",
          state: { id: elibData.BookID },
        });
      });
  }
  function UpdateShare(id, type) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateElibraryShare",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => { });
  }
  const FileList = () => {
    
    if (typeof fileList !== "undefined") {
     
      return fileList.map(
        (fileItem) =>
          fileItem.AttachmentType !== "VDO" &&
          fileItem.AttachmentType !== "IMG" && (
            <div className="card-item" key={fileItem.AttachmentID}>
              <div className="row list-download">
                <div className="col-md-8 box-download-left">
                  <img
                    src={
                      fileItem.AttachmentType === "PDF"
                        ? pdfIcon
                        : fileItem.AttachmentType === "ZIP"
                          ? zipIcon
                          : fileItem.AttachmentType === "WORD"
                            ? wordIcon
                            : fileItem.AttachmentType === "PPT"
                              ? pptIcon
                              : fileItem.AttachmentType === "XLS"
                                ? excelIcon
                                : unknowIcon
                    }
                    className="icon"
                    alt="file icon"
                  />
                  <span className="file-name">{`${fileItem.FilenameOld}`}</span>
                </div>
                <div className="col-md-4 box-download-right float-right">
                    <span className="time-ago"><i className="far fa-clock" /></span>
                    <span>{`${utils.MariatoThaiDateStringShort(fileItem.CreatedDate)}`}</span>
                    |
                    <a href={fileItem.AttachmentPath} onClick={(e) => { UpdateDownloadsCount();}} className="view-download text-gray"><i className="fas fa-download" /></a>
                    <span>({utils.Bytes2Size(fileItem.Filesize)})</span>
                </div>
              </div>
            </div>
          )
      );
    } else return <></>;
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"คลังความรู้"}
          eheader={"E-library"}
          bannerkey="ELIB"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/elibrary/ELibrary",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "คลังความรู้" : "knowledge base"}
              </Link>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{
                  pathname: "/authen/elibrary/Read/" + elibData.BookID,
                  state: { id: elibData.BookID, userID: useID },
                }}
              >
                {elibData.BookName}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page elibRead">
        <section className="edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card book-cover mb2f">
                  <div className="card-body" style={{ marginLeft: -10 }}>
                    <img
                      src={elibData.ThumnailWeb}
                      className="cover"
                      alt="Bookmark cover"
                    />
                    <a
                      href={elibData?.ShortDetail ? elibData.ShortDetail : "/"}
                      className="btn btn-primary"
                      target="_blank"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   readData(elibData.BookID);
                      // }}
                      rel="noreferrer"
                    >
                      {/* BookCatID 59 =>> มัลติมีเดีย */}
                      {/* BookCatID 47 =>> สื่อสิ่งพิมพ์ */}
                      {elibData?.BookCatID ? elibData.BookCatID === 59 ? (language === "Thai" ? "รับชม" : "watch") : (language === "Thai" ? "อ่าน" : "read") : null}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="controlRowFlex">
                  <div>
                    <h1>
                      {elibData.BookName}
                      {canBookmark === true && (
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            bookMark(elibData.BookID);
                          }}
                        >
                          <i
                            className="ml-3 fas fa-bookmark text-silver"
                            style={{ fontSize: 26 }}
                          ></i>
                        </a>
                      )}
                      {canBookmark === false && (
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            bookMark(elibData.BookID);
                          }}
                        >
                          <i
                            className="ml-3 fas fa-bookmark text-gold"
                            style={{ fontSize: 26 }}
                          ></i>
                        </a>
                      )}
                    </h1>
                    {/*<div className="detailKnowledge">
                      {elibData.Author +
                        " " +
                        elibData.PublisherLocation +
                        " " +
                        elibData.Publisher +
                        " " +
                        Number(elibData.PublishYear).toString()}
                      </div>*/}
                    <div className="desDetail"
                      dangerouslySetInnerHTML={{ __html: elibData.Description }}
                    />
                    {
                      elibData.ShortDetail !== "" && (
                        <div className="controlTextFlex">
                          <div className="textFlex">
                            {language === "Thai" ? "แหล่งเชื่อมโยง" : "Source Data"}
                          </div>
                          <div className="rightFlex">: {elibData.ShortDetail}</div>
                        </div>
                      )
                    }
                    {/* <div className="controlTextFlex">
                      <div className="textFlex">
                        {language === "Thai" ? "แหล่งเชื่อมโยง" : "Source Data"}
                      </div>
                      <div className="rightFlex">: {elibData.ShortDetail}</div>
                    </div> */}
                    <div className="controlTextFlex">
                      <div className="textFlex">
                        {language === "Thai" ? "อ่าน" : "Read"}{" "}
                      </div>
                      <div className="rightFlex">: {elibData.Views}</div>
                    </div>
                  </div>
                  <div>
                    <div className="book-rating">
                      <hr />
                      <span className="rating-text">Ratings :</span>
                      <Rating
                        name="half-rating-read"
                        value={ratingavg}
                        precision={1.0}
                        readOnly={currentUser == null || canRating === false}
                        onChange={(event, newValue) => {
                          handleRating(event, elibData.BookID, newValue);
                        }}
                      />
                      <span className="rating-text ml-3">{ratingavg}</span>
                      <div className="share-tool float-right">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={
                            language === "Thai"
                              ? elibData.BookName
                              : elibData.BookNameEN.trim().length === 0
                                ? elibData.BookName
                                : elibData.BookNameEN
                          }
                          className="Demo__some-network__share-button"
                          onClick={(e) => {
                            e.preventDefault();
                            UpdateShare(elibData.BookID, "Facebook");
                          }}
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                        <LineShareButton
                          url={shareUrl}
                          quote={
                            language === "Thai"
                              ? elibData.BookName
                              : elibData.BookNameEN.trim().length === 0
                                ? elibData.BookName
                                : elibData.BookNameEN
                          }
                          className="Demo__some-network__share-button"
                          onClick={(e) => {
                            e.preventDefault();
                            UpdateShare(elibData.BookID, "Line");
                          }}
                        >
                          <LineIcon size={50} round />
                        </LineShareButton>
                        <EmailShareButton
                          url={shareUrl}
                          quote={
                            language === "Thai"
                              ? elibData.BookName
                              : elibData.BookNameEN.trim().length === 0
                                ? elibData.BookName
                                : elibData.BookNameEN
                          }
                          className="shareEmail"
                        /*onClick={(e) => { e.preventDefault(); UpdateShare(elibData.BookID, 'Email') }}*/
                        >
                          <EmailIcon size={50} round />
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-data contentMargin">
            <div className="container">
              <div className="tabbable-panel">
                <div className="tabbable-line">
                  <div className="nav nav-tabs ">
                    <a
                      href="#tab_default_1"
                      className="active"
                      data-toggle="tab"
                    >
                      {" "}
                      {language === "Thai" ? "รายละเอียด" : "Detail"}{" "}
                    </a>
                    { typeof elibData.listofContent !== "undefined" && elibData.listofContent.length > 0? 
                    (<a href="#tab_default_2" data-toggle="tab">
                      {" "}
                      {language === "Thai" ? "สารบัญ" : "List of contents"}{" "}
                    </a>)
                    : (<></>)}
                    {(typeof fileList !== "undefined" && fileList.length > 0) && 
                    (<a href="#tab_default_3" data-toggle="tab">
                      {" "}
                      {language === "Thai" ? "ดาวน์โหลด" : "Download"}{" "}
                    </a>)}
                    {elibData.Comment !== 2 && (
                    <a href="#tab_default_4" data-toggle="tab">
                      {" "}
                      {language === "Thai" ? "แสดงความเห็น" : "Comment"}{" "}
                    </a>
                    )}
                  </div>
                  
                  <div className="tab-content">
                    <div className="tab-pane active" id="tab_default_1">
                      <div className="card">
                        <div className="card-body padding2f">
                          <div className="listResponsive">
                            <ul className="content-list mt-0 mb-0 list2f">
                              <li className="list-item">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span className="title">
                                      {language === "Thai" ? "ชื่อเรื่อง" : "Title"}
                                    </span>{" "}
                                  </div>
                                  <div className="col-sm-9">
                                    <span className="detail">
                                      {" "}
                                      {language === "Thai"
                                        ? elibData.BookName
                                        : elibData.BookNameEN.trim().length === 0
                                          ? elibData.BookName
                                          : elibData.BookNameEN}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              { elibData.Author ? 
                              (
                                <li className="list-item">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <span className="title">
                                        {language === "Thai"
                                          ? "ชื่อผู้แต่ง"
                                          : "Author"}
                                      </span>{" "}
                                    </div>
                                    <div className="col-sm-9">
                                      <span className="detail">
                                        {" "}
                                        {elibData.Author}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )
                              : (<></>)
                              }
                               { elibData.CoAuthor && elibData.CoAuthor.trim() !== '-' ? 
                              (
                                <li className="list-item">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <span className="title">
                                        {language === "Thai"
                                          ? "ชื่อผู้แต่งร่วม"
                                          : "Co-Author"}
                                      </span>{" "}
                                    </div>
                                    <div className="col-sm-9">
                                      <span className="detail">
                                        {" "}
                                        {elibData.CoAuthor}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )
                                : (<></>)
                              }
                              { elibData.Translator && elibData.Translator.trim() !== '-' ? 
                              (
                                <li className="list-item">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <span className="title">
                                        {language === "Thai"
                                          ? "ชื่อผู้แปล"
                                          : "Translator"}{" "}
                                      </span>{" "}
                                    </div>
                                    <div className="col-sm-9">
                                      <span className="detail">
                                        {" "}
                                        {elibData.Translator}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )
                                : (<></>)
                              }
                              { elibData.CorporateAgency && elibData.CorporateAgency.trim() !== '-' ? 
                              (
                              <li className="list-item">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span className="title">
                                      {language === "Thai"
                                        ? "ชื่อหน่วยงานนิติบุคคล"
                                        : "Corporation"}{" "}
                                    </span>{" "}
                                  </div>
                                  <div className="col-sm-9">
                                    <span className="detail">
                                      {" "}
                                      {elibData.CorporateAgency}{" "}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              )
                              : (<></>)
                              }
                               { elibData.PublisherLocation || elibData.Publisher || elibData.PublishYear ? 
                              (
                              <li className="list-item">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span className="title">
                                      {language === "Thai"
                                        ? "พิมพ์ลักษณ์"
                                        : "Publisher"}
                                    </span>{" "}
                                  </div>
                                  <div className="col-sm-9">
                                    <span className="detail">
                                      {" "}
                                      {elibData.PublisherLocation +
                                        " " +
                                        elibData.Publisher +
                                        " " +
                                        Number(
                                          elibData.PublishYear
                                        ).toString()}{" "}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              )
                              : (<></>)
                              }
                              { elibData.Pages && elibData.CorporateAgency.trim() !== 0? 
                              (
                              <li className="list-item">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span className="title">
                                      {language === "Thai" ? "จำนวนหน้า" : "Pages"}{" "}
                                    </span>{" "}
                                  </div>
                                  <div className="col-sm-9">
                                    <span className="detail">
                                      {" "}
                                      {elibData.Pages}{" "}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              )
                              : (<></>)
                              }
                              { elibData.ISBN ? 
                              (
                              <li className="list-item">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span className="title">ISBN/ISSN </span>{" "}
                                  </div>
                                  <div className="col-sm-9">
                                    <span className="detail">
                                      {" "}
                                      {elibData.ISBN}{" "}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              )
                              : (<></>)
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    { typeof elibData.listofContent !== "undefined" && elibData.listofContent.length > 0?
                    (<div className="tab-pane" id="tab_default_2">
                      <div className="card">
                        <div className="card-body">
                          <ul className="content-list">
                            <BookContentList />
                          </ul>
                        </div>
                      </div>
                    </div>
                    )
                    : (<></>) }
                    {elibData.Comment !== 2 && (
                    <>
                      <div className="tab-pane" id="tab_default_3">
                        <div className="card">
                          <div className="card-body">
                            <FileList />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab_default_4">
                        <div className="sub-title"></div>
                          <CommentList
                            sourceid={elibData.BookID}
                            sourcetype={"BOOK"}
                            mode={elibData.Comment}
                          />
                      </div>
                    </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
