import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "utils/Pagination";
import Navbar from "components/Navbar/Navbar.js";
import { authenticationService } from "services/authentication.service.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import Footer from "components/Footer.js";
import moment from "moment";
const swal = withReactContent(Swal);
export default function SurveyList(props) {
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  console.log(currentUser);
  const [dataList, setDataList] = useState([]);
  const PageSize = 12;
  const [currentPage, setCurrentPage] = useState(0);
  function LoginAlert() {
    Swal.fire({
      icon: "error",
      title: "ผิดพลาด",
      text: "กรุณาเข้าสู่ระบบ",
    }).then((result) => {
      props.history.push({ pathname: "/" });
    });
  }
  useEffect(() => {
    if (currentUser === null) {
      LoginAlert();
    }
  }, []); /* <-- add this for run once*/
  useMemo(() => {
    setDataList([]);
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchSurvey", {
        params: { status: 1, checkdate: 1 },
      })
      .then((response) => {
        setDataList(response.data);
        setCurrentPage(0);
        setCurrentPage(1);
      });
  }, []);
  const readData = (item) => {
    if (item.Link_Type === "internal") {
      axios
        .post(process.env.REACT_APP_APIURL + "/api-web/saveSurveyView", {
          SurveyID: item.SurveyID,
          UserID: currentUser.UserID,
        })
        .then((response) => {
          props.history.push({
            pathname: "/survey/ViewSurvey/" + item.SurveyID,
            state: { id: item.SurveyID },
          });
        });
    } else {
      axios
        .post(process.env.REACT_APP_APIURL + "/api-web/saveSurveyView", {
          SurveyID: item.SurveyID,
          UserID: currentUser.UserID,
        })
        .then((response) => {
          window.location.href = `${item.Link_Question}`;
        });
    }
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return dataList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const DataDIV = currentTableData.map((item) => {
    console.log("item", item);
    return (
      <div className="col-md-12 survey-item" key={item.type + "-" + item.id}>
        <div className="row">
          {
            <>
              <div
                className="col-5 col-lg-6 surVeyCol"
                style={{ padding: "0px 38px" }}
              >
                <h4 className="content-title">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      readData(item);
                    }}
                  >
                    {language === "Thai"
                      ? item.SurveyNameTH
                      : item.SurveyNameEN.trim().length === 0
                      ? item.SurveyNameTH
                      : item.SurveyNameEN}
                  </a>
                </h4>
              </div>
              <div
                className="col-7 col-lg-6 surVeyCol text-right"
                style={{ padding: "0px 38px" }}
              >
                <span className="text">วันที่เผยแพร่</span>
                <span className="date">
                  <i className="far fa-clock" />
                  {moment(item.PublishedStartDate)
                    .add(543, "year")
                    .format("DD/MM/YYYY")}
                </span>
                <span className="view">
                  <span className="before"></span>
                  <i className="fas fa-eye" />
                  {item.View}
                </span>
              </div>
            </>
          }
        </div>
      </div>
    );
  });
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}

        <ContentBanner
          language={language}
          theader={"แบบสอบถาม"}
          eheader={"Surveys"}
          bannerkey="NEWS"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{ pathname: "/survey/SurveyList" }}
              >
                {language === "Thai" ? " แบบสอบถาม " : " Survey "}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile row custom-survey">
            <div className="news-activity-data">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">แบบสอบถาม</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-list">
              <div className="list">{DataDIV}</div>
            </div>
            <div className="col-12">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={dataList.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
