import React from "react";
//import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
//import Navbar from "../components/Navbar/Navbar.js";
import AdminHeader from "components/AdminHeader.js";
import Sidebar from "components/Sidebar.js";
import Footer from "components/Footer.js";
import { PrivateRoute } from "components/PrivateRoute.js";

import Login from "views/authen/Login.js";
import Register from "views/authen/Register.js";

import Profile from "views/admin/Profile.js";
import News from "views/admin/news/News.js";
import NewsCategory from "views/admin/news/NewsCategory.js";
import NewNewsCategory from "views/admin/news/NewNewsCategory.js";
import EditNewsCategory from "views/admin/news/EditNewsCategory.js";
import NewNews from "views/admin/news/NewNews.js";
import EditNews from "views/admin/news/EditNews.js";
import NewsReorder from "views/admin/news/NewsReorder.js";
import NewsPinReorder from "views/admin/news/NewsPinReorder.js";

import Activity from "views/admin/activity/Activity";
import NewActivity from "views/admin/activity/NewActivity";
import EditActivity from "views/admin/activity/EditActivity";
import ActivityCategory from "views/admin/activity/ActivityCategory";
import NewActivityCategory from "views/admin/activity/NewActivityCategory";
import EditActivityCategory from "views/admin/activity/EditActivityCategory";
import ActivityMember from "views/admin/activity/ActivityMember";
import NewActivityEnroll from "views/admin/activity/NewActivityEnroll";
import EditActivityEnroll from "views/admin/activity/EditActivityEnroll";
import ActivityReorder from "views/admin/activity/ActivityReorder";
import ActivityPinReorder from "views/admin/activity/ActivityPinReorder";

import NetworkORGList from "views/admin/network/NetworkORGList";
import NetworkPROCOUNList from "views/admin/network/NetworkPROCOUNList";
import EditNetwork from "views/admin/network/EditNetwork";
import ViewNetworkActivity from "views/admin/network/ViewNetworkActivity";
import ViewNetworkAppeal from "views/admin/network/ViewNetworkAppeal";
import PersonNetworkList from "views/admin/network/PersonNetworkList";
import EditPersonNetwork from "views/admin/network/EditPersonNetwork";

import NetworkMOUList from "views/admin/network/NetworkMOUList";
import NewNetworkMOU from "views/admin/network/NewNetworkMOU";
import EditNetworkMOU from "views/admin/network/EditNetworkMOU";

import EducationCoordinateList from "views/admin/network/EducationCoordinateList";
import NewEducationCoordinate from "views/admin/network/NewEducationCoordinate";
import EditEducationCoordinate from "views/admin/network/EditEducationCoordinate";

import BestPersonRewardList from "views/admin/network/BestPersonRewardList";
import NewBestPersonReward from "views/admin/network/NewBestPersonReward";
import EditBestPersonReward from "views/admin/network/EditBestPersonReward";
import BestPersonRewardReorder from "views/admin/network/BestPersonRewardReorder";

import RelationList from "views/admin/network/RelationList";
import NewRelation from "views/admin/network/NewRelation";
import EditRelation from "views/admin/network/EditRelation";
import RelationReorder from "views/admin/network/RelationReorder";

import ElibraryCategory from "views/admin/elibrary/ElibraryCategory";
import NewElibraryCategory from "views/admin/elibrary/NewElibraryCategory";
import EditElibraryCategory from "views/admin/elibrary/EditElibraryCategory";
import ElibraryList from "views/admin/elibrary/ElibraryList";
import NewElibrary from "views/admin/elibrary/NewElibrary";
import EditElibrary from "views/admin/elibrary/EditElibrary";
import ElibraryReorder from "views/admin/elibrary/ElibraryReorder";
import ElibraryPinReorder from "views/admin/elibrary/ElibraryPinReorder";

import ELearningCourseList from "views/admin/elearning/ELearningCourseList";
import NewELearningCourseType from "views/admin/elearning/NewELearningCourseType";
import EditELearningCourseType from "views/admin/elearning/EditELearningCourseType";
import ELearningList from "views/admin/elearning/ELearningList";
import NewESubject from "views/admin/elearning/NewESubject";
import EditESubject from "views/admin/elearning/EditESubject";
import ESubjectChapter from "views/admin/elearning/ESubjectChapter";

import ChapterQuestion from "views/admin/elearning/ChapterQuestion";
import ChapterContent from "views/admin/elearning/ChapterContent";
import ChapterVDO from "views/admin/elearning/ChapterVDO";
import ChapterLink from "views/admin/elearning/ChapterLink";
import EditChapterQuestion from "views/admin/elearning/EditChapterQuestion";
import EditChapterContent from "views/admin/elearning/EditChapterContent";
import EditChapterVDO from "views/admin/elearning/EditChapterVDO";
import EditChapterLink from "views/admin/elearning/EditChapterLink";
import ManageQuestionItem from "views/admin/elearning/ManageQuestionItem";
import NewQuestionItem from "views/admin/elearning/NewQuestionItem";
import EditQuestionItem from "views/admin/elearning/EditQuestionItem";
import SubChapterList from "views/admin/elearning/SubChapterList";

import MemberGroup from "views/admin/members/MemberGroup";
import NewMemberGroup from "views/admin/members/NewMemberGroup";
import EditMemberGroup from "views/admin/members/EditMemberGroup";
import ManageMemberGroup from "views/admin/members/ManageMemberGroup";
import Permission from "views/admin/members/Permission";
import MemberList from "views/admin/members/MemberList";
import MemberQuestion from "views/admin/members/MemberQuestion";
import NewMemberQuestion from "views/admin/members/NewMemberQuestion";
import EditMemberQuestion from "views/admin/members/EditMemberQuestion";
import NewMember from "views/admin/members/NewMember";
import EditMember from "views/admin/members/EditMember";

import MenuManagement from "views/admin/menu/MenuManagement";
import NewMenu from "views/admin/menu/NewMenu";
import EditMenu from "views/admin/menu/EditMenu";

import ContactType from "views/admin/contact/ContactType";
import NewContactUsType from "views/admin/contact/NewContactUsType";
import EditContactUsType from "views/admin/contact/EditContactUsType";
import ContactMessage from "views/admin/contact/ContactMessage";
import ViewContactMessage from "views/admin/contact/ViewContactMessage";
import ContactUs from "views/admin/contact/ContactUs";
import NewContactUs from "views/admin/contact/NewContactUs";
import EditContactUs from "views/admin/contact/EditContactUs";

import SettingContactUs from "views/admin/setting/SettingContactUs";
import MenuBanner from "views/admin/setting/MenuBanner";
import Banner from "views/admin/setting/Banner";
import NewBanner from "views/admin/setting/NewBanner";
import EditBanner from "views/admin/setting/EditBanner";
import ViewBanner from "views/admin/setting/ViewBanner";
import BannerReorder from "views/admin/setting/BannerReorder";
import PopupBeforeLogin from "views/admin/setting/PopupBeforeLogin";
import PopupAfterLogin from "views/admin/setting/PopupAfterLogin";
import NewPopup from "views/admin/setting/NewPopup";
import EditPopup from "views/admin/setting/EditPopup";

import NHRCTopic from "views/admin/setting/NHRCTopic";
import NewNHRCTopic from "views/admin/setting/NewNHRCTopic";
import EditNHRCTopic from "views/admin/setting/EditNHRCTopic";

import Survey from "views/admin/survey/Survey";
import NewSurvey from "views/admin/survey/NewSurvey";
import EditSurvey from "views/admin/survey/EditSurvey";
import PreviewSurvey from "views/admin/survey/PreviewSurvey";
import ViewSurveyResult from "views/admin/survey/ViewSurveyResult";

import Dashboard from "views/admin/report/Dashboard";
import PageReport from "views/admin/report/PageReport";
import UserReport from "views/admin/report/UserReport";
import SearchReport from "views/admin/report/SearchReport";
import NewsReport from "views/admin/report/NewsReport";
import ActivityReport from "views/admin/report/ActivityReport";
import ELibraryReport from "views/admin/report/ELibraryReport";
import ELearningReport from "views/admin/report/ELearningReport";
import QuestionaireReport from "views/admin/report/QuestionaireReport";
import NetworkTypeReport from "views/admin/report/NetworkTypeReport";
import NetworkReport from "views/admin/report/NetworkReport";
import ContactReport from "views/admin/report/ContactReport";

import Complain from "views/admin/complain/Complain";
import ComplainInfo from "views/admin/complain/ComplainInfo";
import ContactUsReorder from "views/admin/contact/ContactUsReorder";
import AffiliationList from "views/admin/network/AffiliationList";
import NewAffiliation from "views/admin/network/NewAffiliation";
import EditAffiliation from "views/admin/network/EditAffiliation";

import Comment from "views/admin/comment/Index";
import NhrcNearYou from "views/admin/report/NhrcNearYou";
import ComplainReport from "views/admin/complain/ComplainReport";

export default function Admin() {
  //console.log('Admin template')
  return (
    <>
      {/*<Navbar />*/}
      <AdminHeader />
      <Sidebar />
      <main className="admin-content">
        <div className="main-content">
          <Switch>
            <Route path="/authen/Register" exact component={Register} />
            <Route path="/authen/Login" exact component={Login} />
            <PrivateRoute path="/admin/Profile" exact component={Profile} />

            <PrivateRoute path="/admin/news/News" exact component={News} />
            <PrivateRoute
              path="/admin/news/NewsCategory"
              exact
              component={NewsCategory}
            />
            <PrivateRoute
              path="/admin/news/NewNewsCategory"
              exact
              component={NewNewsCategory}
            />
            <PrivateRoute
              path="/admin/news/EditNewsCategory"
              exact
              component={EditNewsCategory}
            />
            <PrivateRoute
              path="/admin/news/NewNews"
              exact
              component={NewNews}
            />
            <PrivateRoute
              path="/admin/news/EditNews"
              exact
              component={EditNews}
            />
            <PrivateRoute
              path="/admin/news/NewsReorder"
              exact
              component={NewsReorder}
            />
            <PrivateRoute
              path="/admin/news/NewsPinReorder"
              exact
              component={NewsPinReorder}
            />

            <PrivateRoute
              path="/admin/activity/Activity"
              exact
              component={Activity}
            />
            <PrivateRoute
              path="/admin/activity/NewActivity"
              exact
              component={NewActivity}
            />
            <PrivateRoute
              path="/admin/activity/EditActivity"
              exact
              component={EditActivity}
            />
            <PrivateRoute
              path="/admin/activity/ActivityCategory"
              exact
              component={ActivityCategory}
            />
            <PrivateRoute
              path="/admin/activity/NewActivityCategory"
              exact
              component={NewActivityCategory}
            />
            <PrivateRoute
              path="/admin/activity/EditActivityCategory"
              exact
              component={EditActivityCategory}
            />
            <PrivateRoute
              path="/admin/activity/ActivityMember"
              exact
              component={ActivityMember}
            />
            <PrivateRoute
              path="/admin/activity/NewActivityEnroll"
              exact
              component={NewActivityEnroll}
            />
            <PrivateRoute
              path="/admin/activity/EditActivityEnroll"
              exact
              component={EditActivityEnroll}
            />
            <PrivateRoute
              path="/admin/activity/ActivityReorder"
              exact
              component={ActivityReorder}
            />
            <PrivateRoute
              path="/admin/activity/ActivityPinReorder"
              exact
              component={ActivityPinReorder}
            />

            <PrivateRoute
              path="/admin/network/NetworkORGList"
              exact
              component={NetworkORGList}
            />
            <PrivateRoute
              path="/admin/network/NetworkPROCOUNList"
              exact
              component={NetworkPROCOUNList}
            />
            <PrivateRoute
              path="/admin/network/EditNetwork"
              exact
              component={EditNetwork}
            />
            <PrivateRoute
              path="/admin/network/ViewNetworkActivity/:networkid/:networkactivityid"
              exact
              component={ViewNetworkActivity}
            />
            <PrivateRoute
              path="/admin/network/ViewNetworkActivity"
              exact
              component={ViewNetworkActivity}
            />
            <PrivateRoute
              path="/admin/network/ViewNetworkAppeal/:networkid/:networkappealid"
              exact
              component={ViewNetworkAppeal}
            />
            <PrivateRoute
              path="/admin/network/ViewNetworkAppeal"
              exact
              component={ViewNetworkAppeal}
            />

            <PrivateRoute
              path="/admin/network/PersonNetworkList"
              exact
              component={PersonNetworkList}
            />
            <PrivateRoute
              path="/admin/network/EditPersonNetwork/:personnetworkid"
              exact
              component={EditPersonNetwork}
            />
            <PrivateRoute
              path="/admin/network/EditPersonNetwork"
              exact
              component={EditPersonNetwork}
            />

            <PrivateRoute
              path="/admin/network/NetworkMOUList"
              exact
              component={NetworkMOUList}
            />
            <PrivateRoute
              path="/admin/network/NewNetworkMOU"
              exact
              component={NewNetworkMOU}
            />
            <PrivateRoute
              path="/admin/network/EditNetworkMOU"
              exact
              component={EditNetworkMOU}
            />

            <PrivateRoute
              path="/admin/network/EducationCoordinateList"
              exact
              component={EducationCoordinateList}
            />
            <PrivateRoute
              path="/admin/network/NewEducationCoordinate"
              exact
              component={NewEducationCoordinate}
            />
            <PrivateRoute
              path="/admin/network/EditEducationCoordinate"
              exact
              component={EditEducationCoordinate}
            />

            <PrivateRoute
              path="/admin/network/BestPersonRewardList"
              exact
              component={BestPersonRewardList}
            />
            <PrivateRoute
              path="/admin/network/NewBestPersonReward"
              exact
              component={NewBestPersonReward}
            />
            <PrivateRoute
              path="/admin/network/EditBestPersonReward"
              exact
              component={EditBestPersonReward}
            />
            <PrivateRoute
              path="/admin/network/BestPersonRewardReorder"
              exact
              component={BestPersonRewardReorder}
            />
            <PrivateRoute
              path="/admin/network/RelationList"
              exact
              component={RelationList}
            />
            <PrivateRoute
              path="/admin/network/NewRelation"
              exact
              component={NewRelation}
            />
            <PrivateRoute
              path="/admin/network/EditRelation"
              exact
              component={EditRelation}
            />
            <PrivateRoute
              path="/admin/network/RelationReorder"
              exact
              component={RelationReorder}
            />
            <PrivateRoute
              path="/admin/network/AffiliationList"
              exact
              component={AffiliationList}
            />
            <PrivateRoute
              path="/admin/network/NewAffiliation"
              exact
              component={NewAffiliation}
            />
            <PrivateRoute
              path="/admin/network/EditAffiliation"
              exact
              component={EditAffiliation}
            />

            <PrivateRoute
              path="/admin/elibrary/ElibraryCategory"
              exact
              component={ElibraryCategory}
            />
            <PrivateRoute
              path="/admin/elibrary/NewElibraryCategory"
              exact
              component={NewElibraryCategory}
            />
            <PrivateRoute
              path="/admin/elibrary/EditElibraryCategory"
              exact
              component={EditElibraryCategory}
            />
            <PrivateRoute
              path="/admin/elibrary/ElibraryList"
              exact
              component={ElibraryList}
            />
            <PrivateRoute
              path="/admin/elibrary/NewElibrary"
              exact
              component={NewElibrary}
            />
            <PrivateRoute
              path="/admin/elibrary/EditElibrary"
              exact
              component={EditElibrary}
            />
            <PrivateRoute
              path="/admin/elibrary/ElibraryReorder"
              exact
              component={ElibraryReorder}
            />
            <PrivateRoute
              path="/admin/elibrary/ElibraryPinReorder"
              exact
              component={ElibraryPinReorder}
            />

            <PrivateRoute
              path="/admin/elearning/ELearningCourseList"
              exact
              component={ELearningCourseList}
            />
            <PrivateRoute
              path="/admin/elearning/NewELearningCourseType"
              exact
              component={NewELearningCourseType}
            />
            <PrivateRoute
              path="/admin/elearning/EditELearningCourseType"
              exact
              component={EditELearningCourseType}
            />
            <PrivateRoute
              path="/admin/elearning/ELearningList"
              exact
              component={ELearningList}
            />
            <PrivateRoute
              path="/admin/elearning/NewESubject"
              exact
              component={NewESubject}
            />
            <PrivateRoute
              path="/admin/elearning/EditESubject"
              exact
              component={EditESubject}
            />
            <PrivateRoute
              path="/admin/elearning/ESubjectChapter"
              exact
              component={ESubjectChapter}
            />
            <PrivateRoute
              path="/admin/elearning/ChapterQuestion"
              exact
              component={ChapterQuestion}
            />
            <PrivateRoute
              path="/admin/elearning/ChapterContent"
              exact
              component={ChapterContent}
            />
            <PrivateRoute
              path="/admin/elearning/ChapterVDO"
              exact
              component={ChapterVDO}
            />
            <PrivateRoute
              path="/admin/elearning/ChapterLink"
              exact
              component={ChapterLink}
            />
            <PrivateRoute
              path="/admin/elearning/EditChapterQuestion"
              exact
              component={EditChapterQuestion}
            />
            <PrivateRoute
              path="/admin/elearning/EditChapterContent"
              exact
              component={EditChapterContent}
            />
            <PrivateRoute
              path="/admin/elearning/EditChapterVDO"
              exact
              component={EditChapterVDO}
            />
            <PrivateRoute
              path="/admin/elearning/EditChapterLink"
              exact
              component={EditChapterLink}
            />
            <PrivateRoute
              path="/admin/elearning/ManageQuestionItem"
              exact
              component={ManageQuestionItem}
            />
            <PrivateRoute
              path="/admin/elearning/NewQuestionItem"
              exact
              component={NewQuestionItem}
            />
            <PrivateRoute
              path="/admin/elearning/EditQuestionItem"
              exact
              component={EditQuestionItem}
            />
            <PrivateRoute
              path="/admin/elearning/SubChapterList"
              exact
              component={SubChapterList}
            />

            <PrivateRoute
              path="/admin/members/MemberGroup"
              exact
              component={MemberGroup}
            />
            <PrivateRoute
              path="/admin/members/NewMemberGroup"
              exact
              component={NewMemberGroup}
            />
            <PrivateRoute
              path="/admin/members/EditMemberGroup"
              exact
              component={EditMemberGroup}
            />
            <PrivateRoute
              path="/admin/members/ManageMemberGroup"
              exact
              component={ManageMemberGroup}
            />
            <PrivateRoute
              path="/admin/members/Permission"
              exact
              component={Permission}
            />
            <PrivateRoute
              path="/admin/members/MemberList"
              exact
              component={MemberList}
            />
            <PrivateRoute
              path="/admin/members/MemberQuestion"
              exact
              component={MemberQuestion}
            />
            <PrivateRoute
              path="/admin/members/NewMemberQuestion"
              exact
              component={NewMemberQuestion}
            />
            <PrivateRoute
              path="/admin/members/EditMemberQuestion"
              exact
              component={EditMemberQuestion}
            />
            <PrivateRoute
              path="/admin/members/NewMember"
              exact
              component={NewMember}
            />
            <PrivateRoute
              path="/admin/members/EditMember"
              exact
              component={EditMember}
            />

            <PrivateRoute
              path="/admin/menu/MenuManagement"
              exact
              component={MenuManagement}
            />
            <PrivateRoute
              path="/admin/menu/NewMenu"
              exact
              component={NewMenu}
            />
            <PrivateRoute
              path="/admin/menu/EditMenu"
              exact
              component={EditMenu}
            />

            <PrivateRoute
              path="/admin/contact/ContactType"
              exact
              component={ContactType}
            />
            <PrivateRoute
              path="/admin/contact/NewContactUsType"
              exact
              component={NewContactUsType}
            />
            <PrivateRoute
              path="/admin/contact/EditContactUsType"
              exact
              component={EditContactUsType}
            />
            <PrivateRoute
              path="/admin/contact/ContactMessage"
              exact
              component={ContactMessage}
            />
            <PrivateRoute
              path="/admin/contact/ViewContactMessage"
              exact
              component={ViewContactMessage}
            />
            <PrivateRoute
              path="/admin/contact/ContactUs"
              exact
              component={ContactUs}
            />
            <PrivateRoute
              path="/admin/contact/NewContactUs"
              exact
              component={NewContactUs}
            />
            <PrivateRoute
              path="/admin/contact/EditContactUs"
              exact
              component={EditContactUs}
            />
            <PrivateRoute
              path="/admin/contact/ContactUsReorder"
              exact
              component={ContactUsReorder}
            />

            <PrivateRoute
              path="/admin/setting/SettingContactUs"
              exact
              component={SettingContactUs}
            />
            <PrivateRoute
              path="/admin/setting/MenuBanner"
              exact
              component={MenuBanner}
            />
            <PrivateRoute
              path="/admin/setting/Banner"
              exact
              component={Banner}
            />
            <PrivateRoute
              path="/admin/setting/NewBanner"
              exact
              component={NewBanner}
            />
            <PrivateRoute
              path="/admin/setting/EditBanner"
              exact
              component={EditBanner}
            />
            <PrivateRoute
              path="/admin/setting/ViewBanner"
              exact
              component={ViewBanner}
            />
            <PrivateRoute
              path="/admin/setting/BannerReorder"
              exact
              component={BannerReorder}
            />
            <PrivateRoute
              path="/admin/setting/PopupBeforeLogin"
              exact
              component={PopupBeforeLogin}
            />
            <PrivateRoute
              path="/admin/setting/PopupAfterLogin"
              exact
              component={PopupAfterLogin}
            />
            <PrivateRoute
              path="/admin/setting/NewPopup"
              exact
              component={NewPopup}
            />
            <PrivateRoute
              path="/admin/setting/EditPopup"
              exact
              component={EditPopup}
            />

            <PrivateRoute
              path="/admin/setting/NHRCTopic"
              exact
              component={NHRCTopic}
            />
            <PrivateRoute
              path="/admin/setting/NewNHRCTopic"
              exact
              component={NewNHRCTopic}
            />
            <PrivateRoute
              path="/admin/setting/EditNHRCTopic"
              exact
              component={EditNHRCTopic}
            />

            <PrivateRoute
              path="/admin/survey/Survey"
              exact
              component={Survey}
            />
            <PrivateRoute
              path="/admin/survey/NewSurvey"
              exact
              component={NewSurvey}
            />
            <PrivateRoute
              path="/admin/survey/EditSurvey"
              exact
              component={EditSurvey}
            />
            <PrivateRoute
              path="/admin/survey/PreviewSurvey"
              exact
              component={PreviewSurvey}
            />
            <PrivateRoute
              path="/admin/survey/ViewSurveyResult"
              exact
              component={ViewSurveyResult}
            />

            <PrivateRoute
              path="/admin/report/Dashboard"
              exact
              component={Dashboard}
            />
            <PrivateRoute
              path="/admin/report/PageReport"
              exact
              component={PageReport}
            />
            <PrivateRoute
              path="/admin/report/UserReport"
              exact
              component={UserReport}
            />
            <PrivateRoute
              path="/admin/report/SearchReport"
              exact
              component={SearchReport}
            />
            <PrivateRoute
              path="/admin/report/NewsReport"
              exact
              component={NewsReport}
            />
            <PrivateRoute
              path="/admin/report/ActivityReport"
              exact
              component={ActivityReport}
            />
            <PrivateRoute
              path="/admin/report/ELibraryReport"
              exact
              component={ELibraryReport}
            />
            <PrivateRoute
              path="/admin/report/ELearningReport"
              exact
              component={ELearningReport}
            />
            <PrivateRoute
              path="/admin/report/QuestionaireReport"
              exact
              component={QuestionaireReport}
            />
            <PrivateRoute
              path="/admin/report/NetworkTypeReport"
              exact
              component={NetworkTypeReport}
            />
            <PrivateRoute
              path="/admin/report/NetworkReport"
              exact
              component={NetworkReport}
            />
            <PrivateRoute
              path="/admin/report/ContactReport"
              exact
              component={ContactReport}
            />
            <PrivateRoute
              path="/admin/report/NhrcNearYou"
              exact
              component={NhrcNearYou}
            />

            <PrivateRoute
              path="/admin/complain/Complain"
              exact
              component={Complain}
            />
            <PrivateRoute
              path="/admin/complain/ComplainInfo"
              exact
              component={ComplainInfo}
            />
            <PrivateRoute
              path="/admin/complain/ComplainReport"
              exact
              component={ComplainReport}
            />

            <PrivateRoute
              path="/admin/comment/management"
              exact
              component={Comment}
            />

            <Redirect from="/admin" to="/admin/Profile" />
          </Switch>
        </div>
      </main>
      {/*<Footer />*/}
    </>
  );
}
