import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Steper from "components/Steper";
import {
    useMutation,
    useQuery,
    gql,
} from "@apollo/client";
const swal = withReactContent(Swal)

const USER_FOLLOW_COMPLAIN = gql`
    query Complaint($where: ComplainWhereUniqueInput!) {
  complain(where: $where) {
    ComplainID
    ComplainUUID
    ...ComplainFragment
  }
}

fragment ComplainFragment on Complain {
    UserID    
    allNHRCComplaintJobData {
        ComplaintJob {
            N_AllID
            N_Type
            N_ID
            N_ComplaintJobID
            Year
            active
        }
    }
}`;

export default function FollowComplain(props) {
    const [isLoading, setLoading] = useState(true);
    const [isReady, setReady] = useState(false);
    const [complainList, setComplainList] = useState([]);
    const [mode, setMode] = useState(0);
    const [activeComplain, setActiveComplain] = useState(null);
    const [userID, setUserID] = useState(null);
    const language = localStorage.getItem("language");
    const currentUser = authenticationService.currentUserValue;

    function LoginAlert() {
        Swal.fire({
            icon: 'error',
            title: 'ผิดพลาด',
            text: 'กรุณาเข้าสู่ระบบ',
        }).then((result) => {
            props.history.push({ pathname: "/" });
        })
    }
    useEffect(() => {

        if (currentUser === null) {
            LoginAlert();
            setLoading(false);
        }
        else {

            let userid = 0;
            userid = currentUser.UserID;
            setUserID(currentUser.UserID);
            utils.AccessInfo('ติดตามเรื่องร้องเรียน', '/authen/complain/Complain', 'F', currentUser);
            axios.get(process.env.REACT_APP_APIURL + '/api-web/complainbyUserID', { params: { userid: userid } })
                .then((response) => {
                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i].N_AllID = "";
                        response.data[i].N_ID = "";
                        response.data[i].Year = "";
                    }
                    setComplainList(response.data);
                    setLoading(false);
                })
        }


    }, []); /* <-- add this for run once*/

    function GetJobInfo(props) {
        const { data, error, loading } = useQuery(USER_FOLLOW_COMPLAIN, {
            variables: { "where": { "ComplainID": props.ComplainID } }, onCompleted: (data) => {
                if (typeof data !== 'undefined') {
                    var tmp = [...complainList];
                    var complainIndex = tmp.findIndex(function (c) {
                        return Number(c.ComplainID) == Number(props.ComplainID);
                    });

                    tmp[complainIndex].N_AllID = data.complain.allNHRCComplaintJobData.ComplaintJob.N_AllID;
                    tmp[complainIndex].N_ID = data.complain.allNHRCComplaintJobData.ComplaintJob.N_ID;
                    tmp[complainIndex].Year = data.complain.allNHRCComplaintJobData.ComplaintJob.Year;
                    tmp[complainIndex].N_ComplaintJobID = data.complain.allNHRCComplaintJobData.ComplaintJob.N_ComplaintJobID;
                    /*tmp[complainIndex].N_Type = 2;
                    tmp[complainIndex].WSComplianNo ='xxx/2564';
                    tmp[complainIndex].ExaminerReportNo='xxx/2564';*/
                    if (props.loop == props.max){
                        setTimeout(function() { //Start the timer
                            setReady(true)
                        }, 300)
                        
                    }
                }
            }
        });

        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;

        //console.log(complainList)

        return (<></>)
    }
    function GetAllJob() {
        if (isLoading == false) {
            return complainList.map((item, index) => (
                <GetJobInfo key={"job-" + index} ComplainID={item.ComplainID} loop={index} max={complainList.length - 1} />
            ))
        }
        else
            return (<></>)
    }
    const complain = complainList.filter((data) => {
        return data.N_AllID != null
    }).map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.N_AllID + "/" + item.Year} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));
    const acceptcomplain = complainList.filter((data) => {
        return data.N_ID != null
    }).map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.N_ID + "/" + item.Year} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));
    const protest = complainList.filter((data) => {
        return data.N_ComplaintJobID != null
    }).map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.N_ComplaintJobID + "/" + item.Year} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));

    if (isLoading) {
        return (<></>);
    }
console.log("isReady", isReady);

    return (
        <>
            <GetAllJob />
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner language={language} theader={"ร้องเรียน"} eheader={"Complaint"} bannerkey="CMPFOLLOW" banner={banner}
                    path={<>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/complain/FollowComplain" }}>{language === "Thai" ? "ร้องเรียน" : "Complain"}</Link>
                        <div className="d-none d-sm-block ml-auto">
                        <Link className="p-1 m-1 rounded text-white" style={{backgroundColor: '#36a9ae', lineHeight: 1.9}} target="_blank" to={{pathname: "http://180.180.241.28/online/progress_complaint/search"}}>
                            ค้นหาเลขรับเรื่อง/เลขคำร้อง/เลขรายงาน
                        </Link>
                        </div>
                    </>}
                />
            </div>
            <div className="m-auto p-1 d-flex justify-content-center d-sm-none" style={{maxWidth: 1160 }}>
                <Link className="rounded p-2 text-white" style={{backgroundColor: '#36a9ae',   lineHeight: 1.9}} target="_blank" to={{pathname: "http://180.180.241.28/online/progress_complaint/search"}}>
                ค้นหาเลขรับเรื่อง/เลขคำร้อง/เลขรายงาน
                </Link>
            </div>
            {(isReady === true) && (<main className="content-page">
                <div className="container">
                    <section className="edit-profile row custom-complain" >
                        <div className="news-activity-data mb-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header text-center"><span className="orang content-underline">ติดตามเรื่องร้องเรียน</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(mode === 0) && (<>
                            <div className="subheader  col-12 mt-4">แจ้งเรื่อง</div>
                            <div className="content-data">
                                <div className="row formContent">
                                    {complain}
                                </div>
                            </div>
                            <div className="subheader  col-12 mt-4">รับเรื่อง</div>
                            <div className="content-data">
                                <div className="row formContent">
                                    {acceptcomplain}
                                </div>
                            </div>
                            <div className="subheader col-12 mt-4">คำร้อง</div>
                            <div className="content-data">
                                <div className="row formContent">
                                    {protest}
                                </div>
                            </div>
                        </>)
                        }
                        {(mode === 1) && (<>
                            <div className="subheader col-12">เรื่อง : {activeComplain.ComplainTitle}</div>
                            <div className="listResponsive">
                                <ul className="content-list">
                                    <li className="list-item">
                                        <span className="title">
                                            เจ้าหน้าที่ออกเลขร้องเรียน
                                        </span>{" "}
                                        <span className="detail">
                                            {activeComplain.UpdatedBy === null ? "-" : activeComplain.UpdatedBy}
                                        </span>
                                    </li>
                                    <li className="list-item">
                                        <span className="title">
                                            เจ้าหน้าที่ผู้รับเรื่องร้องเรียน
                                        </span>{" "}
                                        <span className="detail">
                                            {activeComplain.CreatedBy === null ? "-" : activeComplain.CreatedBy}
                                        </span>
                                    </li>
                                    <li className="list-item">
                                        <span className="title">
                                            เบอร์โทรติดต่อเจ้าหน้าที่ผู้รับผิดชอบคำร้อง
                                        </span>{" "}
                                        <span className="detail">
                                            1377
                                        </span>
                                    </li>
                                    <li className="list-item">
                                        <span className="title">
                                            เลขที่รายงาน
                                        </span>{" "}
                                        <span className="detail">
                                            {activeComplain.ExaminerReportNo === null ? "-" : activeComplain.ExaminerReportNo}
                                        </span>
                                    </li>
                                    <li className="list-item">
                                        <span className="title">
                                            เจ้าหน้าที่ผู้รับเรื่องร้องเรียน
                                        </span>{" "}
                                        <span className="detail">
                                            {activeComplain.ExaminerAction === null ? "-" : activeComplain.ExaminerAction}
                                        </span>
                                    </li>
                                    <li className="list-item">
                                        <span className="title">
                                            ความคืบหน้าล่าสุด
                                        </span>{" "}
                                        <span className="detail">
                                            {(Number(activeComplain.N_Type) === 2) && (<><p>ออกเลขรายงาน </p></>)}
                                            {(Number(activeComplain.N_Type) != 2) && (activeComplain.CurrentProgress === null ? "-" : activeComplain.CurrentProgress)}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="row formContent" style={{ width: 100 + '%' }}>
                                <div className="col-12">
                                    <button
                                        type="button"
                                        style={{
                                            borderRadius: 22,
                                            lineHeight: 1.5 + "rem",
                                            fontSize: 1.5 + "rem",
                                            padding: "0.5rem 3rem",
                                        }}
                                        className="btn btn-info"
                                    >
                                        <h2 style={{ marginBottom: "0" }}>แบบสอบถาม</h2>
                                    </button>

                                    <input type="submit" className="fadeIn fourth" value="ย้อนกลับ" onClick={(e) => { e.preventDefault(); setMode(0); }} />
                                </div>
                            </div>
                        </>)
                        }
                    </section>
                </div>
            </main>
            )}
        </>
    );
};