import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import utils from 'utils/utils';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function SettingContactUs(props) {
    const currentUser = authenticationService.currentUserValue;
    const [orgNameTH, setOrgNameTH] = useState('');
    const [addressTH, setAddressTH] = useState('');
    const [orgNameEN, setOrgNameEN] = useState('');
    const [addressEN, setAddressEN] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [callCenter, setCallCenter] = useState('');
    const [facebook, setFacebook] = useState('');
    const [youtube, setYoutube] = useState('');
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);
    const [logoFile, setLogoFile] = useState(null);

    const [errors, setError] = useState({});
    const contactID = 1;
    useEffect(() => {
        //console.log(props.location.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSettingContact', { params: { id: 1 } })
            .then((response) => {
                let data = response.data[0];
                //console.log(data)
                setOrgNameTH(data.OrgNameTH);
                setAddressTH(data.AddressTH);
                setOrgNameEN(data.OrgNameEN);
                setAddressEN(data.AddressEN);
                setPhone(data.Phone);
                setFax(data.Fax);
                setEmail(data.Email);
                setCallCenter(data.CallCenter);
                if (data.Facebook !== null)
                    setFacebook(data.Facebook);
                if (data.Youtube !== null)
                    setYoutube(data.Youtube);
                setLatitude(data.Latitude);
                setLongitude(data.Longitude);

                if (data.LogoImage.trim().length > 0) {
                    const logoimageFile = {
                        file: data.LogoImage,
                        name: data.LogoImage.substring(data.LogoImage.lastIndexOf('/') + 1),
                        preview: data.LogoImage,
                        size: utils.getFileSize(data.LogoImage)
                    };
                    setLogoFile(logoimageFile);
                }
            });
    }, []); /* <-- add this for run once*/

    const rx_latln = /^[0-9.]+$/;
    const handleLatChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLatitude(e.target.value);
        }
    }
    const handleLongChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLongitude(e.target.value);
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (orgNameTH.trim().length === 0) {
            formIsValid = false;
            serrors["orgNameTH"] = "กรุณากรอกข้อมูล";
        }
        if (addressTH.trim().length === 0) {
            formIsValid = false;
            serrors["addressTH"] = "กรุณากรอกข้อมูล";
        }
        if (orgNameEN.trim().length === 0) {
            formIsValid = false;
            serrors["orgNameEN"] = "กรุณากรอกข้อมูล";
        }
        if (addressEN.trim().length === 0) {
            formIsValid = false;
            serrors["addressEN"] = "กรุณากรอกข้อมูล";
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณากรอกข้อมูล";
        }
        if (fax.trim().length === 0) {
            formIsValid = false;
            serrors["fax"] = "กรุณากรอกข้อมูล";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณากรอกข้อมูล";
        }
        if (latitude.length === 0) {
            formIsValid = false;
            serrors["latitude"] = "กรุณากรอกข้อมูล";
        }
        if (longitude.length === 0) {
            formIsValid = false;
            serrors["longitude"] = "กรุณากรอกข้อมูล";
        }
        if (logoFile === null) {
            formIsValid = false;
            serrors["logoFile"] = "กรุณาเลือกภาพโลโก้เพื่ออัปโหลด";
        }

        setError(serrors);
        //console.log(status)
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("contactID", contactID);
            formData.append("orgNameTH", orgNameTH);
            formData.append("addressTH", addressTH);
            formData.append("orgNameEN", orgNameEN);
            formData.append("addressEN", addressEN);
            formData.append("phone", phone);
            formData.append("fax", fax);
            formData.append("email", email);
            formData.append("callCenter", callCenter);
            formData.append("facebook", facebook);
            formData.append("youtube", youtube);
            formData.append("latitude", latitude);
            formData.append("longitude", longitude);
            formData.append("logoFile", logoFile.file);
            formData.append("userID", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateSiteContact', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/Profile" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const handleLogoimgOnDrop = (newImageFile, onChange) => {
        const imgFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLogoFile(imgFile);
    };
    const handleRemoveLogoImgFile = () => {
        const formData = new FormData();
        formData.append("folder", 'images');
        formData.append("url", logoFile.file);
        formData.append("fieldName", 'LogoImage');
        formData.append("id", contactID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteSiteContactLogoFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setLogoFile(null);
    };
    const LogoImgPreview = (
        (logoFile !== null) && (<img className="preview-upload" src={logoFile.preview} onChange={(e) => { e.target.src = URL.createObjectURL(logoFile); }} alt="Site logo" />)
    );
    const cancel = () => {
        props.history.push({ pathname: "/admin/Profile" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การตั้งค่าเว็บไซต์"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเว็บไซต์</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/setting/SettingContactUs" }}>{"การจัดการสถานที่ติดต่อ"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <label htmlFor="orgNameTH" className="required">ชื่อหน่วยงาน(TH)</label>
                                    <input type="text" className="form-control " id="orgNameTH" value={orgNameTH} name="orgNameTH" placeholder="กรุณากรอก" onChange={(e) => { setOrgNameTH(e.currentTarget.value) }} />
                                    <SError error={errors["orgNameTH"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="orgNameEN" className="required">ชื่อหน่วยงาน(EN)</label>
                                    <input type="text" className="form-control " id="orgNameEN" value={orgNameEN} name="orgNameEN" placeholder="กรุณากรอก" onChange={(e) => { setOrgNameEN(e.currentTarget.value) }} />
                                    <SError error={errors["orgNameEN"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="addressTH" className="required">ที่อยู่(TH)</label>
                                    <div>
                                        <textarea className="form-control" name="addressTH" id="addressTH" rows="3" value={addressTH} onChange={e => { setAddressTH(e.target.value) }}></textarea>
                                        <SError error={errors["addressTH"]} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="addressEN" className="required">ที่อยู่(EN)</label>
                                    <div>
                                        <textarea className="form-control" name="addressEN" id="addressEN" rows="3" value={addressEN} onChange={e => { setAddressEN(e.target.value) }}></textarea>
                                        <SError error={errors["addressEN"]} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="phone" className="required">โทรศัพท์</label>
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { setPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="fax" className="required">โทรสาร</label>
                                    <input type="text" className="form-control " id="fax" value={fax} name="fax" placeholder="กรุณากรอก" onChange={(e) => { setFax(e.currentTarget.value) }} />
                                    <SError error={errors["fax"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="callCenter">Call Center</label>
                                    <input type="text" className="form-control " id="callCenter" value={callCenter} name="callCenter" placeholder="กรุณากรอก" onChange={(e) => { setCallCenter(e.currentTarget.value) }} />
                                    <SError error={errors["callCenter"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">แผนที่ Google map</label>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="latitude" name="latitude" placeholder="กรุณากรอก ละติจูด,ลองจิจูด" value={latitude} onChange={(e) => { handleLatChange(e) }} />
                                    <SError error={errors["latitude"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="longitude" name="longitude" placeholder="กรุณากรอก ลองจิจูด" value={longitude} onChange={(e) => { handleLongChange(e) }} />
                                    <SError error={errors["longitude"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="facebook">Facebook</label>
                                    <input type="text" className="form-control " id="facebook" value={facebook} name="facebook" placeholder="กรุณากรอก" onChange={(e) => { setFacebook(e.currentTarget.value) }} />
                                    <SError error={errors["facebook"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="youtube">YouTube</label>
                                    <input type="text" className="form-control " id="youtube" value={youtube} name="youtube" placeholder="กรุณากรอก" onChange={(e) => { setYoutube(e.currentTarget.value) }} />
                                    <SError error={errors["youtube"]} />
                                </div>

                                <div className="col-12">
                                    <label className="required">Logo หน่วยงาน</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoimgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["logoFile"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoImgPreview}
                                                {(logoFile !== null) && (<SingleFileTable filedata={logoFile} removefn={handleRemoveLogoImgFile} />)}
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center margintop40" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
