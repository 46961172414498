import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import Navbar from "components/Navbar/Navbar.js";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import AttatchmentList from 'components/AttatchmentList';
import Dropzone from "react-dropzone";
import SingleFileTable from 'components/SingleFileTable';
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SError from 'components/SError';
import Captcha from "demos-react-captcha";
import 'assets/styles/custompage.css'
import {
    useMutation,
    //useQuery,
    gql,
} from "@apollo/client";
const swal = withReactContent(Swal)
export default function Complain(props) {
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    const [complainTitle, setComplainTitle] = useState('');
    const [comFirstName, setComFirstName] = useState('');
    const [comLastName, setComLastName] = useState('');
    const [comIdCard, setComIdCard] = useState('');
    const [comPassportNo, setComPassportNo] = useState('');

    const [comAdrNo, setComAdrNo] = useState('');
    const [comAdrMoo, setComAdrMoo] = useState('');
    const [comAdrSoi, setComAdrSoi] = useState('');
    const [comAdrRoad, setComAdrRoad] = useState('');
    const [comGeoID, setComGeoID] = useState(0);
    const [comAdrProvinceID, setComAdrProvinceID] = useState(0);
    const [comAdrDistrictID, setComAdrDistrictID] = useState(0);
    const [comAdrSubDistrictID, setComAdrSubDistrictID] = useState(0);
    const [comAdrRegionName, setComAdrRegionName] = useState('');
    const [comAdrProvinceName, setComAdrProvinceName] = useState('');
    const [comAdrDistrictName, setComAdrDistrictName] = useState('');
    const [comAdrSubDistrictName, setComAdrSubDistrictName] = useState('');
    const [comAdrPostCode, setComAdrPostCode] = useState('');
    const [comTel1, setComTel1] = useState('');
    const [comTel2, setComTel2] = useState('');
    const [complainTypeList, setComplainTypeList] = useState([]);
    const [complainTypeID, setComplainTypeID] = useState(0);
    const [complainDetail, setComplainDetail]= useState('');
    const [comEmail, setComEmail] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [orgFirstName, setOrgFirstName] = useState('');
    const [orgLastName, setOrgLastName] = useState('');
    const [orgAdrNo, setOrgAdrNo] = useState('');
    const [orgAdrMoo, setOrgAdrMoo] = useState('');
    const [orgAdrSoi, setOrgAdrSoi] = useState('');
    const [orgAdrRoad, setOrgAdrRoad] = useState('');
    const [orgGeoID, setOrgGeoID] = useState(0);
    const [orgAdrProvinceID, setOrgAdrProvinceID] = useState(0);
    const [orgAdrDistrictID, setOrgAdrDistrictID] = useState(0);
    const [orgAdrSubDistrictID, setOrgAdrSubDistrictID] = useState(0);
    const [orgAdrRegionName, setOrgAdrRegionName] = useState('');
    const [orgAdrProvinceName, setOrgAdrProvinceName] = useState('');
    const [orgAdrDistrictName, setOrgAdrDistrictName] = useState('');
    const [orgAdrSubDistrictName, setOrgAdrSubDistrictName] = useState('');
    const [orgAdrPostCode, setOrgAdrPostCode] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [orgPhone, setOrgPhone] = useState('');
    const [orgRemark, setOrgRemark] = useState('');

    const [complainFiles, setComplainFile] = useState([]);
    const [filespreview, setFilesPreview] = useState([]);

    const [allgeoList, SetAllGeoList] = useState([]);
    const [allprovinceList, SetAllProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);

    const [geoList, SetGeoList] = useState([]);
    const [provinceList, SetProvinceList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [geoList1, SetGeoList1] = useState([]);
    const [provinceList1, SetProvinceList1] = useState([]);
    const [districtList1, SetDistrictList1] = useState([]);
    const [subDistrictList1, SetSubDistrictList1] = useState([]);
    const [capcha, setcapcha] = useState(false);

    function LoginAlert() {
        Swal.fire({
            icon: 'error',
            title: 'ผิดพลาด',
            text: 'กรุณาเข้าสู่ระบบ',
        }).then((result) => {
            props.history.push({ pathname: "/" });
        })
    }
    useEffect(() => {
        if (currentUser === null) {
            LoginAlert();
        }
        else {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/memberbyID', { params: { id: currentUser.UserID } })
                .then((response) => {
                    setComFirstName(currentUser.FirstnameTH.trim().length === 0 ? currentUser.FirstnameEN : currentUser.FirstnameTH);
                    setComLastName(currentUser.LastnameTH.trim().length === 0 ? currentUser.astnameEN : currentUser.LastnameTH);
                    setComIdCard(currentUser.CitizenID);
                    setComPassportNo(currentUser.PassportNo === 'null' ? '' : currentUser.PassportNo);
                    setComEmail(response.data[0].Email);
                    setComTel1(response.data[0].Phone);
                });
            utils.AccessInfo('ร้องเรียน', '/authen/complain/Complain', 'F', currentUser);
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getComplainType')
                .then((response) => {
                    setComplainTypeList(response.data);
                    console.log(response.data)
                });
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getGeo')
                .then((response) => {
                    SetAllGeoList(response.data);
                    SetGeoList(response.data);
                    SetGeoList1(response.data);
                });
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
                .then((response) => {
                    SetAllProvinceList(response.data);
                    SetProvinceList(response.data);
                    SetProvinceList1(response.data);
                });
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
                .then((response) => {
                    SetDistrictList(response.data);
                    SetDistrictList1(response.data);
                    SetAllDistrictList(response.data);
                });
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
                .then((response) => {
                    SetSubDistrictList(response.data);
                    SetSubDistrictList1(response.data);
                    SetAllSubDistrictList(response.data);
                });
            Swal.fire({
                title: '<h1 style="margin-bottom:2px;"><strong style="font-size:35px;color:#202124">ข้อควรรู้เกี่ยวกับการร้องเรียน</strong></h1>',
                width: '1160px',
                html:
                '<div class="subheader col-12 mt-4" style="padding:15px 46px;font-size:30px;height:auto;text-align:left;">หน้าที่และอำนาจของ กสม. เกี่ยวกับการร้องเรียน</div>' +
                '<label style="font-size:27px;padding:0 46px;text-align:justify;">ตรวจสอบการละเมิดสิทธิมนุษยชน เสนอแนะนโยบายและข้อเสนอในการปรับปรุงกฏหมาย กฏ ระเบียบ หรือคำสั่งใดๆ ' +
                ' ต่อรัฐสภาและคณะรัฐมนตรีเพื่อส่งเสริมและคุ้มครองสิทธิมนุษยชน ส่งเสริมการศึกษา การวิจัย และการเผยแพร่ความรู้ด้านสิทธิมนุษยชน' +
                ' ส่งเสริมความร่วมมือและการประสานงานระหว่างหน่วยราชการ องค์การเอกชน และองค์การอื่นในด้านสิทธิมนุษยชน และภาคประชาสังคม</label>' +
                '<div class="subheader col-12 mt-4" style="padding:15px 46px;font-size:30px;height:auto;text-align:left;" >เงื่อนไขการรับเรื่องร้องเรียน</div>' +
                '<label style="font-size:27px;padding:0 46px;text-align:justify;width:100%;">ส่งเรื่องร้องเรียนโดยตรงถึงกรรมการสิทธิมนุษยชนแห่งชาติหรือเจ้าหน้าที่สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ' +
                ' ร้องเรียนด้วยวาจา ร้องเรียนทางไปรษณีย์ลงทะเบียนตอบรับ ทางจดหมายอิเล็กทรอนิกส์ หรือ ณ สถานที่ที่คณะกรรมการสิทธิมนุษยชนแห่งชาติกำหนด</label>' +
                '<div class="subheader col-12 mt-4" style="padding:15px 46px;font-size:30px;height:auto;text-align:left;" >การนำเข้าข้อมูลอันเป็นเท็จ ตาม พ.ร.บ. คอมพิวเตอร์</div>' +
                '<label style="font-size:27px;padding:0 46px;text-align:justify;">มาตรา 14 (1) นำเข้าสู่ระบบคอมพิวเตอร์ซึ่งข้อมูลคอมพิวเตอร์ที่บิดเบือน หรือปลอมไม่ว่าทั้งหมดหรือบางส่วน ' +
                ' หรือข้อมูลคอมพิวเตอร์อันเป็นเท็จ โดยประการที่น่าจะเกิดความเสียหาย แก่ประชาชน' +
                ' อันมิใช่การกระทำความผิดฐานหมิ่นประมาทตามประมวลกฎหมายอาญา ไม่เกิน 5 ปี ไม่เกิน 100,000 บาท</label>' +
                '<div class="subheader col-12 mt-4" style="padding:15px 46px;font-size:30px;height:auto;text-align:left;" >กรณีขอรับคำปรึกษาหรือคำแนะนำเกี่ยวกับเรื่องสิทธิมนุษยชน หรือกระบวนการยื่นเรื่องร้องเรียน</div>' +
                '<label style="font-size:27px;padding:0 46px;text-align:justify;display:block;">เปิดให้บริการวัน จันทร์ ถึง วันศุกร์ (ยกเว้นวันหยุดที่ทางราชการกำหนด ' +
                'และวันหยุดนักขัตฤกษ์) เวลา 8.30 - 16.30 น. ทางสายด่วน 1377 หรือ  ณ สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</label>' +
                '<div class="subheader col-12 mt-4" style="padding:15px 46px;font-size:30px;height:auto;text-align:left;" >กรณีที่ผู้ร้องต้องการปกปิดชื่อหรือการร้องเรียนโดยไม่ต้องการเปิดเผยชื่อ</div>' +
                '<label style="font-size:27px;padding:0 46px;text-align:justify;display:block;">สามารถยื่นเรื่องในช่องทางอื่น ๆ แทน เช่น ทางไปรษณีย์ ทางจดหมายอิเล็กทรอนิกส์ ทางสายด่วน 1377 เป็นต้น </label>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowOutsideClick: false,
                confirmButtonText:
                    '<div style="font-size:30px"><i class="far fa-check-square"></i> รับทราบ</div>',
                confirmButtonAriaLabel: 'Thumbs up, great!',
            }).then((result) => {
                setLoading(false);
            })
        }
        setLoading(false);

    }, []); /* <-- add this for run once*/
    const handleChangeGeo = GeoID => {
        /*SetProvinceList(allprovinceList?.filter(item => item['GeoID'] === Number(GeoID)))
        SetDistrictList(allDistrictList?.filter(item => item['GeoID'] === Number(GeoID)));
        SetSubDistrictList(allSubDistrictList?.filter(item => item['GeoID'] === Number(GeoID)));
        setComAdrProvinceID(0);
        setComAdrDistrictID(0);
        setComAdrSubDistrictID(0);*/
    };
    const handleChangeProvince = ProvinceID => {
        let province =allprovinceList?.filter(item => item['ProvinceID'] === Number(ProvinceID))
        setComGeoID(province[0].GeoID);
        SetDistrictList(allDistrictList?.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList(allSubDistrictList?.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        setComAdrDistrictID(0);
        setComAdrSubDistrictID(0);
    };
    const handleChangeDistrict = DistrictID => {
        SetSubDistrictList(allSubDistrictList?.filter(item => item['DistrictID'] === Number(DistrictID)));
        setComAdrSubDistrictID(0);
    };
    const handleChangeSubDistrict = SubDistrictID => {
        const subdistrictitem = allSubDistrictList?.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            setComAdrPostCode(subdistrictitem[0].PostCode);
        }
        else {
            setComAdrPostCode('');
        }
    };

    const ComplainTypeSelect = complainTypeList?.map((item) => (
        <option
            key={item.ComplainTypeID}
            value={item.ComplainTypeID}
        >
            {language === "Thai" ? item?.TextTH : item?.TextEN}
        </option>
    ));
    
    const GeoSelect = allgeoList?.map((province) => (
        <option
            key={province.GeoID}
            value={province.GeoID}
        >
            {province.GeoName}
        </option>
    ));
    const GeoSelect1 = allgeoList?.map((province) => (
        <option
            key={province.GeoID}
            value={province.GeoID}
        >
            {province.GeoName}
        </option>
    ));
    const ProvinceSelect = provinceList?.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList?.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList?.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));

    const handleChangeGeo1 = GeoID => {
        /*SetProvinceList1(allprovinceList?.filter(item => item['GeoID'] === Number(GeoID)))
        SetDistrictList1(allDistrictList?.filter(item => item['GeoID'] === Number(GeoID)));
        SetSubDistrictList1(allSubDistrictList?.filter(item => item['GeoID'] === Number(GeoID)));
        setOrgAdrProvinceID(0);
        setOrgAdrDistrictID(0);
        setOrgAdrSubDistrictID(0);*/
    };
    const handleChangeProvince1 = ProvinceID => {
        let province =allprovinceList?.filter(item => item['ProvinceID'] === Number(ProvinceID))
        setOrgGeoID(province[0].GeoID);
        SetDistrictList1(allDistrictList?.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList1(allSubDistrictList?.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        setOrgAdrDistrictID(0);
        setOrgAdrSubDistrictID(0);
    };
    const handleChangeDistrict1 = DistrictID => {
        SetSubDistrictList1(allSubDistrictList?.filter(item => item['DistrictID'] === Number(DistrictID)));
        setOrgAdrSubDistrictID(0);
    };
    const handleChangeSubDistrict1 = SubDistrictID => {
        const subdistrictitem = allSubDistrictList?.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            setOrgAdrPostCode(subdistrictitem[0].PostCode);
        }
        else {
            setOrgAdrPostCode('');
        }
    };
    const ProvinceSelect1 = provinceList1.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect1 = districtList1.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect1 = subDistrictList1.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    function chkDigitPid(p_iPID) {
        var total = 0;
        var iPID;
        var chk;
        var Validchk;
        iPID = p_iPID.replace(/-/g, "");
        Validchk = iPID.substr(12, 1);
        var j = 0;
        var pidcut;
        for (var n = 0; n < 12; n++) {
            pidcut = parseInt(iPID.substr(j, 1));
            total = (total + ((pidcut) * (13 - n)));
            j++;
        }

        chk = 11 - (total % 11);

        if (chk == 10) {
            chk = 0;
        } else if (chk == 11) {
            chk = 1;
        }
        if (chk == Validchk) {
            //alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
            return true;
        } else {
            //alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
            return false;
        }

    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (comIdCard.trim().length === 0 && comPassportNo.trim().length === 0) {
            formIsValid = false;
            swal.fire({
                icon: 'warning',
                title: "แจ้งให้ทราบ!",
                html: "<p style='text-align: left;font-size: 25px;'>หากท่านไม่มีข้อมูลบัตรประชาชนและเลขหนังสือเดินทาง<span style='color:#B00'> ให้ติดต่อเจ้าหน้าที่</sapn></p>",
                showCancelButton: true,
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {

            });
            return formIsValid;
        }
        if (complainTitle.trim().length === 0) {
            formIsValid = false;
            serrors["complainTitle"] = "กรุณาระบุชื่อเรื่อง";
        }
        if (comFirstName.trim().length === 0) {
            formIsValid = false;
            serrors["comFirstName"] = "กรุณาระบุชื่อผู้ร้อง";
        }
        if (comLastName.trim().length === 0) {
            formIsValid = false;
            serrors["comLastName"] = "กรุณาระบุนามสกุลผู้ร้อง";
        }
        if (comIdCard.trim().length === 0 && comPassportNo.trim().length === 0) {
            formIsValid = false;
            serrors["comIdCard"] = "กรุณาระบุหมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต";
            serrors["comPassportNo"] = "กรุณาระบุหมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต";
        }
        if (comIdCard.trim().length > 0) {
            if (chkDigitPid(comIdCard.trim()) === false) {
                formIsValid = false;
                serrors["comIdCard"] = "หมายเลขบัตรประชาชนไม่ถูกต้อง";
            }
        }
        if(complainDetail.trim().length === 0) {
            formIsValid = false;
            serrors["complainDetail"] = "กรุณาระบุรายละเอียด/พฤติการณ์ที่ต้องการร้องเรียน";
        }
        if (comAdrNo.trim().length === 0) {
            formIsValid = false;
            serrors["comAdrNo"] = "กรุณาระบุเลขที่บ้านผู้ร้อง";
        }
        if (Number(comGeoID) === 0) {
            formIsValid = false;
            serrors["comGeoID"] = "กรุณาระบุภาคผู้ร้อง";
        }
        if (Number(comAdrProvinceID) === 0) {
            formIsValid = false;
            serrors["comAdrProvinceID"] = "กรุณาระบุจังหวัดผู้ร้อง";
        }
        /*if (Number(comAdrDistrictID) === 0) {
            formIsValid = false;
            serrors["comAdrDistrictID"] = "กรุณาระบุอำเภอ/เขตผู้ร้อง";
        }
        if (Number(comAdrSubDistrictID) === 0) {
            formIsValid = false;
            serrors["comAdrSubDistrictID"] = "กรุณาระบุตำบล/แขวงผู้ร้อง";
        }
        if (comAdrPostCode.trim().length === 0) {
            formIsValid = false;
            serrors["comAdrPostCode"] = "กรุณาระบุรหัสไปรษณีย์ผู้ร้อง";
        }*/
        if (comTel1.trim().length === 0) {
            formIsValid = false;
            serrors["comTel1"] = "กรุณาระบุหมายเลขโทรศัพท์ผู้ร้อง";
        }
        if (Number(complainTypeID) === 0) {
            formIsValid = false;
            serrors["complainTypeID"] = "กรุณาระบุประเภทการร้องเรียน";
        }
        /*if (comEmail.trim().length === 0) {
            formIsValid = false;
            serrors["comEmail"] = "กรุณาระบุอีเมลผู้ร้อง";
        }*/

        if (organizationName.trim().length === 0) {
            formIsValid = false;
            serrors["organizationName"] = "กรุณาระบุชื่อ/สถาบันของผู้ถูกร้อง";
        }
        /*if (orgFirstName.trim().length === 0) {
            formIsValid = false;
            serrors["orgFirstName"] = "กรุณาระบุชื่อผู้ถูกร้อง";
        }
        if (orgLastName.trim().length === 0) {
            formIsValid = false;
            serrors["orgLastName"] = "กรุณาระบุนามสกุลผู้ถูกร้อง";
        }
        if (orgAdrNo.trim().length === 0) {
            formIsValid = false;
            serrors["orgAdrNo"] = "กรุณาระบุเลขที่บ้านผู้ถูกร้อง";
        }
        if (Number(orgGeoID) === 0) {
            formIsValid = false;
            serrors["orgGeoID"] = "กรุณาระบุภาคผู้ถูกร้อง";
        }
        if (Number(orgAdrProvinceID) === 0) {
            formIsValid = false;
            serrors["orgAdrProvinceID"] = "กรุณาระบุจังหวัดผู้ถูกร้อง";
        }
        if (Number(orgAdrDistrictID) === 0) {
            formIsValid = false;
            serrors["orgAdrDistrictID"] = "กรุณาระบุอำเภอ/เขตผู้ถูกร้อง";
        }
        if (Number(orgAdrSubDistrictID).length === 0) {
            formIsValid = false;
            serrors["orgAdrSubDistrictID"] = "กรุณาระบุตำบล/แขวงผู้ถูกร้อง";
        }
        if (orgAdrPostCode.trim().length === 0) {
            formIsValid = false;
            serrors["orgAdrPostCode"] = "กรุณาระบุรหัสไปรษณีย์ผู้ถูกร้อง";
        }
        if (orgPhone.trim().length === 0) {
            formIsValid = false;
            serrors["orgPhone"] = "กรุณาระบุหมายเลขโทรศัพท์ผู้ถูกร้อง";
        }
        if (orgEmail.trim().length === 0) {
            formIsValid = false;
            serrors["orgEmail"] = "กรุณาระบุอีเมลผู้ถูกร้อง";
        }*/
        
        // if (complainFiles.length === 0) {
        //     formIsValid = false;
        //     serrors["complainFiles"] = "กรุณาแนบไฟล์เอกสารประกอบการยื่นคำร้อง";
        // }


        if (capcha === false) {
            formIsValid = false;
            serrors["capcha"] = "กรุณากรอกรหัสความปลอดภัยให้ถูกต้อง";
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกรหัสความปลอดภัยให้ถูกต้อง!',
              })
        }
        console.log(serrors)
        setError(serrors);
        return formIsValid;
    };

    const USER_COMPLAIN = gql`
        mutation createComplain($data: ComplainCreateInputWithCaptcha!) {
        createComplain(data: $data) {
            ComplainID
    ComplainUUID
        }
      }
      `;

    const [userComplain] = useMutation(USER_COMPLAIN, {
        onError:()=>{console.log("oneerrrrr")},
        update: (proxy, mutationResult) => {
            console.log('mutationResult: ', mutationResult.data.login);
            if (mutationResult.data.login) {
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'บันทึกข้อมูลเรียบร้อย',
                    //footer: '<a href="">Why do I have this issue?</a>'
                }).then((value) => {
                    /*const user = JSON.stringify(mutationResult.data.login.member);
                    localStorage.setItem('user', user);
                    authenticationService.setuserprofile(mutationResult.data.login.member);
                    const currentUser = authenticationService.currentUserValue;

                    //console.log(Number(currentUser.GroupID) === 1);
                    if (currentUser.GroupID === 1) {
                        console.log('yes');
                        props.history.push("/admin");
                        //props.history.push("/admin/profile");
                        window.location.reload();
                    }
                    else if (currentUser.GroupID === 2) {
                        props.history.push("/authen");
                        //props.history.push("/authen/profile");
                        window.location.reload();
                    }*/
                    //window.location.href = "/Profile";
                });
            } else {
                console.log(mutationResult.error.message);
                swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: mutationResult.error.message,
                    //footer: '<a href="">Why do I have this issue?</a>'
                })
            }
        }

    });

    const saveData = (open) => {
        let docs = [];
        /*for (let i = 0; i < complainFiles.length; i += 1) {
            console.log(complainFiles[i])
            uploadToS3('attachment', complainFiles[i].name, complainFiles[i])
                .then((result) => {
                    console.log(result)
                    docs.push({ url: result.url, attachmentType: "COMPLAIN", filename: complainFiles[i].name, filenameNew: result.filename, size: complainFiles[i].size, mimetype: complainFiles[i].mimetype })
                });
        }
        console.log(docs)*/
        console.log(docs)
        
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("userID", currentUser.UserID);
            for (let i = 0; i < complainFiles.length; i += 1) {
                formData.append("fileList[]", complainFiles[i]);
            }

            axios.post(process.env.REACT_APP_APIURL + '/api-web/uploadComplainFile', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    docs = response.data;
                    console.log(docs);
                    userComplain({
                        variables: {
                            data: {
                                UserID: Number(currentUser.UserID), ComplainChannel: 1, SecurityLevel: 1, ComplainTypeID: Number(complainTypeID), ComplainTitle: complainTitle,
                                Detail: complainDetail, ComEmail: comEmail, ComFirstName: comFirstName, ComLastName: comLastName, ComIdCard: comIdCard, ComPassportNo: comPassportNo,
                                ComAdrNo: comAdrNo, ComAdrMoo: comAdrMoo, ComAdrSoi: comAdrSoi, ComAdrRoad: comAdrRoad, ComAdrRegionID: Number(comGeoID),
                                ComAdrProvinceID: Number(comAdrProvinceID), ComAdrDistrictID: Number(comAdrDistrictID), ComAdrSubDistrictID: Number(comAdrSubDistrictID),
                                ComAdrRegionName: comAdrRegionName, ComAdrProvinceName: comAdrProvinceName, ComAdrDistrictName: comAdrDistrictName, ComAdrSubDistrictName: comAdrSubDistrictName,
                                ComAdrPostCode: comAdrPostCode, ComTel1: comTel1, ComTel2: comTel2, OrganizationName: organizationName, OrgFirstName: orgFirstName, OrgLastName: orgLastName,
                                OrgAdrNo: orgAdrNo, OrgAdrMoo: orgAdrMoo, OrgAdrSoi: orgAdrSoi, OrgAdrRoad: orgAdrRoad, OrgAdrRegionID: Number(orgGeoID),
                                OrgAdrProvinceID: Number(orgAdrProvinceID), OrgAdrDistrictID: Number(orgAdrDistrictID), OrgAdrSubDistrictID: Number(orgAdrSubDistrictID),
                                OrgAdrRegionName: orgAdrRegionName, OrgAdrProvinceName: orgAdrProvinceName, OrgAdrDistrictName: orgAdrDistrictName, OrgAdrSubDistrictName: orgAdrSubDistrictName,
                                OrgAdrPostCode: orgAdrPostCode, OrgCountryID: 1, OrgPhone: orgPhone, OrgEmail: orgEmail, CreatedBy: Number(currentUser.UserID), Captcha: "1a2b3c",
                                SurveyLink: "",
                                attachMents: docs.map((info) => ({
                                    AttachmentType: info.attachmentType,
                                    AttachmentPath: info.url,
                                    FilenameOld: info.filename,
                                    FilenameNew: info.filenameNew,
                                    Filesize: Number.parseFloat(info.size),
                                    MimeType: info.mimetype,
                                }))
                            }
                        }
                    })
                        .then(function (data) {
                            console.log(data,"if success")
                            Swal.fire({
                                icon: 'success',
                                title: 'ส่งคำร้องเรียบร้อย',
                            }).then((result) => {
                                props.history.push({ pathname: "/authen/complain/FollowComplain" });
                            })
                            //     let timerInterval;
                            //     swal.fire({
                            //         title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                            //         timer: 1000, timerProgressBar: true,
                            //         didOpen: () => {
                            //             Swal.showLoading()
                            //             timerInterval = setInterval(() => {
                            //                 const content = Swal.getHtmlContainer()
                            //                 if (content) {
                            //                     const b = content.querySelector('b')
                            //                     if (b) {
                            //                         b.textContent = Swal.getTimerLeft()
                            //                     }
                            //                 }
                            //             }, 100)
                            //         },
                            //         willClose: () => {
                            //             clearInterval(timerInterval);
                            //         }
                            //     })
                        
                            //props.history.push({ pathname: "/" });
                        })
                        .catch(e => { console.log(e + "thissssssssss"); props.history.push({ pathname: "/" }); })
                });

            /**/
            /*const formData = new FormData();
            
            formData.append("complainTitle", complainTitle);
            formData.append("comFirstName", comFirstName);
            formData.append("comLastName", comLastName);
            formData.append("comIdCard", comIdCard);
            formData.append("comPassportNo", comPassportNo);
            formData.append("comAdrNo", comAdrNo);
            formData.append("comAdrMoo", comAdrMoo);
            formData.append("comAdrSoi", comAdrSoi);
            formData.append("comAdrRoad", comAdrRoad);
            formData.append("comGeoID", comGeoID);
            formData.append("comAdrProvinceID", comAdrProvinceID);
            formData.append("comAdrDistrictID", comAdrDistrictID);
            formData.append("comAdrSubDistrictID", comAdrSubDistrictID);
            formData.append("comAdrPostCode", comAdrPostCode);
            formData.append("comTel1", comTel1);
            formData.append("comTel2", comTel2);
            formData.append("complainTypeID", complainTypeID);
            formData.append("comEmail", comEmail);
            formData.append("organizationName", organizationName);
            formData.append("orgFirstName", orgFirstName);
            formData.append("orgLastName", orgLastName);
            formData.append("orgAdrNo", orgAdrNo);
            formData.append("orgAdrMoo", orgAdrMoo);
            formData.append("orgAdrSoi", orgAdrSoi);
            formData.append("orgAdrRoad", orgAdrRoad);
            formData.append("orgGeoID", orgGeoID);
            formData.append("orgAdrProvinceID", orgAdrProvinceID);
            formData.append("orgAdrDistrictID", orgAdrDistrictID);
            formData.append("orgAdrSubDistrictID", orgAdrSubDistrictID);
            formData.append("orgAdrPostCode", orgAdrPostCode);
            formData.append("orgEmail", orgEmail);
            formData.append("orgPhone", orgPhone);

            formData.append("userID", currentUser.UserID);
            for (let i = 0; i < complainFiles.length; i += 1) {
                formData.append("fileList[]", complainFiles[i]);
            }

            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveComplain', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {

                        //props.history.push({ pathname: "/" });
                    })
                });
*/
        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }

    };



    const cancel = () => {
        props.history.push({ pathname: "/" });
    };
    const handleRemoveComplainFileList = filename => {
        setComplainFile(complainFiles?.filter(item => item['name'] !== filename));
    };
    const ComplainFileList = complainFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2 controlbtn" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger controlbtn" onClick={(e) => { e.preventDefault(); handleRemoveComplainFileList(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const onCapChaChange = (value) => {
        console.log(value)
        setcapcha(value)
    }
    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner language={language} theader={"ร้องเรียน"} eheader={"Complaint"} bannerkey="COMPLAIN" banner={banner}
                    path={<>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/complain/Complain" }}>{language === "Thai" ? "ร้องเรียน" : "Complain"}</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section className="edit-profile row custom-complain" >
                        <div className="subheader col-12">ชื่อเรื่อง</div>
                        <div className="content-data">
                            <div className="row formContent custom-complain">
                                <div className="col-12">
                                    <input type="text" className="form-control" id="complainTitle" defaultValue={complainTitle} name="complainTitle" placeholder="ชื่อเรื่อง" autoComplete="off" onBlur={(e) => { setComplainTitle(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["complainTitle"]} />
                                </div>
                            </div>
                        </div>
                        <div className="subheader col-12 mt-4">ข้อมูลผู้ร้องเรียน</div>
                        <div className="content-data">
                            <div className="row formContent custom-complain">
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comFirstName" defaultValue={comFirstName} name="comFirstName" placeholder="ชื่อผู้ร้อง" autoComplete="off" onBlur={(e) => { setComFirstName(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comFirstName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comLastName" defaultValue={comLastName} name="comLastName" placeholder="นามสกุล" autoComplete="off" onBlur={(e) => { setComLastName(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comLastName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comIdCard" defaultValue={comIdCard} name="comIdCard" placeholder="รหัสบัตรประชาชน" autoComplete="off" maxLength={13} onBlur={(e) => { setComIdCard(e.currentTarget.value) }} />
                                    <SError error={errors["comIdCard"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comPassportNo" defaultValue={comPassportNo} name="comPassportNo" placeholder="เลขหนังสือเดินทาง" autoComplete="off" onBlur={(e) => { setComPassportNo(e.currentTarget.value) }} />
                                    <SError error={errors["comPassportNo"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comAdrNo" defaultValue={comAdrNo} name="comAdrNo" placeholder="บ้านเลขที่" autoComplete="off" onBlur={(e) => { setComAdrNo(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comAdrNo"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comAdrMoo" defaultValue={comAdrMoo} name="comAdrMoo" placeholder="หมู่ที่" autoComplete="off" onBlur={(e) => { setComAdrMoo(e.currentTarget.value) }} />
                                    <SError error={errors["comAdrMoo"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comAdrSoi" defaultValue={comAdrSoi} name="comAdrSoi" placeholder="ซอย" autoComplete="off" onBlur={(e) => { setComAdrSoi(e.currentTarget.value) }} />
                                    <SError error={errors["comAdrSoi"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comAdrRoad" defaultValue={comAdrRoad} name="comAdrRoad" placeholder="ถนน" autoComplete="off" onBlur={(e) => { setComAdrRoad(e.currentTarget.value) }} />
                                    <SError error={errors["comAdrRoad"]} />
                                </div>
                                
                                <div className="col-6">
                                    <select className="custom-select max-450" id="comAdrProvinceID" name="comAdrProvinceID" value={comAdrProvinceID} onChange={(e) => {
                                        setComAdrProvinceID(e.currentTarget.value);
                                        handleChangeProvince(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setComAdrProvinceName(e.nativeEvent.target[index].text);
                                    }} >
                                        <option key="0" value="0" >จังหวัด</option>
                                        {ProvinceSelect}
                                    </select>
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comAdrProvinceID"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="comGeoID" name="comGeoID" value={comGeoID} onChange={(e) => {
                                        setComGeoID(e.currentTarget.value);
                                        handleChangeGeo(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setComAdrRegionName(e.nativeEvent.target[index].text);
                                    }} >
                                        <option key="0" value="0" >ภาค</option>
                                        {GeoSelect}
                                    </select>
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comGeoID"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="comAdrDistrictID" name="comAdrDistrictID" value={comAdrDistrictID} onChange={(e) => {
                                        setComAdrDistrictID(e.currentTarget.value);
                                        handleChangeDistrict(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setComAdrDistrictName(e.nativeEvent.target[index].text);
                                    }}>
                                        <option key="0" value="0" >เขต/อำเภอ</option>
                                        {DistrictSelect}
                                    </select>
                                    <SError error={errors["comAdrDistrictID"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="comAdrSubDistrictID" name="comAdrSubDistrictID" value={comAdrSubDistrictID} onChange={(e) => {
                                        setComAdrSubDistrictID(e.currentTarget.value);
                                        handleChangeSubDistrict(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setComAdrSubDistrictName(e.nativeEvent.target[index].text);
                                    }}>
                                        <option key="0" value="0" >แขวง/ตำบล</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <SError error={errors["comAdrSubDistrictID"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comAdrPostCode" defaultValue={comAdrPostCode} name="comAdrPostCode" placeholder="รหัสไปรษณีย์" autoComplete="off" onBlur={(e) => { setComAdrPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["comAdrPostCode"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comEmail" defaultValue={comEmail} name="comEmail" placeholder="อีเมล" autoComplete="off" onBlur={(e) => { setComEmail(e.currentTarget.value) }} />
                                    <SError error={errors["comEmail"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="comTel1" defaultValue={comTel1} name="comTel1" placeholder="หมายเลขโทรศัพท์ 1" autoComplete="off" onBlur={(e) => { setComTel1(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["comTel1"]} />
                                </div>

                                <div className="col-6">
                                    <input type="text" className="form-control " id="comTel2" defaultValue={comTel2} name="comTel2" placeholder="หมายเลขโทรศัพท์ 2" autoComplete="off" onBlur={(e) => { setComTel2(e.currentTarget.value) }} />
                                    <SError error={errors["comTel2"]} />
                                </div>                                
                            </div>
                        </div>
                        <div className="subheader col-12 mt-4">ผู้ถูกร้องเรียน</div>
                        <div className="content-data">
                            <div className="row formContent custom-complain">
                                <div className="col-6">
                                    <input type="text" className="form-control " id="organizationName" defaultValue={organizationName} name="organizationName" placeholder="ชื่อองค์กร" autoComplete="off" onBlur={(e) => { setOrganizationName(e.currentTarget.value) }} />
                                    <span className="contentrequire"></span>
                                    <SError error={errors["organizationName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgFirstName" defaultValue={orgFirstName} name="orgFirstName" placeholder="ชื่อ" autoComplete="off" onBlur={(e) => { setOrgFirstName(e.currentTarget.value) }} />
                                    <SError error={errors["orgFirstName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgLastName" defaultValue={orgLastName} name="comLastName" placeholder="นามสกุล" autoComplete="off" onBlur={(e) => { setOrgLastName(e.currentTarget.value) }} />
                                    <SError error={errors["orgLastName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgAdrNo" defaultValue={orgAdrNo} name="orgAdrNo" placeholder="บ้านเลขที่" autoComplete="off" onBlur={(e) => { setOrgAdrNo(e.currentTarget.value) }} />
                                    <SError error={errors["orgAdrNo"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgAdrMoo" defaultValue={orgAdrMoo} name="orgAdrMoo" placeholder="หมู่ที่" autoComplete="off" onBlur={(e) => { setOrgAdrMoo(e.currentTarget.value) }} />
                                    <SError error={errors["orgAdrMoo"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgAdrSoi" defaultValue={orgAdrSoi} name="orgAdrSoi" placeholder="ซอย" autoComplete="off" onBlur={(e) => { setOrgAdrSoi(e.currentTarget.value) }} />
                                    <SError error={errors["orgAdrSoi"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgAdrRoad" defaultValue={orgAdrRoad} name="orgAdrRoad" placeholder="ถนน" autoComplete="off" onBlur={(e) => { setOrgAdrRoad(e.currentTarget.value) }} />
                                    <SError error={errors["orgAdrRoad"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="orgAdrProvinceID" name="orgAdrProvinceID" value={orgAdrProvinceID} onChange={(e) => {
                                        setOrgAdrProvinceID(e.currentTarget.value);
                                        handleChangeProvince1(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setOrgAdrProvinceName(e.nativeEvent.target[index].text);
                                    }} >
                                        <option key="0" value="0" >จังหวัด</option>
                                        {ProvinceSelect1}
                                    </select>
                                    <SError error={errors["orgAdrProvinceID"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="orgGeoID" name="orgGeoID" value={orgGeoID} onChange={(e) => {
                                        setOrgGeoID(e.currentTarget.value);
                                        handleChangeGeo1(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setOrgAdrRegionName(e.nativeEvent.target[index].text);
                                    }} >
                                        <option key="0" value="0" >ภาค</option>
                                        {GeoSelect1}
                                    </select>
                                    <SError error={errors["orgGeoID"]} />
                                </div>
                                
                                <div className="col-6">
                                    <select className="custom-select max-450" id="orgAdrDistrictID" name="orgAdrDistrictID" value={orgAdrDistrictID} onChange={(e) => {
                                        setOrgAdrDistrictID(e.currentTarget.value);
                                        handleChangeDistrict1(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setOrgAdrDistrictName(e.nativeEvent.target[index].text);
                                    }}>
                                        <option key="0" value="0" >เขต/อำเภอ</option>
                                        {DistrictSelect1}
                                    </select>
                                    <SError error={errors["orgAdrDistrictID"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select max-450" id="orgAdrSubDistrictID" name="orgAdrSubDistrictID" value={orgAdrSubDistrictID} onChange={(e) => {
                                        setOrgAdrSubDistrictID(e.currentTarget.value);
                                        handleChangeSubDistrict1(e.currentTarget.value);
                                        var index = e.nativeEvent.target.selectedIndex;
                                        setOrgAdrSubDistrictName(e.nativeEvent.target[index].text);
                                    }}>
                                        <option key="0" value="0" >แขวง/ตำบล</option>
                                        {SubDistrictSelect1}
                                    </select>
                                    <SError error={errors["orgAdrSubDistrictID"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgAdrPostCode" defaultValue={orgAdrPostCode} name="orgAdrPostCode" placeholder="รหัสไปรษณีย์" autoComplete="off" onBlur={(e) => { setOrgAdrPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["orgAdrPostCode"]} />
                                </div>
                                {/*<div className="col-6">
                                <input type="text" className="form-control " id="orgCountryID" value={orgCountryID} name="orgCountryID" placeholder="ประเทศ" onChange={(e) => { setOrgCountryID(e.currentTarget.value) }} />
                                <SError error={errors["orgCountryID"]} />
                    </div>*/}
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgEmail" defaultValue={orgEmail} name="orgEmail" placeholder="อีเมล" autoComplete="off" onBlur={(e) => { setOrgEmail(e.currentTarget.value) }} />
                                    <SError error={errors["orgEmail"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="orgPhone" defaultValue={orgPhone} name="orgPhone" placeholder="หมายเลขโทรศัพท์" autoComplete="off" onBlur={(e) => { setOrgPhone(e.currentTarget.value) }} />
                                    <SError error={errors["orgPhone"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="orgRemark" >หมายเหตุ</label>
                                    <div>
                                        <textarea className="form-control" name="orgRemark" id="orgRemark" rows="8" style={{ maxWidth: 100 + '%' }} value={orgRemark} onChange={e => { setOrgRemark(e.target.value) }}></textarea>
                                        <SError error={errors["orgRemark"]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subheader col-12 mt-4">รายละเอียด/พฤติการณ์ที่ต้องการร้องเรียน</div>
                        <div className="content-data">
                            <div className="row formContent custom-complain">
                                <div className="col-6">
                                    <select className="custom-select max-450" id="complainTypeID" name="complainTypeID" value={complainTypeID} onChange={(e) => {
                                        setComplainTypeID(e.currentTarget.value); var index = e.nativeEvent.target.selectedIndex;
                                    }}>
                                        <option key="0" value="" >ความประสงค์ของผู้ร้อง</option>
                                        {ComplainTypeSelect}
                                    </select>
                                    <SError error={errors["complainTypeID"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="complainDetail" >รายละเอียด</label>
                                    <div>
                                        <textarea className="form-control" name="complainDetail" id="complainDetail" rows="8" style={{ maxWidth: 100 + '%' }} value={complainDetail} onChange={e => { setComplainDetail(e.target.value) }}></textarea>
                                        <span className="contentrequire"></span>
                                        <SError error={errors["complainDetail"]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subheader col-12 mt-4">เอกสารแนบ</div>
                        <div className="content-data">
                            <div className="row formContent custom-complain">
                                <div className="col-12 file-custom">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setComplainFile([...complainFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >

                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <div className="noteText text-left " style={{ color: '#C00' }}>
                                                    หมายเหตุ: รองรับสกุลไฟล์ .docx .pdf .jpg .zip มีขนาดไม่เกิน 10 MB
                                                </div>
                                                <aside className="mt-4">
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {ComplainFileList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}                                        
                                    </Dropzone>
                                    <SError error={errors["complainFiles"]} />
                                    
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3"><h4 className="secure-head" style={{ display: 'block', width: 100 + '%' }}>รหัสความปลอดภัย</h4></div>
                                        <div className="col-6 mb-4">
                                            <Captcha onChange={onCapChaChange} onRefresh={onCapChaChange} placeholder="Enter captcha" />
                                        </div>
                                    </div>
                                    <SError error={errors["capcha"]} />
                                    <input type="submit" className="fadeIn fourth" value="ส่งข้อมูล" onClick={(e) => { e.preventDefault(); saveData(); }} />
                                    <input type="submit" className="fadeIn fourth" value="ยกเลิก" onClick={(e) => { e.preventDefault(); cancel(); }} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};